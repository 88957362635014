import React, { useCallback, useRef, useState } from "react";
import { Card } from "@shopify/polaris";

export default function SlideItem({ data, templateId, setTemplateId, i18n }) {
    const [selectedimg, seSelectedImg] = useState(data?.images?.[0]);
    return (
        <Card>
            <div className="top_image">
                <img alt="" width="100%" height="100%" src={selectedimg} />
            </div>
            <div className="thumbnail_image">
                {data?.images?.map((image) => {
                    return (
                        <img
                            alt=""
                            width="100%"
                            height="100%"
                            class={image == selectedimg ? "active" : ""}
                            src={image}
                            onClick={() => {
                                seSelectedImg(image);
                            }}
                        />
                    );
                })}
            </div>

            <div className="title_heading">{data?.title}</div>
            <div className="categories_data">

            </div>
            <div className="action_button">
                <button className="use_temp" onClick={() => { setTemplateId(data?.id) }}>Use Template</button>
                <button className="prev">{i18n.t("preview")} </button>
            </div>
        </Card>
    );
}