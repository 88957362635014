import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  ChoiceList,
  DataTable,
  DropZone,
  Filters,
  Frame,
  Icon,
  List,
  Modal,
  Page,
  Spinner,
  Stack,
  Tabs,
  Thumbnail,
  Toast,
} from '@shopify/polaris';
import Papa from 'papaparse';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory, useParams } from 'react-router-dom';
import swapIcon from '../../assets/images/swap.svg';

import moment from 'moment';
import { Pagination } from '@shopify/polaris';
import LoadingScreen from '../LoadingScreen';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import { DomainContext } from '../domain-context';
import SubscriptionView from '../SubscriptionView';
import {
  DeleteMajor,
  NoteMinor,
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';

import dayjs from 'dayjs';

const {
  Parser,
  transforms: { unwind },
} = require('json2csv');

const AffiliateOrders = (props) => {
  const {
    domain,
    shopifyDomain,
    i18n,
    detailView,
    setDetailView,
    userToken,
    formatDate,
  } = props;
  const history = useHistory();
  const { user_token } = useParams();
  // Start Tabs
  const [selectedTab, setSelectedTab] = useState(0);

  // End tabs
  const [sortOrder, setSortOrder] = useState(0);

  const [moneySpent, setMoneySpent] = useState(null);
  const [taggedWith, setTaggedWith] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [filterConsignments, setFilterConsignments] = useState([]);
  const [token, setToken] = useState();
  const [selectedDates, setSelectedDates] = useState();
  const handleFilterByDate = () => {
    setFilterConsignments([]);
  };

  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );

  const handleMoneySpentRemove = useCallback(() => setMoneySpent(null), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleMoneySpentRemove, handleQueryValueRemove, handleTaggedWithRemove]);

  const filters = [
    {
      key: 'taggedWith',
      label: 'Tagged with',
      filter: (
        <ChoiceList
          title="Tagged with"
          titleHidden
          choices={[
            { label: 'Cancelled', value: 'cancelled' },
            { label: 'Active', value: 'active' },
            { label: 'In Trial', value: 'inTrial' },
            { label: 'Future', value: 'future' },
          ]}
          selected={taggedWith || []}
          onChange={handleTaggedWithChange}
        // allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'moneySpent',
      label: 'Money spent',
      // filter: (
      //   <RangeSlider
      //     label="Money spent is between"
      //     labelHidden
      //     value={moneySpent || [0, 500]}
      //     prefix="$"
      //     output
      //     min={0}
      //     max={2000}
      //     step={1}
      //     onChange={handleMoneySpentChange}
      //   />
      // ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(moneySpent)) {
    const key = 'moneySpent';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = 'taggedWith';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'moneySpent':
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [status, setStatus] = useState('active');
  const [searchQuery, setSearchQuery] = useState();
  const [subOrdersheaderButton, setsubOrdersHeaderButton] = useState('pending');
  const [subscriptionOrders, setSubscriptionOrders] = useState([]);
  const [apiData, setApiData] = useState();
  const [tableheadings, setTableHeadings] = useState([]);
  const [formattedRows, setFormattedData] = useState([]);

  useEffect(() => {
    setPage(1);
    setTableHeadings([]);
    setStatus(subOrdersheaderButton);
  }, [subOrdersheaderButton]);

  const subOrdersheaderButtons = [
    {
      val: 'pending',
      name: 'Pending',
    },
    {
      val: 'processed',
      name: 'Processed',
    },
    {
      val: 'in_dispute',
      name: 'In Dispute',
    },
    {
      val: 'revoked',
      name: 'Revoked',
    },
  ];

  const fetchAffiliateOrders = () => {
    fetch(
      `/fetch_affiliate_referred_order?shopify_domain=${domain}&page=${page}&commission_status=${subOrdersheaderButton}`,
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        formatRows(data?.affiliate_referred_orders);
        setTotalPages(data?.total_pages);
        setApiData({ ...data });
      });
  };
  // -------------------

  useEffect(() => {
    fetchAffiliateOrders();
  }, [page, subOrdersheaderButton]);

  const handleFiltersQueryChange = (value) => {
    setQueryValue(value);
    if (value.length > 1) {
      setSearchQuery(value);
    } else {
      setSearchQuery();
    }
  };

  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);

  const [selectedCustomers, setselectedCustomers] = useState([]);
  const handleChangeCheckedCustomers = (newChecked, customerId) => {
    if (newChecked) {
      setselectedCustomers([...selectedCustomers, customerId]);
    } else {
      const index = selectedCustomers.indexOf(customerId);
      setselectedCustomers([
        ...selectedCustomers.slice(0, index),
        ...selectedCustomers.slice(index + 1),
      ]);
    }
  };
  //upper first letter
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  //each row in data table
  const formatRows = (rows) => {
    let rowData = [];

    rowData = rows?.map((row, i) => {
      return [
        <Checkbox
          label={formatDate(row?.order_date)}
          checked={selectedCustomers.indexOf(row?.id) != -1}
          onChange={(newChecked) =>
            handleChangeCheckedCustomers(newChecked, row?.id)
          }
        />,
        row?.order_status,
        row?.affiliate_url,
        row?.affiliate_commission,
        row?.earned_commission,
        row?.commission_status,
      ];
    });
    setTableHeadings([
      'Date',
      'Order Status',
      'Tracking url',
      'Affiliate Commission',
      'Earned Commission',
      'Commission status',
    ]);
    setFormattedData([...rowData]);
  };

  const [customers, setCustomers] = useState([]);
  const [filterCustomers, setFilterCustomers] = useState([]);
  const [summaryFilterCustomers, setSummaryFilterCustomers] = useState([]);

  const filterCustomersValue = () => {
    setFilterCustomers(customers);
  };

  useEffect(() => {
    if (customers) {
      filterCustomersValue();
    }
    // console.log('searchvalue: ', queryValue);
  }, [queryValue, taggedWith, customers]);

  const fields = [
    'id',
    'firstName',
    'lastName',
    'email',
    'communication',
    'phone',
    'language',
    'autoCollection',
    'status',
    'subscription',

    'additionalContacts.id',
    'additionalContacts.email',
    'additionalContacts.firstName',
    'additionalContacts.lastName',
    'additionalContacts.companyName',
    'additionalContacts.phone',

    'billingAddress.firstName',
    'billingAddress.lastName',
    'billingAddress.email',
    'billingAddress.company',
    'billingAddress.phone',
    'billingAddress.address1',
    'billingAddress.address2',
    'billingAddress.city',
    'billingAddress.language',
    'billingAddress.zipCode',
  ];
  const transforms = [
    unwind({ paths: ['additionalContacts', 'billingAddress'] }),
  ];
  // const exportCSV = () => {
  const dataSelected = [...filterCustomers].filter((item) =>
    selectedCustomers.find((select) => select === item.id)
  );
  const json2csvParser = new Parser({ fields, transforms });
  let csv = json2csvParser.parse(dataSelected);

  //

  // };

  //import customer by csv:
  const [active, setActive] = useState(false);
  const [checked, setChecked] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleCheckbox = useCallback((value) => setChecked(value), []);

  //upload file
  const [file, setFile] = useState();

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile((file) => acceptedFiles[0]),
    []
  );

  // const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFile = file && (
    <Stack>
      <Thumbnail size="small" alt={file.name} source={NoteMinor} />
      <div>
        {file.name} <Caption>{file.size} bytes</Caption>
      </div>
    </Stack>
  );
  //config

  const csvData = [];

  useEffect(() => {
    if (userToken !== user_token) {
      history.push(`/affiliateOrders/${userToken}`);
    }
  }, []);

  const RenderCustomHeader = () => {
    return (
      <div className="chargezen_custom_page_header">
        <div className="page_title_wrapper">
          <div className="back_button_wrapper">
            <div
              className="back-button pointer"
              onClick={() => {
                let toRedirect = '/';
                history.push(toRedirect);
              }}
            >
              <Icon source={MobileBackArrowMajor} color="base" />
            </div>
            <div class="text_wrapper page_heading_title_wrapper">
              <div
                class="Polaris-Header-Title__TitleAndSubtitleWrapper"
                style={{
                  width: '100%',
                }}
              >
                <div class="Polaris-Header-Title">
                  <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                    <span class="Polaris-TextStyle--variationStrong">
                      <div class="back_button_wrapper">
                        {' '}
                        <div
                          class="text_wrapper page_heading_title_wrapper"
                          style={{
                            width: '100%',
                          }}
                        >
                          <span>Affiliate Orders</span>
                        </div>
                      </div>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar_summary header_description">
          View orders attributed to your affiliate tracking url(s), earned
          commision, order status etc
        </div>
      </div>
    );
  };

  return (
    <>
      {
        <>
          <Frame>
            <Page>
              {<RenderCustomHeader />}
              <div className="without_header_card">
                <Card
                  actions={{
                    content: (
                      <div className="card_header_button_group">
                        <div className="card_header_buttons primary_button_group">
                          <HeaderButtons
                            headerButtons={subOrdersheaderButtons}
                            setHeaderButton={setsubOrdersHeaderButton}
                            headerButton={subOrdersheaderButton}
                          />
                        </div>
                      </div>
                    ),
                  }}
                >
                  <Card.Section>
                    <div
                      className="filter custom_filter_main"
                      style={{
                        display: 'none',
                        width: '100%',
                      }}
                    >
                      <Filters
                        queryValue={queryValue}
                        filters={filters}
                        appliedFilters={appliedFilters}
                        onQueryChange={handleFiltersQueryChange}
                        onQueryClear={handleQueryValueRemove}
                        onClearAll={handleFiltersClearAll}
                      />
                      <span className="separate"></span>
                      <div className="btn-sort">
                        <Button
                          onClick={() => {
                            setFilterCustomers(() => {
                              if (sortOrder === 1) {
                                setSortOrder(0);
                                return [...filterCustomers].sort(
                                  (a, b) =>
                                    new Date(b.updatedAt) -
                                    new Date(a.updatedAt)
                                );
                              } else {
                                setSortOrder(1);
                                return [...filterCustomers].sort(
                                  (a, b) =>
                                    new Date(a.updatedAt) -
                                    new Date(b.updatedAt)
                                );
                              }
                            });
                          }}
                        >
                          <img src={swapIcon} />
                          Sort By last update (Newest First)
                        </Button>
                      </div>
                    </div>
                    <div
                      className={
                        'table customer-subscription-tbl' + ' ' + selectedTab
                      }
                    >
                      <DataTable
                        columnContentTypes={[
                          'text',
                          'text',
                          'text',
                          'text',
                          'text',
                          'text',
                        ]}
                        headings={[
                          'Date',
                          'Order Status',
                          'Affiliate url',
                          'Affiliate Commission',
                          'Earned Commission',
                          'Commission status',
                        ]}
                        rows={formattedRows?.length > 0 ? formattedRows : []}
                      />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <Pagination
                        hasPrevious={hasPrevious}
                        onPrevious={() => {
                          setPage((prev) => +prev - 1);
                        }}
                        hasNext={hasMore}
                        onNext={() => {
                          setPage((prev) => +prev + 1);
                        }}
                      />
                    </div>
                  </Card.Section>
                </Card>
              </div>
            </Page>
          </Frame>
        </>
      }
    </>
  );
};

export default AffiliateOrders;
