import React, { useState, useCallback, useEffect, useContext } from 'react';

import {
  Card,
  DisplayText,
  FormLayout,
  Layout,
  Select,
  TextStyle,
  Stack,
  Icon,
  Button,
  Toast,
} from '@shopify/polaris';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import { gql, useQuery } from '@apollo/client';
import { DomainContext } from '../domain-context';
import PixelIcon from '../../assets/images/PixelIcon';
import { allLanguages } from '../HelperMethods/CommonValues';
const SetLanguage = ({ handleBack, i18n }) => {
  const { domain } = useContext(DomainContext);
  const supportedLanguages = allLanguages;
  const [selectLanguage, setSelectLanguage] = useState();
  const [frontEndSelectLanguage, setFrontEndSelectLanguage] = useState();
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );

  useEffect(() => {
    fetch(`/settings/current_language?shopify_domain=${domain}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectLanguage(data?.language);
        setFrontEndSelectLanguage(data?.front_end_language);
      });
  }, []);

  const updateLanguage = (field_name, selected_language) => {
    fetch('/settings/change_language', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        language_setting: { [field_name]: selected_language },
        shopify_domain: domain,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        setToastActive(true);
      });
  };
  return (
    <div className="product-extention">
      {toastActive && (
        <Toast content="Saved Successfully" onDismiss={toggleToastActive} />
      )}
      <Layout>
        <Layout.Section></Layout.Section>
        <Card
          title={
            <div className="heading_title">
              <PixelIcon />
              {i18n.t('language_setting')}
            </div>
          }
        >
          <Card.Section>
            <div className="billing">
              <FormLayout>
                <DisplayText size="small" element="h6">
                  <TextStyle variation="subdued">
                    {i18n.t('language_setting')}
                  </TextStyle>
                </DisplayText>
                <Select
                  label={i18n.t('select_language')}
                  value={selectLanguage}
                  onChange={(e) => setSelectLanguage(e)}
                  options={supportedLanguages}
                />
                <div className="button">
                  <Button
                    onClick={() => updateLanguage('language', selectLanguage)}
                  >
                    {i18n.t('save')}
                  </Button>
                </div>
              </FormLayout>
            </div>
          </Card.Section>
        </Card>
        <Card
          title={
            <div className="heading_title">
              <PixelIcon />
              {i18n.t('front_end_language_setting')}
            </div>
          }
        >
          <Card.Section>
            <div className="billing">
              <FormLayout>
                <DisplayText size="small" element="h6">
                  <TextStyle variation="subdued">
                    {i18n.t('front_end_language_setting')}
                  </TextStyle>
                </DisplayText>
                <Select
                  label={i18n.t('select_language')}
                  value={frontEndSelectLanguage}
                  onChange={(e) => setFrontEndSelectLanguage(e)}
                  options={supportedLanguages}
                />
                <div className="button">
                  <Button
                    onClick={() =>
                      updateLanguage(
                        'front_end_language',
                        frontEndSelectLanguage
                      )
                    }
                  >
                    {i18n.t('save')}
                  </Button>
                </div>
              </FormLayout>
            </div>
          </Card.Section>
        </Card>
      </Layout>
    </div>
  );
};

export default SetLanguage;
