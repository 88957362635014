import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Text, Card, Toast, ChoiceList, Page, PageActions, TextField, Layout, Banner, List, Frame, Select, Icon, Checkbox, Form, DropZone, Thumbnail, Stack, FormLayout, Button } from '@shopify/polaris';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import SearchProduct from '../../upsell/SearchProduct';
import Preview from '../../plans/Preview';

const CreateWaysToRedeemContent = (props) => {
    const { domain, wrContentFormData, setWrContentFormData, index, wrContentFieldErrors, setWrContentFieldErrors, i18n } = props;
    const [toastContent, setToastContent] = useState("");
    const [toastActive, setToastActive] = useState(false);
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);

    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const [fieldError, setFieldError] = useState({ title: '', body: '' });

    const handleChange = (val, key) => {
        let data = wrContentFormData;
        data[index][key] = val;
        setWrContentFormData([...data]);
        if (val) {
            let errors = wrContentFieldErrors;
            errors.splice(index, 1)
            setWrContentFieldErrors([...errors])
        }
    }

    return (
        <Layout>
            <Layout.Section>
                <Card sectioned>
                    <Form>
                        <FormLayout>
                            <FormLayout.Group>
                                <TextField
                                    label={i18n.t("title")}
                                    type="text"
                                    value={`${wrContentFormData?.[index]?.title}`}
                                    onChange={(e) => {
                                        handleChange(e, "title")
                                    }}
                                    error={wrContentFieldErrors[index]?.title}
                                    autoComplete="off"
                                />
                            </FormLayout.Group>

                            <FormLayout.Group>
                                <TextField
                                    label={i18n.t("body_html")}
                                    type="text"
                                    multiline={4}
                                    value={`${wrContentFormData?.[index]?.body}`}
                                    onChange={(e) => {
                                        handleChange(e, "body")
                                    }}
                                    error={wrContentFieldErrors[index]?.body}
                                    autoComplete="off"
                                />
                            </FormLayout.Group>
                        </FormLayout>
                    </Form>
                </Card>
            </Layout.Section>
        </Layout>

    )
}

export default CreateWaysToRedeemContent;