import { Frame } from '@shopify/polaris'
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ForecastInventory from './Components/ForecastInventory';
import "./style.css"
import SubscriptionCustomers from './Components/SubscriptionCustomers';
import SubscriptionReport from './Components/SubscriptionReport';

const Reports = (props) => {
    const { domain, dateFilter, setDateFilter, showExport, setShowExport, toastContent, setToastContent, selectedDates, customMainButtons, setCustomMainButtons, setHeaderButtons, setHeaderButton, headerButton, shopEmail } = props
    const { subTab, selectedTab } = useParams();
    const history = useHistory();
    const optionsLists = [
        {
            title: "Subscription customers",
            tab: "subscribers",
            desc: "A list of customers with subscription contracts showing contract status, products in their contract(s), upcoming billing dates, total spend & more."
        },
        {
            title: "Subscription contracts",
            tab: "subscriptions",
            desc: "Subscription contracts list with customer, shipping, payment, product, discount, and next order details."
        },
        {
            title: "Subscription products",
            tab: "subscription_products",
            desc: "List showing detailed subscription product information - pricing, quantities, & discounts applied."
        },
        {
            title: "Shopify checkout subscription orders",
            tab: "subscription_checkout_orders",
            desc: "A list of subscription orders placed through the Shopify checkout."
        },
        {
            title: "Subscription orders",
            tab: "subscription_processed_orders",
            desc: "A list of all subscription orders including customer information, shipping details, & payment information."
        },
        {
            title: "Chargezen transactions",
            tab: "transactions",
            desc: "A list of transactions created by Chargezen, including subscription details, customer information, order details & payment information."
        },
        {
            title: "Subscription skipped orders",
            tab: "subscription_skipped_orders",
            desc: "A list of skipped orders with customer details, product information, and payment data."
        },
        {
            title: "Upcoming subscription orders",
            tab: "subscription_upcoming_orders",
            desc: "A list of upcoming subscription orders scheduled for processing sorted by defined time frames."
        },
        {
            title: "Inventory forecast",
            tab: "inventory_forecast",
            desc: "View the sum of subscription products in scheduled subscription orders sorted by defined time frames."
        },
        {
            title: "Subscription logs",
            tab: "subscription_activity_logs",
            desc: "A list of subscriptions events, including timestamps, the source of the event, & any related data."
        },
    ];

    return (
        <div className='analytics_report_container'>
            <Frame>
                {
                    subTab ?
                        <>
                            {
                                subTab == "inventory_forecast" && (
                                    <ForecastInventory {...{ domain, dateFilter, setDateFilter, showExport, setShowExport, toastContent, setToastContent, selectedDates, customMainButtons, setCustomMainButtons, shopEmail }} />
                                )
                            }

                            {

                                subTab == "subscribers" && (
                                    <SubscriptionCustomers {...{ domain, dateFilter, setDateFilter, showExport, setShowExport, toastContent, setToastContent, selectedDates, customMainButtons, setCustomMainButtons, setHeaderButtons, setHeaderButton, headerButton, shopEmail }} />
                                )
                            }
                            {

                                subTab == "subscriptions" && (
                                    <SubscriptionReport {...{ domain, dateFilter, setDateFilter, showExport, setShowExport, toastContent, setToastContent, selectedDates, customMainButtons, setCustomMainButtons, setHeaderButtons, setHeaderButton, headerButton, shopEmail }} />
                                )
                            }

                        </>
                        :
                        <div className='report_options_list'>
                            {
                                optionsLists?.map((tab) => {
                                    return (
                                        <div className='option_list_wrapper' onClick={() => { history.push(`/analytics/${selectedTab}/${tab?.tab}`) }}>
                                            <div className='option_list_content'>
                                                <p><strong>{tab?.title}</strong></p>
                                                <p className='option_desc'>{tab?.desc}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }

            </Frame>

        </div >
    )
}

export default Reports
