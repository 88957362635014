import { Card, Icon, Layout, Stack, TextStyle } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import Discount from './Discount';
import DiscountForm from './DiscountForm';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import { t } from 'i18n-js';
import PixelIcon from '../../../../assets/images/PixelIcon';

const MainDiscount = ({ handleBack, i18n }) => {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = useCallback(() => {
    setShowForm(true);
  }, [setShowForm]);
  const handleCloseForm = useCallback(() => {
    setShowForm(false);
  }, [setShowForm]);

  return (
    <>
      <div>
        <div>
          <Card title=
            {
              <div className="heading_title">
                <PixelIcon />
                Discount Setting
              </div>
            } sectioned
          >
            {showForm ? (
              <DiscountForm i18n={i18n} handleCloseForm={handleCloseForm} />
            ) : (
              <Discount i18n={i18n} handleDiscountCodeForm={handleShowForm} />
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

export default MainDiscount;
