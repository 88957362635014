import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Card,
  DataTable,
  Frame,
  Icon,
  List,
  Page,
  ResourceItem,
  ResourceList,
  Stack,
  Toast,
} from '@shopify/polaris';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import React, { useCallback, useEffect, useState } from 'react';
import EditWaysToEarn from './EditWaysToEarn';
// import {
//     StoreMinor
// } from '@shopify/polaris-icons';
import { gql, useQuery } from '@apollo/client';
import LoadingScreen from '../LoadingScreen';
import { values } from 'lodash';

import PixelIcon from '../../assets/images/PixelIcon';
import EditWaysToRedeem from './EditWaysToRedeem';
import { useParams, useHistory } from 'react-router-dom';

const WaysToRedeemPoints = (props) => {
  const { i18n, domain, detailView, setDetailView } = props;
  const { edit_id } = useParams();
  const history = useHistory();
  const [editForm, setEditForm] = useState(false);
  const [waysToRedeem, setWaysToRedeem] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);
  const [titleOptions, setTitleOptions] = useState([]);
  const [allVariants, setAllVariants] = useState([]);
  const [formData, setFormData] = useState({
    id: '',
    title: '',
    appliesTo: '',
    prefixDiscountCode: '',
    collectionImages: [],
    minimumPurchaseAmount: '',
    pointsCost: '1',
    discountValue: '',
    summary: '',
    minPointsRequired: '',
    maxPointsRequired: '',
    maxShippingAmount: '',
    customerRedeemsIncrement: '',
    customerGets: '',
    variantImages: [],
  });
  const [pointProgramStatus, setPointProgramStatus] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState([]);

  const GET_REDEEM = gql`
    query {
      fetchWaysToRedeemPoints {
        waysToRedeem {
          id
          title
          appliesTo
          status
          prefixDiscountCode
          minimumPurchaseAmount
          pointsCost
          discountValue
          minPointsRequired
          summary
          minPointsRequired
          maxPointsRequired
          maxShippingAmount
          customerRedeemsIncrement
          customerGets
          iconUrl
          collectionImages {
            collectionId
            collectionTitle
            _destroy
            products {
              productId
              image
              _destroy
            }
          }
          variantImages {
            variantId
            image
            title
            price
          }
        }
        allTitles
        pointProgramStatus
      }
    }
  `;

  const {
    data: redeemData,
    loading: redeemLoading,
    error: redeemError,
    refetch: redeemRefetch,
  } = useQuery(GET_REDEEM, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    redeemRefetch();
  }, []);

  useEffect(() => {
    if (redeemData?.fetchWaysToRedeemPoints?.waysToRedeem?.length > 0) {
      setWaysToRedeem(redeemData?.fetchWaysToRedeemPoints?.waysToRedeem);
      setSelectedCollections(
        redeemData?.fetchWaysToRedeemPoints?.collectionImages
      );
    }
    if (redeemData?.fetchWaysToRedeemPoints?.allTitles) {
      let allTitles = JSON.parse(
        redeemData?.fetchWaysToRedeemPoints?.allTitles
      );
      let allTitleArr = [];
      Object.keys(allTitles)?.map((k, v) => {
        allTitleArr.push({ label: `${k}`, value: `${k}` });
      });
      setTitleOptions(allTitleArr);
    }
    if (redeemData?.fetchWaysToRedeemPoints?.pointProgramStatus) {
      setPointProgramStatus(
        redeemData?.fetchWaysToRedeemPoints?.pointProgramStatus
      );
    }
  }, [redeemData]);

  useEffect(() => {
    if (waysToRedeem?.length > 0) {
      setRowData(waysToRedeem);
    }
  }, [waysToRedeem]);

  useEffect(() => {
    fetch('/togglePointsProgram', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        active: pointProgramStatus,
        shopify_domain: domain,
      }),
    });
  }, [pointProgramStatus]);

  const formatRedeemData = (rows) => {
    return rows?.map((row) => {
      return [
        <ul style={{ listStyle: 'none' }}>
          <li>{row.title}</li>
        </ul>,
        row?.status ? (
          <Badge status="success">{i18n.t('active')}</Badge>
        ) : (
          <Badge>{i18n.t('disabled')}</Badge>
        ),
        `${row?.pointsCost} points`,
        <div className="primary_button_wrapper">
          <Button
            primary
            onClick={() => {
              history.push(`/pointsProgram/waysToRedeem/${row.id}`);
            }}
          >
            Edit
          </Button>
        </div>,
      ];
    });
  };

  useEffect(() => {
    if (edit_id === 'createNew') {
      setFormData({
        id: '',
        title: titleOptions[0]?.value || 'Amount discount',
        appliesTo: 'entire_order',
        prefixDiscountCode: '',
        collectionImages: [],
        minimumPurchaseAmount: '',
        pointsCost: '1',
        discountValue: '0',
        summary: '',
        minPointsRequired: '',
        maxPointsRequired: '',
        maxShippingAmount: '',
        customerRedeemsIncrement: '',
        customerGets: '',
        variantImages: [],
      });
      setEditForm(true);
      setAllVariants([]);
      setDetailView(false);
    }
    else if (edit_id && waysToRedeem.length > 0) {
      const row = waysToRedeem?.find((e) => e.id === edit_id);
      setFormData({
        id: row?.id,
        title: row?.title,
        appliesTo: row?.appliesTo,
        prefixDiscountCode: row?.prefixDiscountCode,
        minimumPurchaseAmount: row?.minimumPurchaseAmount,
        pointsCost: `${row?.pointsCost}`,
        discountValue: `${row?.discountValue}`,
        summary: row?.summary,
        minPointsRequired: row?.minPointsRequired,
        maxPointsRequired: row?.maxPointsRequired,
        maxShippingAmount: row?.maxShippingAmount,
        customerRedeemsIncrement: row?.customerRedeemsIncrement,
        customerGets: row?.customerGets,
        variantImages: row?.variantImages,
        iconUrl: row?.iconUrl,
      });
      setAllVariants(row?.variantImages || []);
      setDetailView(false);
      setEditForm(true);
    }
  }, [edit_id, waysToRedeem]);

  return (
    <>
      <Frame>
        {saveSuccess && (
          <Toast
            content={i18n.t('points_programs.ways_to_earn_points_saved')}
            onDismiss={hideSaveSuccess}
          />
        )}
        {formErrors.length > 0 && (
          <>
            <Banner
              title={i18n.t('points_programs.ways_to_earn_points_not_saved')}
              status="critical"
            >
              <List type="bullet">
                {formErrors.map((message, index) => (
                  <List.Item key={index}>{message.message}</List.Item>
                ))}
              </List>
            </Banner>
            <br />
          </>
        )}
        {editForm ? (
          <EditWaysToRedeem
            setFormData={setFormData}
            formData={formData}
            refetch={redeemRefetch}
            setEditForm={setEditForm}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            saveSuccess={saveSuccess}
            setSaveSuccess={setSaveSuccess}
            titleOptions={titleOptions}
            i18n={i18n}
            allVariants={allVariants}
            setAllVariants={setAllVariants}
            selectedCollections={selectedCollections}
            setSelectedCollections={setSelectedCollections}
          />
        ) : redeemLoading ? (
          <LoadingScreen />
        ) : (
          <div className="without_header_card">
            <Card
              actions={{
                content: (
                  <div className="card_header_button_group">
                    <div className="card_header_buttons secondary_button_group">
                      <Button
                        onClick={() => {
                          history.push(`/pointsProgram/waysToRedeem/createNew`);
                        }}
                      >
                        {i18n.t('points_programs.add_ways_to_redeem')}
                      </Button>
                    </div>
                  </div>
                ),
              }}
            >
              <Card.Section>
                <div className={'table customer-subscription-tbl' + ' '}>
                  <DataTable
                    columnContentTypes={['text', 'text', 'text', 'text']}
                    headings={[]}
                    rows={formatRedeemData(
                      waysToRedeem?.length > 0 ? waysToRedeem : []
                    )}
                  />
                </div>
              </Card.Section>
            </Card>
          </div>
        )}
      </Frame>
    </>
  );
};

export default WaysToRedeemPoints;
