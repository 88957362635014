import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import {
    Card,
    Frame,
    Page,
    Tabs,
    AppProvider,
    Form,
    FormLayout,
    TextField,
    Select,
    Button,
    RadioButton,
    ButtonGroup,
    Icon,
    Toast,
} from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import { useCallback } from 'react';
import PixelIcon from '../../assets/images/PixelIcon';
import {
    ArrowDownMinor,
    ArrowUpMinor,
    DeleteMajor,
    MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import CancellationReasons from './CancellationReasons';
import './style.css';
import HtmlEditor from './HtmlEditor';
import EditCancellation from './EditCancellation';
import CancellationUpsells from './CancellationUpsells';
import EditCancellationUpsell from './EditCancellationUpsell';

const CancellationSettings = (props) => {
    const { i18n, selectedTemplateId, domain, toggleActive } = props;

    const [formData, setFormData] = useState();
    const [edit, showEdit] = useState(false);
    const [upsellEdit, showUpsellEdit] = useState(false);
    const [cancellationReasons, setCancellationReasons] = useState([]);
    const [cancellationUpsells, setCancellationUpsells] = useState([]);
    const [toastContent, setToastContent] = useState();
    const hideSaveSuccess = useCallback(() => setToastContent(), []);

    const handleSubmit = () => {
        fetch('/customer_portal/cancellation_flow_setting', {
            method: 'POST',
            body: JSON.stringify({ data: formData, shopify_domain: domain }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((r) => r.json())
            .then((data) => {
                if (data?.success) {
                    setToastContent('Saved Successfully');
                    fetch_cancellation_flow();
                }
            });
    };

    const fetch_cancellation_flow = () => {
        fetch('/customer_portal/fetch_cancellation_flow?shopify_domain=' + domain, {
            method: 'GET',
        })
            .then((r) => r.json())
            .then((data) => {
                if (data?.cancellation_reasons) {
                    setCancellationReasons([...data?.cancellation_reasons]);
                }
                if (data?.cancellation_flow_setting) {
                    setFormData({ ...data?.cancellation_flow_setting });
                }
            });
    };

    useEffect(() => {
        fetch_cancellation_flow();
    }, []);

    return (
        <Frame>
            {toastContent && (
                <Toast content={toastContent} onDismiss={hideSaveSuccess} />
            )}
            <Page
                title={
                    <div className="back-button back_button_wrapper">
                        {edit || upsellEdit ? (
                            <div
                                className="back-button pointer"
                                onClick={() => {
                                    showEdit();
                                    showUpsellEdit();
                                }}
                            >
                                <Icon source={MobileBackArrowMajor} color="base" />
                            </div>
                        ) : (
                            ''
                        )}
                        <h1 class="shopify_style_header">Cancellation setting</h1>
                    </div>
                }
            >
                <div className="translations_form_wrapper product-extention">
                    <>
                        <Card
                            title={
                                <div className="heading_title">
                                    <PixelIcon />
                                    Cancellation button behavior
                                </div>
                            }
                        >
                            <Card.Section>
                                <div className="content_wrapper_container">
                                    <div className="form_wrapper">
                                        <Form>
                                            <FormLayout>
                                                <div className="show_cancellation_instructions_container cancellation_flow_row_wrapper">
                                                    <h6 className="description">
                                                        Configure what should happen when customers try to
                                                        cancel their subscription
                                                    </h6>
                                                    <FormLayout.Group>
                                                        <RadioButton
                                                            label="Allow customers to cancel subscription directly from customer portal. (Recommended)"
                                                            checked={
                                                                formData?.show_cancellation_instructions ===
                                                                false
                                                            }
                                                            id="false"
                                                            name="show_cancellation_instructions"
                                                            onChange={(e) => {
                                                                setFormData({
                                                                    ...formData,
                                                                    show_cancellation_instructions: false,
                                                                });
                                                            }}
                                                        />
                                                    </FormLayout.Group>
                                                    <FormLayout.Group>
                                                        <RadioButton
                                                            label="Show cancellation instructions to customers when they try to cancel subscription."
                                                            checked={
                                                                formData?.show_cancellation_instructions ===
                                                                true
                                                            }
                                                            name="show_cancellation_instructions"
                                                            id="true"
                                                            onChange={(e) => {
                                                                setFormData({
                                                                    ...formData,
                                                                    show_cancellation_instructions: true,
                                                                });
                                                            }}
                                                        />
                                                    </FormLayout.Group>
                                                    {formData?.show_cancellation_instructions && (
                                                        <div className="show_cancellation_instructions_wrapper">
                                                            <TextField
                                                                label="Pop-up title"
                                                                name=""
                                                                value={formData?.cancellation_inst_title}
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        cancellation_inst_title: e,
                                                                    });
                                                                }}
                                                            />
                                                            <TextField
                                                                label="Cancellation instructions (HTML supported)"
                                                                name=""
                                                                value={formData?.cancellation_inst_body}
                                                                multiline={5}
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        cancellation_inst_body: e,
                                                                    });
                                                                }}
                                                            />
                                                            <TextField
                                                                label="Button text"
                                                                name=""
                                                                value={formData?.cancelltion_inst_button_text}
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        cancelltion_inst_button_text: e,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </FormLayout>
                                        </Form>
                                    </div>
                                </div>
                            </Card.Section>
                        </Card>
                        <Card
                            title={
                                <div className="heading_title">
                                    <PixelIcon />
                                    Minimum orders criteria
                                </div>
                            }
                        >
                            <Card.Section>
                                <div className="content_wrapper_container">
                                    <div className="form_wrapper">
                                        <Form>
                                            <FormLayout>
                                                <div className="show_cancellation_instructions_container cancellation_flow_row_wrapper">
                                                    <h6 className="description">
                                                        Configure what message needs to be shown when
                                                        customers try to cancel subscription before the
                                                        minimum orders have been placed by customer. This
                                                        is only needed if you have set minimum orders
                                                        condition in the selling plans
                                                    </h6>
                                                    <FormLayout.Group>
                                                        <RadioButton
                                                            label="Allow customers to cancel even if minimum number or orders not completed. (Recommended)"
                                                            checked={
                                                                formData?.minimum_order_criteria === false
                                                            }
                                                            id="minimum_order_criteria_false"
                                                            name="minimum_order_criteria"
                                                            onChange={(e) => {
                                                                setFormData({
                                                                    ...formData,
                                                                    minimum_order_criteria: false,
                                                                });
                                                            }}
                                                        />
                                                    </FormLayout.Group>
                                                    <FormLayout.Group>
                                                        <RadioButton
                                                            label="Show message to customers when they try to cancel subscription before completing minimum number of orders."
                                                            checked={
                                                                formData?.minimum_order_criteria === true
                                                            }
                                                            name="minimum_order_criteria"
                                                            id="minimum_order_criteria_true"
                                                            onChange={(e) => {
                                                                setFormData({
                                                                    ...formData,
                                                                    minimum_order_criteria: true,
                                                                });
                                                            }}
                                                        />
                                                    </FormLayout.Group>
                                                    {formData?.minimum_order_criteria && (
                                                        <div className="show_cancellation_instructions_wrapper">
                                                            <TextField
                                                                label="Pop-up title"
                                                                name=""
                                                                value={formData?.min_order_criteria_title}
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        min_order_criteria_title: e,
                                                                    });
                                                                }}
                                                            />
                                                            <TextField
                                                                label="Cancellation instructions (HTML supported)"
                                                                name=""
                                                                value={formData?.min_order_criteria_body}
                                                                multiline={5}
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        min_order_criteria_body: e,
                                                                    });
                                                                }}
                                                            />
                                                            <TextField
                                                                label="Button text"
                                                                name=""
                                                                value={
                                                                    formData?.min_order_criteria_button_text
                                                                }
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        min_order_criteria_button_text: e,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </FormLayout>
                                        </Form>
                                    </div>
                                </div>
                            </Card.Section>
                        </Card>

                        <div className="submit_button_wrapper">
                            <Button
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </>
                </div>
            </Page>
        </Frame>
    );
};

export default CancellationSettings;
