import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Frame,
  Icon,
  Layout,
  Page,
  TextStyle,
} from '@shopify/polaris';
import { MobileBackArrowMajor, HashtagMajor } from '@shopify/polaris-icons';

import './style.css';
import PixelIcon from '../../assets/images/PixelIcon';
import axiosconfig from '../axiosconfig';

const PlanBilling = ({ i18n, domain, currentPlan, setCurrentPlan }) => {
  const choosePlanView = window.location.pathname.includes('/choosePlan');

  const upgradePlan = (plan) => {
    axiosconfig.post('/select_plan', {
      format: 'json',
      shopify_domain: domain,
      plan: plan,
    }).then(({ data }) => {
      if (data?.redirection_path) {
        window.location.replace(data?.redirection_path);
      }
    });
  };

  return (
    <Frame>
      <div className={`plan_billing_main ${choosePlanView ? 'pl-2per' : ''}`}>
        <Layout>
          <Page title={choosePlanView && 'Choose a plan'}>
            {!choosePlanView && (
              <Layout.Section>
                <Card
                  title={
                    <div className="heading_title">
                      <PixelIcon />
                      {i18n?.t('billing')}
                    </div>
                  }
                  sectioned
                >
                  <Card.Section>
                    <div className="card_body">
                      <div className="left-section">
                        {currentPlan?.name || 'No active plan'}
                        <Badge status="success">
                          {i18n?.t('current_plan')}
                        </Badge>
                      </div>
                      <div className="right-section">
                        <div className="flex_wrap">
                          <span className="plan_price">
                            ${currentPlan?.price || '--'}USD
                          </span>
                          <span className="plan_validity">/month</span>
                        </div>
                        <span className="plan_validity status_wrapper">
                          {currentPlan?.status || ''}
                        </span>
                      </div>
                    </div>
                  </Card.Section>
                </Card>
              </Layout.Section>
            )}

            <div className="all_plans">
              <Layout.Section oneThird>
                <Card title="Starter">
                  <Card.Section>
                    <div className="plans-body">
                      <div className="plan-title">
                        <span className="plan_price">$1USD</span>
                        <span className="plan_validity">/month</span>
                      </div>
                      <div className="body-text">
                        0.99% of attributed revenue with a 50¢ minimum. | Covers
                        up to USD 250k annual GMV.
                      </div>
                      <div className="plans-body_buttons">
                        {+currentPlan?.name != 'Starter' &&
                          currentPlan?.status != 'expired' && (
                            <>
                              <Button onClick={() => upgradePlan('starter')}>
                                {choosePlanView ? 'Choose plan' : 'Upgrade'}
                              </Button>
                            </>
                          )}
                      </div>
                    </div>
                  </Card.Section>
                  <Card.Section subdued>
                    <div className="plan-details">
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Access all revenue channels</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Subscriptions & Memberships</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Deep-dive Analytics</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Unified Customer Portal</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Upsells & Post-purchase Extensions</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>SMS self-service & marketing</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Loyalty & Referrals</div>
                      </div>
                    </div>
                  </Card.Section>
                </Card>
              </Layout.Section>
              <Layout.Section oneThird>
                <Card title="Pro">
                  <Card.Section>
                    <div className="plans-body">
                      <div className="plan-title">
                        <span className="plan_price">$79 USD</span>
                        <span className="plan_validity">/month</span>
                      </div>
                      <div className="body-text">
                        0.79% of attributed revenue with a 50¢ minimum. | Covers
                        up to USD 5M annual GMV.
                      </div>
                      <div className="plans-body_buttons">
                        {+currentPlan?.name?.toLowerCase() != 'pro' &&
                          currentPlan?.status != 'expired' && (
                            <>
                              <Button onClick={() => upgradePlan('pro')}>
                                {choosePlanView ? 'Choose plan' : 'Upgrade'}
                              </Button>
                            </>
                          )}
                      </div>
                    </div>
                  </Card.Section>
                  <Card.Section subdued>
                    <div className="plan-details">
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>All the features in starter</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Headless Quiz</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Concierge Onboarding & Customization</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>24/7 email & live chat support</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Dedicated account manager</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Full access to Tiazen marketing suite</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Returns & Exchanges suite</div>
                      </div>
                    </div>
                  </Card.Section>
                </Card>
              </Layout.Section>
              <Layout.Section oneThird>
                <Card title="Advanced">
                  <Card.Section>
                    <div className="plans-body">
                      <div className="plan-title">
                        <span className="plan_price">299 USD</span>
                        <span className="plan_validity">/month</span>
                      </div>
                      <div className="body-text">
                        0.49% of attributed revenue with a 50¢ minimum. | Covers
                        up to USD 150M annual GMV.
                      </div>
                      <div className="plans-body_buttons">
                        {+currentPlan?.name?.toLowerCase() != 'advanced' &&
                          currentPlan?.status != 'expired' && (
                            <>
                              <Button onClick={() => upgradePlan('advanced')}>
                                {choosePlanView ? 'Choose plan' : 'Upgrade'}
                              </Button>
                            </>
                          )}
                      </div>
                    </div>
                  </Card.Section>
                  <Card.Section subdued>
                    <div className="plan-details">
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>All the features in pro</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Access custom integrations & APIs</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Engineering & Product Concierge</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Unlimited headless frontends</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Unlimited brands</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Negotiable billing</div>
                      </div>
                      <div className="list_items">
                        <div>
                          <Icon source={HashtagMajor} color="base" />
                        </div>
                        <div>Edge hosting & caching</div>
                      </div>
                    </div>
                  </Card.Section>
                </Card>
              </Layout.Section>
            </div>
            {/* <div>
              <Layout.Section>
                <Card sectioned>
                  <Card.Section>
                    <div className="card_body">
                      <div className="left-section">Enterprise</div>
                      <div className="body-text">
                        Manage complex billing operations for holdcos & teams
                        with advanced or unique requirements. Get blank check to
                        request any custom feature. White-glove support right
                        from staging to production, and beyond. Talk to our
                        experts and get tailored pricing for your business.
                      </div>
                      <div className="right-section">
                        <Button>Let's Talk</Button>
                      </div>
                    </div>
                  </Card.Section>
                </Card>
              </Layout.Section>
            </div> */}
          </Page>
        </Layout>
      </div>
    </Frame>
  );
};

export default PlanBilling;
