import { Card, Icon, ProgressBar } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { ChevronDownMinor, MobileCancelMajor } from '@shopify/polaris-icons';
import './FloatingSetupGuide.css';
import { useHistory } from 'react-router-dom';
import { setupcontents } from './setupcontents';

const FloatingSetupGuide = (props) => {
  const { customNavTabs, floatingSetupGuide, setFloatingSetupGuide } = props;
  const [headerButton, setHeaderButton] = useState('subscription_home');
  const [selectedVal, setSelectedVals] = useState({});
  const [showDesc, setShowDesc] = useState();
  const [headerButtons, setHeaderButtons] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [completedItems, setcompletedItems] = useState();
  const [setUpContents, setSetUpContents] = useState({});
  const [showContentTab, setShowContentTab] = useState();

  const history = useHistory();

  const getTotalContent = () => {
    let totalitems = 0;
    let completeditems = 0;
    try {
      setUpContents?.[headerButton]?.map((c) => {
        totalitems += c.contents.length;
        completeditems += c?.contents?.filter((item) => item.completed).length;
        return null;
      });
    } catch (e) {
      console.log('error', e);
    }
    setTotalItems(totalitems);
    setcompletedItems(completeditems);
  };

  const setCustomNavHeaders = () => {
    const headerbuttons = [];
    if (customNavTabs?.subscription_home) {
      headerbuttons.push({
        val: 'subscription_home',
        name: 'Subscription',
      });
    }
    if (customNavTabs?.affiliate_influencer) {
      headerbuttons.push({
        val: 'affiliate_influencer',
        name: 'Affiliate',
      });
    }
    if (customNavTabs?.upsells) {
      headerbuttons.push({
        val: 'upsells',
        name: 'Upsells',
      });
    }
    if (customNavTabs?.sms_smarty) {
      headerbuttons.push({
        val: 'sms_smarty',
        name: 'SMS',
      });
    }
    if (customNavTabs?.kiosk_pos) {
      headerbuttons.push({
        val: 'kiosk_pos',
        name: 'Kiosk',
      });
    }
    if (customNavTabs?.loyalty_rewards) {
      headerbuttons.push({
        val: 'loyalty_rewards',
        name: 'Loyalty',
      });
    }
    if (customNavTabs?.checkout_extensions) {
      headerbuttons.push({
        val: 'checkout_extensions',
        name: 'Checkout',
      });
    }
    if (customNavTabs?.gift_card_store_credit) {
      headerbuttons.push({
        val: 'gift_card_store_credit',
        name: 'Gift card',
      });
    }
    if (customNavTabs?.replenish_me) {
      headerbuttons.push({
        val: 'replenish_me',
        name: 'ReplenishMe',
      });
    }
    if (customNavTabs?.ios_wallet_passes) {
      headerbuttons.push({
        val: 'ios_wallet_passes',
        name: 'iOS Wallet passes',
      });
    }
    if (customNavTabs?.pre_orders) {
      headerbuttons.push({
        val: 'pre_orders',
        name: 'Pre-Orders',
      });
    }
    if (customNavTabs?.try_before_you_buy) {
      headerbuttons.push({
        val: 'try_before_you_buy',
        name: 'Try-before-you-buy',
      });
    }
    setHeaderButtons([...headerbuttons]);
  };

  useEffect(() => {
    setCustomNavHeaders();
  }, [customNavTabs]);

  useEffect(() => {
    if (floatingSetupGuide) {
      setHeaderButton(floatingSetupGuide);
    }
  }, [floatingSetupGuide]);

  const CheckboxIcon = () => {
    return (
      <>
        <svg
          width="20"
          height="20"
          viewBox="2 2 20 20"
          fill="none"
          className="gui_CheckedIcon-module__CheckedIcon___hqAVM gui_CheckedIcon-module__AppearActive___8LaUx"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            opacity: 1,
            transform: 'scale(1) rotate(0)',
            display: 'block',
          }}
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            fill="var(--task-manual-completion-bg)"
            style={{
              fill: '#000',
            }}
          ></circle>
          <path
            d="M17.2738 8.52629C17.6643 8.91682 17.6643 9.54998 17.2738 9.94051L11.4405 15.7738C11.05 16.1644 10.4168 16.1644 10.0263 15.7738L7.3596 13.1072C6.96908 12.7166 6.96908 12.0835 7.3596 11.693C7.75013 11.3024 8.38329 11.3024 8.77382 11.693L10.7334 13.6525L15.8596 8.52629C16.2501 8.13577 16.8833 8.13577 17.2738 8.52629Z"
            style={{
              fill: '#fff',
            }}
          ></path>
        </svg>
      </>
    );
  };

  useEffect(() => {
    setSetUpContents({ ...setupcontents });
  }, []);

  const handleCheckbox = (key, contentTabIndex, index) => {
    let _setupcontents = { ...setUpContents };
    _setupcontents[key][contentTabIndex]['contents'][index]['completed'] =
      !_setupcontents[key][contentTabIndex]['contents'][index]['completed'];
    setSetUpContents({ ..._setupcontents });
  };

  useEffect(() => {
    getTotalContent();
  }, [setUpContents, headerButton]);

  const toggleContentTab = (contentTabIndex) => {
    let contentTab =
      +contentTabIndex === +showContentTab ? null : contentTabIndex;
    setShowContentTab(contentTab);
  };

  return (
    <div className="floating_setup_guide progress_bar_in_header  polaris-no-padding-section">
      <Card>
        <div className="chargezen_custom_header">
          <div className="heading_title contains progressbar_wrapper">
            Setup Guide
            <div className="progressbar_wrapper">
              <div className="pogress_bar_details">
                {`${+completedItems} / ${+totalItems} completed`}
              </div>
              <ProgressBar
                progress={(+completedItems / +totalItems) * 100}
                size="small"
                tone="primary"
                animated
              />
            </div>
            <button
              className="close_floating_setup_guide_button"
              onClick={() => {
                setFloatingSetupGuide(false);
              }}
            >
              <Icon source={MobileCancelMajor} color="base" />
            </button>
          </div>
        </div>
        <Card.Section>
          <div className="setup_guide_content_wrapper">
            {setUpContents[headerButton]?.map((contentTab, contentTabIndex) => {
              return (
                <div className="contentsTab_container">
                  <div className="contents_tab_wrapper">
                    <span>{contentTab?.title}</span>
                    <button
                      className={`toggle_content_tab_button icon_wrapper ${(showContentTab || showContentTab === 0) &&
                        +contentTabIndex === +showContentTab
                        ? 'opened'
                        : ''
                        }`}
                      onClick={() => toggleContentTab(contentTabIndex)}
                    >
                      <Icon source={ChevronDownMinor} tone="base" />
                    </button>
                  </div>
                  <div
                    className={`contents_wrapper_container ${(showContentTab || showContentTab === 0) &&
                      +contentTabIndex === +showContentTab
                      ? ''
                      : 'no-height'
                      }`}
                  >
                    {contentTab?.contents?.map((content, i) => {
                      return (
                        <div
                          className={`chargezen_flex_row_wrapper ${showDesc?.[i] ? `activate` : ''
                            }`}
                        >
                          <div className="left_section_container">
                            <button
                              className={`checkbox_button ${content.completed ? 'active' : ''
                                }`}
                              onClick={() => {
                                handleCheckbox(
                                  'subscription_home',
                                  contentTabIndex,
                                  i
                                );
                                // setSelectedVals({
                                //   ...selectedVal,
                                //   [content.val]: !selectedVal[content.val],
                                // });
                              }}
                            >
                              <CheckboxIcon />
                            </button>
                          </div>
                          <div className="right_section_container">
                            <h2
                              className=""
                              onClick={() => {
                                setShowDesc({ [i]: !showDesc?.[i] });
                              }}
                            >
                              {content?.heading}
                            </h2>
                            <div
                              className={`show_hide_content_wrapper ${showDesc?.[i] ? '' : 'no-height'
                                }`}
                            >
                              <div className="description_wrapper">
                                {content?.description}
                              </div>
                              <div className="buttons_group_wrapper">
                                {content?.button_group?.map((button_group) => {
                                  return (
                                    <button
                                      className="bg_colored_button"
                                      onClick={() => {
                                        setFloatingSetupGuide(headerButton);
                                        history.push(
                                          button_group?.button_redirection
                                        );
                                      }}
                                    >
                                      {button_group?.button_text}
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Card.Section>
      </Card>
    </div>
  );
};

export default FloatingSetupGuide;
