import React, { useEffect, useState } from 'react'
import ExportModal from './ExportModal';
import axiosconfig from '../../axiosconfig';
import { gql, useQuery } from '@apollo/client';
import { Pagination } from '@shopify/polaris';

const SubscriptionReport = (props) => {
    const { domain, dateFilter, setDateFilter, showExport, setShowExport, toastContent, setToastContent, selectedDates, customMainButtons, setCustomMainButtons, setHeaderButtons, setHeaderButton, headerButton } = props;

    const [subCustomers, setSubCustomers] = useState([]);
    const [page, setPage] = useState(1);
    const [hasPrevious, setHasPrevious] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const tableHeadings = [
        "Status",
        "Created at",
        "Created by",
        "Customer ID",
        "Customer email",
        "Customer name",
        "Customer phone",
        "Next billing date",
        "Billing cycle",
        "Delivery cycle",
        "Minimum billing cycles",
        "Maximum billing cycles",
        "Total orders",
        "Products",
        "Subtotal",
        "Discounts added",
        "Discount amount",
        "Discounted total",
        "Delivery profile",
        "Billing address",
        "Billing Zip/Postal code",
        "Billing City",
        "Billing State/province",
        "Billing Country",
        "Delivery Address",
        "Delivery Zip/Postal code",
        "Delivery City",
        "Shipping province",
        "Shipping country",
        "Shipping method",
        "Shipping price",
        "Last payment status",
        "Payment failure reason",
        "Paused at",
        "Paused by",
        "Cancelled at",
        "Cancelled by",
        "Cancellation reason",
        "Cancellation remark",
        "Payment method",
        "Payment method status",
        "Payment instrument",
        "Payment created at",
        "Payment updated at",
        "Charge offset",
        "Custom Attributes"
    ]


    const initHeaderButtons = [
        {
            val: 'active',
            name: 'Active contracts',
        },
        {
            val: 'paused',
            name: 'Paused contracts',
        },
        {
            val: 'expired',
            name: 'Cancelled contracts',
        },
        {
            val: 'pre_paid',
            name: 'Prepaid contracts',
        },
    ];

    const GET_CUSTOMERS = gql`
        query (
            $page: String
            $status: String
            ) {
            fetchCustomers(
                page: $page
                status: $status
            ) {
                customerSubscriptions {
                    id
                    indexId
                    shopifyId
                    shopifyCustomerId
                    formattedProducts
                    email
                    status
                    apiData
                    createdAt
                    firstName
                    lastName
                }
                totalCount
                totalPages
                pageNumber
            }
        }
  `;

    const { data, loading, error, refetch } = useQuery(GET_CUSTOMERS, {
        fetchPolicy: 'no-cache',
        variables: {
            page: page.toString(),
            status: headerButton,
        },
    });

    useEffect(() => {
        refetch({
            variables: {
                page: page.toString(),
                status: headerButton,
            },
        });
        // setTotalPages(data?.fetchCustomers?.totalPages);
    }, [page, headerButton]);

    useEffect(() => {
        setSubCustomers([])
        setSubCustomers(data?.fetchCustomers?.customerSubscriptions || []);
        if (+page < +data?.fetchCustomers?.totalPages) {
            setHasMore(true);
        }
        else {
            setHasMore(false)
        }
        if (+page <= 1) {
            setHasPrevious(false)
        }
        else {
            setHasPrevious(true)
        }
    }, [data])

    useEffect(() => {
        setHeaderButtons(initHeaderButtons);
        setHeaderButton("active")
    }, [])

    useEffect(() => {
        setCustomMainButtons([{
            name: "Export",
            onClick: () => { }
        }])
    }, [dateFilter, selectedDates])
    return (
        <div className='forecastInventory_container'>
            {
                showExport && (
                    <ExportModal setShowExport={setShowExport} showExport={showExport} selectedDates={selectedDates} domain={domain} apiUrl='/export_forecast_inventory' {...{ toastContent, setToastContent }} dateFilter={dateFilter} />
                )
            }
            <table className='forecastInventory_table'>
                <thead>
                    <tr>
                        {
                            tableHeadings?.map((heading) => {
                                return (
                                    <th>{heading}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        subCustomers?.map((sub) => {
                            let apiData = {}

                            apiData =
                                sub?.apiData != null
                                    ? JSON.parse(
                                        sub?.apiData?.replaceAll('=>', ':')?.replaceAll('nil', '"nil"')
                                    )
                                    : '';

                            return (
                                <tr>
                                    <td>{sub?.status}</td>
                                    <td>{apiData?.created_at}</td>
                                    <td>{apiData?.customer?.display_name}</td>
                                    <td>{sub?.shopifyId}</td>
                                    <td>{sub?.email}</td>
                                    <td>{apiData?.customer?.display_name}</td>
                                    <td>{apiData?.customer?.phone}</td>
                                    <td>{apiData?.next_billing_date}</td>
                                    <td>{`${apiData?.billing_policy?.interval_count} ${apiData?.billing_policy?.interval}`}</td>
                                    <td>{`${apiData?.delivery_policy?.interval_count} ${apiData?.delivery_policy?.interval}`}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{apiData?.orders?.edges?.length}</td>
                                    <td>{apiData?.lines?.edges?.length}</td>
                                    <td>{apiData?.lines?.edges?.length}</td>

                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
            <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                <Pagination
                    hasPrevious={hasPrevious}
                    onPrevious={() => {
                        setPage((prev) => +prev - 1)
                    }}
                    hasNext={hasMore}
                    onNext={() => {
                        setPage((prev) => +prev + 1)
                    }}
                />
            </div>
        </div>
    )
}

export default SubscriptionReport
