import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Card,
  Toast,
  ChoiceList,
  Page,
  PageActions,
  TextField,
  Layout,
  Banner,
  List,
  Frame,
  Select,
  Icon,
  Checkbox,
  RadioButton,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
  TextContainer,
} from '@shopify/polaris';
import PixelIcon from '../../assets/images/PixelIcon';
import { MobileBackArrowMajor, NoteMinor } from '@shopify/polaris-icons';
import SearchAffiliates from './SearchAffiliates';
import { useHistory } from 'react-router-dom';
import AssignAffiliatesLink from './AssignAffiliatesLink';
import { useParams } from 'react-router-dom';
import axiosconfig from '../axiosconfig';

const CreateAffiliateCommission = (props) => {
  const {
    domain,
    setEditForm,
    refetch,
    setSaveSuccess,
    i18n,
    toastContent,
    setToastContent,
    customHeader,
    setCustomHeader,
  } = props;

  const { edit_id } = useParams();
  const [icon, setIcon] = useState();
  const [defaultIcon, setDefaultIcon] = useState('default');
  const [fieldError, setFieldError] = useState({
    title: '',
    pointsAwarded: '',
  });
  const [defaultIcons, setDefaultIcons] = useState([]);
  const [base64Icon, setBase64Icon] = useState();
  const [affiliateLink, setAffiliateLink] = useState();
  const [formData, setFormData] = useState({
    commission_type: 'automatic_tracking',
    automatic_tracking_title: '',
    discount_code: '',
    discount_type: 'PERCENTAGE',
    commission_rate_type: 'PERCENTAGE',
    purchase_type: 'BOTH',
    minimum_requirement: 'NONE',
    shopify_domain: domain,
    assoc_affiliates: [],
  });
  const [booleanFields, setBooleanFields] = useState({});

  const [file, setFile] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [affiliateSearchQuery, setAffiliateSearchQuery] = useState();
  const [selectedAffiliate, setSelectedAffiliate] = useState([]);
  const history = useHistory();

  const ADD_WAYS_TO_EARN = gql`
    mutation ($input: AddWaysToEarnPointsInput!) {
      addWaysToEarnPoints(input: $input) {
        waysToEarnPoints {
          id
        }
      }
    }
  `;
  const [addWaysToEarn] = useMutation(ADD_WAYS_TO_EARN);

  const [updated, setUpdated] = useState(false);

  const convertToBase64 = () => {
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setBase64Icon(reader.result);
      };
    }
  };

  useEffect(() => {
    convertToBase64();
  }, file);

  const handleSubmit = () => {
    if (formData.automatic_tracking_title) {
      setFormErrors();
      axiosconfig.post('/save_commission_profile', formData).then(({ data }) => {
        if (data.success) {
          setToastContent('Saved Successfully');

          history.push(`/affiliates/affiliate_commission`);
        }
      })
        .catch((e) => {
          console.log('Somethind went wrong' + e);
        });
    } else {
      setFormErrors({ ...formErrors, discount_code: "This can't be blank" });
    }
  };

  const deleteSubmit = () => {
    axiosconfig.delete(`/commissionProfile/delete/${edit_id}?shopify_domain=${domain}`).then(({ data }) => {
      setToastContent('Deleted Successfully');
      history.push(`/affiliates/affiliate_commission`);
    });
  };

  const getEditState = () => {
    if (edit_id && !isNaN(+edit_id)) {
      axiosconfig.get(`/fetchCommissionProfile/${edit_id}`).then(({ data }) => {
        if (data?.commission_profile) {
          let commission_profile = data?.commission_profile;
          commission_profile.shopify_domain = domain;
          setFormData(commission_profile);
          setSelectedAffiliate([...commission_profile?.assoc_affiliates]);
          setCustomHeader(data?.commission_profile?.automatic_tracking_title);
        } else {
          console.log('Something went wrong...');
        }
      });
    } else {
      setCustomHeader('New commission profile');
    }
  };

  useEffect(() => {
    getEditState();
  }, []);

  const [summaryText, setSummaryText] = useState();
  useEffect(() => {
    let summary;
    let details = [];
    //discount value
    if (formData?.discount_value && formData.discount_type) {
      if (formData.discount_type == 'PERCENTAGE') {
        details.push(`${formData?.discount_value}% off entire order`);
      } else {
        details.push(`$${formData?.discount_value} off entire order`);
      }
    }
    //purchase type
    details.push(
      `Applies to subscriptions ${formData.purchase_type === 'SUBSCRIPTION'
        ? ''
        : formData.purchase_type === 'BOTH'
          ? 'and one-time'
          : 'one-time '
      } purchases`
    );
    //Minimum purchase requirements
    if (formData?.minimum_requirement === 'NONE') {
      details.push(`No minimum purchase requirement`);
    } else if (
      formData?.minimum_requirement === 'SUBTOTAL' &&
      formData?.minimum_requirement_subtotal
    ) {
      details.push(
        `Minimum purchase of $${formData?.minimum_requirement_subtotal}`
      );
    } else if (
      formData?.minimum_requirement === 'QUANTITY' &&
      formData?.minimum_requirement_quantity
    ) {
      details.push(
        `Minimum purchase of ${formData?.minimum_requirement_subtotal} items`
      );
    }
    //customer eligibility
    if (formData?.customer_eligibility === 'EVERYONE') {
      details.push(`All customers`);
    }
    //Maximum discount uses
    if (formData?.limit_times_discount_used) {
      details.push(`Limit of ${+formData?.limit_times_discount_used} uses`);
    }
    if (formData?.limit_one_use_per_customer) {
      details.push(`One per customer`);
    }
    //recurring payment
    if (formData?.recurring_payment_subscription === 'FIRST_PAYMENT') {
      details.push(`Limited to 1 recurring payment`);
    } else if (formData?.recurring_payment_subscription === 'ALL_PAYMENTS') {
      details.push(`For all recurring payments`);
    } else if (
      formData?.recurring_payment_subscription === 'MULTIPLE_PAYMENTS' &&
      formData?.limit_discount_recurring_payment
    ) {
      details.push(
        `Limited to ${formData?.limit_discount_recurring_payment} recurring payments`
      );
    }
    //Combinations
    if (
      formData?.combine_product_discount ||
      formData?.combine_order_discount ||
      formData?.combine_shipping_discount
    ) {
      let combined = [];
      if (formData?.combine_product_discount) {
        combined.push('product discounts');
      }
      if (formData?.combine_order_discount) {
        combined.push('order discounts');
      }
      if (formData?.combine_shipping_discount) {
        combined.push('shipping discounts');
      }
      details.push(`Combines with ${combined.join(',')} discounts`);
    } else {
      details.push(`Can’t combine with other discounts`);
    }
    details = details?.map((detail) => {
      if (detail) {
        return <li>{detail}</li>;
      }
    });
    setSummaryText(details);
  }, [formData]);

  const handleChange = useCallback((_, newValue) => {
    setDefaultIcon(newValue);
    setFile();
  }, []);

  const discountTypeOptions = [
    { label: 'Percentage', value: 'PERCENTAGE' },
    { label: 'Fixed amount', value: 'FIXED_AMOUNT' },
  ];
  const purchaseTypeOptions = [
    { label: 'One-time purchase', value: 'ONE_TIME_PURCHASE' },
    { label: 'Subscription', value: 'SUBSCRIPTION' },
    { label: 'both', value: 'BOTH' },
  ];

  return (
    <Frame>
      <Layout>
        <Layout.Section>
          <Card
            title={
              <div className="heading_title">
                <PixelIcon />
                Cookie-less, coupon-free tracking
              </div>
            }
            sectioned
          >
            <div className="chargezen_form_row method_wrapper">
              <div className="method_header_title">Method</div>
              <div className="change_method_wrapper">
                <button
                  className={`toggleMethod ${formData?.commission_type == 'discount_code'
                    }`}
                  onClick={() => {
                    // setFormData({
                    //   ...formData,
                    //   commission_type: 'discount_code',
                    // });
                  }}
                >
                  Discount code
                </button>
                <button
                  className={`toggleMethod ${formData?.commission_type == 'automatic_tracking'
                    }`}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      commission_type: 'automatic_tracking',
                    });
                  }}
                >
                  Automatic tracking
                </button>
              </div>
            </div>
            <div className="chargezen_form_row">
              {formData?.commission_type == 'automatic_tracking' ? (
                <TextField
                  label={'Title'}
                  type="text"
                  value={`${formData?.automatic_tracking_title}`}
                  onChange={(e) => {
                    if (e.length > 0 && +e < 0) {
                      setFieldError({
                        ...fieldError,
                        automatic_tracking_title:
                          'Please enter automatic tracking title',
                      });
                      setFormData({
                        ...formData,
                        automatic_tracking_title: '1',
                      });
                    } else {
                      setFieldError({
                        ...fieldError,
                        automatic_tracking_title: '',
                      });
                      setFormData({
                        ...formData,
                        automatic_tracking_title: `${e}`,
                      });
                    }
                  }}
                  error={fieldError?.discount_code}
                  autoComplete="off"
                  helpText="Affiliates' referred customers will be tracked automatically at checkout."
                />
              ) : (
                <TextField
                  label={'Discount code'}
                  type="text"
                  value={`${formData?.discount_code}`}
                  onChange={(e) => {
                    if (e.length > 0 && +e < 0) {
                      setFieldError({
                        ...fieldError,
                        discount_code: 'Please enter discount code',
                      });
                      setFormData({ ...formData, discount_code: '1' });
                    } else {
                      setFieldError({ ...fieldError, discount_code: '' });
                      setFormData({ ...formData, discount_code: `${e}` });
                    }
                  }}
                  error={fieldError?.discount_code}
                  autoComplete="off"
                  helpText="Customers must enter this code at checkout."
                />
              )}
            </div>
          </Card>

          <Card
            title={
              <div className="heading_title">
                <PixelIcon />
                Affiliate Discount
              </div>
            }
            sectioned
          >
            <div className="chargezen_form_row flex_wrapper">
              <Select
                label=""
                options={discountTypeOptions}
                onChange={(e) => {
                  setFormData({ ...formData, discount_type: e });
                }}
                value={formData?.discount_type}
              />
              <TextField
                label={''}
                type="number"
                value={`${formData?.discount_value}`}
                suffix={formData?.discount_type === 'PERCENTAGE' ? '%' : ''}
                prefix={formData?.discount_type != 'PERCENTAGE' ? '$' : ''}
                onChange={(e) => {
                  if (e.length > 0 && +e < 0) {
                    setFieldError({
                      ...fieldError,
                      discount_value: 'Please enter discount value',
                    });
                    setFormData({ ...formData, discount_value: '1' });
                  } else {
                    setFieldError({ ...fieldError, discount_value: '' });
                    setFormData({ ...formData, discount_value: `${e}` });
                  }
                }}
                error={fieldError?.discount_value}
                autoComplete="off"
              />
            </div>
            {formData?.commission_type == 'discount_code' && (
              <div className="chargezen_form_row">
                <Select
                  label=""
                  options={purchaseTypeOptions}
                  onChange={(e) => {
                    setFormData({ ...formData, purchase_type: e });
                  }}
                  value={formData?.purchase_type}
                />
              </div>
            )}

            <div className="heading_title affiliate_discount">
              Affiliate Commission
            </div>
            <div className="chargezen_form_row flex_wrapper">
              <Select
                label=""
                options={discountTypeOptions}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    commission_rate: e,
                  });
                }}
                value={formData?.commission_rate_type}
              />
              <TextField
                label={''}
                type="number"
                value={`${formData?.commission_rate}`}
                suffix={
                  formData?.commission_rate_type === 'PERCENTAGE' ? '%' : ''
                }
                prefix={
                  formData?.commission_rate_type != 'PERCENTAGE' ? '$' : ''
                }
                onChange={(e) => {
                  if (e.length > 0 && +e < 0) {
                    setFieldError({
                      ...fieldError,
                      commission_rate: 'Please enter discount value',
                    });
                    setFormData({
                      ...formData,
                      commission_rate: '1',
                    });
                  } else {
                    setFieldError({
                      ...fieldError,
                      commission_rate: '',
                    });
                    setFormData({
                      ...formData,
                      commission_rate: `${e}`,
                    });
                  }
                }}
                error={fieldError?.commission_rate}
                autoComplete="off"
              />
            </div>
          </Card>

          <Card
            title={
              <div className="heading_title">
                <PixelIcon />
                Assign to Affiliate
              </div>
            }
            sectioned
          >
            <div className="selected_plan_wrapper upsell_plan_wrapper mt-16">
              {formData?.assoc_affiliates?.map((aff, i) => {
                return (
                  <div className="selectedPlan">
                    <div
                      className="removePlan"
                      onClick={() => {
                        let affiliates = formData?.assoc_affiliates?.map(
                          (a) => a
                        );
                        affiliates.splice(i, 1);
                        setFormData({
                          ...formData,
                          assoc_affiliates: [...affiliates],
                        });
                      }}
                    >
                      x
                    </div>
                    {aff?.email}
                  </div>
                );
              })}
            </div>
            <div className="chargezen_form_row">
              <TextField
                placeholder="Search affiliates"
                value={affiliateSearchQuery}
                onChange={(e) => {
                  setAffiliateSearchQuery(e);
                }}
                helpText="Choose the affiliate(s) or influencer(s) that will use this commission profile. "
                autoComplete="off"
              />
              {affiliateSearchQuery && (
                <SearchAffiliates
                  {...{
                    affiliateSearchQuery,
                    setAffiliateSearchQuery,
                    formData,
                    setFormData,
                    selectedAffiliate,
                    setSelectedAffiliate,
                  }}
                />
              )}
            </div>
          </Card>

          {formData.assoc_affiliates?.length > 0 && (
            <Card
              title={
                <div className="heading_title">
                  <PixelIcon />
                  Affiliate link destination url
                  <div className="heading_description_wrapper">
                    Set up the url where affiliates' referral links are
                    redirected to.
                  </div>
                </div>
              }
              sectioned
            >
              <div className="chargezen_form_row">
                <label className="custom_label">
                  <span>
                    Click on each affiliate to setup their unique destination
                    url
                  </span>
                </label>
              </div>
              <div class="sidbar-section seec-2">
                <ul class="Polaris-List" style={{ padding: 0 }}>
                  <div className="selected_plan_wrapper upsell_plan_wrapper mt-16">
                    {formData?.assoc_affiliates?.map((aff, i) => {
                      return (
                        <div
                          className="selectedPlan"
                          onClick={() => {
                            setAffiliateLink(i);
                          }}
                        >
                          {aff?.email}
                        </div>
                      );
                    })}
                  </div>
                </ul>
              </div>
              {(affiliateLink || affiliateLink === 0) && (
                <AssignAffiliatesLink
                  {...{
                    affiliateLink,
                    setAffiliateLink,
                    formData,
                    setFormData,
                    selectedAffiliate,
                    setSelectedAffiliate,
                  }}
                />
              )}
            </Card>
          )}

          <Card
            title={
              <div className="heading_title">
                <PixelIcon />
                Minimum purchase requirements
              </div>
            }
            sectioned
          >
            <div className="chargezen_form_row flow-cloumn">
              <RadioButton
                label="No minimum requirements"
                checked={formData?.minimum_requirement === 'NONE'}
                id="NONE"
                name="minimum_requirement"
                onChange={(_, e) => {
                  setFormData({ ...formData, minimum_requirement: e });
                }}
              />
            </div>
            <div className="chargezen_form_row flow-cloumn">
              <RadioButton
                label="Minimum purchase amount ($)"
                checked={formData?.minimum_requirement === 'SUBTOTAL'}
                id="SUBTOTAL"
                name="minimum_requirement"
                helpText="Applies to all products"
                onChange={(_, e) => {
                  setFormData({ ...formData, minimum_requirement: e });
                }}
              />
              {formData?.minimum_requirement === 'SUBTOTAL' && (
                <TextField
                  label={''}
                  type="number"
                  value={`${formData?.minimum_requirement_subtotal}`}
                  prefix={`$`}
                  onChange={(e) => {
                    if (e.length > 0 && +e < 0) {
                      setFieldError({
                        ...fieldError,
                        minimum_requirement_subtotal:
                          'Minimum purchase can’t be blank',
                      });
                    } else {
                      setFieldError({
                        ...fieldError,
                        minimum_requirement_subtotal: '',
                      });
                      setFormData({
                        ...formData,
                        minimum_requirement_subtotal: `${e}`,
                      });
                    }
                  }}
                  error={fieldError?.minimum_requirement_subtotal}
                  autoComplete="off"
                />
              )}
            </div>
            <div className="chargezen_form_row flow-cloumn">
              <RadioButton
                label="Minimum quantity of items"
                checked={formData?.minimum_requirement === 'QUANTITY'}
                id="QUANTITY"
                name="minimum_requirement"
                onChange={(_, e) => {
                  setFormData({ ...formData, minimum_requirement: e });
                }}
              />
              {formData?.minimum_requirement === 'QUANTITY' && (
                <TextField
                  label={''}
                  type="number"
                  value={`${formData?.minimum_requirement_quantity}`}
                  helpText="Applies to all products."
                  onChange={(e) => {
                    if (e.length > 0 && +e < 0) {
                      setFieldError({
                        ...fieldError,
                        minimum_requirement_quantity:
                          'Minimum quantity can’t be blank',
                      });
                    } else {
                      setFieldError({
                        ...fieldError,
                        minimum_requirement_quantity: '',
                      });
                      setFormData({
                        ...formData,
                        minimum_requirement_quantity: `${e}`,
                      });
                    }
                  }}
                  error={fieldError?.minimum_requirement_quantity}
                  autoComplete="off"
                />
              )}
            </div>
          </Card>

          {formData?.commission_type == 'discount_code' && (
            <Card
              title={
                <div className="heading_title">
                  <PixelIcon />
                  Maximum discount uses
                </div>
              }
              sectioned
            >
              <div className="chargezen_form_row">
                <Checkbox
                  label="Limit number of times this discount can be used in total"
                  checked={booleanFields?.limit_times_discount}
                  onChange={(e) => {
                    setBooleanFields({
                      ...booleanFields,
                      limit_times_discount: e,
                    });
                  }}
                />
                {booleanFields?.limit_times_discount && (
                  <TextField
                    label={''}
                    type="number"
                    value={`${formData?.limit_times_discount_used}`}
                    prefix={`$`}
                    onChange={(e) => {
                      if (e.length > 0 && +e < 0) {
                        setFieldError({
                          ...fieldError,
                          limit_times_discount_used:
                            'Minimum purchase can’t be blank',
                        });
                      } else {
                        setFieldError({
                          ...fieldError,
                          limit_times_discount_used: '',
                        });
                        setFormData({
                          ...formData,
                          limit_times_discount_used: `${e}`,
                        });
                      }
                    }}
                    error={fieldError?.limit_times_discount_used}
                    autoComplete="off"
                  />
                )}
              </div>
              <div className="chargezen_form_row">
                <Checkbox
                  label="Limit to one use per customer"
                  checked={formData?.limit_one_use_per_customer}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      limit_one_use_per_customer: e,
                    });
                  }}
                />
              </div>
              <div className="recurring_payments_subscription_container">
                <h3>Recurring payments for subscriptions</h3>
                <div className="chargezen_form_row">
                  <RadioButton
                    label="Limit discount to the first payment"
                    checked={
                      formData?.recurring_payment_subscription ===
                      'FIRST_PAYMENT'
                    }
                    id="FIRST_PAYMENT"
                    name="recurring_payment_subscription"
                    onChange={(_, e) => {
                      setFormData({
                        ...formData,
                        recurring_payment_subscription: e,
                      });
                    }}
                  />
                </div>
                <div className="chargezen_form_row">
                  <RadioButton
                    label="Limit discount to multiple recurring payments"
                    checked={
                      formData?.recurring_payment_subscription ===
                      'MULTIPLE_PAYMENTS'
                    }
                    id="MULTIPLE_PAYMENTS"
                    name="recurring_payment_subscription"
                    onChange={(_, e) => {
                      setFormData({
                        ...formData,
                        recurring_payment_subscription: e,
                      });
                    }}
                  />
                </div>
                {formData?.recurring_payment_subscription ===
                  'MULTIPLE_PAYMENTS' && (
                    <TextField
                      label={''}
                      type="number"
                      value={`${formData?.limit_discount_recurring_payment}`}
                      prefix={`$`}
                      onChange={(e) => {
                        if (e.length > 0 && +e < 0) {
                          setFieldError({
                            ...fieldError,
                            limit_discount_recurring_payment:
                              'Discount limit can’t be blank',
                          });
                        } else {
                          setFieldError({
                            ...fieldError,
                            limit_discount_recurring_payment: '',
                          });
                          setFormData({
                            ...formData,
                            limit_discount_recurring_payment: `${e}`,
                          });
                        }
                      }}
                      error={fieldError?.limit_discount_recurring_payment}
                      autoComplete="off"
                    />
                  )}
                <div className="chargezen_form_row">
                  <RadioButton
                    label="Discount applies to all recurring payments"
                    checked={
                      formData?.recurring_payment_subscription ===
                      'ALL_PAYMENTS'
                    }
                    id="ALL_PAYMENTS"
                    name="recurring_payment_subscription"
                    onChange={(_, e) => {
                      setFormData({
                        ...formData,
                        recurring_payment_subscription: e,
                      });
                    }}
                  />
                </div>
              </div>
            </Card>
          )}
          {formData?.commission_type == 'discount_code' && (
            <Card
              title={
                <div className="heading_title">
                  <PixelIcon />
                  Combinations
                </div>
              }
              sectioned
            >
              <div className="chargezen_form_row">
                <Checkbox
                  label="Product discounts"
                  helpText="No product discounts are set to combine. To let customers use more than one discount, set up at least one product discount that combines with order discounts."
                  checked={formData?.combine_product_discount}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      combine_product_discount: e,
                    });
                  }}
                />
              </div>
              <div className="chargezen_form_row">
                <Checkbox
                  label="Order discounts"
                  helpText="No order discounts are set to combine. To let customers use more than one discount, set up at least one order discount that combines with order discounts."
                  checked={formData?.combine_order_discount}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      combine_order_discount: e,
                    });
                  }}
                />
              </div>
              <div className="chargezen_form_row">
                <Checkbox
                  label="Shipping discounts"
                  helpText="No shipping discounts are set to combine. To let customers use more than one discount, set up at least one shipping discount that combines with order discounts."
                  checked={formData?.combine_shipping_discount}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      combine_shipping_discount: e,
                    });
                  }}
                />
              </div>
            </Card>
          )}

          {formData?.commission_type == 'discount_code' && (
            <Card
              title={
                <div className="heading_title">
                  <PixelIcon />
                  Active dates
                </div>
              }
              sectioned
            >
              <div className="chargezen_form_row">
                <label className="custom_label">
                  <span>Start date</span>
                  <input
                    type="date"
                    value={formData?.start_date}
                    onChange={(e) =>
                      setFormData({ ...formData, start_date: e.target.value })
                    }
                  />
                </label>
                <label className="custom_label">
                  <span>Start time (EST)</span>
                  <input
                    type="time"
                    value={formData?.start_time}
                    onChange={(e) =>
                      setFormData({ ...formData, start_time: e.target.value })
                    }
                  />
                </label>
              </div>
              <div className="chargezen_form_row">
                <Checkbox
                  label="Set end date"
                  checked={formData?.has_end_date}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      has_end_date: e,
                    });
                  }}
                />
              </div>
              {formData?.has_end_date && (
                <div className="chargezen_form_row">
                  <label className="custom_label">
                    <span>End date</span>
                    <input
                      type="date"
                      value={formData?.end_date}
                      onChange={(e) =>
                        setFormData({ ...formData, end_date: e.target.value })
                      }
                    />
                  </label>
                  <label className="custom_label">
                    <span>End time (EST)</span>
                    <input
                      type="time"
                      value={formData?.end_time}
                      onChange={(e) =>
                        setFormData({ ...formData, end_time: e.target.value })
                      }
                    />
                  </label>
                </div>
              )}
            </Card>
          )}
        </Layout.Section>
        <div className="defauleLayoutSection sdebar-right-cstm">
          <Layout.Section secondary>
            <Card title="Summary">
              <Card.Section>
                <strong className="summary_discount_code">
                  {formData?.commission_type == 'automatic_tracking'
                    ? formData?.automatic_tracking_title || 'No title yet'
                    : formData?.discount_code || 'No discount code yet'}
                </strong>
                <div class="sidbar-section seec-1">
                  <h3>Type and method</h3>
                  <ul class="Polaris-List">
                    <li>
                      {formData?.discount_type === 'PERCENTAGE'
                        ? `${formData?.discount_value || ''
                        } Percentage off order`
                        : `${formData?.discount_value || ''} Amount off order`}
                    </li>
                    <li>
                      {formData?.commission_type == 'automatic_tracking'
                        ? 'Automatic tracking'
                        : 'Discount Code'}
                    </li>
                  </ul>
                </div>
                <div class="sidbar-section seec-2">
                  <h3>Assigned Affiliate(s)</h3>
                  <ul class="Polaris-List">
                    {formData?.assoc_affiliates?.map((aff) => {
                      return <li>{aff?.email}</li>;
                    })}
                  </ul>
                </div>

                {formData?.commission_type == 'automatic_tracking' ? (
                  <div class="sidbar-section seec-3">
                    <h3>Performance</h3>
                    <p>Commission profile is not active yet</p>
                  </div>
                ) : (
                  <div class="sidbar-section seec-3">
                    <h3>Details</h3>
                    <List type="bullet">
                      <List.Item>{summaryText}</List.Item>
                    </List>
                  </div>
                )}
              </Card.Section>
            </Card>
          </Layout.Section>
        </div>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: 'Save',
              onClick: () => handleSubmit(),
            }}
            secondaryActions={[
              edit_id
                ? {
                  content: 'Delete',
                  onClick: () => deleteSubmit(),
                  destructive: true,
                }
                : '',
            ]}
          />
        </Layout.Section>
      </Layout>
    </Frame>
  );
};

export default CreateAffiliateCommission;
