import { Icon } from '@shopify/polaris';
import { SearchMajor } from '@shopify/polaris-icons';
import React, { useState } from 'react';
import SearchCollectionModal from './SearchCollectionModal';
import { ChevronDownMinor, MobileCancelMajor } from '@shopify/polaris-icons';

const SearchCollectionPicker = (props) => {
  const { formData, setFormData } = props;
  const [showCollectionModal, setShowCollectionModal] = useState(false);
  const [query, setQuery] = useState();
  return (
    <div className="product-variant-picker-container">
      {showCollectionModal && (
        <SearchCollectionModal
          {...{
            showCollectionModal,
            setShowCollectionModal,
            query,
            setQuery,
            formData,
            setFormData,
          }}
          fieldName={'collection'}
        />
      )}
      <div className="product_searchProduct">
        <div className="product_search_input">
          <Icon source={SearchMajor} tone="base" />
          <input
            type="text"
            placeholder="Search collection"
            id="search_product_query"
            onChange={(e) => {
              setQuery(e?.target?.value || '');
              setShowCollectionModal(true);
            }}
            value={query}
          />
        </div>
        <button
          type="button"
          className="browse_product_button"
          onClick={() => setShowCollectionModal(true)}
        >
          Browse
        </button>
      </div>
      {formData?.collection && (
        <div className="product_preview_row">
          <div className="product_variant_preview_wrapper">
            <div className="imgwrapper">
              <img src={formData?.collection?.image} />
            </div>
            <div className="pv_desc_container">
              <div className="product_variant_desc">
                <span>{formData?.collection?.title}</span>
              </div>
              <div className="edit_remove_wrapper">
                <button
                  type="button"
                  className="editbutton"
                  onClick={() => setShowCollectionModal(true)}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchCollectionPicker;
