import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import _, { values } from 'lodash';
import * as yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';

import { gql, useMutation, useLazyQuery } from '@apollo/client';
import './index.css';
import {
  Card,
  Button,
  Form,
  FormLayout,
  TextField,
  TextContainer,
  Frame,
  ContextualSaveBar,
  Select,
  Subheading,
  Toast,
  Banner,
  List,
  Page,
  ButtonGroup,
  TextStyle,
  Checkbox,
  Stack,
  Heading,
  Spinner,
  Autocomplete,
  Icon,
  Layout,
  PageActions,
} from '@shopify/polaris';
import SearchPlan from '../upsell/SearchPlan';
import LoadingScreen from '../LoadingScreen';
import PixelIcon from '../../assets/images/PixelIcon';
import SearchProduct from './SearchProduct';
import Preview from '../plans/Preview';
import SearchVariants from '../Rebuy/SearchVariants';
import SellingPlanModal from './SellingPlanModal';
import SellingPlanPicker from './SellingPlanPicker';
import ProductVariantPicker from '../plans/ProductVariantPicker';

const CreateSubscriptionProduct = (props) => {
  const { i18n, detailView } = props;
  const { edit_id } = useParams();

  const id = edit_id != "createNew" ? edit_id : null;
  const subTypeOptions = [
    { label: "Swap products", value: "swap_products" },
    { label: "Bundle products", value: "bundle_products" },
    { label: "Product journey", value: "product_journey" },
  ]

  var handleClose = () => { };
  const [disableForm, setDisableForm] = useState(false);

  const statusOptions = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Draft',
      value: 'draft',
    },
  ];

  const [initialValues, setInitialValues] = useState({
    status: 'active',
    subType: 'product_journey',
    sellingPlan: [],
    productImages: [],
    variantImages: [],
    contentHtml: "",
    richTitle: "",
  });
  const [editData, setEditData] = useState({});

  const [formErrors, setFormErrors] = useState([]);
  const [campaignData, setCampaignData] = useState(null);
  const [checkboxDisabled, setcheckboxDisabled] = useState(true);

  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);

  const validationSchema = yup.object().shape({
    // sellingPlan: yup.array().required().label("Please select a SellingPlan.")
    // publicName: yup.string().required().label('Public name'),
    // selectorTitle: yup.string().required().label('Campaign selector title'),
    // upsellCampaigns: yup.array().of(
    //   yup.object().shape({
    //     name: yup.string().required().label('Name'),
    //     selectorLabel: yup.string().required().label('Plan selector label'),
    // ruleCartValue: yup.object().shape({
    //   productId: yup.string().label('Only select'),
    // }),
    // productOffer: yup.object().shape({
    //   productId: yup.string().required().label('Only select'),
    // }),
    // ruleCustomerValue: yup.object().shape({
    //   sellingPlanId: yup.string().required().label('Only select'),
    // }),
    //   })
    // ),
  });

  const GET_SUBSCRIPTION_PRODUCT = gql`
    query ($id: ID!) {
      fetchSubscriptionProduct(id: $id) {
        id
        sellingPlan {
          sellingPlanId
          sellingPlanName
          selected
        }
        status
        richTitle
        contentHtml
        subType
        productImages {
          id
          title
          image
          variants {
            id
            title
            price
            selected
          }
        }
        variantImages {
          id
          variantId
          image
          title
          price
        }
        createdAt
      }
    }
  `;
  // const { id } = useParams();

  const [getSubscriptionProduct, { data, loading, error }] = useLazyQuery(
    GET_SUBSCRIPTION_PRODUCT,
    {
      variables: { id: id },
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (id && id != "createNew") {
      getSubscriptionProduct();
    }
  }, []);

  const UPDATE_BOX_CAMPAIGN = gql`
    mutation ($input: UpdateBuildABoxCampaignGroupInput!) {
      updateBoxCampaign(input: $input) {
        campaign {
          id
          internalName
        }
      }
    }
  `;
  const [updateBoxCampaign] = useMutation(UPDATE_BOX_CAMPAIGN);

  const CREATE_SUBSCRIPTION_PRODUCT = gql`
    mutation ($input: AddSubscriptionProductInput!) {
      addSubscriptionProduct(input: $input) {
        subscriptionProduct {
          id
        }
      }
    }
  `;

  const history = useHistory();
  const [createMemberShipGQL] = useMutation(CREATE_SUBSCRIPTION_PRODUCT);

  const formRef = useRef(null);

  const [selectedProductOptions, setSelectedProductOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [selectedFreeProductOptions, setSelectedFreeProductOptions] = useState(
    []
  );
  const [selectedFreeProducts, setSelectedFreeProducts] = useState([]);

  const [selectedCollectionOptions, setSelectedCollectionOptions] = useState(
    []
  );
  const [selectedFreeCollectionOptions, setSelectedFreeCollectionOptions] =
    useState([]);

  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedFreeCollections, setSelectedFreeCollections] = useState([]);

  const handleRemoveProduct = (index) => {
    setUpdated(true);
    setSelectedProducts(() => {
      let newSelectedProduct = [...(selectedProducts || [])];
      newSelectedProduct[index]._destroy = true;
      return newSelectedProduct;
    });
    setSelectedProductOptions([
      ...selectedProductOptions.slice(0, index),
      ...selectedProductOptions.slice(index + 1),
    ]);
  };

  const handleRemoveCollectionProduct = (collectionIndex, productIndex) => {
    setUpdated(true);
    setSelectedCollections(() => {
      let newSelectedCollection = [...(selectedCollections || [])];
      newSelectedCollection[collectionIndex].products[
        productIndex
      ]._destroy = true;
      return newSelectedCollection;
    });
  };

  //// All Search Plans
  const [allSelectedPlans, setAllSelectedPlans] = useState([]);

  const handleRemovePlan = (id) => {
    setUpdated(true);
    setAllSelectedPlans(
      allSelectedPlans.filter((plan) => plan.sellingPlanId !== id)
    );
  };

  // useEffect(() => {
  //   if (data && data?.fetchBuildABoxCampaignGroup) {
  //     data.fetchBuildABoxCampaignGroup.buildABoxCampaign.triggers =
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.triggers[0]?.name; //Manipulate later
  //     data.fetchBuildABoxCampaignGroup.buildABoxCampaign.endDate =
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.endDate || '';
  //     data.fetchBuildABoxCampaignGroup.buildABoxCampaign.startDate =
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.startDate || '';
  //     setCampaignData(data.fetchBuildABoxCampaignGroup);
  //     setSelectedProducts(
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.productImages
  //     );
  //     setSelectedProductOptions(() => {
  //       const defaultOption = [];
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.productImages?.map(
  //         (image) =>
  //           image._destroy == false && defaultOption.push(image.productId)
  //       );
  //       return defaultOption;
  //     });
  //     setSelectedCollections(
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.collectionImages
  //     );
  //     setSelectedCollectionOptions(() => {
  //       const defaultOption = [];
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.collectionImages?.map(
  //         (image) =>
  //           image._destroy == false && defaultOption.push(image.collectionId)
  //       );
  //       return defaultOption;
  //     });
  //     setAllSelectedPlans(
  //       data.fetchBuildABoxCampaignGroup.sellingPlans
  //     );
  //   }
  // }, [data]);

  const [updated, setUpdated] = useState(false);

  const [allProducts, setAllProducts] = useState([]);
  const [allVariants, setAllVariants] = useState([]);
  const [sellingPlanType, setSellingPlanType] = useState('all');

  useEffect(() => {
    if (data && data?.fetchSubscriptionProduct) {
      let fetchSubscriptionProduct = data.fetchSubscriptionProduct;
      delete fetchSubscriptionProduct['__typename'];
      delete fetchSubscriptionProduct['createdAt'];

      if (data?.fetchSubscriptionProduct?.subType == "all_products") {
        setDisableForm(true);
      }
      setInitialValues({ ...fetchSubscriptionProduct });
      setEditData({ ...fetchSubscriptionProduct });

      setAllProducts(() => {
        const defaultOption = data.fetchSubscriptionProduct.productImages?.map(
          (image) => {
            delete image.__typename;
            return image;
          }
        );

        return defaultOption;
      });

      setAllVariants(() => {
        const defaultOption = data.fetchSubscriptionProduct.variantImages?.map(
          (image) => {
            delete image.__typename;
            return image;
          }
        );

        return defaultOption;
      });
      if (data.fetchSubscriptionProduct?.sellingPlan) {
        setSellingPlanType('selling_plan');
      }

    } else {
      setInitialValues({
        status: 'active',
        subType: 'product_journey',
        sellingPlan: [],
        productImages: [],
        variantImages: [],
        contentHtml: "",
        richTitle: "",
      });
    }
  }, [data]);

  useEffect(() => {
    const plans = allSelectedPlans?.filter((s) => !!s);
    if (plans.length > 1) {
      setAllSelectedPlans([...[plans[plans.length - 1]]]);
    }
  }, [allSelectedPlans]);

  const getSelectedVariants = (products) => {
    let total = 0;
    try {
      products?.map((p) => {
        total += +(p.variants?.filter((v) => v.selected)?.length)
      })
    } catch (e) {
      console.log("Something went wrong...", e)
    }
    return total
  }
  return (
    <Frame>
      {loading && id && <LoadingScreen />}
      {(id && editData?.id) || (!id && initialValues) ? (
        <Formik
          validationSchema={validationSchema}
          initialValues={editData?.id ? editData : initialValues}
          innerRef={formRef}
          onSubmit={(values, { setSubmitting }) => {
            values.productImages ||= [];
            values.variantImages ||= [];
            const formData = { ...values };
            formData.sellingPlan ||= [];
            if (id) {
              formData.id = id;
            }

            // formData.upsellCampaigns[0].productOffer = allProducts;  Manipulate later

            //const variables = formatUpsellCampaignGroup(values);
            createMemberShipGQL({
              variables: { input: { params: formData } },
            })
              .then((resp) => {
                const data = resp.data;
                const errors = data.errors;
                if (errors) {
                  setFormErrors(errors);
                  setSubmitting(false);
                } else {
                  setSaveSuccess(true);
                  history.push('/customersTab/subscription_products');
                  handleClose();
                }
              })
              .catch((error) => {
                setSubmitting(false);
                setFormErrors(error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
            dirty,
            formik,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              {(dirty || updated) && (
                <ContextualSaveBar
                  message="Unsaved changes"
                  saveAction={{
                    onAction: handleSubmit,
                    loading: isSubmitting,
                    disabled: false,
                  }}
                  discardAction={{
                    onAction: () => {
                      //   canceledProducts.map(prod => {
                      //     allProducts.push(prod)
                      //   });

                      //   setAllProducts(allProducts);
                      //   setCanceledProducts(prod => prod = []);
                      setUpdated((flag) => (flag = false));
                      resetForm();
                    },
                  }}
                />
              )}

              {saveSuccess && (
                <Toast
                  content="Subscription Product is saved"
                  onDismiss={() => {
                    hideSaveSuccess();
                    window.location.reload();
                  }}
                />
              )}

              {formErrors.length > 0 && (
                <>
                  <Banner
                    title="Subscription Product could not be created"
                    status="critical"
                  >
                    <List type="bullet">
                      {formErrors.map((message, index) => (
                        <List.Item key={index}>{message.message}</List.Item>
                      ))}
                    </List>
                  </Banner>
                  <br />
                </>
              )}
              <Layout>
                <Layout.Section>
                  <div className="build-a-box-create">
                    <Card
                      title={
                        <div className='polaris_header_wrapper'>
                          <div className="heading_title">
                            Associated selling plan
                          </div>
                          <div className='heading_subtitle'>
                            Customers with these selling plans can swap to these products if there are no defined swap subscription products.
                          </div>
                        </div>

                      }
                      sectioned
                    >
                      <Card.Section>
                        <FormLayout>
                          <FormLayout.Group>
                            <Checkbox
                              label="All selling plans"
                              checked={sellingPlanType === 'all'}
                              onChange={() => {
                                setSellingPlanType('all');
                                setFieldValue('selling_plan', []);
                              }}
                              helpText="All customers can swap to these products."
                              disabled={disableForm}
                            />
                          </FormLayout.Group>
                          <FormLayout.Group>
                            <Checkbox
                              label="Choose selling plan"
                              checked={sellingPlanType === 'selling_plan'}
                              onChange={() => {
                                setSellingPlanType('selling_plan');
                              }}
                              helpText="Only customers with these selling plans can swap to these products ."
                              disabled={disableForm}
                            />
                          </FormLayout.Group>
                          {sellingPlanType === 'selling_plan' && (
                            <FormLayout.Group>
                              <div className="box-subscription-search">
                                <SellingPlanPicker
                                  idForTextField={`selling_plan-${Math.random()}`}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  fieldName={`sellingPlan`}
                                  i18n={i18n}
                                  disabled={disableForm}
                                  singleLine={true}
                                />
                              </div>
                            </FormLayout.Group>
                          )}
                        </FormLayout>
                      </Card.Section>
                    </Card>

                    <Card
                      title={
                        <div className="heading_title">
                          Subscription product type
                        </div>
                      }
                      sectioned
                    >
                      <Card.Section>
                        <FormLayout>
                          <FormLayout.Group>
                            {
                              disableForm ?
                                <Select
                                  options={["All products"]}
                                  label="Status"
                                  value={`${values.status}`}
                                  error={touched.status && errors.status}
                                  onChange={(e) => setFieldValue(`status`, e)}
                                  disabled={disableForm}
                                />
                                :
                                <Select
                                  options={subTypeOptions}
                                  label={
                                    values?.subType === "product_journey" ?
                                      `Create subscription benefits card in the customer portal`
                                      :
                                      values?.subType === "bundle_products  " ?
                                        `Create subscription benefits card in the customer portal`
                                        :
                                        values?.subType === "swap_products" ?
                                          "Customers with the above defined selling plan can only swap to these products."
                                          :
                                          "Represents products on your store currently associated with a selling plan."
                                  }
                                  value={`${values.subType}`}
                                  onChange={(e) => setFieldValue(`subType`, e)}
                                  disabled={disableForm}
                                />
                            }
                          </FormLayout.Group>
                        </FormLayout>
                      </Card.Section>
                    </Card>
                    <Card
                      title={
                        <div className="heading_title">
                          Product
                        </div>
                      }
                      sectioned
                    >
                      <Card.Section>
                        <FormLayout>
                          <FormLayout.Group>
                            <div className="box-subscription-search">
                              <ProductVariantPicker
                                fieldName="productImages"
                                {...{ setFieldValue, values }}
                                disabled={disableForm}
                              />
                            </div>
                          </FormLayout.Group>
                        </FormLayout>
                      </Card.Section>
                    </Card>
                    {
                      values?.subType == "product_journey" && (
                        <Card>
                          <Card.Section>
                            <FormLayout>
                              <FormLayout.Group>
                                <TextField
                                  label="Rich Title"
                                  value={`${values.richTitle}`}
                                  onChange={(e) => setFieldValue(`richTitle`, e)}
                                  multiline={5}
                                  helpText='Please enter/paste the heading html'
                                  disabled={disableForm}
                                />
                              </FormLayout.Group>

                              <FormLayout.Group>
                                <TextField
                                  label="Rich Content"
                                  value={`${values.contentHtml}`}
                                  onChange={(e) => setFieldValue(`contentHtml`, e)}
                                  multiline={25}
                                  helpText='Please enter/paste the Description html'
                                  disabled={disableForm}
                                />
                              </FormLayout.Group>
                            </FormLayout>
                          </Card.Section>
                        </Card>
                      )
                    }

                  </div>
                </Layout.Section>
                <div className="defauleLayoutSection sdebar-right-cstm">
                  <Layout.Section secondary>
                    <Card title={i18n.t('summary')}>
                      <Card.Section>
                        <div className='workflow_summary_wrapper_container'>
                          <div className='workflow_status_wrapper'>

                            <Select
                              options={statusOptions}
                              label="Status"
                              value={`${values.status}`}
                              error={touched.status && errors.status}
                              onChange={(e) => setFieldValue(`status`, e)}
                              disabled={disableForm}
                            />

                          </div>
                          <List type="bullet">
                            <List.Item>
                              {sellingPlanType === 'selling_plan' ? "Associated with specific selling plan" : "Associated with all selling plans"}
                            </List.Item>
                            <List.Item>
                              Type is {subTypeOptions?.find((option) => option.value === values.subType)?.label}
                            </List.Item>
                            <List.Item>
                              {values?.productImages?.length} products selected.
                            </List.Item>
                          </List>
                        </div>
                      </Card.Section>
                    </Card>
                  </Layout.Section>
                </div>
                <Layout.Section>
                  <PageActions
                    primaryAction={
                      {
                        content: i18n.t('save'),
                        onClick: () => {
                          handleSubmit();
                        }
                      }
                    }
                  // secondaryActions={[
                  //   editId
                  //     ? {
                  //       content: i18n.t('delete'),
                  //       destructive: true,
                  //       onClick: () => {
                  //         deleteWorkflow();
                  //       },
                  //     }
                  //     : {},
                  // ]}
                  />
                </Layout.Section>
              </Layout>
            </Form>
          )
          }
        </Formik >
      ) : (
        <></>
      )
      }
    </Frame >
  );
};

export default CreateSubscriptionProduct;
