import React, { useEffect, useState } from "react";
import { Card, Stack, TextField } from "@shopify/polaris";
import { GetStartedIcon } from "../../components/layout/CustomIcon";

const style_objects = [
    {
        category: "Passwordless Login",
        name: "Primary Theme color",
        key: "primary_theme_color",
        fields: {
            css_property: "color",
            type: "color",
            value: "#ffffff"
        }

    },
    {
        category: "Passwordless Login",
        name: "Secondary Theme color",
        key: "secondary_theme_color",
        fields: {
            css_property: "color",
            type: "color",
            value: "#ffffff"
        }

    },
    {
        category: "Passwordless Login",
        name: "Main font color",
        key: "main_font_color",
        fields: {
            css_property: "color",
            type: "color",
            value: ""
        }

    },
    {
        category: "Passwordless Login",
        name: "Secondary font color",
        key: "secondary_font_color",
        fields: {
            css_property: "color",
            type: "color",
            value: ""
        }

    },
    {
        category: "Passwordless Login",
        name: "Offset shadow color",
        key: "offset_shadow_color",
        fields: {
            css_property: "color",
            type: "color",
            value: ""
        }

    },
    {
        category: "Passwordless Login",
        name: "Button background color",
        key: "button_background_color",
        fields: {
            css_property: "color",
            type: "color",
            value: "#000000"
        }

    },
    {
        category: "Passwordless Login",
        name: "Button font color",
        key: "button_font_color",
        fields: {
            css_property: "color",
            type: "color",
            value: "#ffffff"
        }

    },
    {
        category: "Passwordless Login",
        name: "Error box color",
        key: "error_box_color",
        fields: {
            css_property: "background",
            type: "color",
            value: "#ce131399"
        }

    },
    {
        category: "Passwordless Login",
        name: "Error box font color",
        key: "error_box_font_color",
        fields: {
            css_property: "color",
            type: "color",
            value: "#ffffff"
        }

    }
];
const ManageStyle = (props) => {
    const { i18n, domain, setActive, setToasContent, selectedTemplateId } = props;
    const [styleObject, setStyleObject] = useState([...style_objects])
    const [formData, setFormData] = useState({ font_family: "", custom_css: [...style_objects] });
    const hangelStyleChange = (value, index) => {
        let new_styles = [...formData?.custom_css]
        new_styles[index].fields.value = value
        setFormData({ ...formData, custom_css: [...new_styles] })
    }

    const fetchPasswordlessCssSettings = () => {
        fetch(`/fetch_passwordless_css_settings?shopify_domain=${domain}&template_id=${selectedTemplateId}`,
            { method: "GET" }
        ).then(r => r.json())
            .then((data) => {
                if (data?.passwordless_custom_css) {
                    setFormData({ ...data?.passwordless_custom_css })
                }
            })
    }

    useEffect(() => {
        fetchPasswordlessCssSettings();
    }, [])

    const handleSubmit = () => {

        fetch("/passwordless_css_settings", {
            method: "POST",
            body: JSON.stringify({ data: formData, shopify_domain: domain, template_id: selectedTemplateId }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(r => r.json())
            .then((data) => {
                if (data?.success) {
                    setActive(true);
                    setToasContent("Saved successfully.")
                }
            })
    }

    return (
        <>
            <Card
                sectioned
                primaryFooterAction={{ content: "Save and continue", onAction: () => { handleSubmit() } }}
                footerActionAlignment="left"
            >
                <div className='card-box'>
                    <div className='rewards_settings main-title'>
                        <span className='heading_icons'>
                            <GetStartedIcon />
                        </span>
                        <span className="title_wrapper">{i18n.t("zennportal_form.style_setting")}</span>
                    </div>
                    <div className="zennportal_style_form_wrapper">
                        <TextField
                            type={"text"}
                            label={"Font family"}
                            placeholder=''
                            value={formData?.font_family}
                            onChange={(e) => { setFormData({ ...formData, font_family: e }) }}
                            autoComplete="off"
                        />
                        <div className="manage_colors">
                            {
                                formData?.custom_css?.map((style, i) => {
                                    return (
                                        <div className="sub_manage_color_row">
                                            <TextField
                                                type={style?.fields?.css_property}
                                                label={style?.name}
                                                placeholder=''
                                                value={style.fields?.value || "#000"}
                                                onChange={(e) => { hangelStyleChange(e, i) }}
                                                autoComplete="off"
                                            />
                                            {
                                                style?.fields?.css_property == "color" ?
                                                    <TextField
                                                        type={"text"}
                                                        label=""
                                                        placeholder=''
                                                        value={style.fields?.value || "#000"}
                                                        onChange={(e) => { hangelStyleChange(e, i) }}
                                                        autoComplete="off"
                                                    />
                                                    :
                                                    ""
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default ManageStyle


