import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Icon } from '@shopify/polaris';
import './HeaderButtons.css';
import { HorizontalDotsMinor, MobileCancelMajor } from '@shopify/polaris-icons';

const HeaderButtonsWithMore = ({ headerButtons, setHeaderButton, headerButton, dismiss, setDismiss }) => {
  const [dropDownButtonsIndexes, setDropDownButtonIndexes] = useState([]);
  const [toggleMoreButtons, setToggleMoreButtons] = useState(false);
  const RenderButtonDropDown = () => {
    return (
      <>
        {dropDownButtonsIndexes?.length > 0 && (
          <div className="chargezen_extra_header_buttons_container">
            <div
              className="more_icon_wrapper"
              onClick={() => setToggleMoreButtons(!toggleMoreButtons)}
            >
              <Icon source={HorizontalDotsMinor} color="base" />
            </div>
            {toggleMoreButtons && (
              <div className="drop_down_wrapper_container">
                {
                  (dismiss && setDismiss) && (
                    <Button
                      onClick={() => {
                        setDismiss(true);
                      }}
                    >
                      <span className="dismiss_wrapper_button">
                        <Icon source={MobileCancelMajor} color='base' />
                        Dismiss
                      </span>
                    </Button>
                  )
                }
                {dropDownButtonsIndexes?.map((buttonIndex) => {
                  let obj = headerButtons[buttonIndex];
                  return (
                    <Button
                      onClick={() => {
                        setHeaderButton(obj?.val);
                      }}
                      primary={headerButton === obj?.val ? true : false}
                    >
                      {obj?.name}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  const setDropDownButtons = () => {
    if (headerButtons?.length > 5) {
      let headerbuttons = [];
      for (let i = 6; i < headerButtons?.length; i++) {
        headerbuttons.push(i);
      }
      setDropDownButtonIndexes([...headerbuttons]);
    }
  };

  useEffect(() => {
    setDropDownButtons();
  }, [headerButtons]);

  return (
    <ButtonGroup>
      {headerButtons?.map((obj, index) => {
        if (index <= 5) {
          return (
            <Button
              onClick={() => {
                setHeaderButton(obj?.val);
              }}
              primary={headerButton === obj?.val ? true : false}
            >
              {obj?.name}
            </Button>
          );
        }
      })}
      <RenderButtonDropDown />
    </ButtonGroup>
  );
};

export default HeaderButtonsWithMore;