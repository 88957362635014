import { Button, Card, FormLayout, Layout, Page, TextField } from '@shopify/polaris';
import { method } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DomainContext } from '../domain-context';

import PixelIcon from '../../assets/images/PixelIcon';

const NewStaff = ({ setFormErrors, setSaveSuccess, refetch, setAddStaff, i18n, editStaff, setEditStaff }) => {
    const { domain } = useContext(DomainContext);
    const [checkedSettings, setCheckedSettings] = useState([]);
    const [formData, setFormData] = useState({ firstName: null, lastName: null, email: null, password: null, confirmPassword: null });

    const handleChange = (event) => {
        let settings = checkedSettings
        if (!settings.includes(event?.target?.value)) {
            settings.push(event?.target?.value)
        } else {
            let indexOf = settings?.indexOf(event?.target?.value)
            settings.splice(indexOf, 1)
        }
        setCheckedSettings([...settings])
    }

    const allSettings = [
        { val: "dashboard_access", name: "Dashboard", key: "dashboardAccess" },
        { val: "manage_plan_access", name: "Mange Plans", key: "mangePlanAccess" },
        { val: "subscription_orders_access", name: "Subscriptions Orders", key: "subscriptionsOrdersAccess" },
        { val: "analytics_access", name: "Analytics", key: "analyticsAccess" },
        { val: "installation_access", name: "Installation", key: "installationAccess" },
        { val: "tiazen_access", name: "Tiazen", key: "tiazensAccess" },
        { val: "toolbox_access", name: "Toolbox", key: "toolboxAccess" },
        { val: "settings_access", name: "Settings", key: "settingsAccess" },
        { val: "ways_to_earn", name: "WaysToEarn Point", key: "waysToEarnAccess" },
        { val: "customer_modal", name: "Customer Modal", key: "customerModalAccess" },
        { val: "manage_staff", name: "Manage Staff", key: "manageStaffAccess" },
    ]

    useEffect(() => {
        if (editStaff) {
            setFormData({
                id: editStaff.id,
                firstName: editStaff.firstName,
                lastName: editStaff.lastName,
                email: editStaff.email,
            })
            let permittedSettings = []
            allSettings?.map((setting) => {
                if (editStaff[setting.key]) {
                    permittedSettings.push(setting.val)
                }
            })
            setCheckedSettings([...permittedSettings])
        }
    }, [editStaff])

    const submitForm = () => {
        let success = true;
        let formErrors = []
        if (!formData?.email) {
            formErrors.push({ message: "Please Enter a email" })
            success = false
        }
        if (!formData?.password && !editStaff) {
            formErrors.push({ message: "Please Enter a Password" })
            success = false
        }
        if (formData?.password?.lenght < 6 && !editStaff) {
            formErrors.push({ message: "Minimum length of password should 6" })
            success = false
        } else if ((formData?.password != formData?.confirmPassword && !editStaff)) {
            formErrors.push({ message: "Password and confirm Password should be same" })
            success = false
        }
        if (success) {
            fetch("/user_shops/create_user_shop_child", {
                method: "POST",
                body: JSON.stringify({ user_data: formData, settings: checkedSettings, shopify_domain: domain }),
                headers: {
                    'Content-Type': 'application/json'
                },
            }
            ).then((response) => response.json())
                .then((data) => {
                    if (data?.error) {
                        formErrors.push({ message: JSON.stringify(data?.error) })
                        setFormErrors(formErrors)
                    } else {
                        refetch()
                        setAddStaff(false)
                        setSaveSuccess(success)
                    }
                })
        } else {
            setFormErrors(formErrors)
        }
    }
    return (
        <>
            <Page
                title={i18n.t('add_staff')}
            >
                <Card
                    title=
                    {
                        <div className="heading_title">
                            <PixelIcon />
                            {i18n.t('add_staff')}
                        </div>
                    }
                >
                    <Card.Section>
                        <Layout>
                            <Layout.Section>
                                <div className='add_staff_main'>
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <TextField
                                                label="First Name"
                                                type="text"
                                                value={formData?.firstName}
                                                onChange={(val) => { setFormData({ ...formData, firstName: val }) }}
                                            ></TextField>
                                            <TextField
                                                label="Last Name"
                                                value={formData?.lastName}
                                                onChange={(val) => { setFormData({ ...formData, lastName: val }) }}
                                            ></TextField>
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                label="Email"
                                                type="email"
                                                value={formData?.email}
                                                onChange={(val) => { setFormData({ ...formData, email: val }) }}
                                            ></TextField>
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                label="Password"
                                                type="password"
                                                value={formData?.password}
                                                onChange={(val) => { setFormData({ ...formData, password: val }) }}
                                            ></TextField>
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                label="Confirm Password"
                                                type="password"
                                                value={formData?.confirmPassword}
                                                onChange={(val) => { setFormData({ ...formData, confirmPassword: val }) }}
                                            ></TextField>
                                        </FormLayout.Group>
                                    </FormLayout>
                                </div>
                                <div className='add_staff_settings'>
                                    {
                                        allSettings?.map((setting) => {
                                            return (
                                                <label>
                                                    <input type='checkbox' checked={checkedSettings.includes(setting?.val) ? true : false} className='settings' value={setting?.val} onChange={(e) => handleChange(e)} />
                                                    {setting?.name}
                                                </label>
                                            )
                                        })
                                    }
                                </div>
                                <div className='submit staff_submit submit_button_wrapper'>
                                    <Button onClick={() => submitForm()}>Save Changes</Button>
                                    <Button onClick={() => { setEditStaff(); setAddStaff(false) }}>Cancel</Button>
                                </div>
                            </Layout.Section>
                        </Layout>
                    </Card.Section>
                </Card>
            </Page>
        </>
    )
}

export default NewStaff;