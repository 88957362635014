import { Button, Icon, Select } from '@shopify/polaris';
import { TextField } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { NotificationMajor, SearchMajor } from '@shopify/polaris-icons';
import './nav_style.css';
import Notifications from './Notifications';
import { useHistory } from 'react-router';
import ChangeStore from './ChangeStore';
import ChargeZenLogo from './../../assets/images/ChargeZen-Logo2.png';
import ManageApp from './ManageApp';
import axiosconfig from '../../Pages/axiosconfig';

const AppHeader = (props) => {
  const {
    domain,
    i18n,
    shopVersion,
    customNavTabs,
    setCustomNavTabs,
    fetchNavBarTabs,
    showManageAppsButton,
  } = props;
  const [showManageApps, setShowManageApps] = useState(false);

  const history = useHistory();
  const [selectedShop, setSelectedShop] = useState(props?.domain);
  const [activeNotification, setActiveNotification] = useState(false);

  const [allShops, setAllShops] = useState([]);
  const [currentUser, setCurrentUser] = useState();

  const handleSelectChange = useCallback((value) => {
    setSelectedShop(value);
    // localStorage.setItem("selectedShop", value)
    changeShop(value);
  }, []);

  useEffect(() => {
    const shopsArray = localStorage.getItem('allShops')
      ? JSON.parse(localStorage.getItem('allShops'))
      : '';
    setAllShops([...shopsArray]);
  }, [localStorage.getItem('allShops')]);

  useEffect(() => {
    setCurrentUser(localStorage.getItem('currentuser'));
  }, [localStorage.getItem('currentuser')]);

  const [value, setValue] = useState('');

  // useEffect(() => {
  //     setSelectedShop(localStorage.getItem("selectedShop"))
  // }, [localStorage.getItem("selectedShop")])

  const changeShop = (domain) => {
    axiosconfig.post(`/changeShop`, {
      shopify_domain: domain,
    }).then((data) => {
      window.location.replace(`/${domain?.replace('.myshopify.com', '')}`);
    });
  };

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  return (
    <>
      <div className="main_header">
        <div className="header_main_div">
          <div className="logo">
            <img src={ChargeZenLogo} />
            <div className="shop_tier_wrapper">
              {shopVersion == 'chargezen_lite'
                ? 'Lite'
                : shopVersion == 'chargezen_full'
                  ? 'Full'
                  : shopVersion == 'chargezen_test'
                    ? 'Test'
                    : ''}
            </div>
            {showManageAppsButton && (
              <div
                className={`manage_apps_icon_wrapper ${showManageApps ? 'activate' : ''
                  }`}
              >
                <div
                  className="manage_apps_wrapper"
                  onClick={() => setShowManageApps(!showManageApps)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-grid-3x3"
                  >
                    <rect width="18" height="18" x="3" y="3" rx="2" />
                    <path d="M3 9h18" />
                    <path d="M3 15h18" />
                    <path d="M9 3v18" />
                    <path d="M15 3v18" />
                  </svg>
                  <span className="text_wrapper">Apps</span>
                </div>
                <ManageApp
                  {...{
                    domain,
                    i18n,
                    showManageApps,
                    setShowManageApps,
                    customNavTabs,
                    setCustomNavTabs,
                    fetchNavBarTabs,
                  }}
                />
              </div>
            )}
          </div>
          <div className="first_div">
            <div className="search_div">
              <TextField
                value={value}
                onChange={handleChange}
                autoComplete="off"
                placeholder={`${i18n.t('search')}...`}
              />
              <div className="search_icon">
                <Icon source={SearchMajor} color="base" />
              </div>
            </div>
          </div>
          <div className="second_div">
            <div className="status">
              <span className="online_symbol" />
              Online
            </div>
            <div className="notification">
              <Button
                className="admin_notification_button"
                onClick={() => history.push('/notifications')}
              >
                <Icon source={NotificationMajor} color="base" />
              </Button>
            </div>
            <div className="userDetailMain">
              <div className="user_details">
                <div className="avatar">
                  <h2>{currentUser}</h2>
                </div>
                <div className="left_section">
                  <p className="changeStoreDiv">
                    <ChangeStore
                      allShops={allShops || []}
                      handleSelectChange={handleSelectChange}
                      selectedShop={selectedShop}
                      domain={props?.domain}
                      currentUser={currentUser}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AppHeader;
