import React, { useEffect, useState } from 'react';
import Nav from './Nav';
import Footer from './Footer';
import AppHeader from './AppHeader';
import './style.css';
import { useHistory } from 'react-router-dom';
import FloatingSetupGuide from '../../Pages/home/FloatingSetupGuide';
import Navigation from './Navigation';
import "./layout.scss";

const Layout = (props) => {
  const {
    domain,
    customNavTabs,
    setCustomNavTabs,
    floatingSetupGuide,
    setFloatingSetupGuide,
    fetchNavBarTabs,
    selectedApps,
    shopApiData,
    shopBillingStatus,
    shopVersion,
    currentPlan,
    setCurrentPlan,
  } = props;

  const history = useHistory();
  const [navCollapse, setNavCollapse] = useState(false);

  useEffect(() => {
    if (
      !window.location.pathname.includes('/choosePlan') &&
      !window.location.pathname.includes('/pickProduct')
    ) {
      if (selectedApps?.length <= 0) {
        fetchNavBarTabs();
        history.push(`/pickProduct`);
      } else {
        if (!shopApiData?.data?.plan?.partnerDevelopment) {
          if (
            (shopBillingStatus != "active") &&
            (selectedApps?.includes('revenueos') ||
              selectedApps?.includes('subscription_home') ||
              selectedApps?.includes('affiliate_influencer') ||
              selectedApps?.includes('sms_smarty'))
          ) {
            history.push('/choosePlan');
          }
        }
      }
    }
  }, [window.location.pathname]);

  return (
    <>
      <AppHeader
        showManageAppsButton={true}
        domain={props?.domain}
        i18n={props?.i18n}
        shopVersion={props?.shopVersion}
        customNavTabs={customNavTabs}
        setCustomNavTabs={setCustomNavTabs}
        fetchNavBarTabs={fetchNavBarTabs}
      />
      <div
        className={`module-navbar dashboardArea ${navCollapse ? 'collapsedNav' : ''
          }`}
      >
        <Navigation
          domain={props?.domain}
          setNavCollapse={setNavCollapse}
          navCollapse={navCollapse}
          i18n={props?.i18n}
          mixpanelId={props?.mixpanelId}
          shopVersion={props?.shopVersion}
          customNavTabs={customNavTabs}
          setCustomNavTabs={setCustomNavTabs}
        />
        <div
          className={`app_content ${floatingSetupGuide ? 'with_floating_setup_guide' : ''
            }`}
        >
          {props.children}
          {process.env.APP_TYPE == 'public' && <Footer i18n={props?.i18n} />}
          {floatingSetupGuide && (
            <div className="setup_guide_float_right_container">
              <FloatingSetupGuide
                {...{
                  customNavTabs,
                  floatingSetupGuide,
                  setFloatingSetupGuide,
                }}
              />
            </div>
          )}

          <br />
        </div>
      </div>
    </>
  );
};

export default Layout;
