import React, { useCallback, useEffect, useState } from 'react';
import { render } from 'react-dom';

import {
  AppProvider,
  TextField,
  Select,
  Collapsible,
  Icon,
  Popover,
  ActionList,
} from '@shopify/polaris';
import { StoreMajor } from '@shopify/polaris-icons';

import './ChangeStore.css';

const ChangeStore = (props) => {
  const { allShops, handleSelectChange, selectedShop, currentUser } = props;
  const [headerDropdown, setHeaderDropdown] = useState(false);
  const [all_shops, setallShops] = useState(allShops || []);
  const toggleHeaderDropdown = useCallback(
    () => setHeaderDropdown((headerDropdown) => !headerDropdown),
    []
  );

  function domainToAcronym(domain) {
    try {
      if (domain.includes('-')) {
        return domain
          .split('-')
          .map((d) => d[0]?.toUpperCase())
          .join('');
      } else if (domain.includes(' ')) {
        return domain
          .split(' ')
          .map((d) => d[0]?.toUpperCase())
          .join('');
      } else {
        return `${domain[0]?.toUpperCase()}${domain[1]?.toUpperCase()}`;
      }
    } catch (error) {
      return '';
    }
  }

  const headerActivator = (
    <button
      className="header_dropdown_button"
      onClick={() => toggleHeaderDropdown()}
    >
      <span>{selectedShop || 'Welcome back'}</span>
      <span className="icon-text">
        {domainToAcronym(selectedShop || localStorage.getItem('currentuser'))}
      </span>
    </button>
  );

  useEffect(() => {
    const shopsArray = localStorage.getItem('allShops')
      ? JSON.parse(localStorage.getItem('allShops'))
      : [];
    setallShops([...shopsArray]);
  }, [localStorage.getItem('allShops')]);

  return (
    <div className="header_drop_down_container" style={{ width: '100%' }}>
      <div>
        <Popover
          active={headerDropdown}
          activator={headerActivator}
          autofocusTarget="first-node"
          onClose={toggleHeaderDropdown}
        >
          <div className="header_dropdown_items_wrap hidden">
            <div className="sub_item_wrapper">
              <ul className="header_dropdown_items_wrapper ">
                {all_shops?.map((shop) => {
                  return (
                    <li
                      className={`header_dropdown_item ${selectedShop == shop ? 'active' : ''
                        }`}
                      onClick={() => {
                        handleSelectChange(shop);
                      }}
                    >
                      <span className="icon_text">{domainToAcronym(shop)}</span>
                      <span className="header_item_text">{shop}</span>
                    </li>
                  );
                })}
                <li className="header_dropdown_item">
                  <span className="header_icon-wrapper">
                    {<Icon source={StoreMajor} color="base" />}
                  </span>
                  <a href={`https://${window.location.host}`}>All stores</a>
                </li>
              </ul>
            </div>
            <div className="sub_item_wrapper">
              <div className="sub_item_heading">
                <h3 className="">{currentUser}</h3>
                {/* <h5 className="">alaw3813@gmail.com</h5> */}
              </div>
              <ul className="header_dropdown_items_wrapper">
                <li className="header_dropdown_item">
                  <span className="header_item_text">
                    <a
                      href={`/${selectedShop?.replace(
                        '.myshopify.com',
                        ''
                      )}/app-settings`}
                    >
                      Manage account
                    </a>
                  </span>
                </li>
                <li className="header_dropdown_item">
                  <span className="header_item_text">
                    <a href="/users/sign_out" data-method="delete">
                      Logout
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default ChangeStore;
