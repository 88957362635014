import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Stack,
  Modal,
  TextContainer,
  Autocomplete,
  Icon,
} from '@shopify/polaris';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { SearchMinor } from '@shopify/polaris-icons';

const SearchProduct = (props) => {
  const {
    i18n,
    formData,
    setFormData,
    index,
    field = null,
    subField = null,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(formData?.[field]?.[index]?.[subField] || []);


  const GET_PRODUCT = gql`
    query ($query: String!) {
      products(first: 10, query: $query) {
        edges {
          node {
            id
            title
            description
            featuredImage {
              id
              transformedSrc
            }
          }
        }
      }
    }
  `;

  const [productList, setProductList] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (field) {
      setSelectedProducts(formData?.[field]?.[index]?.[subField] || []);
    } else if (formData?.conditions[index]?.products) {
      setSelectedProducts(formData?.conditions[index]?.products);
    }
  }, []);

  useEffect(() => {
    if (field) {
      let form_data_actions = formData?.[field];
      form_data_actions[index][subField] = [...selectedProducts];
      setFormData({ ...formData, [field]: form_data_actions });
    } else {
      let form_data_conditions = formData?.conditions;
      form_data_conditions[index]['products'] = selectedProducts;
      setFormData({ ...formData, conditions: form_data_conditions });
    }
  }, [selectedProducts]);

  const [GetProducts, { loading, data, errors }] = useLazyQuery(GET_PRODUCT, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'shopify-link' },
  });

  useEffect(() => {
    if (data && data.products) {
      setProductList(() => {
        const result = [];
        data.products.edges.map((product) =>
          result.push({
            value: product.node.id,
            label: product.node.title,
            image: product.node.featuredImage?.transformedSrc,
            description: product.node.description,
          })
        );

        return result;
      });
    }
  }, [data]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);
      if (value) {
        GetProducts({ variables: { query: `title:*${value}*` } });
      }
    },
    [productList]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      value={inputValue}
      placeholder={i18n.t('search_products_to_add')}
    />
  );

  const removeTag = (index) => {
    setSelectedProducts(() => {
      let newSelectedProduct = [...(selectedProducts || [])];
      newSelectedProduct[index]._destroy = true;
      return newSelectedProduct;
    });
  };

  const handelSelected = (selected) => {
    if (
      selected.length >
      selectedProducts.filter((item) => item._destroy === false).length &&
      !selectedProducts.find((product) => product.value === selected[0])
    ) {
      setSelectedOptions(selected);
      setSelectedProducts(() => {
        let newProductList = [...selectedProducts];
        const newItemIndex = productList.findIndex(
          (item) => item.value === selected[0]
        );

        const isHave = newProductList.findIndex(
          (product) => product.productId === productList[newItemIndex].value
        );

        if (isHave != -1) {
          newProductList[isHave]._destroy = false;
        } else {
          newProductList.push({
            productId: productList[newItemIndex].value,
            image: productList[newItemIndex].image,
            title: productList[newItemIndex].label,
            description: productList[newItemIndex].description,
            _destroy: false,
          });
        }

        return newProductList;
      });
    } else {
      for (var i = 0; i < selectedProducts.length; i++) {
        if (!selected.find((item) => item === selectedProducts[i].productId)) {
          removeTag(i);
        }
      }

      setSelectedOptions(selected);
    }
  };

  const emptyState = (
    <React.Fragment>
      <Icon source={SearchMinor} />
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Could not find any results</TextContainer>
      </div>
    </React.Fragment>
  );

  return (
    <div className="">
      <div className="selected_plan_wrapper">
        {selectedProducts?.map((product, i) => {
          return (
            <div className="selectedPlan">
              <div
                className="removePlan"
                onClick={() => {
                  let allProducts = [...selectedProducts];
                  allProducts?.splice(i, 1);
                  setSelectedProducts([...allProducts]);
                }}
              >
                x
              </div>
              {product?.title}
            </div>
          );
        })}
      </div>
      <Autocomplete
        allowMultiple
        options={productList}
        selected={selectedOptions}
        textField={textField}
        onSelect={(selected) => handelSelected(selected)}
        listTitle="Suggested Tags"
        loading={loading}
        emptyState={emptyState}
        willLoadMoreResults={true}
      />
    </div>
  );
};

export default SearchProduct;
