import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import {
    Card,
    Frame,
    Page,
    Tabs,
    AppProvider,
    Form,
    FormLayout,
    TextField,
    Select,
    Button,
    RadioButton,
    ButtonGroup,
    Icon,
    Checkbox,
    Popover,
    ActionList
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import { useCallback } from "react";
import PixelIcon from "../../assets/images/PixelIcon";
import { ArrowDownMinor, ArrowUpMinor, DeleteMajor } from "@shopify/polaris-icons";
import CancellationReasons from "./CancellationReasons";
import "./style.css"
import HtmlEditor from "./HtmlEditor";
import Preview from "../plans/Preview";

import CheckProductTitle from "../Workflows/CheckProductTitle";
import SearchProduct from "../Workflows/SearchProduct";
import SearchPlan from "../Workflows/SearchPlan";
import SearchPlanGroup from "../Workflows/SearchPlanGroup";
import SearchVariants from "../Workflows/SearchVariants";
import SearchUpsellVariants from "./SearchUpsellVariants";

const EditCancellationUpsell = (props) => {
    const {
        i18n,
        selectedTemplateId,
        domain,
        toggleActive,
        edit,
        showEdit,
        setToastContent,
        fetch_cancellation_flow
    } = props;

    const [formData, setFormData] = useState();
    const [allFreeVariants, setAllFreeVariants] = useState([]);
    const [allSwapVariants, setAllSwapVariants] = useState([]);
    const [isUpdated, setUpdated] = useState(false);
    const [showCondtionOption, setShowCondtionOption] = useState(true);
    const [allPlans, setAllPlans] = useState([]);
    const [allSelectedPlanGroups, setAllSelectedPlanGroups] = useState([]);
    const toggleConditionActive = useCallback(() => setShowCondtionOption((showCondtionOption) => !showCondtionOption), []);
    const handleSubmit = () => {
        fetch('/customer_portal/cancellation_upsell', {
            method: 'POST',
            body: JSON.stringify({ data: formData, shopify_domain: domain }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(r => r.json()).then((data) => {
            if (data?.success) {
                showEdit();
                setToastContent("Saved Successfully");
                fetch_cancellation_flow();
            }
        })
    }

    const activator = (
        <Button onClick={toggleConditionActive} disclosure>
            Add condition
        </Button>
    );

    useEffect(() => {
        if (edit) {
            setFormData(edit)
        }
    }, [edit])

    const upsellsTypes = [
        { label: "Free product", value: "free_product" },
        { label: "Billing cycle discount", value: "billing_cycle_discount" },
        { label: "Product swap", value: "product_swap" }
    ]

    const allCondtions = [
        {
            title: 'SELLING PLAN',
            items: [
                { content: 'any_selling_plans' },
                { content: 'any_selling_plan_groups' },
            ],
        },
        {
            title: 'PRODUCT VARIANT',
            items: [
                { content: 'any_variant_matching_title' },
                { content: 'all_selected_variants' },
                { content: 'any_selected_variants' },
                { content: 'not_contain_any_selected_variant' },
            ],
        },
        {
            title: 'CUSTOMER PROPERTIES',
            items: [
                { content: 'Shown to customers who have spent a certain amount' },
            ],
        },
        {
            title: 'OTHER',
            items: [
                { content: 'Shown to customers who have active contract for x amount of time.' }, ,
            ],
        },
    ]

    const handleActionCondtions = (content) => {
        let formConditions = formData?.conditions || [];
        formConditions?.push({ condition_title: content });
        setFormData({ ...formData, conditions: [...formConditions] })
    }

    const renderConditions = (condition, index) => {
        const conditionsComponents = {
            "any_selling_plans": (

                <SearchPlan
                    idForTextField={`searchPlan-${Math.random()}`}
                    allSelectedPlans={allPlans || []}
                    setAllSelectedPlans={setAllPlans}
                    setFormData={setFormData}
                    formData={formData}
                    index={index}
                    i18n={i18n}
                />

            ),
            "any_selling_plan_groups": (

                <SearchPlanGroup
                    allSelectedPlans={allSelectedPlanGroups}
                    setAllSelectedPlans={setAllSelectedPlanGroups}
                    i18n={i18n}
                    formData={formData}
                    setFormData={setFormData}
                    index={index}
                />

            ),
            "any_product_matching_title": <CheckProductTitle setFormData={setFormData} formData={formData} index={index} />,
            "all_selected_products": <SearchProduct i18n={i18n} setFormData={setFormData} formData={formData} index={index} />,
            "any_selected_products": <SearchProduct i18n={i18n} setFormData={setFormData} formData={formData} index={index} />,
            "not_contain_any_selected_product": <SearchProduct i18n={i18n} setFormData={setFormData} formData={formData} index={index} />,

            "any_variant_matching_title": <SearchVariants i18n={i18n} setFormData={setFormData} formData={formData} index={index} />,
            "all_selected_variants": <SearchVariants i18n={i18n} setFormData={setFormData} formData={formData} index={index} />,
            "any_selected_variants": <SearchVariants i18n={i18n} setFormData={setFormData} formData={formData} index={index} />,
            "not_contain_any_selected_variant": <SearchVariants i18n={i18n} setFormData={setFormData} formData={formData} index={index} />,
            "no_active_subcription": <></>,
            'Shown to customers who have active contract for x amount of time.': <TextField type="number" value={formData?.min_amount_spend} onChange={(e) => { setFormData({ ...formData, active_contract_time: e }) }} label="Active contract for amount of time" />,
            "Shown to customers who have spent a certain amount": <TextField type="number" value={formData?.min_amount_spend} onChange={(e) => { setFormData({ ...formData, min_amount_spend: e }) }} label="Minimum amount spent" />,
        };
        return (
            conditionsComponents[condition]
        );
    }

    return (
        <div className="translations_form_wrapper cancellation_upsell_wrapper">
            <Card title=
                {
                    <div className="heading_title">
                        <PixelIcon />
                        {formData?.id ? "Edit" : "Add"} Cancellation Upsell
                    </div>
                }>
                <Card.Section>
                    <div className="content_wrapper_container cancellation_upsell_container">
                        <div className="form_wrapper">
                            <Form>
                                <FormLayout>
                                    <div className="show_cancellation_instructions_container cancellation_flow_row_wrapper">
                                        <FormLayout.Group>
                                            <Select
                                                label="Upsell type"
                                                options={upsellsTypes}
                                                value={formData?.upsell_type}
                                                onChange={(e) => { setFormData({ ...formData, upsell_type: e }) }}
                                            />
                                        </FormLayout.Group>
                                        <FormLayout.Group>
                                            <TextField
                                                label="Upsell title1"
                                                name=""
                                                value={formData?.title1}
                                                onChange={(e) => { setFormData({ ...formData, title1: e }) }}
                                            />
                                            <TextField
                                                label="Upsell title2"
                                                name=""
                                                value={formData?.title2}
                                                onChange={(e) => { setFormData({ ...formData, title2: e }) }}
                                            />
                                        </FormLayout.Group>

                                        <FormLayout.Group>
                                            <TextField
                                                label="Upsell terms"
                                                name=""
                                                value={formData?.upsell_terms}
                                                onChange={(e) => { setFormData({ ...formData, upsell_terms: e }) }}
                                            />
                                            <TextField
                                                label="Button text"
                                                name=""
                                                value={formData?.cta_button_text}
                                                onChange={(e) => { setFormData({ ...formData, cta_button_text: e }) }}
                                            />
                                        </FormLayout.Group>
                                        {
                                            formData?.upsell_type == "free_product" ?
                                                <FormLayout.Group>
                                                    <div className='selectVariantMain'>
                                                        <h2 className="custom_label_wrapper">Select free product</h2>
                                                        <div className="product-search">
                                                            <SearchUpsellVariants
                                                                value={formData?.free_variant || [[]]}
                                                                fieldName={`free_variant`}
                                                                allVariants={allFreeVariants || [[]]}
                                                                setAllVarients={setAllFreeVariants}
                                                                i18n={i18n}
                                                                setFormData={setFormData}
                                                                formData={formData}
                                                            />
                                                        </div>
                                                        <Preview
                                                            isUpdate={false}
                                                            allProducts={allFreeVariants || [[]]}
                                                            setAllProducts={setAllFreeVariants}
                                                            setUpdated={setUpdated}
                                                            i18n={i18n}
                                                        />
                                                    </div>
                                                    <TextField
                                                        label="Add free products to next billing cycles"
                                                        type="number"
                                                        value={formData?.free_variants_billing_cycles}
                                                        onChange={(e) => { setFormData({ ...formData, free_variants_billing_cycles: e }) }}
                                                    />
                                                </FormLayout.Group>
                                                :
                                                formData?.upsell_type == "product_swap" ?
                                                    <FormLayout.Group>
                                                        <div className="search_variant_wrapper">
                                                            <div className="swap_all_products_wrapper">
                                                                <Checkbox
                                                                    label="Show all products to swap"
                                                                    checked={formData?.swap_all_products}
                                                                    onChange={(e) => { setFormData({ ...formData, swap_all_products: e }) }}
                                                                />
                                                            </div>
                                                            {
                                                                !formData?.swap_all_products && (

                                                                    <>
                                                                        <h2 className="custom_label_wrapper">Select products to swap</h2>
                                                                        <div className='selectVariantMain'>
                                                                            <div className="product-search">
                                                                                <SearchUpsellVariants
                                                                                    value={formData?.swap_variant || [[]]}
                                                                                    fieldName={`swap_variant`}
                                                                                    allVariants={allSwapVariants || [[]]}
                                                                                    setAllVarients={setAllSwapVariants}
                                                                                    i18n={i18n}
                                                                                    setFormData={setFormData}
                                                                                    formData={formData}
                                                                                />
                                                                            </div>
                                                                            <Preview
                                                                                isUpdate={false}
                                                                                allProducts={allSwapVariants || [[]]}
                                                                                setAllProducts={setAllSwapVariants}
                                                                                setUpdated={setUpdated}
                                                                                i18n={i18n}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </FormLayout.Group>
                                                    :
                                                    <FormLayout.Group>
                                                        <TextField
                                                            type="number"
                                                            value={formData?.billing_cycle_discount}
                                                            prefix="%"
                                                            label="Billing cycle discount"
                                                            onChange={(e) => { setFormData({ ...formData, billing_cycle_discount: e }) }}
                                                        />
                                                        <TextField
                                                            type="number"
                                                            value={formData?.billing_cycle_discount_cycle}
                                                            prefix="%"
                                                            label={`Billing cycle discount for next ${formData?.billing_cycle_discount_cycle} cycles`}
                                                            onChange={(e) => { setFormData({ ...formData, billing_cycle_discount_cycle: e }) }}
                                                        />
                                                    </FormLayout.Group>
                                        }
                                        <FormLayout.Group>
                                            <div className="add_condition_wrapper">
                                                <h2>Add Conditions</h2>
                                                <div className='selected_conditions_wrapper'>
                                                    {
                                                        formData?.conditions?.map((condition, i) => {
                                                            return (
                                                                <div className='searchPlanWrapper selected_conditions_wrapper'>
                                                                    <h3>{condition?.condition_title}</h3>
                                                                    {renderConditions(condition?.condition_title, i)}
                                                                    <div className='removeConditionButtonWrapper'>
                                                                        <button className='remove_condition_button' onClick={() => {
                                                                            let all_conditions = formData?.conditions;
                                                                            all_conditions?.splice(i, 1);
                                                                            setFormData({ ...formData, conditions: [...all_conditions] })
                                                                        }}>
                                                                            <Icon
                                                                                source={DeleteMajor}
                                                                                color="base"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className='add_condition_wrapper'>
                                                    <Popover
                                                        active={showCondtionOption}
                                                        activator={activator}
                                                        autofocusTarget="first-node"
                                                        onClose={toggleConditionActive}
                                                    >
                                                        <ActionList
                                                            actionRole="menuitem"
                                                            sections={
                                                                allCondtions?.map((condition) => {
                                                                    return (
                                                                        {
                                                                            title: condition?.title,
                                                                            items: condition?.items?.map((item) => {
                                                                                return (
                                                                                    {
                                                                                        content: item?.content,
                                                                                        onAction: () => { handleActionCondtions(item?.content) }
                                                                                    }
                                                                                )
                                                                            })
                                                                        }
                                                                    )
                                                                })
                                                            }
                                                        />
                                                    </Popover>
                                                </div>
                                            </div>
                                        </FormLayout.Group>
                                    </div>

                                </FormLayout>
                            </Form>
                        </div>
                    </div>
                    <div className="submit_button_wrapper">
                        <Button onClick={() => { handleSubmit() }}>Save</Button>
                    </div>
                </Card.Section>
            </Card>

        </div>
    );
};

export default EditCancellationUpsell
