import React, { useEffect, useState } from 'react'
import ExportModal from './ExportModal';
import axiosconfig from '../../axiosconfig';

const ForecastInventory = (props) => {
    const { domain, dateFilter, setDateFilter, showExport, setShowExport, toastContent, setToastContent, selectedDates, customMainButtons, setCustomMainButtons, shopEmail } = props;

    const [lineItems, setLineItems] = useState([]);

    const fetchInventory = () => {
        if (dateFilter || (selectedDates?.start && selectedDates?.end)) {
            axiosconfig.get(`/inventory_forecast_index?shop=${domain}&billing_range=${dateFilter || ''}&start_date=${selectedDates?.start || ''}&end_date=${selectedDates?.end || ''}`)
                .then(({ data }) => {
                    if (data?.line_items?.length > 0) {
                        setLineItems([])
                        setLineItems([...data?.line_items])
                    }
                })
        }
    }

    useEffect(() => {
        fetchInventory();
        setCustomMainButtons([{
            name: "Export",
            onClick: () => { setShowExport(true) }
        }])
    }, [dateFilter, selectedDates])
    return (
        <div className='forecastInventory_container'>
            {
                showExport && (
                    <ExportModal setShowExport={setShowExport} showExport={showExport} selectedDates={selectedDates} domain={domain} apiUrl='/export_forecast_inventory' {...{ toastContent, setToastContent, shopEmail }} dateFilter={dateFilter} />
                )
            }
            <table className='forecastInventory_table'>
                <thead>
                    <tr>
                        <th>Product ID</th>
                        <th>Variant ID</th>
                        <th>Product name</th>
                        <th>Product SKU</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lineItems?.map((lineItem) => {
                            return (
                                <tr>
                                    <td>{lineItem?.product_id}</td>
                                    <td>{lineItem?.variant_id}</td>
                                    <td>{lineItem?.product_name}</td>
                                    <td>{lineItem?.sku}</td>
                                    <td>{lineItem?.quantity}</td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    )
}

export default ForecastInventory
