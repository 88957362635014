import mixpanel from "mixpanel-browser";
import React, { useEffect, useLayoutEffect, useState } from "react";
import CustomScrollbar from "./CustomScrollbar";
import "./yoda.css";
function YodaRevenue(props) {
    const { mixpanelId } = props;
    const [messageInput, setMessageInput] = useState("");
    const [messages, setMessages] = useState([]);
    const [currentModal, setCurrentModal] = useState("Yoda");
    const [currentMixpanel, setCurrentMixpanel] = useState();
    const [allModals, setAllModals] = useState([
        "Yoda",
        "Shuri",
        "Hermione Granger",
        "Sherlock Holmes",
        "Aang"
    ]);

    const TextWithNewLines = (text) => {
        const textArray = text?.split("\n\n");

        return (
            <>
                {textArray.map((item, index) => (
                    <React.Fragment key={index}>
                        {item}
                        {index !== textArray.length - 1 && <br />}
                    </React.Fragment>
                ))}
            </>
        );
    };

    const handleSubmit = async (currentMessages) => {
        setMessageInput("");
        try {
            const response = await fetch("/chat_gpt", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ message: messageInput, character: currentModal }),
            });
            const data = await response.json();

            // Update the response for the question
            setMessages((prevMessages) =>
                prevMessages.map((message, index) => {
                    if (index === currentMessages.length - 1) {
                        return { ...message, response: data.response, character: currentModal };
                    }
                    return message;
                })
            );

        } catch (error) {
            console.error("Error fetching ChatGPT response:", error);
        }
    };


    const MIN_TEXTAREA_HEIGHT = 0;
    const textareaRef = React.useRef(null);
    const onChange = (event) => setMessageInput(event.target.value);

    useLayoutEffect(() => {
        // Reset height - important to shrink on delete
        textareaRef.current.style.height = "inherit";
        // Set height
        textareaRef.current.style.height = `${Math.max(
            textareaRef.current.scrollHeight,
            MIN_TEXTAREA_HEIGHT
        )}px`;
    }, [messageInput]);

    const [showModelOptions, setShowModalOptions] = useState(false);

    //Initialise Mixpanel
    mixpanel.init(mixpanelId, {
        debug: true, loaded: function () {
            setCurrentMixpanel(mixpanel.get_distinct_id());
        }
    });

    const getMixpanelUser = () => {
        fetch(`/getMixpanelUser/${currentMixpanel}`, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(response => response.json())
            .then((data) => {
                if (data?.user) {
                    mixpanel.track("GPT-4 Revenue Copilot", { shop: `${props?.domain}`, userEmail: `${data?.user}` })
                } else {
                    mixpanel.track("GPT-4 Revenue Copilot", { shop: `${props?.domain}`, userEmail: `${localStorage.getItem("currentuser")}` })
                }
            })
    }
    useEffect(() => {
        if (currentMixpanel) {
            getMixpanelUser();
        }
    }, [currentMixpanel])

    useEffect(() => {
        if (
            messages[messages.length - 1]?.question &&
            !messages[messages.length - 1]?.response
        ) {
            handleSubmit([...messages]);
        }
    }, [messages.length]);

    return (
        <div className={`yodaRevenue_main ${currentModal}`}>
            <CustomScrollbar />
            <div className={`yodaRevenue_main_sub`}>
                <div className="model_selector_main">
                    <div className="model_selector_sub">
                        <button
                            className="model_selector_main"
                            id=""
                            type="button"
                            onClick={() => setShowModalOptions(!showModelOptions)}
                        >
                            <label className="heading" data-headlessui-state="">
                                Character
                            </label>
                            <span className="model_name">
                                <span className="model_name_sub">{currentModal}</span>
                            </span>
                            <span className="icon">
                                <svg
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    className="h-4 w-4  text-gray-400"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                            </span>
                            {showModelOptions && (
                                <div className="model_options_main">
                                    <ul class="modalOptions">
                                        {allModals?.map((modal) => {
                                            return (
                                                <li
                                                    onClick={() => setCurrentModal(modal.split(" ").join("-"))}
                                                    class={currentModal === modal.split(" ").join("-") ? "active" : ""}
                                                >
                                                    {modal}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                        </button>
                    </div>
                </div>
                <div className="body_content">
                    {messages?.map((message) => {
                        if (message?.character == currentModal) {
                            return (
                                <>
                                    <div className="question_main_div">
                                        <div className="question_sub_div">
                                            {message?.question}
                                        </div>
                                    </div>
                                    <div className="answer_main_div">
                                        <div className={`answer_sub_div ${message?.response ? "" : "typing"}`}>
                                            {message?.response ? TextWithNewLines(message?.response) : "typing ......"}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })}
                </div>
            </div>
            <div className="footer_main">
                <div className="message_sender_main">
                    <div className=""></div>
                    <div className="message_sender_sub">
                        <textarea
                            tabindex="0"
                            data-id="root"
                            rows="1"
                            placeholder="Send a message..."
                            className=""
                            ref={textareaRef}
                            value={messageInput}
                            style={{
                                maxHeight: "200px",
                                height: "24px",
                                overflowY: "auto",
                                width: '95%',
                                paddingRight: '10px'
                            }}
                            onChange={onChange}
                        ></textarea>
                        <button
                            className="send_message_button"
                            onClick={() => {
                                setMessages([
                                    ...messages,
                                    {
                                        character: currentModal,
                                        question: messageInput,
                                        response: null
                                    }
                                ]);
                            }}
                        >
                            <svg
                                stroke="currentColor"
                                fill="none"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="h-4 w-4 mr-1"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <line x1="22" y1="2" x2="11" y2="13"></line>
                                <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="footer_links">
                    <span>
                        <a href="#" target="_blank" rel="noreferrer" className="underline">
                            Yoda Mar 23 Version
                        </a>
                        . YodaRevenueCopilot may produce inaccurate information about
                        people, places, or facts
                    </span>
                </div>
            </div>
        </div >
    );
}

export default YodaRevenue;
