import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Card,
  Toast,
  ChoiceList,
  Page,
  PageActions,
  TextField,
  Layout,
  Banner,
  List,
  Frame,
  Select,
  Icon,
  Checkbox,
  RadioButton,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
  TextContainer,
  Button,
  Popover,
  ActionList,
} from '@shopify/polaris';
import PixelIcon from '../../assets/images/PixelIcon';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
  DeleteMajor,
} from '@shopify/polaris-icons';
import SearchVariants from './SearchVariants';
import SearchPlan from './SearchPlan';
import SearchPlanGroup from './SearchPlanGroup';
import CheckProductTitle from './CheckProductTitle';
import SearchProduct from './SearchProduct';
import ProductTag from './ProductTag';
import { useHistory } from 'react-router-dom';
import ProductVariantPicker from './Components/ProductVariantPicker';

const CreateWorkflow = (props) => {
  const {
    i18n,
    editId,
    setEdit,
    setCreateForm,
    detailView,
    setDetailView,
    domain,
    fetchWorkflows,
    setCustomHeader
  } = props;

  const [fieldError, setFieldError] = useState({
    title: '',
    pointsAwarded: '',
  });

  const statusOptions = [{ label: "Active", value: "active" }, { label: "Draft", value: "draft" }]

  const [formData, setFormData] = useState({
    conditions: [],
    events: [],
    actions: [],
    status: statusOptions[0].value
  });

  const all_events = [
    {
      id: 'subscription_created',
      value: 'Subscription is created',
    },
    {
      id: 'subscription_paused',
      value: 'Subscription is paused',
    },
    {
      id: 'subscription_resumed',
      value: 'Subscription is resumed',
    },
    {
      id: 'subscription_cancelled',
      value: 'Subscription is cancelled',
    },
    {
      id: 'subscription_expires',
      value: 'Subscription expires',
    },
    {
      id: 'subscription_payment_retrying',
      value: 'Subscription payment fails (retrying)',
    },
    {
      id: 'subscription_payment_last_attempt',
      value: 'Subscription payment fails (last attempt)',
    },
    {
      id: 'subscription_payment_succeeds',
      value: 'Subscription payment succeeds',
    },
  ];

  const [showCondtionOption, setShowCondtionOption] = useState(true);
  const [showAddAction, setShowAddAction] = useState(true);
  const [summaryText, setSummaryText] = useState('');

  const [allSelectedPlanGroups, setAllSelectedPlanGroups] = useState([]);
  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(
    () => history.push(`/customersTab/subscription_workflow`),
    []
  );
  const [allConditions, setAllConditions] = useState([]);
  const history = useHistory();

  const toggleActive = useCallback(
    () => setShowCondtionOption((showCondtionOption) => !showCondtionOption),
    []
  );
  const toggleAddActionAction = useCallback(
    () => setShowAddAction((showAddAction) => !showAddAction),
    []
  );

  const activator = (
    <Button onClick={toggleActive} disclosure>
      Add condition
    </Button>
  );

  const addActionActivator = (
    <Button onClick={toggleAddActionAction} disclosure>
      Add Action
    </Button>
  );

  const allCondtions = [
    {
      title: 'SELLING PLAN',
      items: [
        { content: 'any_selling_plans' },
        { content: 'any_selling_plan_groups' },
      ],
    },
    {
      title: 'PRODUCT',
      items: [
        { content: 'any_product_matching_title' },
        { content: 'all_selected_products' },
        { content: 'any_selected_products' },
        { content: 'not_contain_any_selected_product' },
      ],
    },
    {
      title: 'PRODUCT VARIANT',
      items: [
        { content: 'any_variant_matching_title' },
        { content: 'all_selected_variants' },
        { content: 'any_selected_variants' },
        { content: 'not_contain_any_selected_variant' },
      ],
    },
    {
      title: 'CUSTOMER PROPERTIES',
      items: [
        { content: 'customer_tag_present' },
        { content: 'customer_tag_not_present' },
        { content: 'total_orders_placed_by_customer' },
        { content: 'total_spent_by_a_customer' },
      ],
    },
    {
      title: 'OTHER',
      items: [{ content: 'no_active_subcription' }],
    },
  ];

  const allActions = [
    {
      title: 'SUBSCRIPTION BASED',
      items: [
        { content: 'add_discount' },
        { content: 'remove_discount' },
        { content: 'add_trial_period' },
        { content: 'change_subscription_plan' },
      ],
    },
    {
      title: 'PRODUCT BASED',
      items: [
        { content: 'add_one_time_product' },
        { content: 'add_subscription_product' },
        { content: 'remove_product_one_time' },
        { content: 'remove_product_from_subscription' },
        { content: 'swap_product' },
      ],
    },
    {
      title: 'CUSTOMER BASED',
      items: [
        { content: 'add_tag_to_customer' },
        { content: 'remove_tag_from_customer' },
      ],
    },
    {
      title: 'OTHER',
      items: [{ content: 'add_tag_to_order' }],
    },
  ];

  const handleActionCondtions = (content) => {
    let formConditions = [...formData?.conditions];
    formConditions?.push({ condition_title: content });
    setFormData({ ...formData, conditions: [...formConditions] });
  };

  const handleActions = (action) => {
    let formActions = [...formData?.actions];
    formActions?.push({ title: action });
    setFormData({ ...formData, actions: formActions });
  };

  useEffect(() => {
    let condtion_operator = formData?.check_all_condtions ? 'and' : 'or';
    let conditions = formData?.conditions
      ?.map((c) => i18n.t(`workflow_conditions.${c.condition_title}`))
      ?.join(` ${condtion_operator} `);
    let form_events = formData?.events?.join(' or ');
    let form_data = formData;
    let actions = formData?.actions?.map((a) => { return `${a?.title}` })?.join(" ")
    form_data.summary_condition = conditions;
    form_data.summary_events = form_events;
    form_data.summary_actions = actions;

    setFormData({ ...form_data });
  }, [formData?.conditions, formData?.events, formData?.check_all_condtions, formData?.actions]);

  const handleConditionChange = (val, key, index) => {
    let conditions = formData?.conditions;
    conditions[index][key] = val;
    setFormData({ ...formData, conditions: conditions });
  };

  const renderConditions = (condition, index) => {
    const conditionsComponents = {
      any_selling_plans: (
        <SearchPlan
          idForTextField={`searchPlan-${Math.random()}`}
          setFormData={setFormData}
          formData={formData}
          index={index}
          i18n={i18n}
        />
      ),
      any_selling_plan_groups: (
        <SearchPlanGroup
          idForTextField={`searchPlanGroup-${Math.random()}`}
          allSelectedPlans={allSelectedPlanGroups || []}
          setAllSelectedPlans={setAllSelectedPlanGroups}
          i18n={i18n}
          formData={formData}
          setFormData={setFormData}
          index={index}
        />
      ),
      any_product_matching_title: (
        <CheckProductTitle
          setFormData={setFormData}
          formData={formData}
          index={index}
        />
      ),
      all_selected_products: (
        <ProductVariantPicker
          i18n={i18n}
          setFormData={setFormData}
          formData={formData}
          index={index}
          field="conditions"
          subField="products"
          selectType="product"
        />
      ),
      any_selected_products: (
        <ProductVariantPicker
          i18n={i18n}
          setFormData={setFormData}
          formData={formData}
          index={index}
          field="conditions"
          subField="products"
          selectType="product"
        />
      ),
      not_contain_any_selected_product: (
        <ProductVariantPicker
          i18n={i18n}
          setFormData={setFormData}
          formData={formData}
          index={index}
          field="conditions"
          subField="products"
          selectType="product"
        />
      ),

      any_variant_matching_title: (
        <CheckProductTitle
          labelText="Variant title"
          setFormData={setFormData}
          formData={formData}
          index={index}
        />
      ),
      all_selected_variants: (
        <>
          <ProductVariantPicker
            i18n={i18n}
            setFormData={setFormData}
            formData={formData}
            index={index}
            field="conditions"
            subField="variants"
            selectType="variants"
          />
        </>


      ),
      any_selected_variants: (
        <>
          <ProductVariantPicker
            i18n={i18n}
            setFormData={setFormData}
            formData={formData}
            index={index}
            field="conditions"
            subField="variants"
            selectType="variants"
          />
        </>
      ),
      not_contain_any_selected_variant: (
        <>
          <ProductVariantPicker
            i18n={i18n}
            setFormData={setFormData}
            formData={formData}
            index={index}
            field="actions"
            subField="variants"
            selectType="variants"
          />
        </>
      ),
      no_active_subcription: <></>,
      customer_tag_present: (
        <TextField
          type="text"
          label={`Tag`}
          value={formData?.conditions?.[index]?.tag || ''}
          onChange={(val) => handleConditionChange(val, 'tag', index)}
        />
      ),
      customer_tag_not_present: (
        <TextField
          type="text"
          label={`Tag`}
          value={formData?.conditions?.[index]?.tag || ''}
          onChange={(val) => handleConditionChange(val, 'tag', index)}
        />
      ),
      total_orders_placed_by_customer: (
        <TextField
          type="number"
          label={`Total orders`}
          value={`${formData?.conditions?.[index]?.total_orders || ''}`}
          onChange={(val) => handleConditionChange(val, 'total_orders', index)}
        />
      ),
      total_spent_by_a_customer: (
        <TextField
          type="number"
          label={`Total spent`}
          value={`${formData?.conditions?.[index]?.total_spent || ''}`}
          onChange={(val) => handleConditionChange(val, 'total_spent', index)}
        />
      ),
    };
    return conditionsComponents[condition];
  };

  const handleActionChange = (value, key, index) => {
    let form_data_actions = formData?.actions;
    form_data_actions[index][key] = value;
    setFormData({ ...formData, actions: form_data_actions });
  };

  const renderActions = (action, i) => {
    const actionsComponents = {
      add_tag_to_customer: (
        <ProductTag formData={formData} setFormData={setFormData} index={i} />
      ),
      remove_tag_from_customer: (
        <ProductTag formData={formData} setFormData={setFormData} index={i} />
      ),
      add_tag_to_order: (
        <ProductTag formData={formData} setFormData={setFormData} index={i} />
      ),
      add_discount: (
        <TextField
          type="text"
          value={`${formData?.actions[i]?.discount_code || ''}`}
          onChange={(val) => handleActionChange(val, 'discount_code', i)}
          placeholder="Enter discount code"
        />
      ),
      remove_discount: (
        <TextField
          type="text"
          value={`${formData?.actions[i]?.discount_code || ''}`}
          onChange={(val) => handleActionChange(val, 'discount_code', i)}
          placeholder="Enter discount code id"
          helpText="Please enter discount code id to be removed."
        />
      ),
      add_trial_period: (
        <TextField
          suffix="cycles"
          type="number"
          value={`${formData?.actions[i]?.trial_period || 0}`}
          onChange={(val) => handleActionChange(val, 'trial_period', i)}
          helpText={`The subscription will be free for ${formData?.actions[i]?.trial_period || 0
            } cycles.`}
        />
      ),
      change_subscription_plan: (
        <>
          <SearchPlan
            idForTextField={`searchPlan-${Math.random()}`}
            setFormData={setFormData}
            formData={formData}
            index={i}
            i18n={i18n}
            field="actions"
            subField="selling_plan"
            multiple={false}
          />
        </>
      ),
      add_one_time_product: (
        <>
          <ProductVariantPicker
            i18n={i18n}
            setFormData={setFormData}
            formData={formData}
            index={i}
            field="actions"
            subField="product"
            selectType="product"
          />
        </>
      ),
      add_subscription_product: (
        <>
          <>
            <ProductVariantPicker
              i18n={i18n}
              setFormData={setFormData}
              formData={formData}
              index={i}
              field="actions"
              subField="product"
              selectType="product"
            />
          </>
        </>
      ),
      remove_product_one_time: (
        <>
          <>
            <ProductVariantPicker
              i18n={i18n}
              setFormData={setFormData}
              formData={formData}
              index={i}
              field="actions"
              subField="product"
              selectType="product"
            />
          </>
        </>
      ),
      remove_product_from_subscription: (
        <>
          <>
            <ProductVariantPicker
              i18n={i18n}
              setFormData={setFormData}
              formData={formData}
              index={i}
              field="actions"
              subField="product"
              selectType="product"
            />
          </>
        </>
      ),
      swap_product: (
        <>
          <>
            <ProductVariantPicker
              i18n={i18n}
              setFormData={setFormData}
              formData={formData}
              index={i}
              field="actions"
              subField="product"
              selectType="variant"
              previewVariants={true}
            />
            <div className='swap_product_quantity_discount_container'>
              <h4>Swap product Quantity and discount settings.</h4>
              <div className='swap_product_quatity'>
                <TextField
                  label="Quantity"
                  type='number'
                  value={`${formData?.['actions']?.[i]?.['quantity']}`}
                  onChange={(e) => handleActionChange(e, 'quantity', i)}

                />
              </div>
              <div className="swap_product_swap_quantity_wrapper">
                <RadioButton
                  label="Add products at their base price with no subscription discounts"
                  checked={!formData?.['actions']?.[i]?.['swap_discount']}
                  id={`swap_products_without_discoun${i}`}
                  name="swap_discount"
                  onChange={(e) => handleActionChange(false, 'swap_discount', i)}
                />
                <RadioButton
                  label="Add products with discounts as configured on the selling plan"
                  id={`swap_products_with_discoun${i}`}
                  name="swap_discount"
                  checked={formData?.['actions']?.[i]?.['swap_discount']}
                  onChange={(e) => handleActionChange(true, 'swap_discount', i)}
                />
              </div>
            </div>
            <div className="custom_helptext">
              This action only applies to contracts with only one
              variant/product.
            </div>
          </>
        </>
      ),
    };
    return actionsComponents[action];
  };

  const handleSubmit = () => {
    if (formData?.name) {
      fetch('/create_subscription_workflow', {
        method: 'POST',
        body: JSON.stringify({
          subscription_workflow: formData,
          shopify_domain: domain,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((data) => {
          if (data?.status) {
            setToastContent('Saved Successfully');
            fetch_workflow();
          }
        });
    }
  };

  const fetch_workflow = () => {
    fetch(`/fetch_workflow?id=${editId}&shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((data) => {
        let form_data = data?.subscription_workflows;
        setFormData({ ...form_data });
        setCustomHeader(`Edit ${form_data?.name} workflow`)
      });
  };

  useEffect(() => {
    if (editId) {
      fetch_workflow();
    }
  }, [editId]);

  const deleteWorkflow = () => {
    fetch(`/destroy_workflow?id=${editId}&shopify_domain=${domain}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.status) {
          setEdit();
          setToastContent('Deleted Successfully');
          history.push(`/customersTab/subscription_workflow`)
        }
      });
  };

  return (
    <div className="create_workflow_wrapper">
      <Frame>
        {toastContent && (
          <Toast content={toastContent} onDismiss={hideSaveSuccess} />
        )}

        <Layout>
          <Layout.Section>
            <Card
              title={
                <div className="heading_title">
                  <PixelIcon />
                  Name of Workflow
                </div>
              }
              sectioned
            >
              <div className="text_flex_wrapper">
                <TextField
                  label={<div className="order_summary">Name</div>}
                  type="text"
                  value={formData?.name}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e });
                  }}
                  error={fieldError?.name}
                  autoComplete="off"
                />
              </div>
            </Card>

            <Card
              title={
                <div className="heading_title">
                  <PixelIcon />
                  Events
                </div>
              }
              sectioned
            >
              <div className="text_flex_wrapper">
                {all_events?.map((event) => {
                  return (
                    <Checkbox
                      label={event?.value}
                      checked={formData?.events?.includes(event?.value)}
                      onChange={(e) => {
                        if (e) {
                          let temp_events = [...formData?.events] || [];
                          temp_events?.push(event?.value);
                          setFormData({
                            ...formData,
                            events: temp_events,
                          });
                        } else {
                          let temp_events = [...formData?.events] || [];
                          let indexOf = temp_events?.indexOf(event?.value);
                          temp_events?.splice(indexOf, 1);
                          setFormData({
                            ...formData,
                            events: temp_events,
                          });
                        }
                      }}
                    />
                  );
                })}
              </div>
            </Card>

            <div className="condtions_wrapper">
              <Card
                title={
                  <div className="heading_title">
                    <PixelIcon />
                    Conditions (optional)
                  </div>
                }
                sectioned
              >
                <div className="text_flex_wrapper">
                  {formData?.conditions?.length > 0 ? (
                    <div className="workflow_unconditionally">
                      <RadioButton
                        label="Run if ALL of the following conditions are true"
                        checked={formData?.check_all_condtions}
                        id="all_conditions"
                        name="check_all_condtions"
                        onChange={() => {
                          setFormData({
                            ...formData,
                            check_all_condtions: true,
                          });
                        }}
                      />
                      <RadioButton
                        label="Run if at least ONE of the following conditions is true"
                        id="one_condtion"
                        name="check_all_condtions"
                        checked={!formData?.check_all_condtions}
                        onChange={() => {
                          setFormData({
                            ...formData,
                            check_all_condtions: false,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <div className="workflow_unconditionally">
                      Run workflow unconditionally
                    </div>
                  )}

                  <div className="selected_conditions_wrapper">
                    {formData?.conditions?.map((condition, i) => {
                      return (
                        <div className="searchPlanWrapper selected_conditions_wrapper">
                          <h3>
                            {i18n.t(
                              `workflow_conditions.${condition?.condition_title}`
                            )}
                          </h3>
                          {renderConditions(condition?.condition_title, i)}
                          <div className="removeConditionButtonWrapper">
                            <button
                              className="remove_condition_button"
                              onClick={() => {
                                let all_conditions = formData?.conditions;
                                all_conditions?.splice(i, 1);
                                setFormData({
                                  ...formData,
                                  conditions: [...all_conditions],
                                });
                              }}
                            >
                              <Icon source={DeleteMajor} color="base" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="add_condition_wrapper">
                    <Popover
                      active={showCondtionOption}
                      activator={activator}
                      autofocusTarget="first-node"
                      onClose={toggleActive}
                    >
                      <ActionList
                        actionRole="menuitem"
                        sections={allCondtions?.map((condition) => {
                          return {
                            title: condition?.title,
                            items: condition?.items?.map((item) => {
                              return {
                                content: i18n.t(
                                  `workflow_conditions.${item?.content}`
                                ),
                                onAction: () => {
                                  handleActionCondtions(item?.content);
                                },
                              };
                            }),
                          };
                        })}
                      />
                    </Popover>
                  </div>
                </div>
              </Card>
              <Card
                title={
                  <div className="heading_title">
                    <PixelIcon />
                    Actions
                  </div>
                }
                sectioned
              >
                <div className="add_action_button">
                  {formData?.actions?.map((action, i) => {
                    return (
                      <div className="searchPlanWrapper">
                        <h3>
                          {i18n.t(`workflow_conditions.${action?.title}`)}
                        </h3>
                        {renderActions(action?.title, i)}
                        <div className="removeConditionButtonWrapper">
                          <button
                            className="remove_condition_button"
                            onClick={() => {
                              let all_actions = formData?.actions;
                              all_actions?.splice(i, 1);
                              setFormData({
                                ...formData,
                                actions: [...all_actions],
                              });
                            }}
                          >
                            <Icon source={DeleteMajor} color="base" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  <Popover
                    active={showAddAction}
                    activator={addActionActivator}
                    autofocusTarget="first-node"
                    onClose={toggleAddActionAction}
                  >
                    <ActionList
                      actionRole="addaction"
                      sections={allActions?.map((action) => {
                        return {
                          title: action?.title,
                          items: action?.items?.map((item) => {
                            return {
                              content: i18n.t(
                                `workflow_conditions.${item?.content}`
                              ),
                              onAction: () => {
                                handleActions(item?.content);
                              },
                            };
                          }),
                        };
                      })}
                    />
                  </Popover>
                </div>
              </Card>
            </div>
          </Layout.Section>
          <div className="defauleLayoutSection sdebar-right-cstm">
            <Layout.Section secondary>
              <Card title={i18n.t('summary')}>
                <Card.Section>
                  <div className='workflow_summary_wrapper_container'>
                    <div className='workflow_status_wrapper'>
                      <Select
                        label="Status"
                        options={statusOptions}
                        value={formData?.status}
                        onChange={(e) => {
                          setFormData({ ...formData, status: e });
                        }}
                      />
                    </div>
                    <List type="bullet">
                      <List.Item>
                        {formData?.summary_events
                          ? formData?.summary_events
                          : 'No events selected'}
                      </List.Item>
                      <List.Item>
                        {formData?.summary_condition
                          ? formData?.summary_condition
                          : 'No conditions selected'}
                      </List.Item>
                      <List.Item>
                        {formData?.summary_actions
                          ? formData?.summary_actions
                          : 'No actions selected'}
                      </List.Item>
                    </List>
                  </div>
                </Card.Section>
              </Card>
            </Layout.Section>
          </div>
          <Layout.Section>
            <PageActions
              primaryAction={{
                content: i18n.t('save'),
                onClick: () => {
                  handleSubmit();
                },
              }}
              secondaryActions={[
                editId
                  ? {
                    content: i18n.t('delete'),
                    destructive: true,
                    onClick: () => {
                      deleteWorkflow();
                    },
                  }
                  : {},
              ]}
            />
          </Layout.Section>
        </Layout>
      </Frame>
    </div>
  );
};

export default CreateWorkflow;
