import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  ContextualSaveBar,
  Form,
  FormLayout,
  Frame,
  Icon,
  Layout,
  Modal,
  Page,
  Select,
  Tabs,
  TextContainer,
  TextField,
  Toast,
} from '@shopify/polaris';
import {
  ProfileMajor,
  MobileChevronMajor,
  MobileMajor,
  DesktopMajor,
} from '@shopify/polaris-icons';

import React, { useCallback, useEffect, useState } from 'react';
import '@shopify/polaris/dist/styles.css';
import { gql, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import BundleSettingSideBar from './BundleSettingSideBar';
import { useParams } from 'react-router-dom';

const EditTemplate = ({
  i18n,
  domain,
  editTemplate,
  setEditTemplate,
  editOffer,
  setEditOffer,
  fetchAllOffers,
  toastContent,
  hideSaveSuccess,
  setToastContent,
}) => {
  const history = useHistory();
  const { edit_id, template_id } = useParams();
  const initContentBlock = {
    image:
      'https://presidio-up.s3.us-east-2.amazonaws.com/images/mock-block-1.jpeg',
    desktop_image_alignment: 'Left',
    desktop_image_width: 'Default',
    headline: 'The Scent: Melrose Place',
    description:
      'A one-way ticket to L.A., Melrose Place has a velvety-rich rose scent infused with a swoon-worthy blend of bergamot, lychee, white musk, champagne, and more.',
  };

  const settingsSideBar = [
    {
      icon: <Icon source={ProfileMajor} tone="base" />,
      title: 'Funnel Name',
      tab: 'funnel_name',
    },
    {
      icon: <Icon source={ProfileMajor} tone="base" />,
      title: 'Shipping bar',
      tab: 'shippig_bar',
    },
    {
      icon: <Icon source={ProfileMajor} tone="base" />,
      title: 'All offers',
      tab: 'all_offers',
    },
    {
      icon: <Icon source={ProfileMajor} tone="base" />,
      title: 'Cart note',
      tab: 'cart_note',
    },
    {
      icon: <Icon source={ProfileMajor} tone="base" />,
      title: 'CTA bar',
      tab: 'cta_bar',
    },

  ];

  const initConditionBlock = {
    min_order_value: '',
    max_order_value: '',
    min_line_items_quantity: '',
    min_customer_order_count: '',
    has_selling_plan: false,
    order_currency: '',
    current_condition: 'min_order_value',
    variant_images: [],
  };

  const [selected, setSelected] = useState(0);
  const [subForm, setSubForm] = useState('');
  const [toggleOnButton, setToggleOnButton] = useState(false);
  const [contentBlock, setContentBlock] = useState([initContentBlock]);
  const [conditionBlock, setConditionBlock] = useState([initConditionBlock]);
  const [allVariants, setAllVariants] = useState([]);
  const [selectedTab, setSelectedTab] = useState(settingsSideBar[0]?.tab);
  const [initialFormData, setInitialFormData] = useState({
    name: '',
    collection: {},
  });
  const [updated, setUpdated] = useState(false);
  const [formData, setFormData] = useState({
    name: 'Funnel 21',
    shop: domain,
    cart_funnel_template_id: template_id,
    edit_id: edit_id || '',
  });

  console.log('formData', formData);
  const [scheduleOffer, setScheduleOffer] = useState();
  const [showSchedule, setShowSchedule] = useState(false);
  const [renderBanner, setRenderBanner] = useState(false);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );
  const [saveSuccess, setSaveSuccess] = useState(false);

  const [leavePageModal, setLeavePageModal] = useState(false);
  const handleLeaveModalChange = useCallback(
    () => setLeavePageModal(!leavePageModal),
    [leavePageModal]
  );

  const tabs = [
    {
      id: 'offer',
      content: 'Offer',
      accessibilityLabel: 'All customers',
      panelID: 'all-customers-fitted-content-3',
    },
  ];
  const [mobileView, setMobileView] = useState(true);

  const handleSubmit = async () => {
    const response = await fetch('/cartFunnel', {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (data.success) {
      history.push('/chargezenUpsells/cartFunnels');
      setToastContent('Saved Successfully');
    }
  };

  const fetchFunnel = () => {
    let url =
      '/getCartFunnel?shop=' +
      domain +
      '&cart_funnel_template_id=' +
      template_id +
      `&edit_id=${edit_id || ''}`;

    fetch(url, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.funnel) {
          setFormData({ ...data.funnel });
          setInitialFormData({ ...data.funnel });
        }
      });
  };

  useEffect(() => {
    let update = false;
    if (JSON.stringify(formData) != JSON.stringify(initialFormData)) {
      update = true;
    }
    setUpdated(update);
  }, [formData, initialFormData]);

  useEffect(() => {
    fetchFunnel();
  }, [template_id]);

  // useEffect(() => {
  //   if (formData.type != 'multiple_products' && allVariants.length > 1) {
  //     let last_index = allVariants.length - 1;
  //     let variant = [allVariants[last_index]];
  //     setAllVariants([...variant]);
  //   }
  // }, [allVariants]);

  const resetForm = () => {
    setFormData({ ...initialFormData });
    setUpdated(false);
  };

  return (
    <div className="upsell_setting_container">
      <Frame>
        {toastContent && (
          <Toast content={toastContent} onDismiss={hideSaveSuccess} />
        )}
        {updated && (
          <ContextualSaveBar
            message="Unsaved changes"
            saveAction={{
              onAction: () => {
                handleSubmit();
              },
              loading: false,
              disabled: false,
            }}
            discardAction={{
              onAction: () => resetForm(),
            }}
          />
        )}
        <div className="affiliate_setting_main_container shopify_chargezen_card">
          <div className="affiliate_setting_wrapper">
            <BundleSettingSideBar
              {...{ settingsSideBar, domain, selectedTab, setSelectedTab }}
            />
            <div className="affiliate_setting_body_container">
              {!settingsSideBar?.find((s) => s.tab === selectedTab)
                ?.noHeader && (
                  <h1 className="shopify_style_header">
                    {settingsSideBar?.find((s) => s.tab === selectedTab)?.title}
                  </h1>
                )}
              <div className="shopify_base_card">
                {selectedTab == 'funnel_name' && (
                  <>
                    <Card
                      title={<div className="heading_title">Funnel Name</div>}
                      sectioned
                    >
                      <div className="card-box">
                        <div className="upsells_wrapper">
                          <TextField
                            label="Name"
                            onChange={(e) => {
                              setFormData({ ...formData, name: e });
                            }}
                            value={formData.name}
                          />
                        </div>
                      </div>
                    </Card>
                  </>
                )}
                {selectedTab == 'bundle_collection' && (
                  <></>
                )}
                {/* <div className="tranparent button_group_wrapper">
                    <Button
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {i18n.t('save_and_continue')}
                    </Button>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </Frame>
    </div>
  );
};

export default EditTemplate;
