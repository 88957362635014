import React, { useCallback, useEffect, useState } from 'react';
import {
  LocationsMinor,
  MobileBackArrowMajor,
  ChevronDownMinor,
  CreditCardMajor,
  ShipmentMajor,
  MobilePlusMajor,
  ChevronRightMinor,
  MobileHorizontalDotsMajor,
} from '@shopify/polaris-icons';
import '../customerModal.css';
import PaypalLogo from '../../../assets/images/paypal.svg';
import { Badge, Card, Frame, Icon, Page, Toast } from '@shopify/polaris';
import dayjs from 'dayjs';
import HeaderButtons from '../../HeaderButtons/HeaderButtons';
import LoyaltyTab from './LoyaltyDetail';
import { useHistory } from 'react-router-dom';
import visaLogo from '../../../assets/images/visa_logo.png';
import masterLogo from '../../../assets/images/mastercard_logo.png';

const CustomerDetail = (props) => {
  const { uid, setuid, domain } = props;
  const [headerButton, setHeaderButton] = useState('subscriptions');
  const [apiData, setApiData] = useState();
  const history = useHistory();
  const headerButtons = [
    {
      val: 'subscriptions',
      name: 'Subscriptions',
    },
    {
      val: 'loyalty',
      name: 'Loyalty',
    },
    {
      val: 'rebuy_carts',
      name: 'Rebuy Carts',
    },
    {
      val: 'pos_orders',
      name: 'POS Orders',
    },
    {
      val: 'quiz',
      name: 'Quiz',
    },
  ];

  const [showAccordion, setShowAccordion] = useState({ orders: false });
  const [lineItemsAccordion, setLineItemsAccordion] = useState({});
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);
  const [showAllLines, setShowAllLines] = useState(false);

  const [waysToRedeem, setWaysToRedeem] = useState([]);

  const [rewardActions, setRewardActions] = useState([]);

  const [usedReferrals, setUsedReferrals] = useState([]);

  const fetchCustomer = () => {
    fetch(`/getCustomer?uid=${uid}&shopify_domain=${domain}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApiData(data?.data);
        setWaysToRedeem(data?.ways_to_redeem);
        setRewardActions(data?.reward_redeem_actions);
        setUsedReferrals(data?.used_referrals);
      });
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  const redirectTo = (url, target = 'same') => {
    window.open(url, target);
  };

  return (
    <div className="chargezen_customer_detail_wrapper">
      <Frame>
        {saveSuccess && (
          <Toast content={toastContent} onDismiss={hideSaveSuccess} />
        )}
        <Page
          title={
            <div className="back_button_wrapper">
              <div
                className="back-button pointer"
                style={{ marginTop: '15px' }}
                onClick={() => {
                  history.push(`/customer-model`);
                  setuid();
                }}
              >
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
              <div class="text_wrapper">
                {apiData?.customer?.first_name || apiData?.customer?.last_name
                  ? `${apiData?.customer?.first_name || ''} ${apiData?.customer?.last_name || ''
                  }`
                  : apiData?.customer.email}
              </div>
            </div>
          }
        >
          <div className="mainContent">
            <div className="block_section">
              <div className="detail-customer-overview-main ">
                <div className="without_header_card">
                  <Card
                    sectioned
                    actions={{
                      content: (
                        <div className="card_header_button_group">
                          <div className="card_header_buttons primary_button_group">
                            <HeaderButtons
                              headerButtons={headerButtons}
                              setHeaderButton={setHeaderButton}
                              headerButton={headerButton}
                            />
                          </div>
                        </div>
                      ),
                    }}
                  >
                    {headerButton == 'subscriptions' &&
                      apiData?.contracts?.length > 0 ? (
                      <div style={{ position: 'relative' }}>
                        {apiData?.contracts?.map((contract, i) => {
                          let cardType =
                            contract?.api_data?.customer_payment_method
                              .instrument?.card?.__typename ||
                            contract?.api_data?.customer_payment_method
                              .instrument?.__typename;
                          if (i <= 4 || showAllLines) {
                            return (
                              <div className="detail-customer-add-product cardBox">
                                <div className="list-section-main">
                                  <ul>
                                    <li className="locationName">
                                      <LocationsMinor />
                                      {contract?.api_data?.delivery_method
                                        ?.address?.address1 ||
                                        'No delivery address'}
                                    </li>
                                    <li
                                      className="actionB"
                                      onClick={() =>
                                        setLineItemsAccordion({
                                          ...lineItemsAccordion,
                                          [i]: !lineItemsAccordion?.[i],
                                        })
                                      }
                                    >
                                      {' '}
                                      Actions <ChevronDownMinor />
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  className={`payment-section ${lineItemsAccordion[i] ? '' : 'no-height'
                                    }`}
                                >
                                  <button className="commanButton">
                                    <CreditCardMajor />
                                    Payment method:
                                    {cardType ==
                                      'CustomerPaypalBillingAgreement'
                                      ? 'Paypal'
                                      : cardType == 'CustomerCreditCard'
                                        ? `${contract?.api_data?.customer_payment_method.instrument?.brand} - ${contract?.api_data?.customer_payment_method.instrument?.last_digits}`
                                        : cardType == 'CustomerShopPayAgreement'
                                          ? 'ShopPay'
                                          : ''}
                                  </button>
                                  <span className="commanButton">
                                    <CreditCardMajor />
                                    {contract?.api_data?.discounts?.edges
                                      ?.length > 0
                                      ? contract?.api_data?.discounts?.edges?.map(
                                        (discount) => {
                                          return (
                                            <>
                                              <span class="discount_title">
                                                {discount.node.title}
                                              </span>
                                              <span class="discounted_main">
                                                {discount?.node?.value
                                                  .percentage
                                                  ? `${discount?.node?.value?.percentage}% off`
                                                  : `${discount?.node?.value?.amount} off`}
                                              </span>
                                            </>
                                          );
                                        }
                                      )
                                      : 'No Discount'}
                                  </span>
                                </div>
                                <div
                                  className={`bottom-section-main ${lineItemsAccordion[i] ? '' : 'no-height'
                                    }`}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>
                                          <input type="checkbox" />
                                        </th>
                                        <th>Product</th>
                                        <th> Next Order</th>
                                        <th>Frequency</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <input type="checkbox" />
                                        </td>
                                        <td className="product_wrapper ">
                                          {contract?.api_data?.lines?.edges?.map(
                                            (line) => {
                                              return (
                                                <div className="product_">
                                                  <img
                                                    src={
                                                      line?.node?.variant_image
                                                        ?.url
                                                    }
                                                  />
                                                  <div className="productInfo">
                                                    {line?.node?.title}
                                                  </div>
                                                  <div className="productPrice">{`${line?.node?.quantity} * $${line?.node?.current_price?.amount}`}</div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </td>
                                        <td className="nextOrder">
                                          {dayjs(
                                            contract?.api_data
                                              ?.next_billing_date
                                          )?.format('MMM DD, YYYY')}
                                        </td>
                                        <td className="frequency">{`${contract?.api_data?.delivery_policy?.interval_count} ${contract?.api_data?.delivery_policy?.interval}`}</td>
                                        <td className="">
                                          <div className="actionB">
                                            Actions <ChevronDownMinor />
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div className="add-product-button">
                                    <button className="commanButton">
                                      {' '}
                                      <MobilePlusMajor />
                                      Add product
                                    </button>
                                  </div>
                                  <div class="addAddress">
                                    <button className="commanButton">
                                      {' '}
                                      <MobilePlusMajor /> Add an address
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                        <div
                          className="customer_modal_show_more_line_items"
                          onClick={() => {
                            setShowAllLines(true);
                          }}
                        >
                          Show all
                        </div>
                      </div>
                    ) : (
                      headerButton == 'subscriptions' && (
                        <div className="chargezen_no_content_wrapper">
                          <h2>No contracts found!</h2>
                          <p>
                            It looks like you don't have any subscription
                            products yet. Get started by adding a subscription.
                          </p>
                        </div>
                      )
                    )}
                    {headerButton == 'loyalty' && (
                      <LoyaltyTab
                        domain={domain}
                        customer={apiData?.customer}
                        referralLink={apiData?.referralLink}
                        customerPointsActions={apiData?.customer_points_actions}
                        fetchCustomer={fetchCustomer}
                        setToastContent={setToastContent}
                        setSaveSuccess={setSaveSuccess}
                        waysToRedeem={waysToRedeem}
                        setWaysToRedeem={setWaysToRedeem}
                        rewardActions={rewardActions}
                        setRewardActions={setRewardActions}
                        usedReferrals={usedReferrals}
                      />
                    )}
                  </Card>
                </div>
                <div className="accordian">
                  {headerButton == 'loyalty' && (
                    <div
                      className="accordion_container"
                      onClick={() => {
                        setShowAccordion({
                          ...showAccordion,
                          orders: !showAccordion?.orders,
                        });
                      }}
                    >
                      <span
                        class={`cardBox ${showAccordion?.orders ? 'active' : ''
                          }`}
                      >
                        <ChevronRightMinor /> <h4>Orders</h4>
                      </span>
                      <div
                        className={`accordion_wrapper ${showAccordion?.orders ? '' : 'hidden'
                          }`}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th>Order</th>
                              <th>Total</th>
                              <th>Rewardable total</th>
                              <th>Payment</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="nextOrder">1010</td>
                              <td className="nextOrder">$28.90</td>
                              <td className="nextOrder">$24</td>
                              <td className="nextOrder">
                                <Badge status="success">Paid</Badge>
                              </td>
                              <td className="nextOrder">May 31, 2023</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {/* <span class="cardBox">
                    <ChevronRightMinor /> <h4>Upcoming orders</h4>
                  </span>
                  <span class="cardBox">
                    <ChevronRightMinor /> <h4>Recent orders</h4>
                  </span>
                  <span class="cardBox">
                    <ChevronRightMinor />
                    <h4>Upcoming orders</h4>
                  </span> */}
                </div>
              </div>

              <div className="button-accordian-payment-main ">
                <div className="customer-overview cardBox commanBoxS">
                  <div className="heading_">
                    <h2>Customer Overview</h2>
                    <MobileHorizontalDotsMajor />
                  </div>
                  <p>{`${apiData?.customer?.first_name} ${apiData?.customer?.last_name}`}</p>
                  <p>{apiData?.customer?.email}</p>
                  <p>{apiData?.customer?.phone || ''}</p>
                  {/* <span>Edit Customer</span> */}
                  <span
                    onClick={() => {
                      redirectTo(
                        `https://${domain}/pages/portal/${apiData?.customer?.uid}@-xyz~${apiData?.auth_token}`,
                        '_blank'
                      );
                    }}
                  >
                    Customer Portal Link <ChevronRightMinor />
                  </span>
                  <span
                    onClick={() => {
                      redirectTo(
                        `https://${domain}/admin/customers/${apiData?.customer.shopify_id}`,
                        '_blank'
                      );
                    }}
                  >
                    View In Shopify{' '}
                  </span>
                </div>
                <div
                  className="payment-methods cardBox commanBoxS"
                  style={{ marginBottom: 0 }}
                >
                  <div className="heading_">
                    <h2>Payment </h2>
                  </div>
                  {apiData?.customer?.api_data?.data?.paymentMethods?.edges
                    ?.length > 0 ? (
                    <>
                      <div class="visa-section">
                        {apiData?.customer?.api_data?.data?.paymentMethods?.edges?.map(
                          (paymentObj) => {
                            paymentObj = paymentObj?.node;
                            return (
                              <div className="payMShow">
                                {paymentObj?.instrument?.__typename ==
                                  'CustomerPaypalBillingAgreement' ? (
                                  <>
                                    <div class="visa-section">
                                      <div class="visa-section">
                                        <img
                                          src={PaypalLogo}
                                          alt="Paypal SVG"
                                        />
                                      </div>
                                    </div>
                                    <div class="visa-text">
                                      <p>Paypal</p>
                                      <p>
                                        Email:
                                        <span
                                          class="no Polaris-TextStyle--variationStrong"
                                          style={{ marginLeft: '0.5rem' }}
                                        >
                                          {
                                            paymentObj?.instrument
                                              ?.paypalAccountEmail
                                          }
                                        </span>
                                      </p>
                                    </div>
                                  </>
                                ) : paymentObj?.instrument?.__typename ==
                                  'CustomerCreditCard' ? (
                                  <>
                                    <div class="visa-section">
                                      <div class="visa-section">
                                        <img
                                          class="card_logo"
                                          src={
                                            paymentObj?.instrument?.brand?.toLowerCase() ==
                                              'visa'
                                              ? visaLogo
                                              : masterLogo
                                          }
                                        />
                                        <div class="visa-text">
                                          <p>
                                            {paymentObj?.instrument?.brand}
                                            <span
                                              class="no Polaris-TextStyle--variationStrong"
                                              style={{ marginLeft: '0.5rem' }}
                                            >
                                              {
                                                paymentObj?.instrument?.lastDigits?.match(
                                                  /\d+/
                                                )?.[0]
                                              }
                                            </span>
                                          </p>
                                          <p>
                                            Exp. Date
                                            <span class="expiry-date">
                                              <b>
                                                {
                                                  paymentObj?.instrument
                                                    ?.expiryMonth
                                                }
                                                /
                                                {
                                                  paymentObj?.instrument
                                                    ?.expiryYear
                                                }
                                              </b>
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : paymentObj?.instrument?.__typename ==
                                  'CustomerShopPayAgreement' ? (
                                  <>
                                    <div class="iconPay">
                                      <icon></icon>
                                      <icon>
                                        <svg
                                          class="card_logo"
                                          width="84"
                                          height="57"
                                          viewBox="0 0 84 57"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M73.3681 0.340088H10.6319C4.76952 0.340088 0 5.10961 0 10.9719V45.7789C0 51.6412 4.76952 56.4107 10.6319 56.4107H73.3681C79.2305 56.4107 84 51.6412 84 45.7789V10.9719C84 5.10961 79.2305 0.340088 73.3681 0.340088ZM10.6319 5.64582H73.3681C76.305 5.64582 78.6943 8.03506 78.6943 10.9719V13.0647H5.30573V10.9719C5.30573 8.03506 7.69497 5.64582 10.6319 5.64582ZM78.6943 24.9292H5.30573V18.3705H78.6943V24.9292ZM73.3681 51.105H10.6319C7.69497 51.105 5.30573 48.7157 5.30573 45.7789V30.2349H78.6943V45.7789C78.6943 48.7157 76.305 51.105 73.3681 51.105Z"
                                            fill="#000000"
                                          />
                                          <path
                                            d="M69.4492 44.4566C70.9949 44.4566 72.2479 43.2035 72.2479 41.6578C72.2479 40.1121 70.9949 38.859 69.4492 38.859C67.9034 38.859 66.6504 40.1121 66.6504 41.6578C66.6504 43.2035 67.9034 44.4566 69.4492 44.4566Z"
                                            fill="#212B36"
                                          />
                                          <path
                                            d="M61.6018 44.4566C63.1475 44.4566 64.4005 43.2035 64.4005 41.6578C64.4005 40.1121 63.1475 38.859 61.6018 38.859C60.056 38.859 58.803 40.1121 58.803 41.6578C58.803 43.2035 60.056 44.4566 61.6018 44.4566Z"
                                            fill="#212B36"
                                          />
                                        </svg>
                                      </icon>
                                    </div>
                                    <div class="">
                                      <p>
                                        {paymentObj?.instrument?.maskedNumber}
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="no_payment_method">
                      No payment method found
                    </div>
                  )}

                  <span
                    onClick={() => {
                      redirectTo(
                        `https://${domain}/admin/customers/${apiData?.customer.shopify_id}`,
                        '_blank'
                      );
                    }}
                  >
                    Manage payment methods{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Page>
      </Frame>
    </div>
  );
};

export default CustomerDetail;
