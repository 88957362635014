import React, { useState, useCallback, useEffect, useContext } from 'react';

import {
    Card,
    DisplayText,
    FormLayout,
    Layout,
    Select,
    TextStyle,
    Stack,
    Icon,
    Button,
    Toast,
} from '@shopify/polaris';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import { gql, useQuery } from '@apollo/client';
import { DomainContext } from '../domain-context';
import PixelIcon from '../../assets/images/PixelIcon';
const PaymentSettings = ({ handleBack, i18n }) => {
    const { domain } = useContext(DomainContext);
    const paymentOptions = ["Shopify payments", "Stripe", "Authorize.net", "Braintree"];
    const [selectPaymentOption, setPaymentOption] = useState();
    const [toastActive, setToastActive] = useState(false);
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);

    const getPaymentSetting = () => {
        fetch('/settings/get_payment_setting?shopify_domain=' + domain, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((data) => {
                setPaymentOption(data.current_setting)
            })
    }

    const updatePaymentOption = () => {
        fetch('/settings/update_payment_setting', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paymentOption: selectPaymentOption, shopify_domain: domain }),
        }).then((response) => response.json())
            .then(() => {
                setToastActive(true)
            })
    }

    useEffect(() => {
        getPaymentSetting()
    }, [])
    return (
        <div className="product-extention">
            {
                toastActive && (
                    <Toast content="Saved Successfully" onDismiss={toggleToastActive} />
                )
            }
            <Layout>
                <Layout.Section>
                </Layout.Section>
                <Card title=
                    {
                        <div className="heading_title">
                            <PixelIcon />
                            Payment Settings
                        </div>
                    }>
                    <Card.Section>
                        <div className="billing">
                            <FormLayout>
                                <DisplayText size="small" element="h6">
                                    <TextStyle variation="subdued">Payment Settings</TextStyle>
                                </DisplayText>
                                <Select
                                    label="Select Payment Gateway"
                                    value={selectPaymentOption}
                                    onChange={(e) => setPaymentOption(e)}
                                    options={paymentOptions?.length > 0 ? paymentOptions : []}
                                />
                                <div className='button'>
                                    <Button
                                        onClick={() => updatePaymentOption()}
                                    >Save</Button>
                                </div>
                            </FormLayout>
                        </div>
                    </Card.Section>
                </Card>
            </Layout>
        </div>
    );
};

export default PaymentSettings;