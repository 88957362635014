import { gql, useMutation } from '@apollo/client';
import {
  Text,
  Card,
  Toast,
  ChoiceList,
  Page,
  PageActions,
  TextField,
  Layout,
  Banner,
  List,
  Frame,
  Select,
  Icon,
  Checkbox,
  Form,
  DropZone,
  Thumbnail,
  Stack,
  FormLayout,
  Button,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import SearchProduct from '../../upsell/SearchProduct';
import Preview from '../../plans/Preview';

const EditPointsProgramTiers = ({
  domain,
  formData,
  setFormData,
  toastContent,
  setToastContent,
  toastActive,
  setToastActive,
  refetch,
  setEditForm,
  i18n,
}) => {
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  const [fieldError, setFieldError] = useState({
    title: '',
    points_required: '',
  });

  const handleSubmit = () => {
    // const formData = new FormData();
    // formData.append("shopify_domain", domain);

    // formData.append("title", formData.title)
    // files?.map((file, i) => {
    //     formData.append(`image${i}`, file);
    // })

    fetch('/create_points_program_tiers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        points_program_tiers_perks: formData,
        shopify_domain: domain,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.success) {
          setToastContent(i18n.t('saved_successfully'));
          refetch();
          setToastActive(true);
          setEditForm(false);
        }
      });
  };

  const handleChange = (key, value, index) => {
    let formdata = formData;
    formdata[index][key] = value;
    setFormData([...formdata]);
  };

  const addMoreTier = () => {
    let formdata = formData;
    formdata.push({ title: '', points_required: '' });
    setFormData([...formdata]);
  };

  return (
    <Frame>
      <Page>
        <Layout>
          <Layout.Section>
            <Card
              title={
                <div className="heading_title">

                  {i18n.t('points_programs.points_program_tiers')}
                </div>
              }
              sectioned
            >
              <Form>
                {formData?.map((form, i) => {
                  return (
                    <div className="loyalty_tiers_wrapper">
                      <FormLayout>
                        <FormLayout.Group>
                          <TextField
                            label={i18n.t('title')}
                            type="text"
                            value={`${formData[i]?.title}`}
                            onChange={(e) => {
                              handleChange('title', e, i);
                            }}
                            error={fieldError?.title}
                            autoComplete="off"
                          />
                        </FormLayout.Group>

                        <FormLayout.Group>
                          <TextField
                            label={i18n.t('points')}
                            type="number"
                            value={`${formData?.[i]?.points_required}`}
                            onChange={(e) => {
                              handleChange('points_required', e, i);
                            }}
                            error={fieldError?.points_required}
                            autoComplete="off"
                          />
                        </FormLayout.Group>
                      </FormLayout>
                    </div>
                  );
                })}

                <FormLayout>
                  <FormLayout.Group>
                    <Button
                      onClick={() => {
                        addMoreTier();
                      }}
                    >
                      {' '}
                      {i18n.t('points_programs.add_more_tier')}
                    </Button>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Button primary onClick={handleSubmit}>
                      {i18n.t('save')}
                    </Button>
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  );
};

export default EditPointsProgramTiers;
