import React, { useState, useCallback, useEffect } from 'react';
import { Card, Tabs, Frame, Page, Modal, Toast } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import SlideItem from './slideItem';
import EditTemplate from './EditTemplate/Index';
import PreviewUpsell from './PreviewUpsell';
import AlreadyCreatedOffers from './AlreadyCreatedOffers';
import './style.css';
import { useHistory, useParams } from 'react-router-dom';
import axiosconfig from '../axiosconfig';
function Upsells({ i18n, domain, formatDate }) {
  const [selected, setSelected] = useState(0);
  const [editOffer, setEditOffer] = useState();
  const { edit_id, template_id } = useParams();
  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(() => setToastContent(), []);
  const history = useHistory();

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
  }, []);

  const [editTemplate, setEditTemplate] = useState(false);
  const [previewTemplate, setpreviewTemplate] = useState(false);
  const [allOffers, setAllOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState({});

  const tabs = [
    {
      id: 'all-customers-1',
      content: 'All',
      accessibilityLabel: 'All customers',
      panelID: 'all-customers-content-1',
    },
    {
      id: 'Food-&-Beverage',
      content: 'Food & Beverage',
      panelID: 'Food-&-Beverage',
    },
    {
      id: 'Health-&-Beauty',
      content: 'Health & Beauty',
      panelID: 'Health-&-Beauty',
    },
    {
      id: 'Gift-Guide',
      content: 'Gift Guide',
      panelID: 'Gift-Guide',
    },
    {
      id: 'Fashion',
      content: 'Fashion',
      panelID: 'Fashion',
    },
    {
      id: 'Furniture',
      content: 'Furniture',
      panelID: 'Furniture',
    },
    {
      id: 'Technology',
      content: 'Technology',
      panelID: 'Technology',
    },
  ];
  const [templates, setTemplates] = useState([]);
  const tabsData = [
    {
      images: [
        'https://dashboard.quizkitapp.com/_next/image?url=https%3A%2F%2Fquiz-kit-files.s3.us-east-2.amazonaws.com%2Fimages%2F1%2FuzIGKu8QU9oavdeUgBVA&w=1920&q=75',
        'https://dashboard.quizkitapp.com/_next/image?url=https%3A%2F%2Fquiz-kit-files.s3.us-east-2.amazonaws.com%2Fimages%2F1%2Fh3sNy4o9Sxwzuz5l6EpQ&w=1920&q=75',
      ],
      name: 'Upsells Template #1',
      categories_data: 'Health & Beauty',
    },
  ];

  const fetchTemplates = () => {
    axiosconfig.get('/fetch_upsell_templates').then(({ data }) => {
      setTemplates(data?.templates);
    });
  };

  const fetchAllOffers = () => {
    axiosconfig.get('/fetch_all_offers').then(({ data }) => {
      setAllOffers(data?.offers);
    });
  };

  useEffect(() => {
    fetchTemplates();
    fetchAllOffers();
  }, []);

  useEffect(() => {
    let all_offers = JSON.parse(JSON.stringify(allOffers));
    let live_offers = [];
    let draft_offers = [];
    let scheduled_offers = [];
    all_offers?.map((offer) => {
      if (offer.status == 'live') {
        live_offers.push(offer);
      } else if (offer.status == 'draft') {
        draft_offers.push(offer);
      } else {
        scheduled_offers.push(offer);
      }
    });
    let filtered_offers = {
      live_offers: live_offers,
      draft_offers: draft_offers,
      scheduled_offers: scheduled_offers,
    };
    setFilteredOffers({ ...filtered_offers });
  }, [allOffers]);

  useEffect(() => {
    if (template_id) {
      setEditTemplate(template_id);
    }
    if (edit_id) {
      setEditOffer(edit_id);
    }
  }, [template_id, edit_id]);

  return editTemplate || editOffer ? (
    <EditTemplate
      setEditTemplate={setEditTemplate}
      i18n={i18n}
      domain={domain}
      editTemplate={editTemplate}
      editOffer={editOffer}
      setEditOffer={setEditOffer}
      fetchAllOffers={fetchAllOffers}
      setToastContent={setToastContent}
      hideSaveSuccess={hideSaveSuccess}
      toastContent={toastContent}
    />
  ) : (
    <Frame>
      {toastContent && (
        <Toast content={toastContent} onDismiss={hideSaveSuccess} />
      )}

      <div className="rewards_template_wrapper zenn_portal_wrapper">
        <Page
          title={
            <div class="back-button">
              <nav role="navigation">
                <button
                  data-polaris-unstyled="true"
                  class="Polaris-Breadcrumbs__Breadcrumb"
                  onClick={() =>
                    edit_id || template_id
                      ? history.push('/chargezenUpsells/manage')
                      : history.push('/chargezenUpsells')
                  }
                  style={{ paddingLeft: '6px' }}
                >
                  <span class="Polaris-Breadcrumbs__Icon">
                    <span class="Polaris-Icon">
                      <span class="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        class="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                      </svg>
                    </span>
                  </span>
                  <span class="Polaris-VisuallyHidden">Quizzes</span>
                </button>
              </nav>
              <div class="Polaris-Page-Header__TitleWrapper">
                <div>
                  <div class="Polaris-Header-Title__TitleAndSubtitleWrapper">
                    <h1 class="Polaris-Header-Title">Upsell offer templates</h1>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <div className="rewards_choose_template show_content_2">
            <Card>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <Card.Section>
                  {filteredOffers?.live_offers?.length > 0 && (
                    <div className="all_offers_container">
                      <h4 className="offer_header live_offer_header">
                        Live offers
                      </h4>
                      {filteredOffers?.live_offers?.map((offer) => {
                        return (
                          <AlreadyCreatedOffers
                            offer={offer}
                            setEditOffer={setEditOffer}
                            setToastContent={setToastContent}
                            fetchAllOffers={fetchAllOffers}
                            formatDate={formatDate}
                          />
                        );
                      })}
                    </div>
                  )}

                  {filteredOffers?.draft_offers?.length > 0 && (
                    <div className="all_offers_container draft_offer_container">
                      <h4 className="offer_header draft_offer_header">
                        Draft offers
                      </h4>
                      {filteredOffers?.draft_offers?.map((offer) => {
                        return (
                          <AlreadyCreatedOffers
                            offer={offer}
                            setEditOffer={setEditOffer}
                            setToastContent={setToastContent}
                            fetchAllOffers={fetchAllOffers}
                            formatDate={formatDate}
                          />
                        );
                      })}
                    </div>
                  )}

                  {filteredOffers?.scheduled_offers?.length > 0 && (
                    <div className="all_offers_container draft_offer_container">
                      <h4 className="offer_header draft_offer_header">
                        Scheduled offers
                      </h4>
                      {filteredOffers?.scheduled_offers?.map((offer) => {
                        return (
                          <AlreadyCreatedOffers
                            offer={offer}
                            setEditOffer={setEditOffer}
                            setToastContent={setToastContent}
                            fetchAllOffers={fetchAllOffers}
                            formatDate={formatDate}
                          />
                        );
                      })}
                    </div>
                  )}
                </Card.Section>
              </Tabs>
            </Card>
          </div>
          <div className="rewards_choose_template show_content_2 upsells_templates_container">
            <Card>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <Card.Section title={tabs[selected].content}>
                  {/* <p>Tab {selected} selected</p> */}
                  <div class="sort-by">
                    <div class="inner-wrap-sort">
                      <label>Sort by:</label>
                      <select
                        id="PolarisSelect1"
                        class="Polaris-Select__Input"
                        aria-invalid="false"
                      >
                        <option value="popular">Popularity</option>
                        <option value="recent">Most recent</option>
                      </select>
                      <svg
                        viewBox="0 0 20 20"
                        class="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M7.676 9h4.648c.563 0 .879-.603.53-1.014l-2.323-2.746a.708.708 0 0 0-1.062 0l-2.324 2.746c-.347.411-.032 1.014.531 1.014Zm4.648 2h-4.648c-.563 0-.878.603-.53 1.014l2.323 2.746c.27.32.792.32 1.062 0l2.323-2.746c.349-.411.033-1.014-.53-1.014Z"></path>
                      </svg>
                    </div>
                  </div>
                  {templates?.map((data) => {
                    return (
                      <SlideItem
                        data={data}
                        id={data.id}
                        setEditTemplate={setEditTemplate}
                        setpreviewTemplate={setpreviewTemplate}
                      />
                    );
                  })}
                </Card.Section>
              </Tabs>
            </Card>
          </div>
        </Page>
      </div>
      <Modal
        open={previewTemplate}
        onClose={() => {
          setpreviewTemplate(!previewTemplate);
        }}
        title="Chargezen Upsell"
      >
        <Modal.Section>
          <PreviewUpsell domain={domain} previewTemplate={previewTemplate} />
        </Modal.Section>
      </Modal>
    </Frame>
  );
}

export default Upsells;
