import { Badge, Card, Frame, TextField, Toast } from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import integrationDetailsData from './integrationDetailsData';
import { gql, useMutation } from '@apollo/client';
import { DisconnectApi, UpdateApiKey } from './ConfirmationModal';
import _ from 'lodash';

const IntegrationDetail = (props) => {
    const { imgsrc, item, getImgSrc, credentials, fetchIntegrations, mapCategory } = props;
    const [detailData, setDetailData] = useState(integrationDetailsData[item?.name?.toLowerCase()]);
    const fieldKeys = item?.keys ? item?.keys?.split(",")?.map(field => _.camelCase(field)) : [];
    const [updateApiModal, setUpdateApiModal] = useState()
    const [disconnectModal, setDisconnectModal] = useState(false);
    const headerButtons = [
        {
            title: "About",
        },
        {
            title: "Setup"
        },
        {
            title: "Events log"
        }
    ];
    const [headerButton, setHeaderButton] = useState(headerButtons?.[0]?.title);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);

    useEffect(() => {
        setDetailData(integrationDetailsData[item?.name?.toLowerCase()?.trim()])
    }, [item, integrationDetailsData]);

    const [formData, setFormData] = useState(credentials);

    const connected = !!Object.keys(credentials)?.find((key) => !!credentials[key] && key != "__typename")

    useEffect(() => {
        setFormData(credentials)
    }, [credentials])

    const updateQuery = gql`
            mutation ($input: UpdateIntegrationInput!) {
                updateIntegration(input: $input) {
                    integration {
                        id
                        name
                        integrationType
                        serviceType
                        default
                        credentials{
                            privateKey
                            publicKey
                        }
                        status
                    }
                }
            }
        `;
    const [updateMutation, { data, loading }] = useMutation(updateQuery);
    const handleSubmit = () => {
        updateMutation({
            variables: {
                input: {
                    params: {
                        id: item.id,
                        credentials: {
                            ...formData
                        }
                    }
                }
            }
        }).then(res => {
            if (!res.data.errors) {
                setSaveSuccess(true);
            }
        })
    }
    return (
        <div className='integration_detail_detail_wrapper'>
            {
                updateApiModal && (
                    <UpdateApiKey {...{
                        setSaveSuccess,
                        updateApiModal,
                        item,
                        updateMutation,
                        credentials,
                        fetchIntegrations,
                        mapCategory
                    }}
                        closeModal={() => setUpdateApiModal()}
                    />
                )
            }
            {
                disconnectModal &&
                <DisconnectApi {... {
                    item,
                    setSaveSuccess,
                    updateMutation,
                    fetchIntegrations,
                    mapCategory
                }}
                    closeModal={() => setDisconnectModal(false)}
                />
            }
            <Card
                actions={{
                    content: (
                        <div className="integration_logo">
                            <div className='header_integ_logo'>
                                <img src={getImgSrc(item?.name)} />
                            </div>
                            <div className="card_header_buttons primary_button_group">
                                {headerButtons?.map((headerbutton) => {
                                    return (
                                        <button className={`integration_button ${headerbutton?.title === headerButton}`} onClick={() => { setHeaderButton(headerbutton?.title) }}>
                                            {headerbutton?.title}
                                        </button>
                                    )
                                })}
                            </div>
                            {
                                connected ?
                                    <Badge status={`success`} >Connected</Badge>
                                    :
                                    <Badge status={`completed`} >Not Connected</Badge>

                            }
                        </div>
                    ),
                }}
            >
                <Card.Section>
                    {
                        headerButton === "About" && (
                            <div className='inte_details_content_container'>
                                <p className='integ_desc'>
                                    {detailData?.desc}
                                </p>
                                {
                                    detailData?.key_features?.length > 0 && (
                                        <div className='integ_key_features'>
                                            <h6>Key Features:</h6>
                                            <ul className='key_features_list'>
                                                {
                                                    detailData?.key_features?.map((key_feature) => {
                                                        return (
                                                            <li>{key_feature}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    )
                                }

                                <div className='integ_footer_button_group'>
                                    {
                                        detailData?.buttons?.map((button) => {
                                            return (button)
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    {

                        headerButton === "Setup" && (
                            <div className='integ_details_content_container'>
                                <div className='integ_header_wrapper'>
                                    <h5>{item?.name} credentials</h5>
                                    {
                                        connected &&
                                        <button className='integ_disconnect' onClick={() => setDisconnectModal(true)}>Disconnect</button>
                                    }
                                </div>
                                <div className='integ_setup_form'>
                                    {
                                        fieldKeys?.length > 0 && fieldKeys?.map(field => (
                                            <>
                                                {
                                                    <div className='integ_form_control'>
                                                        <TextField
                                                            name={field} label={_.startCase(field)}
                                                            value={formData[field] || item?.credentials?.[`${field}`]}
                                                            onChange={(value) => setFormData({ ...formData, [field]: value })}
                                                            disabled={connected ? true : false}
                                                        />
                                                        {
                                                            connected &&
                                                            <button className='integ_update_key' onClick={() => { setUpdateApiModal({ ...{ fieldKey: field, fieldValue: formData[field] } }) }}>Update API key</button>
                                                        }
                                                    </div>

                                                }
                                            </>
                                        ))
                                    }
                                    {
                                        fieldKeys?.length > 0 && (
                                            <button className='integ_connect' disabled={connected ? true : false} onClick={handleSubmit}>Connect</button>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </Card.Section>
            </Card>
            <Frame>
                {saveSuccess && (
                    <Toast
                        content="Saved Successfully"
                        onDismiss={hideSaveSuccess}
                    />
                )}
            </Frame>
        </div >
    )
}

export default IntegrationDetail