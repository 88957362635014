import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';

import { gql, useMutation, useLazyQuery } from '@apollo/client';
import {
  Card,
  Button,
  Form,
  FormLayout,
  TextField,
  TextContainer,
  Frame,
  ContextualSaveBar,
  Select,
  Subheading,
  Toast,
  Banner,
  List,
  Page,
  ButtonGroup,
  TextStyle,
  Checkbox,
  Stack,
  Heading,
  Spinner,
  Autocomplete,
  Icon,
} from '@shopify/polaris';
import SearchCollection from '../plans/SearchCollection';
import SearchProduct from './SearchProduct';
import LoadingScreen from '../LoadingScreen';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import SearchVariants from '../plans/SearchVariants';
import Preview from '../plans/Preview';
import PreviewCollection from './PreviewCollection';
import SelectPlan from './SelectPlan';
import './style.css';

const CreateLoisaWeeklyMenu = (props) => {
  const {
    i18n,
    setSelectedSetting,
    editId,
    setEditId,
    domain,
    editMode,
    setEditMode,
    handleBack,
    bundleType,
  } = props;
  var handleClose = () => { };
  const options = [...Array(99).keys()].map((foo) => (foo + 1).toString());
  const [canceledProducts, setCanceledProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [dishmenuAllProducts, setDishmenuAllProducts] = useState({});
  const [dishmenuUpsellProducts, setDishmenuUpsellProducts] = useState({});
  const [updated, setUpdated] = useState(false);
  const [selectedSellingPlans, setSelectedSellingPlans] = useState({
    pack1: { selectedPlans: [], selectedVariants: [] },
  });
  const [inventory, setInventory] = useState({});
  const locationOptions = [
    { label: 'Curvos', value: 'curvos' },
    { label: 'Loisa', value: 'loisa' },
    { label: 'Huron', value: 'huron' },
  ];

  const triggerOptions = [
    {
      label: 'Customer is subscribed to subscription plan',
      value: 'customer_is_subscribed_to_subscription_plan',
    },
  ];

  const collectionInit = [
    {
      collectionTitle: '',
      image: '',
      productImage: [],
      upsellImage: [],
      inventory: {},
      manageInventory: false,
    },
  ];

  const initialValues = {
    title: '',
    status: 'draft',
    pack1: '',
    pack2: '',
    pack3: '',
    pack4: '',
    pack5: '',
    desc: '',
    packTitle1: '',
    packTitle2: '',
    packTitle3: '',
    packTitle4: '',
    packTitle5: '',
    variantIds1: [],
    variantIds2: [],
    variantIds3: [],
    variantIds4: [],
    variantIds5: [],
    sellingPlans1: {},
    sellingPlans2: {},
    sellingPlans3: {},
    sellingPlans4: {},
    sellingPlans5: {},
    enableDates: false,
    enableMultipleFrequency: false,
    supports: 'subscription',
    collections: [{ ...collectionInit }],
  };

  const [formErrors, setFormErrors] = useState([]);
  const [campaignData, setCampaignData] = useState(null);
  const [dishMenuData, setDishMenuData] = useState(null);
  const [checkboxDisabled, setcheckboxDisabled] = useState(true);

  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);

  const handleRemovingUpsellCampaign = useCallback((values, index) => {
    const campaigns = [...(values.upsellCampaigns || [])];
    campaigns[index]._destroy = true;
    return campaigns;
  });

  const handleAddUpsellCampaign = useCallback((values) => {
    const campaigns = [...(values.upsellCampaigns || [])];
    campaigns.push(initialValues.upsellCampaigns[0]);

    return campaigns;
  });

  const validationSchema = yup.object().shape({
    // publicName: yup.string().required().label('Public name'),
    // selectorTitle: yup.string().required().label('Campaign selector title'),
    // upsellCampaigns: yup.array().of(
    //   yup.object().shape({
    //     name: yup.string().required().label('Name'),
    //     selectorLabel: yup.string().required().label('Plan selector label'),
    // ruleCartValue: yup.object().shape({
    //   productId: yup.string().label('Only select'),
    // }),
    // productOffer: yup.object().shape({
    //   productId: yup.string().required().label('Only select'),
    // }),
    // ruleCustomerValue: yup.object().shape({
    //   sellingPlanId: yup.string().required().label('Only select'),
    // }),
    //   })
    // ),
  });

  const GetLoisaWeeklyMenu = gql`
    query ($id: String) {
      getLoisaWeeklyMenu(id: $id) {
        token
        title
        desc
        pack1
        pack2
        pack3
        pack4
        pack5
        packTitle1
        packTitle2
        packTitle3
        packTitle4
        packTitle5
        variantIds1 {
          variantId
          title
          image
          _destroy
        }
        variantIds2 {
          variantId
          title
          image
          _destroy
        }
        variantIds3 {
          variantId
          title
          image
          _destroy
        }
        variantIds4 {
          variantId
          title
          image
          _destroy
        }
        variantIds5 {
          variantId
          title
          image
          _destroy
        }
        sellingPlans1 {
          sellingPlanId
          sellingPlanName
        }
        sellingPlans2 {
          sellingPlanId
          sellingPlanName
        }
        sellingPlans3 {
          sellingPlanId
          sellingPlanName
        }
        sellingPlans4 {
          sellingPlanId
          sellingPlanName
        }
        sellingPlans5 {
          sellingPlanId
          sellingPlanName
        }
        enableDates
        enableMultipleFrequency
        supports
      }
    }
  `;

  const GetDishmenu = gql`
    query ($id: String) {
      getDishmenu(id: $id) {
        token
        title
        status
        collections {
          id
          collectionTitle
          image
          productImages {
            title
            productId
            image
            _destroy
          }
          upsellImages {
            title
            productId
            image
            _destroy
          }
          inventory {
            productId
            qty
          }
          manageInventory
        }
      }
    }
  `;
  // const { id } = useParams();

  const [getLoisaWeeklyMenu, { data, loading, error }] = useLazyQuery(
    GetLoisaWeeklyMenu,
    {
      variables: { id: editId },
      fetchPolicy: 'no-cache',
    }
  );

  const [
    getDishmenu,
    { data: dishmenuData, loading: dishmenuLoading, error: dishmenuError },
  ] = useLazyQuery(GetDishmenu, {
    variables: { id: editId },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (editId && editMode == 'loisa') {
      getLoisaWeeklyMenu();
      setBundleStyle('loisa');
    } else if (editId && editMode == 'dishmenu') {
      getDishmenu();
      setBundleStyle('dishmenu');
    }
  }, [editId]);
  useEffect(() => {
    if (data?.getLoisaWeeklyMenu) {
      setCampaignData(data?.getLoisaWeeklyMenu);

      setSelectedVariant1(data?.getLoisaWeeklyMenu?.variantIds1);
      setSelectedVariant2(data?.getLoisaWeeklyMenu?.variantIds2);
      setSelectedVariant3(data?.getLoisaWeeklyMenu?.variantIds3);
      setSelectedVariant4(data?.getLoisaWeeklyMenu?.variantIds4);
      setSelectedVariant5(data?.getLoisaWeeklyMenu?.variantIds5);
    } else if (dishmenuData?.getDishmenu) {
      let dishmenu_data = dishmenuData?.getDishmenu;
      dishmenu_data?.collections?.map((collec, index) => {
        if (collec.inventory.length > 0) {
          let formatted_inventory = {};
          collec.inventory.map((iv) => {
            formatted_inventory[iv.productId] = iv.qty;
          });
          dishmenu_data.collections[index].inventory = formatted_inventory;
        } else {
          dishmenu_data.collections[index].inventory = {};
        }
      });


      setDishMenuData(dishmenu_data);
      let dishmenuProducts = {};
      let upsellProducts = {};
      let customInventory = {};
      dishmenuData?.getDishmenu?.collections?.map((collec, index) => {
        dishmenuProducts[`collectionField${index}`] = collec?.productImages;
        upsellProducts[`collectionField${index}`] = collec?.upsellImages;
      });
      setDishmenuAllProducts({ ...dishmenuProducts });
      setDishmenuUpsellProducts({ ...upsellProducts });
    }
  }, [data, dishmenuData]);


  const UPDATE_BOX_CAMPAIGN = gql`
    mutation ($input: UpdateBuildABoxCampaignGroupInput!) {
      updateBoxCampaign(input: $input) {
        campaign {
          id
          internalName
        }
      }
    }
  `;
  const [updateBoxCampaign] = useMutation(UPDATE_BOX_CAMPAIGN);

  const CREATE_BUNDLE_MENU = gql`
    mutation ($input: AddLoisaWeeklyMenuInput!) {
      addLoisaWeeklyMenu(input: $input) {
        loisaWeeklyMenu {
          id
        }
      }
    }
  `;

  const history = useHistory();
  const [createLoisaWeeklyMenuGQL] = useMutation(CREATE_BUNDLE_MENU);

  const formRef = useRef(null);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [selectedCollections, setSelectedCollections] = useState([]);
  const [allVarients, setAllVarients] = useState([]);

  //// All Search Plans
  const [allSelectedPlans, setAllSelectedPlans] = useState([]);

  // useEffect(() => {
  //   if (data && data?.fetchBuildABoxCampaignGroup) {
  //     data.fetchBuildABoxCampaignGroup.buildABoxCampaign.triggers =
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.triggers[0]?.name; //Manipulate later
  //     data.fetchBuildABoxCampaignGroup.buildABoxCampaign.endDate =
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.endDate || '';
  //     data.fetchBuildABoxCampaignGroup.buildABoxCampaign.startDate =
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.startDate || '';
  //     setCampaignData(data.fetchBuildABoxCampaignGroup);
  //     setSelectedProducts(
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.productImages
  //     );
  //     setSelectedProductOptions(() => {
  //       const defaultOption = [];
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.productImages?.map(
  //         (image) =>
  //           image._destroy == false && defaultOption.push(image.productId)
  //       );
  //       return defaultOption;
  //     });
  //     setSelectedCollections(
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.collectionImages
  //     );
  //     setSelectedCollectionOptions(() => {
  //       const defaultOption = [];
  //       data.fetchBuildABoxCampaignGroup.buildABoxCampaign.collectionImages?.map(
  //         (image) =>
  //           image._destroy == false && defaultOption.push(image.collectionId)
  //       );
  //       return defaultOption;
  //     });
  //     setAllSelectedPlans(
  //       data.fetchBuildABoxCampaignGroup.sellingPlans
  //     );
  //   }
  // }, [data]);

  const [selectedVariant1, setSelectedVariant1] = useState([]);
  const [selectedVariant2, setSelectedVariant2] = useState([]);
  const [selectedVariant3, setSelectedVariant3] = useState([]);
  const [selectedVariant4, setSelectedVariant4] = useState([]);
  const [selectedVariant5, setSelectedVariant5] = useState([]);
  const [dishMenuToDelete, setdishMenuToDelete] = useState([]);
  const [customInventory, setCustomInventory] = useState({});
  const [bundleStyle, setBundleStyle] = useState(
    bundleType ? bundleType : 'loisa'
  );
  const supportsOptions = [
    { label: 'Subscription alone', value: 'subscription' },
    { label: 'One-time alone', value: 'one-time' },
    { label: 'Subscription & One-time', value: 'subscription & one-time' },
  ];
  const styleOptions = [
    { label: 'Loisa', value: 'loisa' },
    { label: 'Dishmenu', value: 'dishmenu' },
  ];

  const [dishmenuFormData, setDishmenuFormData] = useState({ title: '' });

  const handleAddCollections = (values) => {
    const collections = [...(values.collections || [])];
    collections.push({ ...initialValues });
    return collections;
  };

  const handleDeleteCollection = (values, index) => {
    const collections = [...(values.collections || [])];
    let dish_menu_delete = dishMenuToDelete;
    dish_menu_delete.push(collections[index]?.id);
    setdishMenuToDelete([...dish_menu_delete]);
    collections[index] = 'Deleted';
    // //manage products object
    // const allCollectionProducts = dishmenuAllProducts;
    // delete allCollectionProducts[[`collectionField${index}`]];
    // let newProductCollections = {}
    // Object.keys(allCollectionProducts).map((cp, i) => {
    //     newProductCollections[`collectionField${i}`] = allCollectionProducts[cp]
    // })
    // setDishmenuAllProducts(newProductCollections);

    // const allUpsellProducts = dishmenuUpsellProducts;
    // delete allUpsellProducts[[`collectionField${index}`]];
    // let newUpsellCollections = {}
    // Object.keys(allUpsellProducts).map((cp, i) => {
    //     newUpsellCollections[`collectionField${i}`] = allUpsellProducts[cp]
    // })

    // setDishmenuUpsellProducts(newUpsellCollections);
    return collections;
  };

  return (
    <Frame>
      <Page
        title={
          !handleBack && (
            <div className="back_button_wrapper">
              <div
                className="back-button pointer"
                onClick={() => setSelectedSetting('loisaWeeklyMenu')}
              >
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
              <div class="text_wrapper">Subscription Bundles</div>
            </div>
          )
        }
      >
        {(loading || dishmenuLoading) && editId && <LoadingScreen />}
        {(campaignData || dishMenuData || !editId) && (
          <Formik
            validationSchema={validationSchema}
            initialValues={campaignData || dishMenuData || initialValues}
            innerRef={formRef}
            onSubmit={(values, { setSubmitting }) => {
              if (bundleStyle == 'loisa') {
                const formData = { ...values };
                formData.variantIds1 = selectedVariant1;
                formData.variantIds2 = selectedVariant2;
                formData.variantIds3 = selectedVariant3;
                delete formData.collections;
                delete formData.status;

                // Manipulate later
                // formData.upsellCampaigns[0].productOffer = allProducts;
                if (editId) {
                  formData.token = editId;
                }
                createLoisaWeeklyMenuGQL({
                  variables: { input: { params: formData } },
                })
                  .then((resp) => {
                    const data = resp.data;
                    const errors = data.errors;
                    if (errors) {
                      setFormErrors(errors);
                      setSubmitting(false);
                    } else {
                      setSaveSuccess(true);
                      setUpdated(false);
                    }
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    setFormErrors(error);
                  });
              } else {
                const formdata = { ...values };
                const formData = new FormData();
                let filteredCollections = formdata.collections?.filter(
                  (c) => c != 'Deleted'
                );
                filteredCollections?.map((collec, index) => {
                  formData.append(
                    `collectionTitle${index}`,
                    collec?.collectionTitle
                  );
                  formData.append(`collectionImage${index}`, collec?.image);
                  formData.append(
                    `inventory${index}`,
                    JSON.stringify(collec?.inventory)
                  );
                  formData.append(
                    `manageInventory${index}`,
                    collec?.manageInventory ? true : false
                  );
                });

                formData.append('collectionCount', filteredCollections?.length);
                formData.append('title', formdata?.title);
                formData.append('status', formdata?.status);
                formData.append(
                  'allProducts',
                  JSON.stringify(dishmenuAllProducts)
                );
                formData.append(
                  'upsellProducts',
                  JSON.stringify(dishmenuUpsellProducts)
                );
                formData.append('shopify_domain', domain);
                formData.append(
                  'dishMenuToDelete',
                  dishMenuToDelete?.join(',')
                );
                formData.append('shopify_domain', domain);


                if (editId) {
                  formData.append('id', editId);
                }

                fetch('/createDishMenu', {
                  method: 'POST',
                  body: formData,
                })
                  .then((response) => response.json())
                  .then((responseData) => {
                    setUpdated(false);
                    getDishmenu();
                    setSaveSuccess(true);
                    handleClose();
                    setTimeout(() => {
                      window.location.reload();
                    }, 5000);
                  });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              resetForm,
              dirty,
              formik,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                {(dirty || updated) && (
                  <ContextualSaveBar
                    message="Unsaved changes"
                    saveAction={{
                      onAction: handleSubmit,
                      loading: isSubmitting,
                      disabled: false,
                    }}
                    discardAction={{
                      onAction: () => {
                        //   canceledProducts.map(prod => {
                        //     allProducts.push(prod)
                        //   });

                        //   setAllProducts(allProducts);
                        //   setCanceledProducts(prod => prod = []);
                        setUpdated((flag) => (flag = false));
                        resetForm();
                      },
                    }}
                  />
                )}

                {saveSuccess && (
                  <Toast
                    content="Subscription Bundle is saved"
                    onDismiss={hideSaveSuccess}
                  />
                )}

                {formErrors.length > 0 && (
                  <>
                    <Banner
                      title="Subscription Bundle could not be saved"
                      status="critical"
                    >
                      <List type="bullet">
                        {formErrors.map((message, index) => (
                          <List.Item key={index}>{message.message}</List.Item>
                        ))}
                      </List>
                    </Banner>
                    <br />
                  </>
                )}
                <div className="build-a-box-create">
                  <Card
                    title={
                      <div className="heading_title">
                        {editId
                          ? i18n.t('update_subscription_bundles')
                          : i18n.t('create_subscription_bundles')}
                      </div>
                    }
                    sectioned
                  >
                    <Card.Section>
                      <FormLayout>
                        {!editId && (
                          <FormLayout.Group>
                            <div>
                              <Select
                                label="Bundle Style"
                                options={styleOptions}
                                onChange={(e) => {
                                  setBundleStyle(e);
                                }}
                                value={bundleStyle}
                              />
                            </div>
                          </FormLayout.Group>
                        )}

                        <FormLayout.Group>
                          <div>
                            <TextField
                              value={values?.title}
                              label="Title"
                              placeholder="Title"
                              type="text"
                              error={touched.title && errors.title}
                              onChange={(e) => {
                                setFieldValue(`title`, e);
                              }}
                              helpText={<span>Title of the bundle menu</span>}
                            />
                          </div>
                        </FormLayout.Group>
                        <div className={bundleStyle == 'loisa' ? '' : 'hidden'}>
                          <FormLayout.Group>
                            <div>
                              <TextField
                                value={values?.desc}
                                label="Description"
                                multiline={4}
                                placeholder="Description"
                                type="text"
                                error={touched.desc && errors.desc}
                                onChange={(e) => {
                                  setFieldValue(`desc`, e);
                                }}
                                helpText={<span>Desc of the bundle menu</span>}
                              />
                            </div>
                          </FormLayout.Group>
                          <FormLayout.Group>
                            <div>
                              <Checkbox
                                label="Enable Dates"
                                checked={values?.enableDates}
                                onChange={(newChecked) => setFieldValue(`enableDates`, newChecked)}
                              />
                            </div>
                            <div>
                              <Checkbox
                                label="Enable multiple billing frequency"
                                checked={values?.enableMultipleFrequency}
                                onChange={(newChecked) => setFieldValue(
                                  `enableMultipleFrequency`,
                                  newChecked
                                )}
                              />
                            </div>
                          </FormLayout.Group>

                          <FormLayout.Group>
                            <div>
                              <Select
                                label="Supports"
                                options={supportsOptions}
                                onChange={(e) => {
                                  setFieldValue(`supports`, `${e}`);
                                }}
                                value={values?.supports}
                              />
                            </div>
                          </FormLayout.Group>
                        </div>
                        {bundleStyle == 'loisa' ? (
                          <div className="loisaStyleForm">
                            <FormLayout.Group>
                              <div className="packs_main">
                                <div className="pack_main_sub">
                                  <TextField
                                    value={values?.packTitle1}
                                    label="Pack I Title"
                                    type="text"
                                    error={
                                      touched.packTitle1 && errors.packTitle1
                                    }
                                    onChange={(e) => {
                                      setFieldValue(`packTitle1`, e);
                                    }}
                                  />
                                  <TextField
                                    value={values?.pack1}
                                    label="Pack I"
                                    placeholder="Number of Selection for pack1"
                                    type="text"
                                    error={touched.pack1 && errors.pack1}
                                    onChange={(e) => {
                                      setFieldValue(`pack1`, e);
                                    }}
                                    helpText={
                                      <span>
                                        Number of products a user can select for
                                        pack 1
                                      </span>
                                    }
                                  />
                                </div>
                                <SelectPlan
                                  pack={1}
                                  setFieldValue={setFieldValue}
                                  allSelectedPlans={selectedSellingPlans}
                                  setAllSelectedPlans={setSelectedSellingPlans}
                                  i18n={i18n}
                                  values={values}
                                />
                                <div className="selectVariantMain">
                                  <div className="product-search">
                                    <SearchVariants
                                      value={values.variantIds1 || [[]]}
                                      setFieldValue={setFieldValue}
                                      fieldName={`variantIds1`}
                                      allVariants={selectedVariant1 || [[]]}
                                      setAllVarients={setSelectedVariant1}
                                      error={
                                        touched.variantIds?.variantId1 &&
                                        errors.variantIds?.variantId1
                                      }
                                      i18n={i18n}
                                    />
                                  </div>
                                  <Preview
                                    isUpdate={false}
                                    allProducts={selectedVariant1 || [[]]}
                                    setAllProducts={setSelectedVariant1}
                                    setUpdated={setUpdated}
                                    i18n={i18n}
                                  />
                                </div>
                              </div>
                            </FormLayout.Group>

                            <FormLayout.Group>
                              <div className="packs_main">
                                <div className="pack_main_sub">
                                  <TextField
                                    value={values?.packTitle2}
                                    label="Pack II Title"
                                    type="text"
                                    error={
                                      touched.packTitle2 && errors.packTitle2
                                    }
                                    onChange={(e) => {
                                      setFieldValue(`packTitle2`, e);
                                    }}
                                  />
                                  <TextField
                                    value={values?.pack2}
                                    label="Pack II"
                                    placeholder="Number of Selection for pack1"
                                    type="text"
                                    error={touched.pack2 && errors.pack2}
                                    onChange={(e) => {
                                      setFieldValue(`pack2`, e);
                                    }}
                                    helpText={
                                      <span>
                                        Number of products a user can select for
                                        pack 2
                                      </span>
                                    }
                                  />
                                </div>
                                <SelectPlan
                                  pack={2}
                                  setFieldValue={setFieldValue}
                                  allSelectedPlans={selectedSellingPlans}
                                  setAllSelectedPlans={setSelectedSellingPlans}
                                  i18n={i18n}
                                  values={values}
                                />
                                <div className="selectVariantMain">
                                  <div className="product-search">
                                    <SearchVariants
                                      value={values.variantIds2 || [[]]}
                                      setFieldValue={setFieldValue}
                                      fieldName={`variantIds2`}
                                      allVariants={selectedVariant2 || [[]]}
                                      setAllVarients={setSelectedVariant2}
                                      error={
                                        touched.variantIds?.variantId2 &&
                                        errors.variantIds?.variantId2
                                      }
                                      i18n={i18n}
                                    />
                                  </div>
                                  <Preview
                                    isUpdate={false}
                                    allProducts={selectedVariant2 || [[]]}
                                    setAllProducts={setSelectedVariant2}
                                    setUpdated={setUpdated}
                                    i18n={i18n}
                                  />
                                </div>
                              </div>
                            </FormLayout.Group>

                            <FormLayout.Group>
                              <div className="packs_main">
                                <div className="pack_main_sub">
                                  <TextField
                                    value={values?.packTitle3}
                                    label="Pack III Title"
                                    type="text"
                                    error={
                                      touched.packTitle3 && errors.packTitle3
                                    }
                                    onChange={(e) => {
                                      setFieldValue(`packTitle3`, e);
                                    }}
                                  />
                                  <TextField
                                    value={values?.pack3}
                                    label="Pack III"
                                    placeholder="Number of Selection for pack1"
                                    type="text"
                                    error={touched.pack3 && errors.pack3}
                                    onChange={(e) => {
                                      setFieldValue(`pack3`, e);
                                    }}
                                    helpText={
                                      <span>
                                        Number of products a user can select for
                                        pack 3
                                      </span>
                                    }
                                  />
                                </div>
                                <SelectPlan
                                  pack={3}
                                  setFieldValue={setFieldValue}
                                  allSelectedPlans={selectedSellingPlans}
                                  setAllSelectedPlans={setSelectedSellingPlans}
                                  i18n={i18n}
                                  values={values}
                                />
                                <div className="selectVariantMain">
                                  <div className="product-search">
                                    <SearchVariants
                                      value={values.variantIds3 || [[]]}
                                      setFieldValue={setFieldValue}
                                      fieldName={`variantIds3`}
                                      allVariants={selectedVariant3 || [[]]}
                                      setAllVarients={setSelectedVariant3}
                                      error={
                                        touched.variantIds?.variantId3 &&
                                        errors.variantIds?.variantId3
                                      }
                                      i18n={i18n}
                                    />
                                  </div>
                                  <Preview
                                    isUpdate={false}
                                    allProducts={selectedVariant3 || [[]]}
                                    setAllProducts={setSelectedVariant3}
                                    setUpdated={setUpdated}
                                    i18n={i18n}
                                  />
                                </div>
                              </div>
                            </FormLayout.Group>

                            <FormLayout.Group>
                              <div className="packs_main">
                                <div className="pack_main_sub">
                                  <TextField
                                    value={values?.packTitle4}
                                    label="Pack IV Title"
                                    type="text"
                                    error={
                                      touched.packTitle4 && errors.packTitle4
                                    }
                                    onChange={(e) => {
                                      setFieldValue(`packTitle4`, e);
                                    }}
                                  />
                                  <TextField
                                    value={values?.pack4}
                                    label="Pack IV"
                                    placeholder="Number of Selection for pack4"
                                    type="text"
                                    error={touched.pack4 && errors.pack4}
                                    onChange={(e) => {
                                      setFieldValue(`pack4`, e);
                                    }}
                                    helpText={
                                      <span>
                                        Number of products a user can select for
                                        pack 4
                                      </span>
                                    }
                                  />
                                </div>
                                <SelectPlan
                                  pack={4}
                                  setFieldValue={setFieldValue}
                                  allSelectedPlans={selectedSellingPlans}
                                  setAllSelectedPlans={setSelectedSellingPlans}
                                  i18n={i18n}
                                  values={values}
                                />
                                <div className="selectVariantMain">
                                  <div className="product-search">
                                    <SearchVariants
                                      value={values.variantIds4 || [[]]}
                                      setFieldValue={setFieldValue}
                                      fieldName={`variantIds4`}
                                      allVariants={selectedVariant4 || [[]]}
                                      setAllVarients={setSelectedVariant4}
                                      error={
                                        touched.variantIds?.variantIds4 &&
                                        errors.variantIds?.variantIds4
                                      }
                                      i18n={i18n}
                                    />
                                  </div>
                                  <Preview
                                    isUpdate={false}
                                    allProducts={selectedVariant4 || [[]]}
                                    setAllProducts={setSelectedVariant4}
                                    setUpdated={setUpdated}
                                    i18n={i18n}
                                  />
                                </div>
                              </div>
                            </FormLayout.Group>

                            <FormLayout.Group>
                              <div className="packs_main">
                                <div className="pack_main_sub">
                                  <TextField
                                    value={values?.packTitle5}
                                    label="Pack V Title"
                                    type="text"
                                    error={
                                      touched.packTitle5 && errors.packTitle5
                                    }
                                    onChange={(e) => {
                                      setFieldValue(`packTitle5`, e);
                                    }}
                                  />
                                  <TextField
                                    value={values?.pack5}
                                    label="Pack V"
                                    placeholder="Number of Selection for pack5"
                                    type="text"
                                    error={touched.pack5 && errors.pack5}
                                    onChange={(e) => {
                                      setFieldValue(`pack5`, e);
                                    }}
                                    helpText={
                                      <span>
                                        Number of products a user can select for
                                        pack 5
                                      </span>
                                    }
                                  />
                                </div>
                                <SelectPlan
                                  pack={5}
                                  setFieldValue={setFieldValue}
                                  allSelectedPlans={selectedSellingPlans}
                                  setAllSelectedPlans={setSelectedSellingPlans}
                                  i18n={i18n}
                                  values={values}
                                />
                                <div className="selectVariantMain">
                                  <div className="product-search">
                                    <SearchVariants
                                      value={values.variantIds5 || [[]]}
                                      setFieldValue={setFieldValue}
                                      fieldName={`variantIds5`}
                                      allVariants={selectedVariant5 || [[]]}
                                      setAllVarients={setSelectedVariant5}
                                      error={
                                        touched.variantIds?.variantIds5 &&
                                        errors.variantIds?.variantIds5
                                      }
                                      i18n={i18n}
                                    />
                                  </div>
                                  <Preview
                                    isUpdate={false}
                                    allProducts={selectedVariant5 || [[]]}
                                    setAllProducts={setSelectedVariant5}
                                    setUpdated={setUpdated}
                                    i18n={i18n}
                                  />
                                </div>
                              </div>
                            </FormLayout.Group>
                          </div>
                        ) : (
                          <>
                            <div className="dishmenuForm">
                              <FormLayout.Group>
                                <div>
                                  <Select
                                    label="Status"
                                    options={[
                                      { label: 'Active', value: 'active' },
                                      { label: 'Draft', value: 'draft' },
                                    ]}
                                    onChange={(e) => {
                                      setFieldValue(`status`, e);
                                    }}
                                    value={values?.status}
                                    helpText={
                                      <span>Status of the bundle menu</span>
                                    }
                                  />
                                </div>
                              </FormLayout.Group>
                              {values?.collections?.map((collection, index) => {
                                if (collection != 'Deleted') {
                                  return (
                                    <div className="collection_card_wrapper">
                                      <div className="delete_collection_wrapper">
                                        <button
                                          type="button"
                                          className="delete_collection_button"
                                          onClick={() => {
                                            let newCollections =
                                              handleDeleteCollection(
                                                values,
                                                index
                                              );
                                            setFieldValue('collections', [
                                              ...newCollections,
                                            ]);
                                          }}
                                        >
                                          Delete this collection
                                        </button>
                                      </div>
                                      <FormLayout.Group>
                                        <div>
                                          <TextField
                                            value={collection?.collectionTitle}
                                            label="Name of Collection"
                                            placeholder="Collection Name"
                                            type="text"
                                            error={
                                              touched.collections?.[index]
                                                ?.collectionTitle &&
                                              errors?.collections?.[index]
                                                ?.collectionTitle
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                `collections[${index}].collectionTitle`,
                                                e
                                              );
                                            }}
                                            helpText={
                                              <span>Name of collection</span>
                                            }
                                          />
                                        </div>
                                      </FormLayout.Group>
                                      <FormLayout.Group>
                                        <div className="collection_image_wrapper_main">
                                          <div>
                                            <div class="Polaris-Labelled__LabelWrapper">
                                              <div class="Polaris-Label">
                                                <label
                                                  id="PolarisSelect2Label"
                                                  for="dishmenu_image"
                                                  class="Polaris-Label__Text"
                                                >
                                                  Collection Image
                                                </label>
                                              </div>
                                            </div>
                                            <input
                                              id="dishmenu_image"
                                              type="file"
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `collections[${index}].image`,
                                                  e.target.files[0]
                                                );
                                              }}
                                            />
                                          </div>
                                          <div className="manage_quantity_wrapper">
                                            <Checkbox
                                              label="Manage inventory"
                                              checked={
                                                collection?.manageInventory
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `collections[${index}].manageInventory`,
                                                  e
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </FormLayout.Group>
                                      <FormLayout.Group>
                                        <div className="preview_collection_image">
                                          <img
                                            src={
                                              collection?.image
                                                ? collection?.image?.name
                                                  ? URL.createObjectURL(
                                                    collection?.image
                                                  )
                                                  : collection?.image
                                                : ''
                                            }
                                          />
                                        </div>
                                      </FormLayout.Group>
                                      <FormLayout.Group>
                                        <div className="product-search">
                                          <SearchProduct
                                            value={
                                              collection?.dishMenuProductIds || [
                                                [],
                                              ]
                                            }
                                            setFieldValue={setFieldValue}
                                            fieldName={`collections[${index}].dishMenuProductIds`}
                                            allProducts={
                                              dishmenuAllProducts || [[]]
                                            }
                                            setAllProducts={
                                              setDishmenuAllProducts
                                            }
                                            error={
                                              touched?.collections?.[index]
                                                ?.productIds?.productId &&
                                              errors?.collections?.[index]
                                                ?.productIds?.productId
                                            }
                                            i18n={i18n}
                                            collectionField={`collectionField${index}`}
                                          />
                                        </div>
                                      </FormLayout.Group>
                                      <PreviewCollection
                                        isUpdate={false}
                                        allProducts={
                                          dishmenuAllProducts || [[]]
                                        }
                                        setAllProducts={setDishmenuAllProducts}
                                        setUpdated={setUpdated}
                                        i18n={i18n}
                                        collectionField={`collectionField${index}`}
                                        values={values}
                                        index={index}
                                        customInventory={customInventory}
                                        setCustomInventory={setCustomInventory}
                                        setFieldValue={setFieldValue}
                                        inventory={inventory}
                                        setInventory={setInventory}
                                      />
                                      {collection?.collectionTitle
                                        ?.toLocaleLowerCase()
                                        ?.trim() == 'bowls' && (
                                          <div className="upsell_images_container">
                                            <h2> Add Upsell Variants</h2>
                                            <FormLayout.Group>
                                              <div className="product-search">
                                                <SearchProduct
                                                  value={
                                                    collection?.dishMenuUpsellProductIds || [
                                                      [],
                                                    ]
                                                  }
                                                  setFieldValue={setFieldValue}
                                                  fieldName={`collections[${index}].dishMenuUpsellProductIds`}
                                                  allProducts={
                                                    dishmenuUpsellProducts || [[]]
                                                  }
                                                  setAllProducts={
                                                    setDishmenuUpsellProducts
                                                  }
                                                  i18n={i18n}
                                                  collectionField={`collectionField${index}`}
                                                />
                                              </div>
                                            </FormLayout.Group>
                                            <PreviewCollection
                                              isUpdate={false}
                                              allProducts={
                                                dishmenuUpsellProducts || [[]]
                                              }
                                              setAllProducts={
                                                setDishmenuUpsellProducts
                                              }
                                              setUpdated={setUpdated}
                                              i18n={i18n}
                                              collectionField={`collectionField${index}`}
                                              setFieldValue={setFieldValue}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                            <FormLayout.Group>
                              <Button
                                onClick={() => {
                                  setFieldValue(
                                    'collections',
                                    handleAddCollections(values)
                                  );
                                }}
                              >
                                Add more Collection
                              </Button>
                            </FormLayout.Group>
                          </>
                        )}

                        <FormLayout.Group>
                          <Button
                            primary
                            onClick={() => handleSubmit()}
                            loading={isSubmitting}
                          >
                            Save
                          </Button>
                        </FormLayout.Group>
                      </FormLayout>
                    </Card.Section>
                  </Card>

                  {/* <Card title="Box Campaign" sectioned>
                    
                  </Card> */}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Page>
    </Frame>
  );
};

export default CreateLoisaWeeklyMenu;
