import React, { useState, useEffect, useCallback, useRef } from 'react';

import RevenueTrends from './RevenueTrends';
import CustomerInsights from './CustomerInsights';
import Retention from './Retention';
import Product from './Product';
import { FilterContextProvider } from './../common/Contexts/AnalyticsFilterContext';
import {
  Card,
  Select,
  ExceptionList,
  Button,
  Icon,
  Modal,
  TextField,
  Layout,
  Page,
  Stack,
  Tabs,
  Frame,
  Toast,
  DatePicker,
} from '@shopify/polaris';
import {
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import { FlagMajor, ChevronDownMinor } from '@shopify/polaris-icons';
import SmartSms from './SmartSms';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import Reports from './Reports';
import { useParams, useHistory } from 'react-router-dom';
import RangeDatePicker from './RangeDatePicker';
import dayjs from 'dayjs';
import "./analytics.scss";
import "./dashboard.scss";

const Analytics = (props) => {
  const { i18n, currency, domain, shopEmail } = props;
  const { subTab, selectedTab } = useParams();
  const [selectedTitleTab, setSelectedTitleTab] = useState(selectedTab || 'revenue_trends');
  const [headerButton, setHeaderButton] = useState(selectedTab || 'revenue_trends');
  const headerButtons = [
    {
      val: "revenue_trends",
      name: 'Revenue Trends',
      index: 0,
    },
    {
      val: "customer_insights",
      name: 'Customer Insights',
      index: 1,
    },
    // ...(process.env.APP_TYPE=="public" ?
    // [{
    //   id: 'retention',
    //   content: 'Retention',
    // }]:[]),
    {
      val: 'product',
      name: 'Product',
      index: 3,
    },
    {
      val: 'smarty_sMS',
      name: 'SmartySMS',
      index: 4,
    },
    {
      val: 'reports',
      name: 'Reports',
      index: 5,
    },
  ];
  const [customHeaderButtons, setCustomHeaderButtons] = useState([]);
  const [customHeaderButton, setCustomHeaderButton] = useState()
  const [showExport, setShowExport] = useState(false);
  const history = useHistory();
  const [dateFilter, setDateFilter] = useState(30);
  const [customRange, setCustomRange] = useState(false);
  const [selectedDates, setSelectedDates] = useState();
  const [{ month, year }, setDate] = useState({ month: parseInt(`${+dayjs().format("M") - 1}`, 10), year: +dayjs().format("YYYY") });

  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(() => setToastContent(), []);
  const [customMainButtons, setCustomMainButtons] = useState([]);

  useEffect(() => {
    if (selectedTab != headerButton) {
      setSelectedTitleTab(headerButton);
    }
  }, [headerButton]);



  const headerObject = {
    default: {
      title: i18n.t('analytics'),
      desc: 'Analyse your store’s performance and customer behavior with our powerful analytics dashboard.',
    },
    reports: {
      title: 'Analytics',
      desc: 'Analyse your store’s performance and customer behavior with our powerful analytics dashboard.',
      inventory_forecast: {
        title: "Inventory forecast",
        desc: "View the projected inventory of subscription products in scheduled subscription orders sorted by defined time frames."
      }
    },
  }

  const mainHeaderButtons = {
    default: {
      buttons: [
        {
          name: "Reports",
          onClick: () => setHeaderButton("reports")
        },
      ]
    },
  };

  useEffect(() => {
    if (selectedTab != headerButton) {
      history.push(`/analytics/${headerButton}`)
    }
  }, [headerButton])

  const dateFilterOptions = [
    {
      title: "7 days",
      val: 7
    },
    {
      title: "30 days",
      val: 30
    },
    {
      title: "3 months",
      val: 90
    },
    {
      title: "6 months",
      val: 180
    },
  ]
  const FilterButtons = () => {
    return (
      <div className='analytics_header_filter_buttons'>
        <div className='billing_label'>Billing Date:</div>
        <div className='billing_filter_button_group'>
          {
            dateFilterOptions?.map((filterOpt) => {
              return (
                <Button primary={+filterOpt?.val === +dateFilter} onClick={() => { setDateFilter(filterOpt?.val); setCustomRange(false) }}>{filterOpt?.title}</Button>
              )
            })
          }
          <div className='calendar-wrapper'>
            <Button primary={!dateFilter} onClick={() => { setDateFilter(); setCustomRange(!customRange) }}>Custom</Button>
            {
              customRange && (
                <div className='custom_date_range_filter'>
                  <RangeDatePicker {...{ selectedDates, setSelectedDates, month, year, setDate }} />
                </div>
              )
            }
          </div>

        </div>
      </div>
    )
  }

  const RenderCustomHeader = () => {
    return (
      <div className="chargezen_custom_page_header">
        {
          toastContent && (
            <Toast
              content={toastContent}
              onDismiss={hideSaveSuccess}
            />
          )
        }
        <div className="page_title_wrapper">
          <div className="back_button_wrapper">
            {
              subTab && (
                <div
                  className="back-button pointer"
                  onClick={() => {
                    history.push(`/analytics/${selectedTab}`);
                  }}
                >
                  <Icon source={MobileBackArrowMajor
                  } color="base" />
                </div>
              )
            }

            <div class="text_wrapper page_heading_title_wrapper">
              <div
                class="Polaris-Header-Title__TitleAndSubtitleWrapper"
                style={{
                  width: '100%',
                }}
              >
                <div class="Polaris-Header-Title">
                  <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                    <span class="Polaris-TextStyle--variationStrong">
                      <div class="back_button_wrapper">
                        {' '}
                        <div
                          class="text_wrapper page_heading_title_wrapper"
                          style={{
                            width: '100%',
                          }}
                        >
                          <span>
                            {
                              !headerObject[selectedTab] ?
                                headerObject?.default?.title
                                :
                                headerObject[selectedTab][subTab] ?
                                  headerObject[selectedTab][subTab]?.title
                                  :
                                  headerObject[selectedTab]?.title

                            }
                          </span>
                          <div className="shpoify_style_button_wrapper">
                            {
                              customMainButtons?.length > 0 ?
                                customMainButtons?.map((mainButton) => {
                                  return (
                                    <div className='button_primary'>
                                      <Button
                                        onClick={mainButton?.onClick}
                                      >
                                        {mainButton?.name}
                                      </Button>
                                    </div>
                                  )
                                })
                                :
                                mainHeaderButtons?.default?.buttons?.map((mainButton) => {
                                  return (
                                    <div className='button_primary'>
                                      <Button
                                        onClick={mainButton?.onClick}
                                      >
                                        {mainButton?.name}
                                      </Button>
                                    </div>
                                  )
                                })
                            }

                          </div>
                        </div>
                      </div>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar_summary header_description">
          {
            !headerObject[selectedTab] ?
              headerObject?.default?.desc
              :
              headerObject[selectedTab][subTab] ?
                headerObject[selectedTab][subTab]?.desc
                :
                headerObject[selectedTab]?.desc

          }
        </div>
      </div>
    );
  };



  return (
    <Frame>
      <Page>
        <RenderCustomHeader />
        <FilterContextProvider>
          <div className="without_header_card">
            <Card
              actions={{
                content: (
                  <>
                    <div className="card_header_button_group custom_filter_button_group">
                      {
                        subTab === "inventory_forecast" ?
                          <FilterButtons />
                          :
                          <div className="card_header_buttons primary_button_group">
                            {
                              customHeaderButtons?.length > 0 ? (
                                <HeaderButtons
                                  headerButtons={customHeaderButtons}
                                  setHeaderButton={setCustomHeaderButton}
                                  headerButton={customHeaderButton}
                                />
                              )
                                :

                                <HeaderButtons
                                  headerButtons={headerButtons}
                                  setHeaderButton={setHeaderButton}
                                  headerButton={headerButton}
                                />

                            }

                          </div>
                      }

                    </div>
                  </>
                ),
              }}
              sectioned
            >
              <Card.Section subdued>
                {selectedTitleTab === 'revenue_trends' ? (
                  <div className="analytics-page-layout">
                    <RevenueTrends i18n={i18n} currency={currency} />
                  </div>
                ) : selectedTitleTab === 'customer_insights' ? (
                  <div className="customer-insight">
                    <CustomerInsights i18n={i18n} currency={currency} />
                  </div>
                ) : process.env.APP_TYPE == 'public' &&
                  selectedTitleTab === 'product' ? (
                  <div className="retention">
                    <Retention i18n={i18n} currency={currency} />
                  </div>
                ) : selectedTitleTab === 'product' ? (
                  <div className="product">
                    <Product i18n={i18n} currency={currency} />
                  </div>
                ) : selectedTitleTab === 'smarty_sMS' ? (
                  <>
                    <SmartSms i18n={i18n} currency={currency} />
                  </>
                ) : selectedTitleTab === 'reports' ? (
                  <>
                    <Reports i18n={i18n} currency={currency} domain={domain} {...{ dateFilter, setDateFilter, showExport, setShowExport, toastContent, setToastContent, selectedDates, customMainButtons, setCustomMainButtons, headerButton, shopEmail }} setHeaderButtons={setCustomHeaderButtons} headerButton={customHeaderButton} setHeaderButton={setCustomHeaderButton} />
                  </>
                ) : (
                  ''
                )}
              </Card.Section>
            </Card>
          </div>
        </FilterContextProvider>
      </Page>
    </Frame>
  );
};
export default Analytics;
