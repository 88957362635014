import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Card,
  Toast,
  ChoiceList,
  Page,
  PageActions,
  TextField,
  Layout,
  Banner,
  List,
  Frame,
  Select,
  Icon,
  Checkbox,
  RadioButton,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
  TextContainer,
} from '@shopify/polaris';
import { MobileBackArrowMajor, NoteMinor } from '@shopify/polaris-icons';
import SearchAffiliates from './SearchAffiliates';
import { useHistory } from 'react-router-dom';
import AssignAffiliatesLink from './AssignAffiliatesLink';
import { useParams } from 'react-router-dom';
import SearchCommission from './SearchCommission';
import axiosconfig from '../axiosconfig';

const InviteAffiliateForm = (props) => {
  const {
    domain,
    setEditForm,
    refetch,
    setSaveSuccess,
    i18n,
    customHeader,
    setCustomHeader,
  } = props;

  const [commissionSearchQuery, setCommissionSearchQuery] = useState('');
  const [selectedCommission, setSelectedCommission] = useState([]);
  const [showCommissionSearch, setShowCommissionSearch] = useState(false);
  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(() => setToastContent(), []);

  const history = useHistory();
  const { edit_id } = useParams();
  const [fieldError, setFieldError] = useState({
    email: '',
    first_name: '',
    last_name: '',
  });
  const [affiliateLink, setAffiliateLink] = useState();
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
  });

  const handleSubmit = () => {
    if (!formData.email) {
      setToastContent("Email field can' be blank.");
      return;
    } else if (!formData?.commission_profile) {
      setToastContent('Please select a commission profile first.');
      return;
    }
    axiosconfig.post('/invite_affiliate_program', formData).then(({ data }) => {
      if (data.success) {
        setToastContent('Invitation sent Successfully');
        // history.push(`/affiliates/`);
      }
    })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  const deleteSubmit = () => {
    axiosconfig.delete(`/commissionProfile/delete/${edit_id}?shopify_domain=${domain}`).then(() => {
      setToastContent('Deleted Successfully');
      history.push(`/affiliates/affiliate_commission`);
    });
  };

  const getEditState = () => {
    if (edit_id && !isNaN(+edit_id)) {
      // fetch(`/fetchCommissionProfile/${edit_id}`, {
      //   method: 'GET',
      // })
      //   .then((r) => r.json())
      //   .then((data) => {
      //     console.log('data?.commission_profile', data?.commission_profile);
      //     if (data?.commission_profile) {
      //       let commission_profile = data?.commission_profile;
      //       commission_profile.shopify_domain = domain;
      //       setFormData(commission_profile);
      //       setSelectedAffiliate([...commission_profile?.assoc_affiliates]);
      //       setCustomHeader(data?.commission_profile?.automatic_tracking_title);
      //     } else {
      //       console.log('Something went wrong...');
      //     }
      //   });
    } else {
      setCustomHeader('New affiliate/influencer');
    }
  };

  useEffect(() => {
    getEditState();
  }, []);

  const [summaryText, setSummaryText] = useState();
  useEffect(() => {
    let details = [];
  }, [formData]);

  return (
    <Frame>
      {toastContent && (
        <Toast content={toastContent} onDismiss={hideSaveSuccess} />
      )}
      <Layout>
        <Layout.Section>
          {showCommissionSearch && (
            <SearchCommission
              {...{
                domain,
                commissionSearchQuery,
                setCommissionSearchQuery,
                formData,
                setFormData,
                selectedCommission,
                setSelectedCommission,
                setShowCommissionSearch,
              }}
            />
          )}
          <Card
            title={
              <div className="heading_title">Choose commision profile</div>
            }
            sectioned
          >
            <div className="chargezen_form_row method_wrapper">
              <div className="method_header_title">
                Start by selecting the commission profile, you are setting the
                affiliate commission for this affiliate; and an affiliate
                tracking url will be automatically generated. You can view this
                in the affiliates section.
              </div>
              <div className="w-full">
                <button
                  className={`select_commission_profile ${''}`}
                  onClick={() => {
                    setShowCommissionSearch(true);
                  }}
                >
                  Select commision profile
                </button>
              </div>
            </div>
          </Card>

          <Card sectioned>
            <div className="chargezen_form_row flex_wrapper">
              <TextField
                label={'First name'}
                type="text"
                value={formData?.first_name || ''}
                onChange={(e) => {
                  setFormData({ ...formData, first_name: e });
                }}
                error={fieldError?.first_name}
                autoComplete="off"
              />
              <TextField
                label={'Last name'}
                type="text"
                value={formData?.last_name || ''}
                onChange={(e) => {
                  setFormData({ ...formData, last_name: e });
                }}
                error={fieldError?.last_name}
                autoComplete="off"
              />
            </div>
            <div className="chargezen_form_row flex_wrapper">
              <TextField
                label={'Email'}
                type="text"
                value={`${formData?.email}`}
                onChange={(e) => {
                  setFormData({ ...formData, email: e });
                }}
                error={fieldError?.email}
                autoComplete="off"
              />
            </div>
          </Card>
        </Layout.Section>
        <div className="defauleLayoutSection sdebar-right-cstm">
          <Layout.Section secondary>
            <Card title="Summary">
              <Card.Section>
                <strong className="summary_discount_code">
                  Invite new affiliate via email
                </strong>
                <div class="sidbar-section seec-1">
                  <h3>Commision profile</h3>
                  <ul class="Polaris-List">
                    {formData?.commission_profile ? (
                      <>
                        <li>
                          {
                            formData?.commission_profile
                              ?.automatic_tracking_title
                          }
                        </li>
                        <li>
                          Affiliate commission:{' '}
                          {formData?.commission_profile?.commission}
                        </li>
                      </>
                    ) : (
                      <li>No commission profile selected yet.</li>
                    )}
                  </ul>
                </div>
                <div class="sidbar-section seec-2">
                  <h3>Invited Affiliate</h3>
                  <ul class="Polaris-List">
                    <li>
                      Name:{' '}
                      {!formData?.first_name && !formData?.last_name
                        ? '--'
                        : `${formData?.first_name} ${formData?.last_name}`}
                    </li>
                    <li>Email: {formData?.email || '--'}</li>
                  </ul>
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
        </div>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: 'Save',
              onClick: () => handleSubmit(),
            }}
            secondaryActions={
              edit_id
                ? [
                  edit_id
                    ? {
                      content: 'Delete',
                      onClick: () => deleteSubmit(),
                      destructive: true,
                    }
                    : '',
                ]
                : null
            }
          />
        </Layout.Section>
      </Layout>
    </Frame>
  );
};

export default InviteAffiliateForm;
