import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Frame, Page, Select, Stack, TextField, Toast } from "@shopify/polaris";
import { GetStartedIcon } from "../../components/layout/CustomIcon";
import "./style.css";
import RenderCustomizations from "./RenderCustomizations";

const style_objects = [
    {
        category: "checkbox",
        name: "checkbox",
        key: "checkbox",
        fields: [{
            key: "cornerRadius",
            type: "select",
            options: ["BASE", "LARGE", "NONE", "SMALL"],
            value: "BASE"
        }]
    },
    {
        category: "control",
        name: "control",
        key: "control",
        fields: [{
            key: "border",
            type: "select",
            options: ["FULL", "NONE"],
            value: "FULL"
        },
        {
            key: "color",
            type: "select",
            options: ["TRANSPARENT", "COLOR1", "COLOR2"],
            value: "TRANSPARENT"
        },
        {
            key: "labelPosition",
            type: "select",
            options: ["INSIDE", "OUTSIDE"],
            value: "INSIDE"
        },
        {
            key: "cornerRadius",
            type: "select",
            options: ["BASE", "LARGE", "NONE", "SMALL"],
            value: "BASE"
        }]
    },
    {
        category: "global",
        name: "global",
        key: "global",
        fields: [{
            key: "cornerRadius",
            name: "cornerRadius",
            type: "select",
            options: ["NONE"],
            value: "NONE"
        },
        {
            category: "typography",
            key: "typography",
            name: "typography",
            fields: [{
                key: "kerning",
                type: "select",
                value: "BASE",
                options: ["BASE", "EXTRA_LOOSE", "LOOSE"]
            },
            {
                key: "letterCase",
                type: "select",
                value: "LOWER",
                options: ["LOWER", "NONE", "TITLE", "UPPER"]
            }]
        },
        ]
    },
    {
        category: "header",
        name: "header",
        key: "header",
        fields: [
            {
                key: "alignment",
                type: "select",
                value: "CENTER",
                options: ["CENTER", "END", "START"]
            },
            {
                category: "logo",
                name: "logo",
                key: "logo",
                fields: [
                    {
                        key: "maxWidth",
                        type: "number",
                        value: ""
                    },]
            },
            {
                key: "position",
                type: "select",
                value: "INLINE",
                options: ["INLINE", "INLINE_SECONDARY", "START"]
            },
        ]
    },
    {
        category: "headingLevel1",
        name: "headingLevel1",
        key: "headingLevel1",
        fields: [
            {
                category: "typography",
                name: "typography",
                key: "typography",
                fields: [
                    {
                        name: "font",
                        key: "font",
                        type: "select",
                        value: "PRIMARY",
                        options: ["PRIMARY", "SECONDARY"]
                    },
                    {
                        name: "kerning",
                        key: "kerning",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "EXTRA_LOOSE", "LOOSE"]
                    },
                    {
                        name: "letterCase",
                        key: "letterCase",
                        type: "select",
                        value: "LOWER",
                        options: ["LOWER", "NONE", "TITLE", "UPPER"]
                    },
                    {
                        name: "size",
                        key: "size",
                        type: "select",
                        value: "BASE",
                        options: [
                            "BASE",
                            "EXTRA_EXTRA_LARGE",
                            "EXTRA_LARGE",
                            "EXTRA_SMALL",
                            "LARGE",
                            "MEDIUM",
                            "SMALL",
                        ]
                    },
                    {
                        name: "weight",
                        key: "weight",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "BOLD"]
                    },
                ]
            },

        ]
    },
    {
        category: "headingLevel2",
        name: "headingLevel2",
        key: "headingLevel2",
        fields: [
            {
                category: "typography",
                name: "typography",
                key: "typography",
                fields: [
                    {
                        name: "font",
                        key: "font",
                        type: "select",
                        value: "PRIMARY",
                        options: ["PRIMARY", "SECONDARY"]
                    },
                    {
                        name: "kerning",
                        key: "kerning",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "EXTRA_LOOSE", "LOOSE"]
                    },
                    {
                        name: "letterCase",
                        key: "letterCase",
                        type: "select",
                        value: "LOWER",
                        options: ["LOWER", "NONE", "TITLE", "UPPER"]
                    },
                    {
                        name: "size",
                        key: "size",
                        type: "select",
                        value: "BASE",
                        options: [
                            "BASE",
                            "EXTRA_EXTRA_LARGE",
                            "EXTRA_LARGE",
                            "EXTRA_SMALL",
                            "LARGE",
                            "MEDIUM",
                            "SMALL",
                        ]
                    },
                    {
                        name: "weight",
                        key: "weight",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "BOLD"]
                    },
                ]
            },

        ]
    },
    {
        category: "headingLevel3",
        name: "headingLevel3",
        key: "headingLevel3",
        fields: [
            {
                category: "typography",
                name: "typography",
                key: "typography",
                fields: [
                    {
                        name: "font",
                        key: "font",
                        type: "select",
                        value: "PRIMARY",
                        options: ["PRIMARY", "SECONDARY"]
                    },
                    {
                        name: "kerning",
                        key: "kerning",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "EXTRA_LOOSE", "LOOSE"]
                    },
                    {
                        name: "letterCase",
                        key: "letterCase",
                        type: "select",
                        value: "LOWER",
                        options: ["LOWER", "NONE", "TITLE", "UPPER"]
                    },
                    {
                        name: "size",
                        key: "size",
                        type: "select",
                        value: "BASE",
                        options: [
                            "BASE",
                            "EXTRA_EXTRA_LARGE",
                            "EXTRA_LARGE",
                            "EXTRA_SMALL",
                            "LARGE",
                            "MEDIUM",
                            "SMALL",
                        ]
                    },
                    {
                        name: "weight",
                        key: "weight",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "BOLD"]
                    },
                ]
            },

        ]
    },
    {
        category: "main",
        name: "main",
        key: "main",
        fields: [
            {
                name: "colorScheme",
                key: "colorScheme",
                type: "select",
                value: "COLOR_SCHEME1",
                options: ["COLOR_SCHEME1", "COLOR_SCHEME2", "TRANSPARENT"]

            },

        ]
    },
    {
        category: "merchandiseThumbnail",
        name: "merchandiseThumbnail",
        key: "merchandiseThumbnail",
        fields: [
            {
                name: "border",
                key: "border",
                type: "select",
                value: "FULL",
                options: ["FULL", "NONE"]

            },
            {
                name: "cornerRadius",
                key: "cornerRadius",
                type: "select",
                value: "BASE",
                options: ["BASE", "LARGE", "NONE", "SMALL"]

            },

        ]
    },
    {
        category: "orderSummary",
        name: "orderSummary",
        key: "orderSummary",
        fields: [
            {
                name: "colorScheme",
                key: "colorScheme",
                type: "select",
                value: "COLOR_SCHEME1",
                options: ["COLOR_SCHEME1", "COLOR_SCHEME2", "TRANSPARENT"]

            }
        ]
    },
    {
        category: "primaryButton",
        name: "primaryButton",
        key: "primaryButton",
        fields: [
            {
                category: "typography",
                name: "typography",
                key: "typography",
                fields: [
                    {
                        name: "font",
                        key: "font",
                        type: "select",
                        value: "PRIMARY",
                        options: ["PRIMARY", "SECONDARY"]
                    },
                    {
                        name: "kerning",
                        key: "kerning",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "EXTRA_LOOSE", "LOOSE"]
                    },
                    {
                        name: "letterCase",
                        key: "letterCase",
                        type: "select",
                        value: "LOWER",
                        options: ["LOWER", "NONE", "TITLE", "UPPER"]
                    },
                    {
                        name: "size",
                        key: "size",
                        type: "select",
                        value: "BASE",
                        options: [
                            "BASE",
                            "EXTRA_EXTRA_LARGE",
                            "EXTRA_LARGE",
                            "EXTRA_SMALL",
                            "LARGE",
                            "MEDIUM",
                            "SMALL",
                        ]
                    },
                    {
                        name: "weight",
                        key: "weight",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "BOLD"]
                    },
                ]
            },
            {
                name: "background",
                key: "background",
                type: "select",
                value: "NONE",
                options: ["NONE", "SOLID"]

            },
            {
                name: "blockPadding",
                key: "blockPadding",
                type: "select",
                value: "BASE",
                options: [
                    "BASE",
                    "EXTRA_LOOSE",
                    "EXTRA_TIGHT",
                    "LOOSE",
                    "NONE",
                    "TIGHT",
                ]

            },
            {
                name: "border",
                key: "border",
                type: "select",
                value: "FULL",
                options: ["FULL", "NONE"]
            },
            {
                name: "cornerRadius",
                key: "cornerRadius",
                type: "select",
                value: "BASE",
                options: ["BASE", "LARGE", "NONE", "SMALL"]

            },
            {
                name: "inlinePadding",
                key: "inlinePadding",
                type: "select",
                value: "BASE",
                options: [
                    "BASE",
                    "EXTRA_LOOSE",
                    "EXTRA_TIGHT",
                    "LOOSE",
                    "NONE",
                    "TIGHT",
                ]

            },



        ]
    },
    {
        category: "secondaryButton",
        name: "secondaryButton",
        key: "secondaryButton",
        fields: [
            {
                category: "typography",
                name: "typography",
                key: "typography",
                fields: [
                    {
                        name: "font",
                        key: "font",
                        type: "select",
                        value: "PRIMARY",
                        options: ["PRIMARY", "SECONDARY"]
                    },
                    {
                        name: "kerning",
                        key: "kerning",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "EXTRA_LOOSE", "LOOSE"]
                    },
                    {
                        name: "letterCase",
                        key: "letterCase",
                        type: "select",
                        value: "LOWER",
                        options: ["LOWER", "NONE", "TITLE", "UPPER"]
                    },
                    {
                        name: "size",
                        key: "size",
                        type: "select",
                        value: "BASE",
                        options: [
                            "BASE",
                            "EXTRA_EXTRA_LARGE",
                            "EXTRA_LARGE",
                            "EXTRA_SMALL",
                            "LARGE",
                            "MEDIUM",
                            "SMALL",
                        ]
                    },
                    {
                        name: "weight",
                        key: "weight",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "BOLD"]
                    },
                ]
            },
            {
                name: "background",
                key: "background",
                type: "select",
                value: "NONE",
                options: ["NONE", "SOLID"]

            },
            {
                name: "blockPadding",
                key: "blockPadding",
                type: "select",
                value: "BASE",
                options: [
                    "BASE",
                    "EXTRA_LOOSE",
                    "EXTRA_TIGHT",
                    "LOOSE",
                    "NONE",
                    "TIGHT",
                ]

            },
            {
                name: "border",
                key: "border",
                type: "select",
                value: "FULL",
                options: ["FULL", "NONE"]

            },
            {
                name: "cornerRadius",
                key: "cornerRadius",
                type: "select",
                value: "BASE",
                options: ["BASE", "LARGE", "NONE", "SMALL"]

            },
            {
                name: "inlinePadding",
                key: "inlinePadding",
                type: "select",
                value: "BASE",
                options: [
                    "BASE",
                    "EXTRA_LOOSE",
                    "EXTRA_TIGHT",
                    "LOOSE",
                    "NONE",
                    "TIGHT",
                ]

            },



        ]
    },
    {
        category: "select",
        name: "select",
        key: "select",
        fields: [
            {
                category: "typography",
                name: "typography",
                key: "typography",
                fields: [
                    {
                        name: "font",
                        key: "font",
                        type: "select",
                        value: "PRIMARY",
                        options: ["PRIMARY", "SECONDARY"]
                    },
                    {
                        name: "kerning",
                        key: "kerning",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "EXTRA_LOOSE", "LOOSE"]
                    },
                    {
                        name: "letterCase",
                        key: "letterCase",
                        type: "select",
                        value: "LOWER",
                        options: ["LOWER", "NONE", "TITLE", "UPPER"]
                    },
                    {
                        name: "size",
                        key: "size",
                        type: "select",
                        value: "BASE",
                        options: [
                            "BASE",
                            "EXTRA_EXTRA_LARGE",
                            "EXTRA_LARGE",
                            "EXTRA_SMALL",
                            "LARGE",
                            "MEDIUM",
                            "SMALL",
                        ]
                    },
                    {
                        name: "weight",
                        key: "weight",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "BOLD"]
                    },
                ]
            },
            {
                name: "border",
                key: "border",
                type: "select",
                value: "BLOCK_END",
                options: ["BLOCK_END", "FULL", "NONE"]

            },
        ]
    },
    {
        category: "textField",
        name: "textField",
        key: "textField",
        fields: [
            {
                category: "typography",
                name: "typography",
                key: "typography",
                fields: [
                    {
                        name: "font",
                        key: "font",
                        type: "select",
                        value: "PRIMARY",
                        options: ["PRIMARY", "SECONDARY"]
                    },
                    {
                        name: "kerning",
                        key: "kerning",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "EXTRA_LOOSE", "LOOSE"]
                    },
                    {
                        name: "letterCase",
                        key: "letterCase",
                        type: "select",
                        value: "LOWER",
                        options: ["LOWER", "NONE", "TITLE", "UPPER"]
                    },
                    {
                        name: "size",
                        key: "size",
                        type: "select",
                        value: "BASE",
                        options: [
                            "BASE",
                            "EXTRA_EXTRA_LARGE",
                            "EXTRA_LARGE",
                            "EXTRA_SMALL",
                            "LARGE",
                            "MEDIUM",
                            "SMALL",
                        ]
                    },
                    {
                        name: "weight",
                        key: "weight",
                        type: "select",
                        value: "BASE",
                        options: ["BASE", "BOLD"]
                    },
                ]
            },
            {
                name: "border",
                key: "border",
                type: "select",
                value: "FULL",
                options: ["BLOCK_END", "FULL", "NONE"]

            }
        ]
    },







];
const designSystem = [
    {
        category: "colors",
        name: "colors",
        key: "colors",
        fields: [
            {
                category: "global",
                name: "global",
                key: "global",
                fields: [
                    {
                        key: "accent",
                        type: "color",
                        value: ""
                    },
                    {
                        key: "brand",
                        type: "color",
                        value: ""
                    },
                    {
                        key: "critical",
                        type: "color",
                        value: ""
                    },
                    {
                        key: "decorative",
                        type: "color",
                        value: ""
                    },
                    {
                        key: "info",
                        type: "color",
                        value: ""
                    },
                    {
                        key: "success",
                        type: "color",
                        value: ""
                    },
                    {
                        key: "warning",
                        type: "color",
                        value: ""
                    }
                ]
            },
            {
                category: "schemes",
                name: "schemes",
                key: "schemes",
                fields: [
                    {
                        category: "scheme1",
                        name: "scheme1",
                        key: "scheme1",
                        fields: [
                            {
                                category: "base",
                                key: "base",
                                name: "base",
                                fields: [
                                    {
                                        key: "accent",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "background",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "border",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "decorative",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "icon",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "text",
                                        type: "color",
                                        value: ""
                                    },
                                ]

                            },
                            {
                                category: "control",
                                key: "control",
                                name: "control",
                                fields: [
                                    {
                                        key: "accent",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "background",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "border",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "decorative",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "icon",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        category: "selected",
                                        key: "selected",
                                        name: "selected",
                                        fields: [
                                            {
                                                key: "accent",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "background",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "border",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "decorative",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "icon",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "text",
                                                type: "color",
                                                value: ""
                                            },
                                        ]

                                    },
                                    {
                                        key: "text",
                                        type: "color",
                                        value: ""
                                    },
                                ]

                            },

                            {
                                category: "primaryButton",
                                key: "primaryButton",
                                name: "primaryButton",
                                fields: [
                                    {
                                        key: "accent",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "background",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "border",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "decorative",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "icon",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        category: "hover",
                                        key: "hover",
                                        name: "hover",
                                        fields: [
                                            {
                                                key: "accent",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "background",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "border",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "decorative",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "icon",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "text",
                                                type: "color",
                                                value: ""
                                            },
                                        ]

                                    },
                                    {
                                        key: "text",
                                        type: "color",
                                        value: ""
                                    },
                                ]

                            },
                            {
                                category: "secondaryButton",
                                key: "secondaryButton",
                                name: "secondaryButton",
                                fields: [
                                    {
                                        key: "accent",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "background",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "border",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "decorative",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "icon",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        category: "hover",
                                        key: "hover",
                                        name: "hover",
                                        fields: [
                                            {
                                                key: "accent",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "background",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "border",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "decorative",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "icon",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "text",
                                                type: "color",
                                                value: ""
                                            },
                                        ]

                                    },
                                    {
                                        key: "text",
                                        type: "color",
                                        value: ""
                                    },
                                ]

                            },

                        ]
                    },
                    {
                        category: "scheme2",
                        name: "scheme2",
                        key: "scheme2",
                        fields: [
                            {
                                category: "base",
                                key: "base",
                                name: "base",
                                fields: [
                                    {
                                        key: "accent",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "background",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "border",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "decorative",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "icon",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "text",
                                        type: "color",
                                        value: ""
                                    },
                                ]

                            },
                            {
                                category: "control",
                                key: "control",
                                name: "control",
                                fields: [
                                    {
                                        key: "accent",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "background",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "border",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "decorative",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "icon",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        category: "selected",
                                        key: "selected",
                                        name: "selected",
                                        fields: [
                                            {
                                                key: "accent",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "background",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "border",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "decorative",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "icon",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "text",
                                                type: "color",
                                                value: ""
                                            },
                                        ]

                                    },
                                    {
                                        key: "text",
                                        type: "color",
                                        value: ""
                                    },
                                ]

                            },

                            {
                                category: "primaryButton",
                                key: "primaryButton",
                                name: "primaryButton",
                                fields: [
                                    {
                                        key: "accent",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "background",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "border",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "decorative",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "icon",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        category: "hover",
                                        key: "hover",
                                        name: "hover",
                                        fields: [
                                            {
                                                key: "accent",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "background",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "border",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "decorative",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "icon",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "text",
                                                type: "color",
                                                value: ""
                                            },
                                        ]

                                    },
                                    {
                                        key: "text",
                                        type: "color",
                                        value: ""
                                    },
                                ]

                            },
                            {
                                category: "secondaryButton",
                                key: "secondaryButton",
                                name: "secondaryButton",
                                fields: [
                                    {
                                        key: "accent",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "background",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "border",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "decorative",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        key: "icon",
                                        type: "color",
                                        value: ""
                                    },
                                    {
                                        category: "hover",
                                        key: "hover",
                                        name: "hover",
                                        fields: [
                                            {
                                                key: "accent",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "background",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "border",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "decorative",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "icon",
                                                type: "color",
                                                value: ""
                                            },
                                            {
                                                key: "text",
                                                type: "color",
                                                value: ""
                                            },
                                        ]

                                    },
                                    {
                                        key: "text",
                                        type: "color",
                                        value: ""
                                    },
                                ]

                            },

                        ]
                    },

                ]
            },
        ]
    },
    {
        category: "cornerRadius",
        key: "cornerRadius",
        name: "cornerRadius",
        fields: [
            {
                key: "base",
                type: "number",
                value: ""
            },
            {
                key: "large",
                type: "number",
                value: ""
            },
            {
                key: "small",
                type: "number",
                value: ""
            },
        ]
    },
    {
        category: "typography",
        name: "typography",
        key: "typography",
        fields: [
            {
                category: "primary",
                name: "primary",
                key: "primary",
                fields: [
                    {
                        category: "customFontGroup",
                        name: "customFontGroup",
                        key: "customFontGroup",
                        fields: [
                            {
                                name: "loadingStrategy",
                                key: "loadingStrategy",
                                type: "select",
                                value: "AUTO",
                                options: [
                                    "AUTO",
                                    "BLOCK",
                                    "FALLBACK",
                                    "OPTIONAL",
                                    "SWAP",
                                ]
                            }

                        ]
                    },
                    {
                        category: "shopifyFontGroup",
                        name: "shopifyFontGroup",
                        key: "shopifyFontGroup",
                        fields: [
                            {
                                name: "loadingStrategy",
                                key: "loadingStrategy",
                                type: "select",
                                value: "AUTO",
                                options: [
                                    "AUTO",
                                    "BLOCK",
                                    "FALLBACK",
                                    "OPTIONAL",
                                    "SWAP",
                                ]
                            }

                        ]
                    }

                ]
            },
            {
                category: "secondary",
                name: "secondary",
                key: "secondary",
                fields: [
                    {
                        category: "customFontGroup",
                        name: "customFontGroup",
                        key: "customFontGroup",
                        fields: [
                            {
                                name: "loadingStrategy",
                                key: "loadingStrategy",
                                type: "select",
                                value: "AUTO",
                                options: [
                                    "AUTO",
                                    "BLOCK",
                                    "FALLBACK",
                                    "OPTIONAL",
                                    "SWAP",
                                ]
                            }

                        ]
                    },
                    {
                        category: "shopifyFontGroup",
                        name: "shopifyFontGroup",
                        key: "shopifyFontGroup",
                        fields: [
                            {
                                name: "loadingStrategy",
                                key: "loadingStrategy",
                                type: "select",
                                value: "AUTO",
                                options: [
                                    "AUTO",
                                    "BLOCK",
                                    "FALLBACK",
                                    "OPTIONAL",
                                    "SWAP",
                                ]
                            }

                        ]
                    }

                ]
            },
            {
                category: "size",
                name: "size",
                key: "size",
                fields: [
                    {
                        name: "base",
                        key: "base",
                        subtype: "float",
                        type: "number",
                        value: ""
                    },
                    {
                        name: "ratio",
                        key: "ratio",
                        subtype: "float",
                        type: "number",
                        value: ""
                    }

                ]
            },
        ]
    },












];
const Checkout = (props) => {
    const { i18n, domain } = props;

    const toggleActive = useCallback(() => { setToasContent() }, []);
    const [toastContent, setToasContent] = useState();

    const [formData, setFormData] = useState({ customizations: [...style_objects], designSystem: [...designSystem] });
    const [dbData, setDbData] = useState({ customizations: [...style_objects], designSystem: [...designSystem] });
    const [checkoutProfileId, setCheckoutProfileId] = useState();
    const [checkoutProfileOptions, setCheckoutProfileOptions] = useState();

    const fetchCheckoutProfiles = () => {
        fetch(`/fetch_checkout_profiles?shopify_domain=${domain}`,
            { method: "GET" }
        ).then(r => r.json())
            .then((data) => {
                if (data?.checkout_profiles) {
                    setCheckoutProfileOptions([...data?.checkout_profiles])
                    setCheckoutProfileId(data?.checkout_profiles?.[0]?.value)
                }
            })
    }

    const fetchCheckoutExtensionSetting = () => {
        fetch(`/fetch_checkout_extension?shopify_domain=${domain}&checkout_profile_id=${checkoutProfileId}`,
            { method: "GET" }
        ).then(r => r.json())
            .then((data) => {
                if (data?.checkout_extension) {
                    const extension_settings = data?.checkout_extension
                    setFormData({ ...extension_settings })
                    setDbData({ ...extension_settings })
                }
            })
    }

    useEffect(() => {
        fetchCheckoutProfiles();
    }, [])

    useEffect(() => {
        if (checkoutProfileId) {
            fetchCheckoutExtensionSetting()
        }
    }, [checkoutProfileId])



    const handleSubmit = () => {
        let extension_setting = formData;
        extension_setting.checkout_profile_id = checkoutProfileId;
        fetch("/save_checkout_extension", {
            method: "POST",
            body: JSON.stringify({ extension_setting: extension_setting, shopify_domain: domain }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(r => r.json())
            .then((data) => {
                if (data?.success) {
                    setToasContent("Saved successfully.")
                }
            })
    }


    return (
        <Frame>
            {toastContent && (
                <Toast content={toastContent || "Data updated successfully."} onDismiss={toggleActive} duration={3000} />
            )}
            <Page
                title="Checkout style settings"
            >
                <div className="select_checkout_profile">
                    <Select
                        label="Select checkout profile"
                        options={checkoutProfileOptions}
                        onChange={(e) => { setCheckoutProfileId(e) }}
                        value={checkoutProfileId}
                    />
                </div>
                <h1 className="customization_header">Customizations</h1>
                {formData?.customizations?.map((style, i) => {
                    return (
                        <RenderCustomizations style={style} index={i} changeFor="customizations" {...{ formData, setFormData }} />
                    )
                })}
                <h1 className="customization_header">Design System</h1>
                {formData?.designSystem?.map((style, i) => {
                    return (
                        <RenderCustomizations style={style} index={i} changeFor="designSystem"  {...{ formData, setFormData }} />
                    )
                })}
                <div className="save_button_wrapper">
                    <Button onClick={() => { handleSubmit() }} >Save</Button>
                    <Button variant="primary" tone="critical" onClick={() => {
                        setFormData({ ...dbData })
                    }} >Discard</Button>
                    <Button onClick={() => {
                        setFormData({ customizations: [...style_objects], designSystem: [...designSystem] })
                    }} >Result to default</Button>
                </div>
            </Page>
        </Frame>
    )
}

export default Checkout


