import { Icon } from '@shopify/polaris';
import { SearchMajor } from '@shopify/polaris-icons';
import React, { useState } from 'react';
import { ChevronDownMinor, MobileCancelMajor } from '@shopify/polaris-icons';
import BundlePickerModal from './SubscriptionBundleModal';

const SubscriptionBundlePicker = (props) => {
  const { setFieldValue, formData, setFormData, disabled, singleLine, label } = props;
  let { values, fieldName } = props
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [query, setQuery] = useState('');

  values ||= formData;
  values[fieldName] ||= [];
  const removeItem = (planIndex) => {
    const plans = values[fieldName];
    plans.splice(planIndex, 1);
    if (setFormData) {
      setFormData({ ...formData, [fieldName]: [...plans] })
    } else {
      setFieldValue(fieldName, [...plans]);
    }
  };

  return (
    <div className="product-variant-picker-container">
      {showPlanModal && (
        <BundlePickerModal
          {...{
            showPlanModal,
            setShowPlanModal,
            query,
            setQuery,
            setFieldValue,
            values,
            formData,
            setFormData,
            fieldName,
            singleLine
          }}
        />
      )}
      {
        label &&
        <label className='input_custom_label'>{label}</label>
      }
      <div className="product_searchProduct">
        <div className="product_search_input">
          <Icon source={SearchMajor} tone="base" />
          <input
            type="text"
            placeholder="Search bundles"
            id="search_plan_query"
            onChange={(e) => {
              setQuery(e?.target?.value || '');
              setShowPlanModal(true);
            }}
            value={query}
            disabled={disabled}
          />
        </div>
        <button
          type="button"
          className="browse_product_button"
          onClick={() => setShowPlanModal(true)}
        >
          Browse
        </button>
      </div>
      <div className="product_preview_row">
        {values[fieldName]?.filter((p) => p.selected)?.map((p, i) => {
          return (
            <div className="product_variant_preview_wrapper" style={{ padding: "10px" }}>
              {/* <div className="imgwrapper">
                <img src={p.image} />
              </div> */}
              <div className="pv_desc_container">
                <div className="product_variant_desc">
                  <span>{p.name}</span>
                </div>
                <div className="edit_remove_wrapper">
                  <button
                    type="button"
                    className="editbutton"
                    onClick={() => setShowPlanModal(true)}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="close"
                    onClick={() => removeItem(i)}
                  >
                    <Icon source={MobileCancelMajor} tone="base" />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionBundlePicker;
