import React, { useEffect, useState } from "react";
import {
    Select,
    AppProvider,
    TextField,
    Frame,
    Card,
    RadioButton,
    RangeSlider
} from "@shopify/polaris";
import "./createQuizStyle.css";
import NoImage from "../../../assets/images/noImage.png";

const CreateQuiz = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentSelection, setCurrentSelection] = useState("content");
    const [questions, setQuestions] = useState([
        {
            heading: "What is your Name ?",
            subheading: "Please enter your name.",
            type: "Radio buttons",
            answers: [],
            typeSetting: {}
        },
        {
            heading: "What is your Age ?",
            subheading: "Please enter your age.",
            type: "Radio Buttons",
            answers: [],
            typeSetting: {}
        }
    ]);
    const inputTypes = [
        "Radio buttons",
        "Image with text",
        "Text box",
        "Range Slider",
        "Select box",
        "Form",
        "Info"
    ];
    const [currentSelectedAnswer, setCurrentSelectedAnswer] = useState({
        question: null,
        answer: null
    });

    const renderInput = (i) => {
        let inputType = questions[i]?.type;
        switch (inputType) {
            case "Radio buttons":
                return (
                    <>
                        {questions[i]?.answers?.map((val, j) => {
                            return (
                                <div className="radioAnswers">
                                    <label className="radioLabel">
                                        <div className="labelText">{val?.title}</div>
                                        <input type="radio" name={`${i}_answer`} />
                                    </label>
                                </div>
                            );
                        })}
                    </>
                );
                break;
            case "Text box":
                return (
                    <div className="textBoxAnswers">
                        {questions[i]?.answers?.map((val, j) => {
                            return (
                                <label className="textBoxLabel">
                                    <div className="labelText">{val?.title}</div>
                                    <input type="checkbox" name={`${i}_answer`} />
                                </label>
                            );
                        })}
                    </div>
                );
            case "Select box":
                return (
                    <>
                        <div className="selectAnswers">
                            <select className="dropDownAnsers">
                                {questions[i]?.answers?.map((val, j) => {
                                    return <option value={val?.title}>{val?.title}</option>;
                                })}
                            </select>
                        </div>
                    </>
                );
            case "Form":
                return (
                    <div className="FormAnswers">
                        <div className="FormDivMain">
                            {questions[i]?.answers?.map((val, j) => {
                                return (
                                    <label className="formLabel">
                                        <div className="labelText">{val?.title}</div>
                                        <input type="text" name={`${i}_answer_${j}`} />
                                    </label>
                                );
                            })}
                            <div className="sumitButton">
                                <button className="globalButton submit_form_button">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                );
            case "Image with text":
                return (
                    <div className="ImageTextAnswers">
                        <div className="ImageTextMain">
                            {questions[i]?.answers?.map((val, j) => {
                                return (
                                    <div className="label_main">
                                        <label className="ImageTextLabel">
                                            <div className="img_main">
                                                <img src={NoImage} />
                                            </div>
                                            <div className="labelText">{val?.title}</div>
                                            <input type="radio" name={`${i}_answer`} />
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            case "Info":
                return (
                    <>
                        <div className="sumitButton">
                            <button className="globalButton submit_form_button">
                                Let's Go
                            </button>
                        </div>
                    </>
                );

            default:
                break;
        }
    };

    const CustomCss = () => {
        const style = `
        // body.chargezen {
        //     overflow: hidden;
        //     background-color: black;
        // }
      `;

        return (
            <style
                dangerouslySetInnerHTML={{
                    __html: style
                }}
            />
        );
    };
    return (
        <AppProvider>
            <Frame>
                <CustomCss />
                <div className="edit_quiz_main">
                    <div className="leftSection">
                        <Card.Section>
                            <div className="subheadTitle">Questions</div>
                            <div className="bodyContent">
                                {questions?.map((question, i) => {
                                    return (
                                        <div className="listItem">
                                            <div
                                                className="listItemHeading"
                                                onClick={() => {
                                                    setCurrentQuestion(i);
                                                    setCurrentSelection("type");
                                                }}
                                            >
                                                <span className="questionNumber">{i + 1}</span>
                                                <span className="question">
                                                    <button type="button">{question?.heading}</button>
                                                </span>
                                            </div>
                                            {
                                                currentQuestion === i && (
                                                    <div className="listItemsItems">
                                                        <div
                                                            className="itemTitle"
                                                            onClick={() => {
                                                                setCurrentQuestion(i);
                                                                setCurrentSelection("content");
                                                            }}
                                                        >
                                                            <button>Content</button>
                                                        </div>
                                                        <div className="itemSubList">
                                                            <div
                                                                className="itemTitle"
                                                                onClick={() => setCurrentQuestion(i)}
                                                            >
                                                                <button>Answers</button>
                                                            </div>
                                                            <div className="subListItems">
                                                                <div className="lists">
                                                                    {currentQuestion === i &&
                                                                        questions[currentQuestion].answers?.map(
                                                                            (answer, j) => {
                                                                                return (
                                                                                    <div
                                                                                        className="list_item select_answer"
                                                                                        onClick={() => {
                                                                                            setCurrentQuestion(i);
                                                                                            setCurrentSelectedAnswer(j);
                                                                                            setCurrentSelection("changeAnswer");
                                                                                        }}
                                                                                    >
                                                                                        <span className="answer_title">
                                                                                            {answer?.title}
                                                                                        </span>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    <div className="list_item">
                                                                        <button
                                                                            onClick={() => {
                                                                                let allQuestions = [...questions];
                                                                                let answers =
                                                                                    allQuestions[currentQuestion]["answers"];
                                                                                answers.push({
                                                                                    title: `Answer ${answers.length + 1}`,
                                                                                    redirect_url: ""
                                                                                });
                                                                                allQuestions[currentQuestion][
                                                                                    "answers"
                                                                                ] = answers;
                                                                                setQuestions(allQuestions);
                                                                            }}
                                                                        >
                                                                            <span className="plusIcon">+</span>
                                                                            <span className="addAnswerButton">
                                                                                Add answer
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    );
                                })}
                                <div className="listItem">
                                    <button
                                        className="addQuestionButton"
                                        onClick={() => {
                                            let allQuestions = [...questions];
                                            allQuestions?.push({
                                                heading: `What's your question ${questions?.length + 1
                                                    }?`,
                                                subheading: "",
                                                type: "Radio buttons",
                                                answers: [],
                                                typeSetting: {}
                                            });
                                            setQuestions(allQuestions);
                                        }}
                                    >
                                        <span className="plusIcon">+</span>
                                        <span className="addQuestionButton">Add Questions</span>
                                    </button>
                                </div>
                            </div>
                        </Card.Section>
                    </div>
                    <div className="preview_quiz">
                        <div id="quiz_kit_content" class="quiz_kit_sub">
                            <div class="quiz_questions_wrapper">
                                <div
                                    id="quiz-kit-container"
                                    class="sc-AxirZ quiz_question_main"
                                >
                                    <h1
                                        id="quiz-kit-question-title-42277"
                                        font-family="Jost"
                                        font-size="44"
                                        color="#000000"
                                        font-weight="700"
                                        letter-spacing="0"
                                        data-aos="fade-left"
                                        data-aos-delay="150"
                                        data-aos-offset="-300"
                                        class="questionHeading"
                                    >
                                        {questions[currentQuestion]?.heading}
                                    </h1>
                                    <p
                                        id="quiz-kit-question-description-42277"
                                        font-family="Jost"
                                        font-size="20"
                                        color="#000000"
                                        font-weight="400"
                                        letter-spacing="0.25"
                                        data-aos="fade-left"
                                        data-aos-delay="300"
                                        data-aos-offset="-300"
                                        class="subHeading"
                                    >
                                        {questions[currentQuestion]?.subheading}
                                    </p>
                                    <div
                                        id="quiz-kit-question-grid-42277"
                                        data-aos="fade-left"
                                        data-aos-delay="450"
                                        data-aos-offset="-300"
                                        class={`sc-AxiKw form_main_container aos-init aos-animate ${questions[currentQuestion]?.type == "Radio buttons"
                                            ? "radio_button_main_div"
                                            : ""
                                            }`}
                                    >
                                        {renderInput(currentQuestion)}
                                    </div>
                                    <div
                                        data-aos="fade-left"
                                        data-aos-delay="450"
                                        data-aos-offset="-300"
                                        id="quiz-kit-question-tooltip"
                                        class="sc-fzpdyU tooltip_main_container aos-init aos-animate"
                                    >
                                        <div class="sc-fzppip tooltip_svg_container">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                            >
                                                <defs>
                                                    <clipPath id="l23ga">
                                                        <path
                                                            fill="#fff"
                                                            d="M5 18c0-7.732 5.373-14 12-14s12 6.268 12 14-5.373 14-12 14S5 25.732 5 18z"
                                                        ></path>
                                                    </clipPath>
                                                </defs>
                                                <g>
                                                    <g>
                                                        <path
                                                            fill="#fff"
                                                            d="M5 18c0-7.732 5.373-14 12-14s12 6.268 12 14-5.373 14-12 14S5 25.732 5 18z"
                                                        ></path>
                                                        <path
                                                            fill="none"
                                                            stroke="#707070"
                                                            stroke-miterlimit="20"
                                                            stroke-width="2"
                                                            d="M5 18c0-7.732 5.373-14 12-14s12 6.268 12 14-5.373 14-12 14S5 25.732 5 18z"
                                                            clip-path='url("#l23ga")'
                                                        ></path>
                                                    </g>
                                                    <g>
                                                        <path
                                                            fill="#f991a9"
                                                            d="M16.992 11.786c-.924 0-1.676-.703-1.676-1.635s.76-1.626 1.676-1.626c.932 0 1.692.694 1.692 1.626 0 .932-.76 1.635-1.692 1.635zm1.56 13.06h-3.121V13.07h3.122zM17 0C7.61 0 0 7.61 0 17s7.61 17 17 17 17-7.61 17-17S26.39 0 17 0z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div
                                            data-content="true"
                                            transform="translateX(-50%)"
                                            class="sc-fznBMq tooltip_sub_container"
                                        >
                                            <h1
                                                font-family="Jost"
                                                font-size="14"
                                                color="#000000"
                                                font-weight="600"
                                                letter-spacing="0"
                                                class="tooltipHeading"
                                            >
                                                Here's an example toolip
                                            </h1>
                                            <p
                                                font-family="Jost"
                                                font-size="10"
                                                color="#000000"
                                                font-weight="400"
                                                letter-spacing="0"
                                                class="tooltipSubHeading"
                                            >
                                                You can add these to any question on your quiz.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        id="quiz-kit-question-navigation"
                                        data-aos="fade-left"
                                        data-aos-delay="600"
                                        data-aos-offset="-300"
                                        class="sc-fzoXzr next_button_main_container aos-init aos-animate"
                                    >
                                        <button
                                            id="quiz-kit-question-next-button"
                                            tabindex="0"
                                            class="next_button_main disabled"
                                            color="#000000"
                                            letter-spacing="1"
                                        >
                                            <span>Next</span>
                                            <svg
                                                width="12px"
                                                height="10px"
                                                viewBox="0 0 14 12"
                                                version="1.1"
                                            >
                                                <title>Next</title>
                                                <g
                                                    id="Symbols"
                                                    stroke="none"
                                                    stroke-width="1"
                                                    fill="none"
                                                    fill-rule="evenodd"
                                                >
                                                    <g
                                                        id="Next/Back-Buttons"
                                                        transform="translate(-263.000000, -54.000000)"
                                                        fill="#000000"
                                                        fill-rule="nonzero"
                                                        stroke="#000000"
                                                    >
                                                        <g
                                                            id="Group-3-Copy"
                                                            transform="translate(0.000000, 50.000000)"
                                                        >
                                                            <g
                                                                id="Group-2"
                                                                transform="translate(214.000000, 0.000000)"
                                                            >
                                                                <path
                                                                    d="M56.0281414,4 C56.1030387,4.00628851 56.1761774,4.03773106 56.2334898,4.09432766 L60.9032854,8.70580175 C61.0322382,8.83314409 61.0322382,9.03960679 60.9032854,9.16694917 C60.7743325,9.29429148 60.5652587,9.29429148 60.4363058,9.16694917 L56.3300891,5.11198996 L56.3310405,16.4516973 L56.3257208,16.5103293 C56.2977637,16.6624347 56.1629516,16.7777778 56.000859,16.7777778 C55.8184884,16.7777778 55.6706443,16.6317903 55.6706317,16.4516973 L55.6696803,5.11198996 L51.5636942,9.16694917 C51.4490694,9.28014233 51.2711392,9.29271938 51.1424466,9.2046802 L51.0967146,9.16694917 C50.9677618,9.03960679 50.9677618,8.83314409 51.0967146,8.70580175 L55.7665102,4.09432766 C55.8238226,4.03773106 55.8969614,4.00628851 55.9718586,4 L56.0281414,4 Z"
                                                                    id="Path-Copy"
                                                                    transform="translate(56.000000, 10.388889) rotate(-270.000000) translate(-56.000000, -10.388889) "
                                                                ></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                    {questions[currentQuestion]?.typeSetting?.skip && (
                                        <div className="skipQuestion">
                                            <div className="skipQuestion" href="#">
                                                Skip
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rightSection">
                        <div className="headingMain">
                            {questions[currentQuestion]?.heading}
                        </div>
                        {currentSelection === "content" && (
                            <>
                                <Card.Section>
                                    <TextField
                                        label={<div className="boldLabel">Heading</div>}
                                        value={questions[currentQuestion]?.heading}
                                        onChange={(e) => {
                                            let allQuestions = [...questions];
                                            allQuestions[currentQuestion]["heading"] = e;
                                            setQuestions(allQuestions);
                                        }}
                                        autoComplete="off"
                                    />
                                    <TextField
                                        label={<div className="boldLabel">Sub Heading</div>}
                                        multiline={4}
                                        value={questions[currentQuestion]?.subheading}
                                        onChange={(e) => {
                                            let allQuestions = [...questions];
                                            allQuestions[currentQuestion]["subheading"] = e;
                                            setQuestions(allQuestions);
                                        }}
                                        autoComplete="off"
                                    />
                                </Card.Section>
                            </>
                        )}
                        {currentSelection === "changeAnswer" && (
                            <>
                                <Card.Section>
                                    <TextField
                                        label={<div className="boldLabel">Title</div>}
                                        value={
                                            questions[currentQuestion]?.answers[currentSelectedAnswer]
                                                ?.title
                                        }
                                        onChange={(e) => {
                                            let allQuestions = [...questions];
                                            let allAnswers = allQuestions[currentQuestion]["answers"];
                                            allAnswers[currentSelectedAnswer] = { title: e };
                                            allQuestions[currentQuestion]["answers"] = allAnswers;
                                            setQuestions(allQuestions);
                                        }}
                                        autoComplete="off"
                                    />
                                    <TextField
                                        label={<div className="boldLabel">Redirect Url</div>}
                                        value={
                                            questions[currentQuestion]?.answers[currentSelectedAnswer]
                                                ?.redirect_url
                                        }
                                        onChange={(e) => {
                                            let allQuestions = [...questions];
                                            let allAnswers = allQuestions[currentQuestion]["answers"];
                                            allAnswers[currentSelectedAnswer] = { redirect_url: e };
                                            allQuestions[currentQuestion]["answers"] = allAnswers;
                                            setQuestions(allQuestions);
                                        }}
                                        autoComplete="off"
                                    />
                                </Card.Section>
                            </>
                        )}
                        {currentSelection === "type" && (
                            <>
                                <Card.Section>
                                    <div>
                                        <div className="boldLabel main_heading">Type</div>
                                        <Select
                                            label="Date range"
                                            options={inputTypes}
                                            onChange={(e) => {
                                                let allQuestions = [...questions];
                                                allQuestions[currentQuestion]["type"] = e;
                                                setQuestions(allQuestions);
                                            }}
                                            value={questions[currentQuestion]?.type}
                                        />
                                    </div>
                                </Card.Section>
                                {questions[currentQuestion].type != "Info" && (
                                    <Card.Section>
                                        <div className="typeSettingsMain">
                                            <div className="boldLabel main_heading">Settings</div>
                                            <div className="settingsBody">
                                                <div className="settingItems">
                                                    <div className="settingItemsSub">
                                                        <span>
                                                            <input
                                                                type="checkbox"
                                                                onClick={function (e) {
                                                                    let allQuestions = [...questions];
                                                                    let typeSetting =
                                                                        allQuestions[currentQuestion]["typeSetting"];
                                                                    typeSetting["skip"] = e.target.checked;
                                                                    allQuestions[currentQuestion][
                                                                        "typeSetting"
                                                                    ] = typeSetting;
                                                                    setQuestions(allQuestions);
                                                                }}
                                                            />
                                                        </span>
                                                        <span className="helpingText">
                                                            Enable ability to skip this question
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="settingItems">
                                                    <div className="settingItemsSub">
                                                        <span>
                                                            <input
                                                                type="checkbox"
                                                                onClick={function (e) {
                                                                    let allQuestions = [...questions];
                                                                    let typeSetting =
                                                                        allQuestions[currentQuestion]["typeSetting"];
                                                                    typeSetting["multiSelect"] = e.target.checked;
                                                                    allQuestions[currentQuestion][
                                                                        "typeSetting"
                                                                    ] = typeSetting;
                                                                    setQuestions(allQuestions);
                                                                }}
                                                            />
                                                        </span>
                                                        <span className="helpingText">
                                                            Enable users to select more than one answer
                                                        </span>
                                                    </div>
                                                    {(questions[currentQuestion]?.typeSetting?.multiSelect && questions[currentQuestion]?.answers.length > 2) &&
                                                        <div className="range">
                                                            <span className="rangeSub">
                                                                <RangeSlider
                                                                    label="Limit of selected answers"
                                                                    value={questions[currentQuestion]?.typeSetting?.range ? questions[currentQuestion]?.typeSetting?.range : 2}
                                                                    onChange={function (e) {
                                                                        let allQuestions = [...questions];
                                                                        let typeSetting =
                                                                            allQuestions[currentQuestion]["typeSetting"];
                                                                        typeSetting["range"] = e;
                                                                        allQuestions[currentQuestion][
                                                                            "typeSetting"
                                                                        ] = typeSetting;
                                                                        setQuestions(allQuestions);
                                                                    }}
                                                                    min={2}
                                                                    max={questions[currentQuestion]?.answers?.length}
                                                                    output
                                                                />
                                                                <span className="currentValueRange">{questions[currentQuestion]["typeSetting"]?.range}</span>
                                                            </span>
                                                        </div>
                                                    }
                                                </div>




                                            </div>
                                        </div>
                                    </Card.Section>
                                )
                                }
                            </>

                        )}
                    </div>
                </div>
            </Frame>
        </AppProvider >
    );
};
export default CreateQuiz;
