import React, { useEffect, useState } from "react";
import { Card, Frame, Page, Select, Stack, TextField } from "@shopify/polaris";
import { GetStartedIcon } from "../../components/layout/CustomIcon";
import "./style.css";
import RenderSubCategory from "./RenderSubCategory";

const RenderCustomizations = (props) => {
    const { style, changeFor, index, formData, setFormData } = props;

    function setFieldValue(indexes, value, changeFor) {
        const pathArray = indexes.split(',');

        let form_data = formData[changeFor];
        let current = form_data;
        pathArray?.map((step, i) => {
            if (i == (pathArray?.length - 1)) {
                current = current?.[step];
            } else {
                current = current?.[step]?.fields;
            }
        })

        if (current) {
            current.value = value;
        }
        setFormData({ ...formData, [changeFor]: form_data });
    }
    return (
        <Card>
            <div className='card-box'>
                <div className='rewards_settings main-title'>
                    <span className='heading_icons'>
                        <GetStartedIcon />
                    </span>
                    <span className="title_wrapper">{style?.name}</span>
                </div>
                <div className="zennportal_style_form_wrapper">
                    <div className="manage_colors">
                        {
                            style?.fields?.map((field, i) => {
                                return (
                                    <div className={field?.category ? "more_category_wrapper_main" : ""} >
                                        {
                                            field?.category ?
                                                <div className="category_field_wrapper">
                                                    <div className="sub_category_field_container">
                                                        <h3>{field?.name}</h3>
                                                        <RenderSubCategory fields={field?.fields} index={`${index},${i}`} {...{ changeFor, setFieldValue }} />
                                                    </div>
                                                </div>
                                                :
                                                <div className={`category_field_wrapper ${field.type == "color" ? "color-field" : ""}`}>
                                                    {
                                                        field?.type == "select" ?
                                                            <Select
                                                                label={field.key}
                                                                options={field.options}
                                                                onChange={(e) => { setFieldValue(`${index},${i}`, e, changeFor) }}
                                                                value={field.value}
                                                            />
                                                            :
                                                            <TextField
                                                                type={field?.type}
                                                                label={field.key}
                                                                placeholder=''
                                                                value={field.value}
                                                                onChange={(e) => { setFieldValue(`${index},${i}`, e, changeFor) }}
                                                                autoComplete="off"
                                                            />
                                                    }

                                                    {
                                                        field?.type == "color" &&
                                                        <TextField
                                                            type={"text"}
                                                            label={field.key}
                                                            placeholder=''
                                                            value={field.value}
                                                            onChange={(e) => { setFieldValue(`${index},${i}`, e, changeFor) }}
                                                            autoComplete="off"
                                                        />
                                                    }
                                                </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default RenderCustomizations