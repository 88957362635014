import { gql, useMutation, useQuery } from '@apollo/client';
import {
    Badge,
    Banner, Button,
    ButtonGroup,
    Caption, Card,
    Checkbox, ChoiceList, DataTable,
    DropZone, Filters,
    Frame, Icon, List, Modal, Page,
    Spinner, Stack,
    Tabs, Thumbnail,
    Toast
} from '@shopify/polaris';
import { DeleteMajor, NoteMinor } from '@shopify/polaris-icons';
import Papa from 'papaparse';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import swapIcon from '../../assets/images/swap.svg';

import moment from 'moment';
import { Pagination } from "@shopify/polaris";
import LoadingScreen from '../LoadingScreen';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import PixelIcon from '../../assets/images/PixelIcon';
import { DomainContext } from '../domain-context';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';


// import json2csv from 'json2csv';
const subscriptions = ['active', 'new', 'returning', 'paused', 'cancelled', 'all'];

const {
    Parser,
    transforms: { unwind },
} = require('json2csv');

// let sortOrder = 0;

const ShowRebuy = (props) => {
    const { i18n, rebuyMenuId, setRebuyMenuId } = props;
    const { domain } = useContext(DomainContext);
    const history = useHistory();
    // Start Tabs
    const [headerButton, setHeaderButton] = useState("auto")


    const [moneySpent, setMoneySpent] = useState(null);
    const [taggedWith, setTaggedWith] = useState(null);
    const [queryValue, setQueryValue] = useState(null);
    const [loadingScreen, setLoadingScreen] = useState(false);

    const handleMoneySpentChange = useCallback(
        (value) => setMoneySpent(value),
        []
    );
    const handleTaggedWithChange = useCallback(
        (value) => setTaggedWith(value),
        []
    );

    const handleMoneySpentRemove = useCallback(() => setMoneySpent(null), []);
    const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
    const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
    const handleFiltersClearAll = useCallback(() => {
        handleMoneySpentRemove();
        handleTaggedWithRemove();
        handleQueryValueRemove();
    }, [handleMoneySpentRemove, handleQueryValueRemove, handleTaggedWithRemove]);

    const filters = [
        {
            key: 'taggedWith',
            label: 'Tagged with',
            filter: (
                <ChoiceList
                    title="Tagged with"
                    titleHidden
                    choices={[
                        { label: 'Cancelled', value: 'cancelled' },
                        { label: 'Active', value: 'active' },
                        { label: 'In Trial', value: 'inTrial' },
                        { label: 'Future', value: 'future' },
                    ]}
                    selected={taggedWith || []}
                    onChange={handleTaggedWithChange}
                // allowMultiple
                />
            ),
            shortcut: true,
        },
        {
            key: 'moneySpent',
            label: 'Money spent',
            // filter: (
            //   <RangeSlider
            //     label="Money spent is between"
            //     labelHidden
            //     value={moneySpent || [0, 500]}
            //     prefix="$"
            //     output
            //     min={0}
            //     max={2000}
            //     step={1}
            //     onChange={handleMoneySpentChange}
            //   />
            // ),
        },
    ];

    const appliedFilters = [];
    if (!isEmpty(moneySpent)) {
        const key = 'moneySpent';
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, moneySpent),
            onRemove: handleMoneySpentRemove,
        });
    }
    if (!isEmpty(taggedWith)) {
        const key = 'taggedWith';
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, taggedWith),
            onRemove: handleTaggedWithRemove,
        });
    }

    function disambiguateLabel(key, value) {
        switch (key) {
            case 'moneySpent':
                return `Money spent is between $${value[0]} and $${value[1]}`;
            case 'taggedWith':
                return `Tagged with ${value}`;
            default:
                return value;
        }
    }

    function isEmpty(value) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value == null;
        }
    }

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [hasPrevious, setHasPrevious] = useState(false);
    const [status, setStatus] = useState("active");
    const [searchQuery, setSearchQuery] = useState();
    // -------------------
    const GETREBUYS = gql`
    query($page: String, $rebuyMenuId: String!) {
      fetchRebuys(page: $page, rebuyMenuId: $rebuyMenuId) {
        rebuys{
          token
          createdAt
          status
          customerName
        }
        totalPages
        totalCount
        pageNumber
      }
    }
  `;
    const { data, loading, error, refetch } = useQuery(GETREBUYS, {
        fetchPolicy: 'no-cache',
        variables: {
            page: page.toString(),
            rebuyMenuId: rebuyMenuId
        },

    });

    useEffect(() => {
        refetch({
            variables: {
                page: page.toString(),
                rebuyMenuId: rebuyMenuId
            }
        });
    }, [page]);

    useEffect(() => {
        setTotalPages(data?.fetchRebuys?.rebuys)
        if (+page < +totalPages) {
            setHasMore(true);
        }
        else {
            setHasMore(false)
        }
        if (+page <= 1) {
            setHasPrevious(false)
        }
        else {
            setHasPrevious(true)
        }
    }, [data]);


    const filterCustomersValue = () => {
        const rowsData = customers.filter((item) => {
            return (
                (item.subscription === headerButton || (headerButton === 'paused') ||
                    (headerButton === 'active') || (headerButton === 'returning') || (headerButton === 'cancelled') || (headerButton === 'new') || (headerButton === 'all')) &&
                (item.name?.toLowerCase()?.includes(queryValue?.toLowerCase()) ||
                    !queryValue) &&
                (item.subscription?.toLowerCase()?.includes(taggedWith) || !taggedWith)
            );
        });

        setFilterCustomers(rowsData);
    };
    useEffect(() => {
        if (customers) {
            filterCustomersValue();
        }
        // console.log('searchvalue: ', queryValue);
    }, [queryValue, taggedWith, customers]);

    const [formErrors, setFormErrors] = useState([]);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);

    const [selectedCustomers, setselectedCustomers] = useState([]);
    const handleChangeCheckedCustomers = (newChecked, customerId) => {
        if (newChecked) {
            setselectedCustomers([...selectedCustomers, customerId]);
        } else {
            const index = selectedCustomers.indexOf(customerId);
            setselectedCustomers([
                ...selectedCustomers.slice(0, index),
                ...selectedCustomers.slice(index + 1),
            ]);
        }
    };
    //upper first letter
    const capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };
    //each row in data table
    const formatRows = (rows) => {
        let apiData;
        return rows?.map((row) => {
            return row?.id !== null ?
                [
                    row.token,
                    row.status,
                    row?.customerName,
                    row.createdAt,
                ] : []
        });
    };
    const [customers, setCustomers] = useState([]);
    const [filterCustomers, setFilterCustomers] = useState([]);


    // useEffect(() => {
    //   filterCustomersValue();
    // }, [selectedCustomers]);

    useEffect(() => {
        if (data && data.fetchRebuys) {
            let rowsData = formatRows(data.fetchRebuys?.rebuys);
            setCustomers(data.fetchRebuys?.rebuys);
            // console.log('data: ', data);
        }
    }, [data]);

    //export data to csv
    // const headers = [
    //   { label: 'Number', key: 'id' },
    //   { label: 'Name', key: 'name' },
    //   { label: 'Date Created', key: 'createdAt' },
    //   { label: 'Status', key: 'status' },
    //   { label: 'Subscription', key: 'subscription' },
    //   { label: 'additionalContacts', key: 'additionalContacts' },
    // ];
    const headers = [
        { label: 'Number', key: 'id' },
        { label: 'firstName', key: 'firstName' },
        { label: 'lastName', key: 'lastName' },
        { label: 'Date Created', key: 'createdAt' },
        { label: 'Status', key: 'status' },
        { label: 'Subscription', key: 'subscription' },
        { label: 'additionalContacts.email', key: 'additionalContacts.email' },
        {
            label: 'additionalContacts.firstName',
            key: 'additionalContacts.firstName',
        },
        {
            label: 'additionalContacts.lastName',
            key: 'additionalContacts.lastName',
        },
        {
            label: 'additionalContacts.companyName',
            key: 'additionalContacts.companyName',
        },
        { label: 'additionalContacts.phone', key: 'additionalContacts.phone' },

        { label: 'billingAddress.firstName', key: 'billingAddress.firstName' },
        { label: 'billingAddress.lastName', key: 'billingAddress.lastName' },
        { label: 'billingAddress.email', key: 'billingAddress.email' },
        { label: 'billingAddress.company', key: 'billingAddress.company' },
        { label: 'billingAddress.phone', key: 'billingAddress.phone' },
        { label: 'billingAddress.language', key: 'billingAddress.language' },
        { label: 'billingAddress.city', key: 'billingAddress.city' },
    ];
    const fields = [
        'id',
        'firstName',
        'lastName',
        'email',
        'communication',
        'phone',
        'language',
        'autoCollection',
        'status',
        'subscription',

        'additionalContacts.id',
        'additionalContacts.email',
        'additionalContacts.firstName',
        'additionalContacts.lastName',
        'additionalContacts.companyName',
        'additionalContacts.phone',

        'billingAddress.firstName',
        'billingAddress.lastName',
        'billingAddress.email',
        'billingAddress.company',
        'billingAddress.phone',
        'billingAddress.address1',
        'billingAddress.address2',
        'billingAddress.city',
        'billingAddress.language',
        'billingAddress.zipCode',
    ];
    const transforms = [
        unwind({ paths: ['additionalContacts', 'billingAddress'] }),
    ];
    // const exportCSV = () => {
    const dataSelected = [...filterCustomers].filter((item) =>
        selectedCustomers.find((select) => select === item.id)
    );
    const json2csvParser = new Parser({ fields, transforms });
    let csv = json2csvParser.parse(dataSelected);

    //

    // };




    return (
        <>
            <Frame>
                {loadingScreen && (
                    <>
                        <LoadingScreen />
                    </>
                )}
                <Page
                    title={
                        <div className='back_button_wrapper'>
                            <div className="back-button pointer" style={{ marginTop: '15px' }} onClick={() => setRebuyMenuId()}>
                                <Icon
                                    source={MobileBackArrowMajor}
                                    color="base" />
                            </div>
                            <div class="text_wrapper">
                                {i18n.t("rebuy")}
                            </div>
                        </div>

                    }
                >
                    <Card
                        title=
                        {
                            <div className="heading_title">
                                <PixelIcon />
                                Offers
                            </div>
                        }
                    >
                        <Card.Section>
                            <div className={"table customer-subscription-tbl" + " "}>
                                <DataTable
                                    columnContentTypes={[
                                        'text',
                                        'text',
                                        'text',
                                        'text'
                                    ]}
                                    headings={[
                                        'Token',
                                        'Status',
                                        'Customer',
                                        'Date of creation',
                                    ]}

                                    rows={formatRows(customers)}
                                />
                            </div>
                            {loading && (
                                <Spinner />
                            )}
                            <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                                <Pagination
                                    hasPrevious={hasPrevious}
                                    onPrevious={() => {
                                        setPage((prev) => +prev - 1)
                                    }}
                                    hasNext={hasMore}
                                    onNext={() => {
                                        setPage((prev) => +prev + 1)
                                    }}
                                />
                            </div>
                        </Card.Section>
                    </Card>
                </Page>
            </Frame >
        </>
    );
};

export default ShowRebuy;
