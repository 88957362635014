import { Button, Card, Checkbox, DropZone, Icon, Select, Stack, TextContainer, TextField, Thumbnail } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import ProductVariantPicker from "../plans/ProductVariantPicker";
import {
    MobileBackArrowMajor
} from '@shopify/polaris-icons';
import dayjs from "dayjs";

const RewardsTimeLineCreate = (props) => {
    const { rewardsRowData, setRewardsRowData, i18n, domain, setShowEditRewards, setShowHeader, rewardsInitValues, toggleActive } = props
    const [rewardsFormData, setRewardsFormData] = useState(rewardsInitValues);

    const rewardTriggerOptions = [
        { label: "Consecutive billing cycles", value: "consecutive_billing_cycles" },
    ]

    const rewardTypeOptions = [
        { label: "Free product", value: "free_product" },
    ]

    const statusOptions = [
        { label: "Active", value: "active" },
        { label: "Draft", value: "draft" },
    ]

    useEffect(() => {
        if (rewardsRowData) {
            setRewardsFormData({ ...rewardsRowData });
        }
    }, [rewardsRowData])

    useEffect(() => {
        setShowHeader(false);
    }, [])


    const saveRewards = () => {
        if (rewardsFormData?.title.replace(/\s/g, '') !== '') {
            const fomrdata = new FormData();
            const objectTypes = ["variant_images"]
            Object.keys(rewardsFormData)?.map((key) => {
                if (!objectTypes.includes(key)) {
                    fomrdata.append(key, rewardsFormData[key])
                }
            })
            fomrdata.append("variant_images", JSON.stringify(rewardsFormData.variant_images))
            fomrdata.append("shop", domain)
            fetch('/customer_portal/create_rewards_timeline', {
                method: 'POST',
                body: fomrdata,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data?.status) {
                        toggleActive();
                        setShowEditRewards(false);
                        setRewardsRowData(rewardsInitValues)
                        setRewardsFormData({ ...rewardsInitValues })
                    }
                });
        }
    }

    return (
        <div className='upsell_index_container'>
            <div className='custom_sub_header_wrapper'>
                <div className="back_button_wrapper">
                    <div
                        className="back-button pointer"
                        onClick={() => {
                            setShowEditRewards(false);
                            setRewardsRowData(rewardsInitValues);
                        }}
                    >
                        <Icon source={MobileBackArrowMajor} color="base" />
                    </div>

                    <h1 className="shopify_style_header">
                        {rewardsRowData?.id ? "Edit Rewards timeline" : "Create a new rewards timeline"}
                    </h1>
                </div>
            </div>
            <Card
                title={<div className="heading_title">Rewards Timeline</div>}
                sectioned
                primaryFooterAction={{
                    content: i18n.t('save_and_continue'),
                    onAction: saveRewards,
                }}
                footerActionAlignment="left"
            >
                <div className="card-box upsell_form_wrapper">
                    <div className="upsell_form_row">
                        <TextField
                            type="text"
                            label={"Title"}
                            placeholder=''
                            value={rewardsFormData?.title}
                            onChange={(newValue) => setRewardsFormData({ ...rewardsFormData, title: newValue })}
                            autoComplete="off"
                        />
                    </div>
                    <div className="upsell_form_row multirow_form_control">
                        <div className="upsell_form_row multirow_form_control">
                            <div className="seperate_multirow_form">
                                <div style={{ marginTop: "-20px" }}>
                                    <Select
                                        label={"Reward trigger"}
                                        value={rewardsFormData?.reward_trigger}
                                        onChange={(newValue) => setRewardsFormData({ ...rewardsFormData, reward_trigger: newValue })}
                                        options={rewardTriggerOptions}
                                    />
                                </div>
                                <TextField
                                    type="number"
                                    label={<>&nbsp;</>}
                                    value={`${rewardsFormData?.trigger_value}`}
                                    onChange={(newValue) => setRewardsFormData({ ...rewardsFormData, trigger_value: newValue })}
                                    suffix="cycles"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="seperate_multirow_form">
                                <Select
                                    label="Reward Type"
                                    value={rewardsFormData?.reward_type}
                                    onChange={(newValue) => setRewardsFormData({ ...rewardsFormData, reward_type: newValue })}
                                    options={rewardTypeOptions}
                                />
                            </div>
                            <div className="seperate_multirow_form">
                                <Select
                                    label="Status"
                                    value={rewardsFormData?.status}
                                    onChange={(newValue) => setRewardsFormData({ ...rewardsFormData, status: newValue })}
                                    options={statusOptions}
                                />
                            </div>
                        </div >
                    </div >
                    <div className="upsell_form_row">
                        <div className='holder color-holder'>
                            <h3>Products</h3>
                            <div className="product-search">
                                <ProductVariantPicker
                                    i18n={i18n}
                                    setFormData={setRewardsFormData}
                                    formData={rewardsFormData}
                                    fieldName="variant_images"
                                    selectType="variant"
                                    previewVariants={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default RewardsTimeLineCreate;