import { Icon, TextField } from '@shopify/polaris';
import { SearchMajor } from '@shopify/polaris-icons';
import React, { useEffect, useState } from 'react';
import ProductVariantModal from './ProductVariantModal';
import { ChevronDownMinor, MobileCancelMajor } from '@shopify/polaris-icons';

const ProductVariantPicker = (props) => {
  const { setFieldValue, formData, setFormData, disabled, label, customQuantityPrice } = props;
  let { values, fieldName } = props
  const [showProductModal, setShowProductModal] = useState(false);
  const [query, setQuery] = useState();

  fieldName ||= "productVariant"
  values ||= formData;
  values[fieldName] ||= []


  const removeItem = (productIndex) => {
    const products = values[fieldName];
    products.splice(productIndex, 1);
    if (setFormData) {
      setFormData({ ...formData, [fieldName]: [...products] })
    } else {
      setFieldValue(fieldName, [...products]);
    }
  };

  const handleChange = (val, index, vindex, key) => {
    let allproducts = values[fieldName];
    allproducts[index]["variants"][vindex][key] = val;
    setFormData({ ...formData, [fieldName]: allproducts })
  }

  console.log("Fff", formData)
  return (
    <div className="product-variant-picker-container">
      {showProductModal && (
        <ProductVariantModal
          {...{
            showProductModal,
            setShowProductModal,
            query,
            setQuery,
            setFieldValue,
            values,
            formData,
            setFormData,
            fieldName
          }}
        />
      )}
      {
        label &&
        <label className='input_custom_label'>{label}</label>
      }
      <div className="product_searchProduct">
        <div className="product_search_input">
          <Icon source={SearchMajor} tone="base" />
          <input
            type="text"
            placeholder="Search products or variants"
            id="search_product_query"
            onChange={(e) => {
              setQuery(e?.target?.value || '');
              setShowProductModal(true);
            }}
            value={query}
            disabled={disabled}
          />
        </div>
        <button
          type="button"
          className="browse_product_button"
          onClick={() => setShowProductModal(true)}
          disabled={disabled}
        >
          Browse
        </button>
      </div>
      <div className="product_preview_row">
        {
          customQuantityPrice ?
            <>
              {
                values[fieldName]?.map((p, i) => {
                  return p.variants.filter((v) => v.selected)?.map((v, vi) => {
                    return (
                      <>
                        <div className="product_variant_preview_wrapper">
                          <div className="imgwrapper">
                            <img src={p.image} />
                          </div>
                          <div className="pv_desc_container">
                            <div className="product_variant_desc">
                              <span>{p.title}</span>
                              <span>
                                {v.title}
                              </span>
                            </div>
                            <div className="edit_remove_wrapper">
                              <button
                                type="button"
                                className="editbutton"
                                onClick={() => setShowProductModal(true)}
                                disabled={disabled}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="close"
                                onClick={() => removeItem(i)}
                                disabled={disabled}
                              >
                                <Icon source={MobileCancelMajor} tone="base" />
                              </button>
                            </div>
                          </div>
                        </div>
                        {
                          customQuantityPrice && (
                            <div className='quanity_price_manager'>
                              <TextField
                                label="Quantity"
                                type='number'
                                value={`${v.quantity || 1}`}
                                onChange={(val) => handleChange(val, i, vi, "quantity")}
                              />
                              <TextField
                                label="Price"
                                type='number'
                                value={`${v.price}`}
                                onChange={(val) => handleChange(val, i, vi, "price")}
                              />
                            </div>
                          )
                        }
                      </>
                    )
                  })
                })
              }
            </>
            :
            <>
              {
                values[fieldName]?.map((p, i) => {
                  return (
                    <>
                      <div className="product_variant_preview_wrapper">
                        <div className="imgwrapper">
                          <img src={p.image} />
                        </div>
                        <div className="pv_desc_container">
                          <div className="product_variant_desc">
                            <span>{p.title}</span>
                            <span>
                              ({p.variants.filter((v) => v.selected).length} of{' '}
                              {p.variants?.length}) variants selected
                            </span>
                          </div>
                          <div className="edit_remove_wrapper">
                            <button
                              type="button"
                              className="editbutton"
                              onClick={() => setShowProductModal(true)}
                              disabled={disabled}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="close"
                              onClick={() => removeItem(i)}
                              disabled={disabled}
                            >
                              <Icon source={MobileCancelMajor} tone="base" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              }
            </>
        }
      </div>
    </div>
  );
};

export default ProductVariantPicker;
