import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'

const Authentication = (props) => {
    const { setDomain } = props;
    const { authParams } = useParams();
    const history = useHistory();

    const getTokens = () => {
        let urlParams = window.location.pathname.split("/")
        let tokens = urlParams[urlParams.length - 1]
        let accessToken = tokens.split("@chargezeninc")[0];
        let authToken = tokens.split("@chargezeninc")[1];
        let shopDomainKey = tokens.split("@chargezeninc")[2];
        let shopDomain = shopDomainKey ? shopDomainKey + ".myshopify.com" : ""
        setDomain(shopDomain);
        localStorage.setItem(`chargezenDomain`, shopDomain);
        window.chargezenDomain = shopDomain;
        localStorage.setItem(`${shopDomainKey}_authToken`, authToken);
        localStorage.setItem(`${shopDomainKey}_accessToken`, accessToken);
        history.push(`/${shopDomainKey}`);
    }


    useEffect(() => {
        getTokens()
    }, [authParams])

    return (
        <></>
    )
}

export default Authentication