import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import {
    Card,
    Frame,
    Page,
    Tabs,
    AppProvider,
    Form,
    FormLayout,
    TextField,
    Select,
    Button
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import { useCallback } from "react";
import "./style.css";
import PixelIcon from "../../../assets/images/PixelIcon";
import ManageRoutes from "./ManageRoutes";

const ManageTranslations = (props) => {
    const {
        i18n,
        selectedTemplateId,
        domain,
        toggleActive
    } = props;
    const [selected, setSelected] = useState(0);
    const [showRoutes, setShowRoutes] = useState(false);
    const [routeFormData, setRouteFormData] = useState({});
    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        []
    );
    const [formData, setFormData] = useState();
    const [selectedLanguage, setSelectedLanguage] = useState("en");

    const fetchDefaultLanguage = () => {
        fetch(`/settings/current_language?shopify_domain=${domain}&language=${selectedLanguage}`, {
            method: "GET"
        }).then((response) => response.json())
            .then((data) => {
                setSelectedLanguage(data?.front_end_language)
            })
    }

    const fetchTranslations = () => {
        fetch(`/cloudlfare/fetch_translations?shopify_domain=${domain}&language=${selectedLanguage}`, {
            method: "GET"
        }).then((response) => response.json())
            .then((data) => {
                setFormData(data?.translations);
            })
    }

    const fetchRoutes = () => {
        fetch(`/customer_portal/fetch_routes?shopify_domain=${domain}&language=${selectedLanguage}&template_id=${selectedTemplateId}`, {
            method: "GET"
        }).then((response) => response.json())
            .then((data) => {
                if (data?.routes) {
                    setRouteFormData(data?.routes)
                }
            })
    }


    const saveTranslations = () => {
        fetch(`/cloudlfare/save_translations`, {
            method: "POST",
            body: JSON.stringify({ translations: formData, shopify_domain: domain, language: selectedLanguage }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((data) => {
                if (data.status) {
                    saveRoutes();
                }
            })
    }

    const saveRoutes = () => {
        fetch(`/customer_portal/save_routes`, {
            method: "POST",
            body: JSON.stringify({ routes: routeFormData, shopify_domain: domain, language: selectedLanguage, template_id: selectedTemplateId }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((data) => {
                if (data.status) {
                    toggleActive(true)
                }
            })
    }

    useEffect(() => {
        fetchDefaultLanguage();
    }, [])
    useEffect(() => {
        fetchTranslations();
        fetchRoutes();
    }, [selectedLanguage])

    const tabs = [
        {
            id: "Header",
            content: i18n.t("zennportal_form.header")
        },
        {
            id: "Side Nav",
            content: i18n.t("zennportal_form.side_nav")
        },
        {
            id: "Subscription",
            content: i18n.t("zennportal_form.subscription")
        },
        {
            id: "Delivery schedule",
            content: i18n.t("zennportal_form.delivery_schedule")
        },
        {
            id: "Order history",
            content: i18n.t("zennportal_form.order_history")
        },
        {
            id: "Address",
            content: i18n.t("zennportal_form.address")
        },
        {
            id: "Form Fields",
            content: i18n.t("zennportal_form.form_fields")
        },
        {
            id: "Billing",
            content: i18n.t("zennportal_form.billing")
        },
        {
            id: "Others",
            content: i18n.t("zennportal_form.others")
        },
        {
            id: "Routes",
            content: i18n.t("zennportal_form.routes")
        }
    ];

    const supportedLanguages = [
        { label: "Arabic", value: "ar" },
        { label: "Danish", value: "da" },
        { label: "Dutch", value: "nl" },
        { label: "English", value: "en" },
        { label: "Finnish", value: "fi" },
        { label: "French", value: "fr" },
        { label: "German", value: "de" },
        { label: "Italian", value: "it" },
        { label: "Japanese", value: "ja" },
        { label: "Korean", value: "ko" },
        { label: "Norwegian Bokmål", value: "nb" },
        { label: "Portuguese - Brazil", value: "pt-BR" },
        { label: "Spanish", value: "es" },
        { label: "Swedish", value: "sv" },
        { label: "Turkish", value: "tr" }
    ];




    const handleChange = (e, key) => {
        let form_data = formData;
        let new_formData = form_data.map((f) => {
            if (f.key == key) {
                f.value = e;
                return f;
            } else {
                return f;
            }
        });
        setFormData(new_formData);
    };
    return (
        <div className="translations_form_wrapper">
            <Card title=
                {
                    <div className="heading_title">
                        <PixelIcon />
                        {i18n.t("zennportal_form.translations_settings")}
                    </div>
                }>
                <Card.Section>
                    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                        <div className="content_wrapper_container">
                            <div className="form_wrapper">
                                <Form>
                                    <FormLayout>
                                        <Select
                                            label={i18n.t("zennportal_form.language")}
                                            options={supportedLanguages}
                                            onChange={(e) => {
                                                setSelectedLanguage(e);
                                            }}
                                            value={selectedLanguage}
                                        />
                                    </FormLayout>
                                    {
                                        tabs[selected].id === "Routes" ?
                                            <>
                                                <ManageRoutes {...{ i18n, routeFormData, setRouteFormData, formData }} />
                                            </>
                                            :
                                            <>
                                                <FormLayout>
                                                    {
                                                        formData?.filter((t) => t.category == tabs[selected]?.id)?.map((formField) => {
                                                            return (
                                                                <FormLayout.Group>
                                                                    <TextField
                                                                        label={formField?.label}
                                                                        value={formField?.value}
                                                                        onChange={(e) => {
                                                                            handleChange(e, formField?.key);
                                                                        }}
                                                                        autoComplete="off"
                                                                    />
                                                                </FormLayout.Group>
                                                            );
                                                        })
                                                    }
                                                </FormLayout>
                                            </>
                                    }
                                </Form>
                            </div>
                        </div>
                    </Tabs>
                    <div className="submit_button_wrapper">
                        <Button onClick={() => saveTranslations()}>{i18n.t("save")}</Button>
                    </div>
                </Card.Section>
            </Card>

        </div>
    );
};

export default ManageTranslations
