import React, { useCallback, useEffect, useState } from 'react';
import { render } from 'react-dom';
import {
  Icon,
  AppProvider,
  TextField,
  Select,
  Frame,
  Card,
  Checkbox,
  RadioButton,
  Page,
  Toast,
} from '@shopify/polaris';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import '@shopify/polaris/dist/styles.css';
import './Style.css';
import RewardsLauncherHtml from './RewardsLauncherHtml';
import PixelIcon from '../../assets/images/PixelIcon';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import ManageTranslations from './ManageTranslations';
import {
  DeleteMajor,
  NoteMinor,
  ChevronRightMinor,
} from '@shopify/polaris-icons';
import HideSideNav from '../helpers/HideSideNav';

const RewardsLauncherSettings = (props) => {
  const { domain, i18n, detailView, setDetailView } = props;
  const initialValues = {
    color: '#6568fe',
    font_color: 'White',
    position: 'Right',
    side_spacing: '20',
    bottom_spacing: '20',
    hide_mobile: false,
    program_name: 'Loyalty Points',
    button_content: 'icon_with_text',
    button_text: 'Rewards',
    panel_tab: 'Home',
    button_icon: '',
  };
  const [toastContent, setToasContent] = useState();
  const hideSaveSuccess = useCallback(() => setToasContent(), []);
  const [currentForm, setCurrentForm] = useState('');
  const [formData, setFormData] = useState(initialValues);
  const [headerButton, setHeaderButton] = useState('Theme');

  const headerButtons = [
    {
      val: 'Theme',
      name: 'Theme',
    },
    {
      val: 'Panel',
      name: 'Panel',
    },
    {
      val: 'Launcher',
      name: 'Launcher',
    },
  ];

  const fetchRewardsLauncherSettings = () => {
    fetch(
      `/points_program/fetch_rewards_launcher_settng?shopify_domain=${domain}`,
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.response?.rewards_launcher_setting) {
          setFormData(data?.response?.rewards_launcher_setting);
        }
      });
  };

  useEffect(() => {
    fetchRewardsLauncherSettings();
  }, []);

  useEffect(() => {
    setCurrentForm(headerButton);
  }, [headerButton]);

  const saveRewardLauncherSetting = () => {
    fetch('/points_program/set_rewards_launcher_setting', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rewards_launcher_setting: formData,
        shopify_domain: domain,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status) {
          setToasContent('Saved Successfully...');
        } else {
          setToasContent('Something went wrong...');
        }
      });
  };

  const buttonIcons = [
    `<svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
            >
                <path
                    fill="000"
                    fill-rule="evenodd"
                    d="M8 8V7a5 5 0 0 1 10 0v1h3.446a2 2 0 0 1 1.991 1.818l.42 4.597a1 1 0 0 1-1.992.182L21.445 10H18v2a1 1 0 0 1-2 0v-2h-6v2a1 1 0 0 1-2 0v-2H4.554a165320.029 165320.029 0 0 0-1.457 16H15a1 1 0 0 1 0 2H2.548a1.5 1.5 0 0 1-1.5-1.5c.008-.15.008-.15.027-.353l.071-.78.283-3.113L2.563 9.818A2 2 0 0 1 4.554 8H8zm2 0h6V7a3 3 0 0 0-6 0v1zm18.002 9.76a3.854 3.854 0 0 1-.13 5.535l-4.643 4.616a1 1 0 0 1-1.41 0l-4.646-4.613c-.041-.044-.085-.086-.127-.13a3.854 3.854 0 0 1 0-5.408c1.477-1.494 4.527-1.099 5.478 1.202.96-2.298 4-2.696 5.478-1.202z"
                />
            </svg>
        `,
    `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
            >
                <path
                    fill="000"
                    fill-rule="nonzero"
                    d="M3.071 14.47a.5.5 0 0 0 .147.354l12.288 12.289L26.82 15.799 14.53 3.51a.5.5 0 0 0-.353-.146H3.07v11.107zm-2 0V3.365a2 2 0 0 1 2-2h11.107a2.5 2.5 0 0 1 1.767.732l12.289 12.289a2 2 0 0 1 0 2.828L16.92 28.527a2 2 0 0 1-2.828 0L1.803 16.238a2.5 2.5 0 0 1-.732-1.767zm7.364-5.742a2 2 0 1 0-2.828-2.829 2 2 0 0 0 2.828 2.829zm11.592 10.844a2.7 2.7 0 0 0 .77-1.895C20.797 16.2 19.616 15 18.16 15c-.728 0-2.15.273-2.637 2.128C15.11 15.245 13.614 15 12.887 15c-1.456 0-2.637 1.2-2.637 2.677 0 .739.295 1.403.77 1.895l3.914 3.974a.827.827 0 0 0 1.179 0l3.914-3.974z"
                />
            </svg>
        `,
    `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
            >
                <path
                    fill="000"
                    fill-rule="nonzero"
                    d="M1.962 11.31l6.53 2.382c.098.036.21.005.273-.077l5.666-7.338a.724.724 0 0 1 1.15.014l5.346 7.32a.241.241 0 0 0 .274.084l6.846-2.389c.517-.18 1.04.252.94.777l-2.138 11.35a.707.707 0 0 1-.7.567H3.851a.707.707 0 0 1-.7-.567l-2.14-11.35c-.1-.53.432-.962.95-.773"
                />
            </svg>
        `,
    `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
            >
                <path
                    fill="000"
                    fill-rule="nonzero"
                    d="M15 23.65l-7.07 3.597A1 1 0 0 1 6.489 26.2l1.235-7.836-5.605-5.613a1 1 0 0 1 .55-1.694l7.834-1.246 3.606-7.066a1 1 0 0 1 1.782 0l3.606 7.066 7.834 1.246a1 1 0 0 1 .55 1.694l-5.605 5.613L23.51 26.2a1 1 0 0 1-1.44 1.047L15 23.65z"
                />
            </svg>
        `,
    `
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path fill="000" fill-rule="nonzero" d="M13 16v14H7a4 4 0 0 1-4-4V16h10zm4 0h10v10a4 4 0 0 1-4 4h-6V16zm-4-8v6H2.5A1.5 1.5 0 0 1 1 12.5v-3A1.5 1.5 0 0 1 2.5 8H13zm4 0h10.5A1.5 1.5 0 0 1 29 9.5v3a1.5 1.5 0 0 1-1.5 1.5H17V8zm-7-8h.004a3.541 3.541 0 0 1 3.494 2.964L14 6h-4a3 3 0 1 1 0-6zm10 0a3 3 0 0 1 0 6h-4l.502-3.036A3.541 3.541 0 0 1 19.996 0H20z"/>
        </svg>
        `,
  ];

  return (
    <Frame>
      {!detailView && <HideSideNav />}
      {toastContent && (
        <Toast content={toastContent} onDismiss={hideSaveSuccess} />
      )}
      <div className="without_header_card">
        <Card
          sectioned
          actions={{
            content: (
              <div className="card_header_button_group">
                <div className="card_header_buttons primary_button_group">
                  <HeaderButtons
                    headerButtons={headerButtons}
                    setHeaderButton={setHeaderButton}
                    headerButton={headerButton}
                  />
                </div>
              </div>
            ),
          }}
        >
          <div className="rewards_launcher_settings_wrapper">
            <div className="header_wraper">
              <span className="left_section">
                {headerButton != currentForm && (
                  <div
                    onClick={() => {
                      setCurrentForm('');
                    }}
                  >
                    <Icon source={ChevronLeftMinor} color="base" />
                  </div>
                )}

                <h2>{headerButton}</h2>
              </span>
              <div className="heder-right-section">
                <button
                  className="save-button"
                  onClick={() => saveRewardLauncherSetting()}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="content-section">
              <div className="left_sidebarr">
                {currentForm === 'colorsTab' ? (
                  <Card>
                    <Card.Section>
                      <div className="card_body_content">
                        <div className="select_colors_container">
                          <TextField
                            label="Color"
                            type="color"
                            placeholder="None"
                            value={formData?.color}
                            autoComplete="off"
                            onChange={(e) => {
                              setFormData({ ...formData, color: e });
                            }}
                          />
                          <TextField
                            label="Enter color manualy"
                            type="text"
                            placeholder="None"
                            value={formData?.color}
                            autoComplete="off"
                            onChange={(e) => {
                              setFormData({ ...formData, color: e });
                            }}
                          />
                        </div>
                        <div className="select_font_color">
                          <Select
                            label="Font color"
                            options={['White', 'Black']}
                            value={formData?.font_color}
                            onChange={(e) => {
                              setFormData({ ...formData, font_color: e });
                            }}
                          />
                        </div>
                      </div>
                    </Card.Section>
                  </Card>
                ) : currentForm === 'placementTab' ? (
                  <>
                    <Card>
                      <Card.Section>
                        <div className="card_body_content">
                          <h3>Position</h3>
                          <Select
                            label="Placement"
                            options={['Right', 'Left']}
                            value={formData?.position}
                            onChange={(e) => {
                              setFormData({ ...formData, position: e });
                            }}
                          />
                        </div>
                      </Card.Section>
                    </Card>
                    <Card>
                      <Card.Section>
                        <div className="card_body_content">
                          <h3>SPACING</h3>
                          <div className="card_description">
                            The position of the panel and launcher relative to
                            the customer's window. Only applies to desktop mode.
                          </div>
                          <div className="select_colors_container">
                            <TextField
                              label="Side spacing"
                              type="number"
                              suffix="px"
                              value={formData?.side_spacing}
                              onChange={(e) => {
                                setFormData({ ...formData, side_spacing: e });
                              }}
                            />
                            <TextField
                              label="Bottom spacing"
                              type="number"
                              suffix="px"
                              value={formData?.bottom_spacing}
                              onChange={(e) => {
                                setFormData({ ...formData, bottom_spacing: e });
                              }}
                            />
                          </div>
                        </div>
                      </Card.Section>
                    </Card>
                  </>
                ) : currentForm === 'visibilityTab' ? (
                  <Card>
                    <Card.Section>
                      <div className="card_body_content">
                        <h3>VISIBILITY</h3>
                        <Checkbox
                          label="Hide on mobile"
                          checked={formData?.hide_mobile}
                          onChange={(e) => {
                            setFormData({ ...formData, hide_mobile: e });
                          }}
                        />
                      </div>
                    </Card.Section>
                  </Card>
                ) : currentForm === 'Panel' ? (
                  <Card>
                    <Card.Section>
                      <h3>PROGRAM NAME</h3>
                      <TextField
                        type="text"
                        value={formData.program_name}
                        onChange={(e) => {
                          setFormData({ ...formData, program_name: e });
                        }}
                      />
                    </Card.Section>
                  </Card>
                ) : currentForm === 'Launcher' ? (
                  <>
                    <Card>
                      <Card.Section>
                        <div className="radio_butons_wrapper">
                          <RadioButton
                            label="Icon with text"
                            checked={
                              formData.button_content === 'icon_with_text'
                            }
                            id="icon_with_text"
                            name="accounts"
                            onChange={(_, e) => {
                              setFormData({ ...formData, button_content: e });
                            }}
                          />
                          <RadioButton
                            label="Text only"
                            checked={formData?.button_content === 'text_only'}
                            id="text_only"
                            name="accounts"
                            onChange={(_, e) => {
                              setFormData({ ...formData, button_content: e });
                            }}
                          />
                          <RadioButton
                            label="Icon only"
                            checked={formData?.button_content === 'icon_only'}
                            id="icon_only"
                            name="accounts"
                            onChange={(_, e) => {
                              setFormData({ ...formData, button_content: e });
                            }}
                          />
                        </div>
                        <TextField
                          type="text"
                          label="Text"
                          value={formData?.button_text}
                          onChange={(e) => {
                            setFormData({ ...formData, button_text: e });
                          }}
                        />
                      </Card.Section>
                    </Card>
                    <Card>
                      <Card.Section>
                        <h3>Icon</h3>
                        <div className="rewards_button_icons_wrapper">
                          {buttonIcons?.map((icon) => {
                            return (
                              <div
                                className={`icon ${formData?.button_icon == icon
                                    ? 'selected'
                                    : ''
                                  }`}
                                dangerouslySetInnerHTML={{ __html: icon }}
                                onClick={() =>
                                  setFormData({
                                    ...formData,
                                    button_icon: icon,
                                  })
                                }
                              ></div>
                            );
                          })}
                          s
                        </div>
                      </Card.Section>
                    </Card>
                    <Card>
                      <Card.Section>
                        <h3>
                          Choose which part of the panel you want to open:
                        </h3>
                        <Select
                          options={['Home', 'Ways to earn', 'Ways to Redeem']}
                          value={formData?.panel_tab}
                          onChange={(e) => {
                            setFormData({ ...formData, panel_tab: e });
                          }}
                        />
                      </Card.Section>
                    </Card>
                  </>
                ) : (
                  <ul className="polaris-card">
                    <li
                      onClick={() => {
                        setCurrentForm('colorsTab');
                      }}
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.588 2.051c-2.056-.259-3.68.458-5 2.23-2.847 3.823-1.715 9.71 2.223 12.064 4.02 2.404 9.32.946 11.563-3.28.536-1.01.729-2.11.575-3.326-.146-1.153-1.173-1.957-2.284-1.812-.12.015-.237.041-.352.078l-.082.026a3.092 3.092 0 01-3.893-2.033 3.124 3.124 0 01-.142-.93c0-1.54-1.124-2.83-2.608-3.017zm.25-1.984c2.49.313 4.358 2.458 4.358 5.001 0 .114.017.226.051.335.182.584.797.908 1.374.724l.082-.027c.23-.073.465-.126.704-.157 2.216-.288 4.242 1.3 4.526 3.545.205 1.619-.06 3.134-.793 4.515-2.816 5.304-9.42 7.01-14.355 4.059-4.914-2.94-6.384-10.164-2.8-14.975C3.727.746 6.054-.283 8.837.067zM6.833 6.929a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM4.5 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm3 4a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm6.25-1a1.75 1.75 0 110-3.5 1.75 1.75 0 010 3.5z"
                          fill="#637381"
                          fill-rule="nonzero"
                        ></path>
                      </svg>
                      <span className="left_section">Colors</span>
                    </li>
                    <li
                      onClick={() => {
                        setCurrentForm('placementTab');
                      }}
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fill-rule="evenodd">
                          <path
                            d="M16.515 11H13a1 1 0 010-2h3.414L15.27 7.935l1.363-1.463 3.048 2.838a1 1 0 01.003 1.461l-3.029 2.839-1.367-1.46L16.515 11zm-12.98 0l1.19 1.114-1.368 1.46-3.029-2.838a1 1 0 01.003-1.462l3.048-2.838L4.742 7.9 3.56 9H7a1 1 0 110 2H3.535zM9 16.497V13a1 1 0 012 0v3.43l1.06-1.154 1.473 1.352-2.796 3.048a1 1 0 01-1.462.013l-2.88-3.029 1.45-1.378L9 16.497zm2-12.994V7a1 1 0 01-2 0V3.57L7.94 4.723 6.467 3.372 9.263.324a1 1 0 011.462-.013l2.88 3.029-1.45 1.378L11 3.503z"
                            fill="#637381"
                            fill-rule="nonzero"
                          ></path>
                        </g>
                      </svg>
                      <span className="left_section">Placement</span>
                    </li>
                    <li
                      onClick={() => {
                        setCurrentForm('visibilityTab');
                      }}
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 17c-3.69 0-6.974-2.19-9.83-6.442a1 1 0 010-1.116C3.026 5.19 6.31 3 10 3c3.69 0 6.974 2.19 9.83 6.442a1 1 0 010 1.116C16.974 14.81 13.69 17 10 17zm0-2c2.788 0 5.376-1.63 7.784-5C15.376 6.63 12.788 5 10 5c-2.788 0-5.376 1.63-7.784 5 2.408 3.37 4.996 5 7.784 5zm0-2a3 3 0 110-6 3 3 0 010 6z"
                          fill="#637381"
                          fill-rule="nonzero"
                        ></path>
                      </svg>
                      <span className="left_section">Visibility</span>
                    </li>
                  </ul>
                )}
                <div className="customize-card">
                  <p>
                    Customize your theme with branded images, text, icons and
                    more, when you upgrade.
                  </p>
                </div>
              </div>
              <div className="right-section">
                {
                  <RewardsLauncherHtml
                    formData={formData}
                    currentForm={currentForm}
                  />
                }
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="translation_settings_wrapper">
        <ManageTranslations
          i18n={i18n}
          domain={domain}
          setToasContent={setToasContent}
        />
      </div>
    </Frame>
  );
};

export default RewardsLauncherSettings;
