import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  ChoiceList,
  DataTable,
  DropZone,
  Filters,
  Frame,
  Icon,
  List,
  Modal,
  Page,
  Spinner,
  Stack,
  Tabs,
  Thumbnail,
  Toast,
} from '@shopify/polaris';
import {
  DeleteMajor,
  NoteMinor,
  ChevronRightMinor,
} from '@shopify/polaris-icons';
import Papa from 'papaparse';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { Pagination } from '@shopify/polaris';
import LoadingScreen from '../LoadingScreen';
import PixelIcon from '../../assets/images/PixelIcon';
import { DomainContext } from '../domain-context';
import { sortBy } from 'lodash';
// import CustomerDetail from './CustomerDetail/CustomerDetail';

// import json2csv from 'json2csv';
const subscriptions = [
  'active',
  'new',
  'returning',
  'paused',
  'cancelled',
  'all',
];

const {
  Parser,
  transforms: { unwind },
} = require('json2csv');

// let sortOrder = 0;

const Affiliates = (props) => {
  const { shopifyDomain, i18n, setDetailView, detailView, setHeaderTitle } =
    props;
  const { domain } = useContext(DomainContext);
  const history = useHistory();
  const [uid, setuid] = useState();
  const [customers, setCustomers] = useState([]);
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [filterCustomers, setFilterCustomers] = useState([]);

  useEffect(() => {
    setHeaderTitle('Affiliates');
  }, []);

  // End tabs
  const [sortOrder, setSortOrder] = useState(0);

  const [moneySpent, setMoneySpent] = useState(null);
  const [taggedWith, setTaggedWith] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [status, setStatus] = useState('active');
  const [searchQuery, setSearchQuery] = useState();
  const [sortFilter, setSortFilter] = useState('newest');

  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );

  const handleMoneySpentRemove = useCallback(() => setMoneySpent(null), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleMoneySpentRemove, handleQueryValueRemove, handleTaggedWithRemove]);

  const filters = [
    {
      key: 'taggedWith',
      label: 'Tagged with',
      filter: (
        <ChoiceList
          title="Tagged with"
          titleHidden
          choices={[
            { label: 'Cancelled', value: 'cancelled' },
            { label: 'Active', value: 'active' },
            { label: 'In Trial', value: 'inTrial' },
            { label: 'Future', value: 'future' },
          ]}
          selected={taggedWith || []}
          onChange={handleTaggedWithChange}
        // allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'moneySpent',
      label: 'Money spent',
      // filter: (
      //   <RangeSlider
      //     label="Money spent is between"
      //     labelHidden
      //     value={moneySpent || [0, 500]}
      //     prefix="$"
      //     output
      //     min={0}
      //     max={2000}
      //     step={1}
      //     onChange={handleMoneySpentChange}
      //   />
      // ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(moneySpent)) {
    const key = 'moneySpent';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = 'taggedWith';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'moneySpent':
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  // -------------------
  const GET_AFFILIATES = gql`
    query ($page: String, $searchquery: String, $sortBy: String) {
      fetchAffiliates(page: $page, searchquery: $searchquery, sortBy: $sortBy) {
        affiliates {
          id
          firstName
          lastName
          email
          taxResidency
          primaryRegion
          status
          createdAt
          sales
          commissionProfile
          commission
          earnedCommission
        }
        totalPages
        totalCount
        pageNumber
      }
    }
  `;
  const { data, loading, error, refetch } = useQuery(GET_AFFILIATES, {
    fetchPolicy: 'no-cache',
    variables: {
      page: page.toString(),
      searchquery: searchQuery,
      sortBy: sortFilter,
    },
  });

  useEffect(() => {
    refetch({
      variables: {
        page: page.toString(),
        searchquery: searchQuery,
        sortBy: sortFilter,
      },
    });
  }, [page, searchQuery]);

  const handleFiltersQueryChange = (value) => {
    setQueryValue(value);
    if (value.length > 1) {
      setSearchQuery(value);
    } else {
      setSearchQuery();
    }
  };

  useEffect(() => {
    setTotalPages(data?.fetchAffiliates?.totalPages);
    if (+page < +totalPages) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    if (+page <= 1) {
      setHasPrevious(false);
    } else {
      setHasPrevious(true);
    }
  }, [data]);

  const filterCustomersValue = () => {
    const rowsData = customers;

    setFilterCustomers(rowsData);
  };
  useEffect(() => {
    if (customers) {
      filterCustomersValue();
    }
  }, [queryValue, taggedWith, customers]);

  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);

  const [selectedCustomers, setselectedCustomers] = useState([]);
  const handleChangeCheckedCustomers = (newChecked, customerId) => {
    if (newChecked) {
      setselectedCustomers([...selectedCustomers, customerId]);
    } else {
      const index = selectedCustomers.indexOf(customerId);
      setselectedCustomers([
        ...selectedCustomers.slice(0, index),
        ...selectedCustomers.slice(index + 1),
      ]);
    }
  };
  //upper first letter
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  //each row in data table
  const formatRows = (rows) => {
    let apiData;
    return rows?.map((row, i) => {
      return row !== null
        ? [
          <div
            onClick={() => {
              history.push(`/affiliatesViewAffiliate/${row.id}`);
            }}
          >
            {row?.email}
          </div>,

          <div
            className=""
            onClick={() => {
              history.push(`/affiliatesViewAffiliate/${row.id}`);
            }}
          >
            {row?.firstName}
          </div>,
          <div
            className=""
            onClick={() => {
              history.push(`/affiliatesViewAffiliate/${row.id}`);
            }}
          >
            {row?.lastName}
          </div>,
          <div
            className=""
            onClick={() => {
              history.push(`/affiliatesViewAffiliate/${row.id}`);
            }}
          >
            {row?.createdAt}
          </div>,
          <div
            className=""
            onClick={() => {
              history.push(`/affiliatesViewAffiliate/${row.id}`);
            }}
          >
            {row?.status}
          </div>,
          <div
            className=""
            onClick={() => {
              history.push(`/affiliatesViewAffiliate/${row.id}`);
            }}
          >
            {row?.commissionProfile}
          </div>,
          <div
            className=""
            onClick={() => {
              history.push(`/affiliatesViewAffiliate/${row.id}`);
            }}
          >
            {row?.commission}
          </div>,
          <div
            className=""
            onClick={() => {
              history.push(`/affiliatesViewAffiliate/${row.id}`);
            }}
          >
            {row?.sales}
          </div>,
          <div
            className=""
            onClick={() => {
              history.push(`/affiliatesViewAffiliate/${row.id}`);
            }}
          >
            {row?.earnedCommission}
          </div>,
          <div
            className=""
            onClick={() => {
              history.push(`/affiliatesViewAffiliate/${row.id}`);
            }}
          >
            {row?.primaryRegion}
          </div>,
          <div
            className=""
            onClick={() => {
              history.push(`/affiliatesViewAffiliate/${row.id}`);
            }}
          >
            {row?.taxResidency}
          </div>,
        ]
        : [];
    });
  };

  // useEffect(() => {
  //   filterCustomersValue();
  // }, [selectedCustomers]);

  useEffect(() => {
    if (data && data.fetchAffiliates) {
      let rowsData = formatRows(data.fetchAffiliates?.affiliates);
      setCustomers(data.fetchAffiliates?.affiliates);
    }
  }, [data]);

  const fields = [
    'id',
    'firstName',
    'lastName',
    'email',
    'communication',
    'phone',
    'language',
    'autoCollection',
    'status',
    'subscription',

    'additionalContacts.id',
    'additionalContacts.email',
    'additionalContacts.firstName',
    'additionalContacts.lastName',
    'additionalContacts.companyName',
    'additionalContacts.phone',

    'billingAddress.firstName',
    'billingAddress.lastName',
    'billingAddress.email',
    'billingAddress.company',
    'billingAddress.phone',
    'billingAddress.address1',
    'billingAddress.address2',
    'billingAddress.city',
    'billingAddress.language',
    'billingAddress.zipCode',
  ];
  const transforms = [
    unwind({ paths: ['additionalContacts', 'billingAddress'] }),
  ];
  // const exportCSV = () => {
  const dataSelected = [...filterCustomers].filter((item) =>
    selectedCustomers.find((select) => select === item.id)
  );
  const json2csvParser = new Parser({ fields, transforms });
  let csv = json2csvParser.parse(dataSelected);

  //

  // };

  //import customer by csv:
  const [active, setActive] = useState(false);
  const [checked, setChecked] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleCheckbox = useCallback((value) => setChecked(value), []);

  //upload file
  const [file, setFile] = useState();

  const csvData = [];

  const parsedata =
    file &&
    Papa.parse(file, {
      header: false,
      step: function (result) {
        csvData.push(result.data);
      },
      complete: function (results, file) {
        // console.log('csvData: ', csvData);
      },
    });

  const CREATE_CUSTOMER = gql`
    mutation ($input: AddCustomersInput!) {
      addCustomers(input: $input) {
        result
      }
    }
  `;
  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
  useEffect(() => {
    if (status == 'subscriptions') {
      filterCustomer();
    } else {
      setFilteredCustomer([]);
    }
  }, [status, customers]);

  const filterCustomer = () => {
    // let allcustomers = deepCopy(customers)
    // let filteredAllCustomers = allcustomers.map((customer) => {
    //   let contracts = []
    //   JSON.parse(customer?.contracts)?.map((contract) => {
    //     try {
    //       contract = JSON.parse(contract)
    //     }
    //     catch (e) {
    //       console.log("error", e)
    //     }
    //     if (contract?.status == "ACTIVE") { contracts.push(JSON.stringify(contract)) }
    //   })

    //   console.log("contracts", contracts);
    //   customer.contracts = JSON.stringify(contracts);
    //   return customer
    // })
    let allcustomers = deepCopy(customers);
    let filteredAllCustomers = allcustomers.filter((customer) => {
      return JSON.parse(customer?.contracts)?.length > 0;
    });
    setFilteredCustomer([...filteredAllCustomers]);
  };

  return (
    <>
      <Frame>
        {saveSuccess && (
          <Toast
            content="Customer data has been saved successfully"
            onDismiss={hideSaveSuccess}
          />
        )}

        {formErrors.length > 0 && (
          <>
            <Banner title="Customer data has not been saved" status="critical">
              <List type="bullet">
                {formErrors.map((message, index) => (
                  <List.Item key={index}>{message.message}</List.Item>
                ))}
              </List>
            </Banner>
            <br />
          </>
        )}
        {loadingScreen && (
          <>
            <LoadingScreen />
          </>
        )}
        <div className="without_header_card">
          <Card>
            <Card.Section>
              <div className={'table customer-subscription-tbl' + ' '}>
                <DataTable
                  columnContentTypes={['text', 'text', 'text', 'text']}
                  headings={[
                    'Email',
                    'First Name',
                    'Last Name',
                    'Date created',
                    'Status',
                    'Commission profile',
                    'Commission',
                    'Sales',
                    'Earned commission',
                    'Primary Region',
                    'Tax Residency',
                  ]}
                  rows={formatRows(customers)}
                />
              </div>
              {loading && <Spinner />}
              <div
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <Pagination
                  hasPrevious={hasPrevious}
                  onPrevious={() => {
                    setPage((prev) => +prev - 1);
                  }}
                  hasNext={hasMore}
                  onNext={() => {
                    setPage((prev) => +prev + 1);
                  }}
                />
              </div>
            </Card.Section>
          </Card>
        </div>
      </Frame>
    </>
  );
};

export default Affiliates;
