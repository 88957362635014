import { gql, useLazyQuery } from '@apollo/client';
import { Button, Checkbox, TextField } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import axiosconfig from '../axiosconfig';

const SubscriptionBundleModal = (props) => {
  const {
    setShowPlanModal,
    query,
    setQuery,
    setFieldValue,
    values,
    formData,
    setFormData,
    fieldName,
    singleLine
  } = props;
  const [allPlans, setAllPlans] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);


  const closeModal = () => {
    setShowPlanModal();
  };


  const getBundles = () => {
    const result = []
    axiosconfig.get(`/search_subscription_bundle?query=${query}`).then(({ data }) => {
      if (data?.bundles?.length > 0) {
        data?.bundles.map((bundle) => {
          let bundles = bundle.collection ? JSON.parse(bundle.collection?.metafields?.edges?.find((col) => col.node.key == "bundle21_options")?.node?.value) : []
          result.push({
            name: bundle.name,
            id: bundle.id,
            size: bundle.size,
            selected: false,
            bundles: bundles
          });
        });
      }
      setAllPlans([...result]);
    })
  }
  useEffect(() => {
    getBundles();
    if (values[fieldName].length > 0) {
      setSelectedPlans([...values[fieldName]]);
    }
  }, [query]);

  const handleChange = (e) => {
    setQuery(e);
  };


  const selectAllPlans = (e, prod) => {
    let index = selectedPlans.findIndex((p) => p.id === prod.id);
    const currentPlans = selectedPlans;
    if (index > -1) {
      currentPlans.splice(index, 1);
    } else {
      prod.selected = true
      currentPlans.push(prod);
    }
    if (singleLine) {
      let plans = currentPlans?.filter((p) => p.id)
      console.log("currentPlanscurrentPlans", index)
      let bundle = plans[plans.length - 1]
      bundle.selected = true;
      setSelectedPlans([bundle]);
    } else {
      setSelectedPlans([...currentPlans]);
    }
  };

  const handleSubmit = () => {
    if (formData) {
      setFormData({ ...formData, [fieldName]: [...selectedPlans] })
    } else {
      setFieldValue(fieldName, [...selectedPlans]);
    }
    closeModal();
  };

  const handleBundleSelection = (index, subIndex, val) => {
    let allBundles = allPlans;

    allBundles?.map((b) => {
      b.selected = false
      b.bundles?.map((b) => {
        b.selected = false;
      })
    })
    allBundles[index].selected = val;
    allBundles[index]["bundles"][subIndex]["selected"] = val;
    setSelectedPlans([]);
    setSelectedPlans([...allBundles?.filter((b) => b.selected)]);
  }
  const checkBundleSelection = (id, subIndex) => {
    let bundle = selectedPlans?.find((b) => b.id === id);
    if (!bundle || !bundle.selected) {
      return false;
    } else {
      return bundle.bundles[subIndex]?.selected;
    }
  }

  return (
    <div className="chargezen_search_model product_variant_picker">
      <div className="modal-content-wrapper">
        <div className="chargezen_search_model_header_container">
          <h2>Select bundles</h2>
        </div>
        <div className="chargezen_search_model_body">
          <TextField
            value={query}
            label=""
            placeholder="Search for a bundle"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <div class="segments_list_wrapper_container">
            <ul class="segments_lists">
              {allPlans?.map((pv, index) => {
                return (
                  <li key={index}>
                    <Checkbox
                      label={
                        <div className="productWrapper">
                          {/* <span className="imgwrapper">
                            <img src={pv.image} />
                          </span> */}
                          <span>{pv.name}</span>
                        </div>
                      }
                      checked={selectedPlans.find((s) => s.id == pv.id)?.selected}
                      onChange={(e) => {
                        selectAllPlans(e, pv);
                      }}
                      disabled
                    />
                    <div className="variantsContainer">
                      {pv.bundles?.map((bundle, i) => {
                        return (
                          <div className="variantRow" key={i}>
                            <Checkbox
                              label={
                                <div className="productWrapper">
                                  <span>{bundle.name}</span>
                                  <span>{bundle?.currency}{bundle.per_price}</span>
                                </div>
                              }
                              checked={checkBundleSelection(pv.id, i)}
                              onChange={(e) => {
                                handleBundleSelection(index, i, e);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="chargezen_search_model_footer justify-between">
          <div className="chargezen_addToCart">
            {selectedPlans.length === 1
              ? '1 bundle selected'
              : selectedPlans.length > 1
                ? `${selectedPlans.length} Bundles selected`
                : ''}
          </div>
          <div className="action_buttons">
            <Button onClick={() => closeModal()}>Cancel</Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBundleModal;
