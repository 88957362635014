import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import { Card } from './Card';
import update from 'immutability-helper';
import { ItemTypes } from './ItemTypes';
const style = {
  width: 400,
};
const Container = (props) => {
  const { cards, setCards, i18n, addContent, setAddContent } = props;
  const moveCard = (temp_token, atIndex) => {
    const { card, index } = findCard(temp_token);
    const updatedCards = update(cards, {
      $splice: [
        [index, 1],
        [atIndex, 0, card],
      ],
    });
    setCards([...updatedCards]);
  };
  const findCard = (temp_token) => {
    const card = cards.filter((c) => `${c.temp_token}` === temp_token)[0];
    return {
      card,
      index: cards.indexOf(card),
    };
  };
  const [, drop] = useDrop({ accept: ItemTypes.CARD });

  const nonDraggableButtons = ['Delivery address field', 'Discount field'];
  return (
    <>
      <div ref={drop} style={style}>
        {cards?.map((card) => {
          if (!nonDraggableButtons?.includes(card?.content_type)) {
            return (
              <Card
                key={card.temp_token}
                temp_token={`${card.temp_token}`}
                text={card?.content_type}
                moveCard={moveCard}
                findCard={findCard}
                addContent={addContent}
                chargezenCard={card}
                setAddContent={setAddContent}
              />
            );
          }
        })}
      </div>
    </>
  );
};
export default Container;
