import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import {
    Card,
    Frame,
    Page,
    Tabs,
    AppProvider,
    Form,
    FormLayout,
    TextField,
    Select,
    Button
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import { useCallback } from "react";
import "./style.css";
import PixelIcon from "../../../assets/images/PixelIcon";

const ManageTranslations = (props) => {
    const {
        i18n,
        selectedTemplateId,
        domain,
        setToastActive,
        setToastContent
    } = props;
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        []
    );
    const [formData, setFormData] = useState();
    const [selectedLanguage, setSelectedLanguage] = useState("en");

    const fetchDefaultLanguage = () => {
        fetch(`/settings/current_language?shopify_domain=${domain}&language=${selectedLanguage}`, {
            method: "GET"
        }).then((response) => response.json())
            .then((data) => {
                setSelectedLanguage(data?.front_end_language)
            })
    }

    const fetchTranslations = () => {
        fetch(`/cloudlfare/fetch_translations?shopify_domain=${domain}&language=${selectedLanguage}&cloudflarepage=referrals`, {
            method: "GET"
        }).then((response) => response.json())
            .then((data) => {
                setFormData(data?.translations)
            })
    }

    const saveTranslations = () => {
        fetch(`/cloudlfare/save_translations`, {
            method: "POST",
            body: JSON.stringify({ translations: formData, shopify_domain: domain, language: selectedLanguage, cloudflarepage: "referrals" }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((data) => {
                if (data.status) {
                    setToastActive(true)
                    setToastContent("Saved successfully.")
                }
            })
    }

    useEffect(() => {
        fetchDefaultLanguage();
    }, [])
    useEffect(() => {
        fetchTranslations();
    }, [selectedLanguage])

    const tabs = [
        {
            id: "Referrals",
            content: i18n.t("referrals.referrals")
        },
    ];

    const supportedLanguages = [
        { label: "Arabic", value: "ar" },
        { label: "Danish", value: "da" },
        { label: "Dutch", value: "nl" },
        { label: "English", value: "en" },
        { label: "Finnish", value: "fi" },
        { label: "French", value: "fr" },
        { label: "German", value: "de" },
        { label: "Italian", value: "it" },
        { label: "Japanese", value: "ja" },
        { label: "Korean", value: "ko" },
        { label: "Norwegian Bokmål", value: "nb" },
        { label: "Portuguese - Brazil", value: "pt-BR" },
        { label: "Spanish", value: "es" },
        { label: "Swedish", value: "sv" },
        { label: "Turkish", value: "tr" }
    ];




    const handleChange = (e, key) => {
        let form_data = formData;
        let new_formData = form_data.map((f) => {
            if (f.key == key) {
                f.value = e;
                return f;
            } else {
                return f;
            }
        });
        setFormData(new_formData);
    };
    return (
        <div className="translations_form_wrapper">
            <Card title=
                {
                    <div className="heading_title">
                        <PixelIcon />
                        {i18n.t("zennportal_form.edit_translations")}
                    </div>
                }>
                <Card.Section>
                    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                        <div className="content_wrapper_container">
                            <div className="form_wrapper">
                                <Form>
                                    <FormLayout>
                                        <Select
                                            label={i18n.t("zennportal_form.language")}
                                            options={supportedLanguages}
                                            onChange={(e) => {
                                                setSelectedLanguage(e);
                                            }}
                                            value={selectedLanguage}
                                        />
                                        {formData
                                            ?.filter((t) => t.category == tabs[selected]?.id)
                                            ?.map((formField) => {
                                                return (
                                                    <FormLayout.Group>
                                                        <TextField
                                                            label={formField?.label}
                                                            value={formField?.value}
                                                            onChange={(e) => {
                                                                handleChange(e, formField?.key);
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                    </FormLayout.Group>
                                                );
                                            })}
                                    </FormLayout>
                                </Form>
                            </div>
                        </div>
                    </Tabs>
                    <div className="submit_button_wrapper">
                        <Button onClick={() => saveTranslations()}>{i18n.t("save")}</Button>
                    </div>
                </Card.Section>
            </Card>

        </div>
    );
};

export default ManageTranslations
