import React, { useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Card,
  DataTable,
  Frame,
  Heading,
  Icon,
  Layout,
  List,
  Page,
  ResourceItem,
  ResourceList,
  Stack,
  TextStyle,
  Toast,
} from '@shopify/polaris';
import { gql, useQuery } from '@apollo/client';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import EmailNotificationsDetails from '../loyalty-rewards/PointsProgram/EmailNotificationDetails';
import Smarty from '../smarty/Index';
import PixelIcon from '../../assets/images/PixelIcon';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import { useHistory, useParams } from 'react-router-dom';

const SubscriptionEmail = (props) => {
  const {
    i18n,
    domain,
    detailView,
    setDetailView,
    selectedIndex,
    setSelectedIndex,
    formatDate,
  } = props;
  const history = useHistory();
  const [editForm, setEditForm] = useState(false);
  const [waysToEarn, setWaysToEarn] = useState([]);
  const [waysToRedeem, setWaysToRedeem] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const hideSaveSuccess = useCallback(
    () => history.push(`/customersTab/subscription_email`),
    []
  );
  const { edit_id } = useParams();

  const [formData, setFormData] = useState({
    id: '',
    title: '',
    pointsAwarded: '1',
    summary: '',
    socialLink: '',
  });
  const [pointProgramStatus, setPointProgramStatus] = useState(false);
  const [headerButton, setHeaderButton] = useState(0);
  const [filteredEmailNotifications, setFilteredEmailNotifications] = useState(
    []
  );
  const [setting, setSetting] = useState();
  const headerButtons = [
    {
      val: 0,
      name: i18n.t('emails'),
      index: 0,
    },
    {
      val: 1,
      name: i18n.t('sms_whatsapp'),
      index: 1,
    },
    {
      val: 3,
      name: i18n.t('all'),
      index: 3,
    },
  ];

  const GET_DATA = gql`
    query {
      fetchSetting {
        reasonsCancels {
          id
          title
          returnContent
          _destroy
        }
        navigationDelivery
        reactiveSubscription
        shipingAddress
        pauseSubscription
        delayOrder
        upcomingQuantity
        swapProduct
        subscriptionCancellation
        shipment
        emailNotifications {
          id
          name
          status
          fromName
          fromEmail
          emailSubject
          emailMessage
          designJson
          slug
          description
          templateType
        }
      }
    }
  `;

  const { data, loading, error, refetch } = useQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.fetchSetting?.emailNotifications?.length > 0) {
      let settings = data?.fetchSetting;
      setSetting(settings);
      let filteredArr = settings?.emailNotifications?.filter(
        (email) => email.templateType == 'subscription'
      );
      setFilteredEmailNotifications(filteredArr);
    }
  }, [data]);

  // useEffect(() => {
  //   if (selectedIndex || selectedIndex == 0) {
  //     setDetailView('subscription_email');
  //   }
  // }, [selectedIndex]);

  useEffect(() => {
    if (edit_id && filteredEmailNotifications?.length > 0) {
      let emailNotificationIndex = filteredEmailNotifications?.findIndex(
        (email) => +email.id === +edit_id
      );
      if (emailNotificationIndex || emailNotificationIndex === 0) {
        setSelectedIndex(emailNotificationIndex);
      }
    }
  }, [filteredEmailNotifications, edit_id]);

  useEffect(() => {
    if ((selectedIndex || selectedIndex === 0) && !edit_id) {
      const emailid = filteredEmailNotifications[selectedIndex]?.id;
      history.push(`/customersTab/subscription_email/${emailid}`);
    }
  }, [selectedIndex]);

  return (
    <Frame>
      {saveSuccess && (
        <Toast
          content={i18n.t('saved_successfully')}
          onDismiss={hideSaveSuccess}
        />
      )}
      <>
        <div className="points_program_emal_wrapper">
          <Layout>
            <Layout.Section>
              <div className="without_header_card">
                <Card
                  actions={{
                    content: (
                      <div className="card_header_button_group">
                        <div className="card_header_buttons primary_button_group">
                          <HeaderButtons
                            headerButtons={headerButtons}
                            setHeaderButton={setHeaderButton}
                            headerButton={headerButton}
                          />
                        </div>
                      </div>
                    ),
                  }}
                >
                  {headerButton == 1 ? (
                    <Smarty
                      i18n={i18n}
                      templateType={'subscription'}
                      formatDate={formatDate}
                      domain={domain}
                    />
                  ) : selectedIndex || selectedIndex === 0 ? (
                    <EmailNotificationsDetails
                      domain={domain}
                      i18n={i18n}
                      currentIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                      filteredEmailNotifications={filteredEmailNotifications}
                      refetch={refetch}
                      setSetting={setSetting}
                      setting={setting}
                      setSaveSuccess={setSaveSuccess}
                      saveSuccess={saveSuccess}
                      setDetailView={setDetailView}
                    />
                  ) : (
                    <Card.Section>
                      <Stack vertical>
                        <Stack.Item>
                          <Heading h4>
                            <span class="Polaris-TextStyle--variationSubdued text-align-left">
                              {i18n.t(
                                'points_programs.email_setting_send_message'
                              )}
                            </span>
                          </Heading>
                        </Stack.Item>
                        {filteredEmailNotifications?.map(
                          (item, i) =>
                            item.slug === 'customer' && (
                              <Stack.Item key={i}>
                                <Stack distribution="equalSpacing">
                                  <Stack.Item>
                                    <Button
                                      plain
                                      textAlign="left"
                                    // onClick={() => setSelectedIndex(i)}
                                    >
                                      <TextStyle variation="strong">
                                        <a
                                          onClick={() => {
                                            history.push(
                                              `/customersTab/subscription_email/${item.id}`
                                            );
                                          }}
                                        >
                                          {item.name}
                                        </a>
                                      </TextStyle>
                                    </Button>
                                    <br />
                                    <TextStyle>{item.description}</TextStyle>
                                  </Stack.Item>
                                  <Stack.Item>
                                    {item.status ? (
                                      <Button primary>
                                        {i18n.t('points_programs.enabled')}
                                      </Button>
                                    ) : (
                                      <Button>
                                        {i18n.t('points_programs.disabled')}
                                      </Button>
                                    )}
                                  </Stack.Item>
                                </Stack>
                              </Stack.Item>
                            )
                        )}
                      </Stack>
                    </Card.Section>
                  )}
                </Card>
              </div>
            </Layout.Section>
          </Layout>
        </div>
      </>
    </Frame>
  );
};

export default SubscriptionEmail;
