import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FilterContextProvider } from '../common/Contexts/AnalyticsFilterContext';
import Customize from './customize';
import Campaigns from './campaigns';
import LoyaltyPerformance from './LoyaltyPerformance';
import EmailTriggers from './EmailTriggers';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import {
  Card,
  Select,
  FormLayout,
  Button,
  Icon,
  Modal,
  TextField,
  Layout,
  Page,
  Stack,
  Tabs,
  ColorPicker,
  Frame,
} from '@shopify/polaris';


const LoyaltyRewards = (props) => {
  const {
    setDetailView,
    handleBack,
    i18n,
    setSelectedTemplateId,
    selectedTemplateId,
    domain,
    rewardsPageId,
    setRewardsPageId,
  } = props;
  const history = useHistory();
  const [selectedTitleTab, setSelectedTitleTab] = useState(0);
  const { template_id, edit_id } = useParams();

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTitleTab(selectedTabIndex),
    []
  );

  useEffect(() => {
    setDetailView(false);
  }, []);

  const tabLoyaltyRewards = [
    {
      id: 'customize',
      content: 'Customize',
    },
    // {
    //     id: 'campaigns',
    //     content: 'Campaigns',
    // },
    // {
    //     id: 'loyalty-performance',
    //     content: 'Loyalty Performance',
    // },
    // {
    //     id: 'email-triggers',
    //     content: 'Email Triggers',
    // },
    // {
    //     id: 'referral-performance',
    //     content: 'Referral Performance',
    // },
    // {
    //     id: 'point-overview',
    //     content: 'Point Overview',
    // },
  ];

  return (
    <>
      <Frame>
        <Page
          title={
            <div class="back-button">
              <nav role="navigation">
                <button
                  data-polaris-unstyled="true"
                  class="Polaris-Breadcrumbs__Breadcrumb"
                  onClick={() => {
                    history.push(`/pointsProgram/rewardsPage`);
                    setSelectedTemplateId();
                    setRewardsPageId();
                  }}
                  style={{ paddingLeft: '6px' }}
                >
                  <span class="Polaris-Breadcrumbs__Icon">
                    <span class="Polaris-Icon">
                      <span class="Polaris-VisuallyHidden"></span>
                      <svg
                        viewBox="0 0 20 20"
                        class="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"></path>
                      </svg>
                    </span>
                  </span>
                  <span class="Polaris-VisuallyHidden">
                    {i18n.t('points_programs.rewards')}
                  </span>
                </button>
              </nav>
              <div class="Polaris-Page-Header__TitleWrapper">
                <div>
                  <div class="Polaris-Header-Title__TitleAndSubtitleWrapper">
                    <h1 class="Polaris-Header-Title">
                      {i18n.t('points_programs.all_rewards_page_template')}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <div className="customize">
            <Customize
              domain={domain}
              selectedTemplateId={template_id}
              i18n={i18n}
              rewardsPageId={edit_id}
              setRewardsPageId={setRewardsPageId}
            />
          </div>
          {/* <FilterContextProvider>
                        <Tabs
                            tabs={tabLoyaltyRewards}
                            selected={selectedTitleTab}
                            onSelect={handleTabChange}
                        >
                            {
                                selectedTitleTab === 0 ? (
                                    <div className="customize">
                                        <Customize />
                                    </div>
                                )
                                    : selectedTitleTab === 1 ? (
                                        <div className="campaigns">
                                            <Campaigns />
                                        </div>
                                    )
                                        : selectedTitleTab === 2 ? (
                                            <div className="loyalty-performance">
                                                <LoyaltyPerformance />
                                            </div>
                                        )
                                            : selectedTitleTab === 3 ? (
                                                <div className="email-triggers">
                                                    <EmailTriggers />
                                                </div>
                                            )
                                                : selectedTitleTab === 4 ? (
                                                    <div className="referral-performance">
                                                        <></>
                                                    </div>
                                                )
                                                    : selectedTitleTab === 5 ? (
                                                        <div className="point-overview">
                                                            <></>
                                                        </div>
                                                    )
                                                        : ""
                            }
                        </Tabs>
                    </FilterContextProvider> */}
        </Page>
      </Frame>
    </>
  );
};
export default LoyaltyRewards;
