import { gql, useLazyQuery } from '@apollo/client';
import { Button, Checkbox, TextField } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import './style.css';
import axiosconfig from '../axiosconfig';

const SearchAffiliates = (props) => {
  const {
    affiliateSearchQuery,
    setAffiliateSearchQuery,
    formData,
    setFormData,
    selectedAffiliate,
    setSelectedAffiliate,
  } = props;
  const [allAffiliates, setAllAffiliates] = useState([]);

  const getAffiliate = () => {
    axiosconfig.get(`/search_affiliates?searchquery=${affiliateSearchQuery}`).then(({ data }) => {
      if (data?.affiliates?.length > 0) {
        setAllAffiliates([...data.affiliates]);
      }
    });
  };

  useEffect(() => {
    getAffiliate();
  }, [affiliateSearchQuery]);

  const handleChange = (e, affiliate) => {
    let selectedaffiliate = [...selectedAffiliate];
    if (e) {
      selectedaffiliate.push(affiliate);
    } else {
      selectedaffiliate = selectedaffiliate?.filter(
        (aff) => aff.id !== affiliate.id
      );
    }
    setSelectedAffiliate(selectedaffiliate);
  };

  const closeModal = () => {
    setAffiliateSearchQuery();
  };

  return (
    <div className="chargezen_search_model">
      <div className="modal-content-wrapper">
        <div className="chargezen_search_model_header_container">
          <h2>Assign to affiliate</h2>
        </div>
        <div className="chargezen_search_model_body">
          <TextField
            value={affiliateSearchQuery}
            label=""
            placeholder="Search affiliates"
            onChange={(e) => {
              setAffiliateSearchQuery(e);
            }}
          />
          <div className="segments_list_wrapper_container">
            <ul className="segments_lists">
              {allAffiliates?.map((affiliate) => {
                return (
                  <li>
                    <Checkbox
                      label={
                        <div className="name_email_wrapper">
                          <p className="name_wrapper">{affiliate?.name}</p>
                          <p className="email_wrapper">{affiliate?.email}</p>
                        </div>
                      }
                      checked={selectedAffiliate.some(
                        (a) => a.id === affiliate.id
                      )}
                      onChange={(e) => {
                        handleChange(e, affiliate);
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="chargezen_search_model_footer">
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button
            onClick={() => {
              setFormData({
                ...formData,
                assoc_affiliates: [...selectedAffiliate],
              });
              closeModal();
            }}
          >
            Assign
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SearchAffiliates;
