import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  ChoiceList,
  DataTable,
  DropZone,
  Filters,
  Frame,
  Icon,
  List,
  Modal,
  Page,
  RadioButton,
  Select,
  Spinner,
  Stack,
  Tabs,
  TextField,
  Thumbnail,
  Toast,
} from '@shopify/polaris';
import {
  DeleteMajor,
  NoteMinor,
  ChevronRightMinor,
} from '@shopify/polaris-icons';
import Papa from 'papaparse';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import swapIcon from '../../assets/images/swap.svg';

import moment from 'moment';
import { Pagination } from '@shopify/polaris';
import LoadingScreen from '../LoadingScreen';
import HeaderButtons from '../HeaderButtons/HeaderButtons';

const GlobalBillingHour = (props) => {
  const { domain, shopifyDomain, i18n, detailView, setDetailView } = props;
  const [formData, setFormData] = useState({
    billing_hour: 'Not Set',
    post_dunning_billing: 'billing_failure',
    order_notes_attriburtes: [''],
  });

  const [headerButton, setHeaderButton] = useState('Billing hour');

  const [toastContent, setToastContent] = useState('');
  const toggleToastActive = useCallback(() => setToastContent(), []);

  const headerButtons = [
    {
      val: 'Billing hour',
      name: 'Billing',
    },
    {
      val: 'Product prices',
      name: 'Product prices',
    },
    {
      val: 'Shipping rates',
      name: 'Shipping rates',
    },
    {
      val: 'Order notes',
      name: 'Order notes',
    },
    {
      val: 'Delivery methods',
      name: 'Delivery methods',
    },
  ];

  const GlobalBillingHours = [
    'Not Set',
    ...Array.from({ length: 24 }, (_, i) => {
      return `${i}:00`;
    }),
  ];

  const postDunningBillingOptions = [
    {
      label: 'Anchor  on date of last billing failure',
      value: 'billing_failure',
    },
    {
      label: 'Anchor on date of  last billing success',
      value: 'billing_success',
    },
  ];

  const handleOrderAttributeChange = (e, index) => {
    let order_attributes = formData?.order_notes_attriburtes;
    order_attributes[index] = e;
    setFormData({ ...formData, order_notes_attriburtes: order_attributes });
  };

  const handleOrderAttributeDelete = (index) => {
    let order_attributes = formData?.order_notes_attriburtes;
    order_attributes.splice(index, 1);
    setFormData({ ...formData, order_notes_attriburtes: order_attributes });
  };

  const addMoreOrderNotesAttribute = () => {
    let order_attributes = formData?.order_notes_attriburtes;
    order_attributes.push('');
    setFormData({ ...formData, order_notes_attriburtes: order_attributes });
  };

  const handleSubmit = () => {
    fetch('/update_global_settings', {
      method: 'POST',
      body: JSON.stringify({
        shopify_domain: domain,
        global_setting: formData,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.status) {
          setToastContent('Saved Successfully ');
        }
      });
  };

  const fethGlobalSettings = () => {
    fetch(`/fetch_global_settings?shopify_domain=${domain}`, {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.global_setting) {
          setFormData(data?.global_setting);
        }
      });
  };

  useEffect(() => {
    fethGlobalSettings();
  }, []);

  return (
    <>
      {
        <>
          <Frame>
            {toastContent && (
              <Toast content={toastContent} onDismiss={toggleToastActive} />
            )}

            <div className="without_header_card">
              <Card
                actions={{
                  content: (
                    <div className="card_header_button_group">
                      <div className="card_header_buttons primary_button_group">
                        <HeaderButtons
                          headerButtons={headerButtons}
                          setHeaderButton={setHeaderButton}
                          headerButton={headerButton}
                        />
                      </div>
                    </div>
                  ),
                }}
              >
                {headerButton == 'Billing hour' ? (
                  <Card.Section>
                    <div className="setGlobalBillingHour">
                      <Select
                        label="Global Billing Hour"
                        value={formData?.billing_hour}
                        options={GlobalBillingHours}
                        onChange={(e) => {
                          setFormData({ ...formData, billing_hour: e });
                        }}
                        helpText={`All subscription orders will now be processed at ${formData?.billing_hour} UTC irrespective of the subscription creation time.`}
                      />
                    </div>
                    <div className="setGlobalBillingHour">
                      <Select
                        label="Post-dunning billing date"
                        value={formData?.post_dunning_billing}
                        options={postDunningBillingOptions}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            post_dunning_billing: e,
                          });
                        }}
                      />
                    </div>
                    {/* <div className='update_shipping_prices_wrapper'>
                            <h3>Update billing hour for existing subscriptions</h3>
                            <div className='update_billing_hour_button_wrapper global_setting_top_border'>
                              <Button >Update billing hour</Button>
                              <div className='message_wrapper hidden'>Operation in Progress</div>
                            </div>
                            <div className='message_helptext_wrapper'>
                              Clicking the above button will update the processing time of upcoming orders in the existing subscriptions as per the configured billing hour settings. This operation is useful in case some orders still getting placed outside of billing hour window.
                            </div>
                          </div> */}
                  </Card.Section>
                ) : headerButton == 'Product prices' ? (
                  <Card.Section>
                    <div className="setGlobalBillingHour">
                      <Checkbox
                        label="Automatically apply product price changes to subscriptions"
                        helpText="When you make changes to a product's price in the Shopify admin, we will apply the price change to all subscriptions containing the product."
                        checked={formData?.apply_product_price_changes}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            apply_product_price_changes: e,
                          });
                        }}
                      />
                    </div>
                    <div className="update_billing_hour_wrapper global_setting_top_border">
                      <Checkbox
                        label="Keep subscription discounts on every price change"
                        helpText="Discounts set for the subscription plan will be applied on the updated price"
                        checked={formData?.subscription_discount_every_price}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            subscription_discount_every_price: e,
                          });
                        }}
                      />
                      <h3>Discount behavior settings</h3>
                      <div className="discount_behavior_wrapper">
                        <RadioButton
                          label="Apply existing subscription discounts as currently configured on the product."
                          checked={
                            formData?.discount_behavior ===
                            'apply_subscription_discount_to_product'
                          }
                          id="apply_subscription_discount_to_product"
                          name="discount_behavior"
                          onChange={() => {
                            setFormData({
                              ...formData,
                              discount_behavior:
                                'apply_subscription_discount_to_product',
                            });
                          }}
                        />
                        <RadioButton
                          label="Recalculate discounts as per the selling plan associated with the product. If no applicable selling plan found, then the existing subscription discounts will be applied."
                          id="recalculate_discount_selling_plan"
                          name="discount_behavior"
                          checked={
                            formData?.discount_behavior ===
                            'recalculate_discount_selling_plan'
                          }
                          onChange={() => {
                            setFormData({
                              ...formData,
                              discount_behavior:
                                'recalculate_discount_selling_plan',
                            });
                          }}
                        />
                      </div>
                      <div className="global_setting_top_border global_setting_top_border">
                        <h3>
                          Update product prices for all existing subscriptions
                        </h3>
                        <div className="update_billing_hour_button_wrapper">
                          <Button>Update product prices</Button>
                          <div className="message_wrapper hidden">
                            Operation in Progress
                          </div>
                        </div>
                        <div className="message_helptext_wrapper">
                          Clicking the above button will update the
                          product/variant prices for all the existing
                          subscriptions as per the current prices set in your
                          shop catalogue.
                        </div>
                      </div>
                    </div>
                  </Card.Section>
                ) : headerButton == 'Shipping rates' ? (
                  <Card.Section>
                    <div className="setGlobalBillingHour">
                      <Checkbox
                        label="Update shipping cost for subscriptions after they get created"
                        helpText="This is useful when the customer places an order having both one-time purchase and subscription products. Customer may get free shipping for the original order but might be charged for subsequent billings if the subscription products value is not enough to get free shipping."
                        checked={formData?.update_shipping_cost_after_created}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            update_shipping_cost_after_created: e,
                          });
                        }}
                      />
                    </div>
                    <div className="setGlobalBillingHour global_setting_top_border">
                      <Checkbox
                        label="Update shipping cost when shipping address is changed in a subscription"
                        helpText="The shipping cost will be automatically updated in case of any product changes done or shipping address is updated in the subscription either via admin or customer portal."
                        checked={formData?.update_shipping_cost_address_changed}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            update_shipping_cost_address_changed: e,
                          });
                        }}
                      />
                    </div>
                    <div className="setGlobalBillingHour global_setting_top_border">
                      <Checkbox
                        label="Update shipping cost when a shipping profile is added or edited"
                        helpText="The shipping cost will be automatically updated when a shipping profile is added or edited in your shop."
                        checked={
                          formData?.update_shipping_cost_shipping_profile
                        }
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            update_shipping_cost_shipping_profile: e,
                          });
                        }}
                      />
                    </div>
                    <div className="setGlobalBillingHour global_setting_top_border">
                      <Checkbox
                        label="Update shipping cost when products are changed in a subscription"
                        helpText="The shipping cost will be automatically updated in case of any product changes done in the subscription either via admin or customer portal."
                        checked={formData?.update_shipping_cost_product_change}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            update_shipping_cost_product_change: e,
                          });
                        }}
                      />
                    </div>
                    <div className="update_shipping_price_wrapper global_setting_top_border">
                      <div className="update_billing_hour_wrapper">
                        <h3>
                          Update shipping prices for all existing subscriptions
                        </h3>
                        <div className="update_billing_hour_button_wrapper">
                          <Button>Recalculate shipping prices</Button>
                          <div className="message_wrapper hidden">
                            Operation in Progress
                          </div>
                        </div>
                        <div className="message_helptext_wrapper">
                          Clicking the above button will recalculate and update
                          the shipping prices for all the existing subscriptions
                          as per the current shipping rates added for your shop
                        </div>
                      </div>
                    </div>
                  </Card.Section>
                ) : headerButton == 'Order notes' ? (
                  <Card.Section>
                    <div className="setGlobalBillingHour">
                      <Checkbox
                        label="Replace order note when a subscription is created"
                        helpText="This is applies when the current order note is not relevant to the subscription order created by Chargezen."
                        checked={
                          formData?.clear_order_note_subscription_created
                        }
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            clear_order_note_subscription_created: e,
                          });
                        }}
                      />
                    </div>
                    <div className="setGlobalBillingHour global_setting_top_border">
                      <Checkbox
                        label="Remove specific order attribute(s) when a subscription order is created"
                        helpText="Define specific order attributes to be removed so they don't appear on future subscription orders placed by Chargezen. You need to add the exact attribute name which you can copy from the Shopify order page."
                        checked={
                          formData?.clear_specific_order_note_subscription_created
                        }
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            clear_specific_order_note_subscription_created: e,
                          });
                        }}
                      />
                      {formData?.clear_specific_order_note_subscription_created && (
                        <div className="order_notes_attribute_wrapper">
                          <div className="sub_attributes_wrapper">
                            {formData?.order_notes_attriburtes?.map(
                              (order, i) => {
                                return (
                                  <div className="order_attributes_wrapper">
                                    <TextField
                                      type="text"
                                      value={order}
                                      onChange={(e) => {
                                        handleOrderAttributeChange(e, i);
                                      }}
                                    />
                                    {i != 0 && (
                                      <button
                                        className="order_attribute_destroy_button"
                                        onClick={() => {
                                          handleOrderAttributeDelete(i);
                                        }}
                                      >
                                        {
                                          <Icon
                                            source={DeleteMajor}
                                            color="base"
                                          />
                                        }
                                      </button>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="add_more_attribute_button_container">
                            <Button
                              onClick={() => {
                                addMoreOrderNotesAttribute();
                              }}
                            >
                              Add more attribute
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card.Section>
                ) : headerButton == 'Delivery methods' ? (
                  <Card.Section>
                    <div className="setGlobalBillingHour">
                      <Checkbox
                        label="Yes, we have multiple delivery methods available in the shop shipping and delivery settings."
                        helpText="Select this checkbox to confirm that you have more than one delivery methods (Shipping, Local delivery and Pickup) enabled on in your store settings. Once enabled, then only we will show the “Change delivery method” button inside the subscription."
                        checked={formData?.multiple_delivery_methods}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            multiple_delivery_methods: e,
                          });
                        }}
                      />
                    </div>
                  </Card.Section>
                ) : (
                  <></>
                )}
                <Card.Section>
                  <div className="save_button_wrapper">
                    <Button onClick={() => handleSubmit()}>Save</Button>
                  </div>
                </Card.Section>
              </Card>
            </div>
          </Frame>
        </>
      }
    </>
  );
};

export default GlobalBillingHour;
