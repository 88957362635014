import React, { useState, useCallback, useEffect } from 'react';
import { Card, Tabs, Frame, Page, Modal, Toast } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import SlideItem from './slideItem';
import EditTemplate from './EditTemplate/Index';
import PreviewUpsell from './PreviewUpsell';
import AlreadyCreatedBundles from './AlreadyCreatedBundles';
import './style.css';
import { useHistory, useParams } from 'react-router-dom';
import axiosconfig from '../axiosconfig';
function SubscriptionBundles({ i18n, domain, formatDate }) {
  const [selected, setSelected] = useState(0);
  const [editBundle, setEditBundle] = useState();
  const { edit_id, template_id } = useParams();
  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(() => setToastContent(), []);
  const history = useHistory();

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
  }, []);

  const [editTemplate, setEditTemplate] = useState(false);
  const [previewTemplate, setpreviewTemplate] = useState(false);
  const [allBundles, setAllBundles] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState({});

  const tabs = [
    {
      id: 'all-customers-1',
      content: 'All',
      accessibilityLabel: 'All customers',
      panelID: 'all-customers-content-1',
    },
    {
      id: 'Food-&-Beverage',
      content: 'Food & Beverage',
      panelID: 'Food-&-Beverage',
    },
    {
      id: 'Health-&-Beauty',
      content: 'Health & Beauty',
      panelID: 'Health-&-Beauty',
    },
    {
      id: 'Gift-Guide',
      content: 'Gift Guide',
      panelID: 'Gift-Guide',
    },
    {
      id: 'Fashion',
      content: 'Fashion',
      panelID: 'Fashion',
    },
    {
      id: 'Furniture',
      content: 'Furniture',
      panelID: 'Furniture',
    },
    {
      id: 'Technology',
      content: 'Technology',
      panelID: 'Technology',
    },
  ];
  const [templates, setTemplates] = useState([]);
  const tabsData = [
    {
      images: [
        'https://dashboard.quizkitapp.com/_next/image?url=https%3A%2F%2Fquiz-kit-files.s3.us-east-2.amazonaws.com%2Fimages%2F1%2FuzIGKu8QU9oavdeUgBVA&w=1920&q=75',
        'https://dashboard.quizkitapp.com/_next/image?url=https%3A%2F%2Fquiz-kit-files.s3.us-east-2.amazonaws.com%2Fimages%2F1%2Fh3sNy4o9Sxwzuz5l6EpQ&w=1920&q=75',
      ],
      name: 'Upsells Template #1',
      categories_data: 'Health & Beauty',
    },
  ];

  const fetchTemplates = () => {
    axiosconfig.get('/fetch_bundle_templates')
      .then((res) => {
        setTemplates(res?.data?.templates);
      });
  };

  const fetchAllBundles = () => {
    axiosconfig.get('/fetch_all_bundles?shopify_domain=' + domain)
      .then((res) => {
        setAllBundles(res?.data?.bundles);
      });
  };

  useEffect(() => {
    fetchTemplates();
    fetchAllBundles();
  }, []);

  useEffect(() => {
    if (template_id) {
      setEditTemplate(template_id);
    }
    if (edit_id) {
      setEditBundle(edit_id);
    }
  }, [template_id, edit_id]);

  return editTemplate || editBundle ? (
    <EditTemplate
      setEditTemplate={setEditTemplate}
      i18n={i18n}
      domain={domain}
      editTemplate={editTemplate}
      editBundle={editBundle}
      setEditBundle={setEditBundle}
      fetchAllBundles={fetchAllBundles}
      setToastContent={setToastContent}
      hideSaveSuccess={hideSaveSuccess}
      toastContent={toastContent}
    />
  ) : (
    <Frame>
      {toastContent && (
        <Toast content={toastContent} onDismiss={hideSaveSuccess} />
      )}

      <div className="rewards_template_wrapper zenn_portal_wrapper subscription_bundle_container">
        <div className="rewards_choose_template show_content_2">
          <Card>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <Card.Section>
                {allBundles?.length > 0 && (
                  <>
                    <div className="all_offers_container">
                      <h4 className="offer_header live_offer_header">
                        Bundles
                      </h4>

                      {allBundles?.map((bundle) => {
                        return (
                          <AlreadyCreatedBundles
                            bundle={bundle}
                            setEditBundle={setEditBundle}
                            setToastContent={setToastContent}
                            fetchAllBundles={fetchAllBundles}
                            formatDate={formatDate}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </Card.Section>
            </Tabs>
          </Card>
        </div>
        <div className="rewards_choose_template show_content_2 upsells_templates_container">
          <Card>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <Card.Section title={tabs[selected].content}>
                {/* <p>Tab {selected} selected</p> */}
                <div class="sort-by">
                  <div class="inner-wrap-sort">
                    <label>Sort by:</label>
                    <select
                      id="PolarisSelect1"
                      class="Polaris-Select__Input"
                      aria-invalid="false"
                    >
                      <option value="popular">Popularity</option>
                      <option value="recent">Most recent</option>
                    </select>
                    <svg
                      viewBox="0 0 20 20"
                      class="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M7.676 9h4.648c.563 0 .879-.603.53-1.014l-2.323-2.746a.708.708 0 0 0-1.062 0l-2.324 2.746c-.347.411-.032 1.014.531 1.014Zm4.648 2h-4.648c-.563 0-.878.603-.53 1.014l2.323 2.746c.27.32.792.32 1.062 0l2.323-2.746c.349-.411.033-1.014-.53-1.014Z"></path>
                    </svg>
                  </div>
                </div>
                {templates?.map((data) => {
                  return (
                    <SlideItem
                      data={data}
                      id={data.id}
                      setEditTemplate={setEditTemplate}
                      setpreviewTemplate={setpreviewTemplate}
                    />
                  );
                })}
              </Card.Section>
            </Tabs>
          </Card>
        </div>
      </div>
      <Modal
        open={previewTemplate}
        onClose={() => {
          setpreviewTemplate(!previewTemplate);
        }}
        title="Chargezen Upsell"
      >
        <Modal.Section>
          <PreviewUpsell domain={domain} previewTemplate={previewTemplate} />
        </Modal.Section>
      </Modal>
    </Frame>
  );
}

export default SubscriptionBundles;
