import React, { useCallback, useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Card,
    Frame,
    Icon,
    Page,
    Tabs,
    Toast,
} from '@shopify/polaris';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "./SubscriptionViewLoading.css"

function SubscriptionViewLoading(props) {
    const { token, domain, setToken, i18n, contractToken, formatDate } = props;
    const history = useHistory();
    const [apiData, setApiData] = useState();
    const [paymentObj, setPaymentObj] = useState();
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [toastContent, setToastContent] = useState('');
    const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);
    const [editRestartDate, setEditRestartDate] = useState(false);
    const [showAllLines, setShowAllLines] = useState(false);
    const RenderCustomHeader = () => {
        return (
            <SkeletonTheme baseColor="#73767829" highlightColor="#73767805" borderRadius="0">
                <div className="chargezen_custom_page_header">
                    <div className="page_title_wrapper">
                        <div className="back_button_wrapper">
                            <div
                                className="back-button pointer"
                                onClick={() => {
                                    if (contractToken) {
                                        history.push('/customersTab/contracts');
                                    } else {
                                        setToken();
                                    }
                                }}
                            >
                                <Icon source={MobileBackArrowMajor} color="base" />
                            </div>
                            <div class="text_wrapper page_heading_title_wrapper">
                                <div
                                    class="Polaris-Header-Title__TitleAndSubtitleWrapper"
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <div class="Polaris-Header-Title">
                                        <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                                            <span class="Polaris-TextStyle--variationStrong">
                                                <div class="back_button_wrapper">
                                                    {' '}
                                                    <div
                                                        class="text_wrapper page_heading_title_wrapper"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <span>
                                                            <Skeleton count={1} style={{ width: "231px", height: "31px" }} />
                                                        </span>

                                                        <div className="shpoify_style_button_wrapper">
                                                            {
                                                                <>
                                                                    <Skeleton count={1} style={{ width: "65px", height: "30px" }} />
                                                                    <Skeleton count={1} style={{ width: "65px", height: "30px" }} />
                                                                </>

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sidebar_summary header_description">
                    </div>
                </div>
            </SkeletonTheme>
        );
    };

    return (
        <Frame>
            <SkeletonTheme baseColor="#73767829" highlightColor="#73767805" borderRadius="0">
                <div className="container-chargen">
                    <Frame>
                        <Page>
                            <RenderCustomHeader />
                            <div className="billing-info">
                                <div className="col-8 subscription-col bg-white padding-20 common-radius">
                                    <div className="subscription-header flex">
                                        <h2>
                                            <Skeleton count={1} style={{ width: "120px", height: "40px" }} />
                                        </h2>
                                        <div>
                                            <span> <Skeleton count={1} style={{ width: "120px", height: "20px" }} /></span>
                                        </div>
                                        <div>
                                            <b><Skeleton count={1} style={{ width: "82px", height: "20px" }} /></b>
                                            <span><Skeleton count={1} style={{ width: "81px", height: "14px" }} /></span>
                                        </div>
                                    </div>

                                    <div
                                        div
                                        class={`meal-saver flex`}
                                    >
                                        <div>
                                            <p>
                                                <Skeleton count={1} style={{ width: "300px", height: "36px" }} />
                                            </p>
                                            <p><Skeleton count={1} style={{ width: "300px", height: "24px" }} /></p>
                                        </div>
                                        <div>
                                            <div class="item_price">
                                                <Skeleton count={1} style={{ width: "200px", height: "20px" }} />
                                            </div>
                                            <Skeleton count={1} style={{ width: "94px", height: "21px" }} />
                                        </div>
                                    </div>

                                    <div className="product-option-button">
                                        <Skeleton count={1} style={{ width: "100%", height: "35px" }} />
                                        <Skeleton count={1} style={{ width: "100%", height: "35px" }} />
                                        <Skeleton count={1} style={{ width: "100%", height: "35px" }} />
                                        <Skeleton count={1} style={{ width: "100%", height: "35px" }} />
                                    </div>
                                </div>
                                <div className="col-4 billing-detaisl bg-white padding-20 common-radius contract_overview_container">
                                    <div className="flex header-bill">
                                        <Skeleton count={1} style={{ width: "125px", height: "20px" }} />
                                    </div>
                                    <div className="contract_overview_content">
                                        <div className="overview_row">
                                            <Skeleton count={1} style={{ width: "100%", height: "18px" }} />
                                        </div>

                                        <>
                                            <div className="overview_row">
                                                <Skeleton count={1} style={{ width: "100%", height: "18px" }} />
                                            </div>
                                            <div className="overview_row">
                                                <Skeleton count={1} style={{ width: "100%", height: "18px" }} />
                                            </div>
                                        </>

                                        <div className="overview_row">
                                            <Skeleton count={1} style={{ width: "100%", height: "18px" }} />
                                        </div>
                                    </div>
                                    <div className="overview_container_footer">
                                        <div className="overview_row">
                                            <Skeleton count={1} style={{ width: "100%", height: "18px" }} />
                                        </div>
                                        <div className="overview_row">
                                            <Skeleton count={1} style={{ width: "100%", height: "18px" }} />
                                        </div>
                                        <div className="overview_row">
                                            <Skeleton count={1} style={{ width: "100%", height: "18px" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="order-info bg-white padding-20">
                                <div className="flex">
                                    {[1, 2, 3, 4, 5, 6]?.map((i) => {
                                        return (
                                            <div className="order-inner">
                                                <div className="order-icon">
                                                    <Skeleton count={1} circle={true} style={{ width: "35px", height: "35px" }} />
                                                </div>
                                                <div class="order-type">
                                                    <Skeleton count={1} style={{ width: "120px", height: "18px " }} />
                                                    <Skeleton count={1} style={{ width: "120px", height: "18px " }} />
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>

                            <div className="tabbing-and-adresses">
                                <div className="flex">
                                    <div className="col-left-tabbing">
                                        <div className="payment-tabs-wrapper padding-20 common-radius bg-white">
                                            <Card
                                                sectioned
                                                title={
                                                    <Skeleton count={1} style={{ width: "240px", height: "20px" }} />
                                                }
                                            >
                                                <Card.Section>
                                                    <div>
                                                        {[1, 2, 3, 4, 5]?.map(() => {
                                                            return (
                                                                <Skeleton count={1} style={{ width: "100%", height: "30px " }} />
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </Card.Section>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="col-right-address">
                                        {
                                            [1, 2, 3]?.map(() => {
                                                return (
                                                    <div className="card-charzen delivery-address padding-20 common-radius bg-white skeleton">
                                                        <div className="billing-address">
                                                            <Skeleton count={1} style={{ width: "100px", height: "20px " }} />
                                                            <p>
                                                                <Skeleton count={1} style={{ width: "100%", height: "18px " }} />
                                                                <Skeleton count={1} style={{ width: "100%", height: "18px " }} />
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Page>
                    </Frame>
                </div>
            </SkeletonTheme>

        </Frame>
    );
}
export default SubscriptionViewLoading;
