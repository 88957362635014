import React, { useState, useEffect, useCallback, useRef } from 'react';

import {
  Card,
  Select,
  TextField,
  ButtonGroup,
  Button,
  Stack,
  Heading,
  TextStyle,
  FormLayout,
  Layout,
  TextContainer,
  Icon,
} from '@shopify/polaris';
import Switch from 'react-switch';
import { CircleLeftMajor } from '@shopify/polaris-icons';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
// import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { data } from 'jquery';
import PreviewEmail from './PreviewEmail';
import { gql } from '@apollo/client';
import EmailEditor from 'react-email-editor';
import PixelIcon from '../../assets/images/PixelIcon';
import HideSideNav from '../helpers/HideSideNav';
import { useParams, useHistory } from 'react-router-dom';
import { allLanguages } from '../HelperMethods/CommonValues';

const EmailNotificationsDetails = (props) => {
  const {
    i18n,
    domain,
    values,
    touched,
    errors,
    setFieldValue,
    index,
    setSelectedIndex,
    handleSubmit,
    setUpdateSetting,
    filteredEmailNotifications,
    setFilteredEmailNotifications,
  } = props;
  const emailEditorRef = useRef(null);
  const [submitForm, setSubmitForm] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();
  const { edit_id } = useParams();
  const history = useHistory();
  const [currrentLanguage, setCurrentLaguage] = useState();
  const [currentEmail, setCurrentEmail] = useState(
    filteredEmailNotifications[index]
  );

  const toRedirect = `${window.location.pathname
    .replace(`/${edit_id}`, '')
    ?.replace(`${domain?.replace('.myshopify.com', '')}/`, '')}`;

  const exportHtml = async () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      setFieldValue(`emailNotifications[${currentIndex}].emailMessage`, html);
      setFieldValue(
        `emailNotifications[${currentIndex}].designJson`,
        JSON.stringify(design)
      );
      setSubmitForm(true);
    });
  };
  const onLoad = (jsonData = null) => {
    // editor instance is created
    // you can load your template here;
    if (values?.emailNotifications[currentIndex]?.designJson) {
      try {
        const templateJson = jsonData
          ? JSON.parse(jsonData)
          : JSON.parse(values.emailNotifications[currentIndex]?.designJson);
        emailEditorRef?.current?.editor?.loadDesign(templateJson);
      } catch (e) {
        console.log('error', e);
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, [currentIndex, values]);

  const onReady = () => {
    // editor is ready
  };

  const codeTextArea = useRef(null);
  const [valueFromName, setValueFromName] = useState();
  const [showEditorCode, setShowEditorCode] = useState(false);
  const [previewHtml, setPreviewHtml] = useState('');
  const [previewActive, setPreviewActive] = useState(false);
  const handleChangeFormName = useCallback(
    (newValue) => setValueFromName(newValue),
    []
  );
  const [selectedSettingEnabled, setSelectedSettingEnabled] = useState(false);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const handleSelectChangeSettingEnabled = useCallback(
    (value) => setSelectedSettingEnabled(value),
    []
  );

  const html_text = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="utf-8">
      <title><!-- Insert your title here --></title>
  </head>
  <body>
      <!-- Insert your content here -->
  </body>
  </html>`;

  useEffect(() => {
    if ((index || index === 0) && currentEmail) {
      let i = values?.emailNotifications.findIndex(
        (e) => +e.id == +currentEmail.id
      );
      setCurrentIndex(i);
    } else {
      setCurrentIndex();
    }
  }, [index]);

  useEffect(() => {
    setUpdateSetting(gql`
      mutation ($input: UpdateSettingInput!) {
        updateSetting(input: $input) {
          setting {
            emailNotifications {
              name
              status
              fromName
              fromEmail
              emailSubject
              emailMessage
              slug
              description
              designJson
            }
          }
        }
      }
    `);
    const contentBlock = htmlToDraft(
      values.emailNotifications[currentIndex]?.emailMessage || ''
    );
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, []);

  const submit = async () => {
    await handleSubmit();
    setSelectedIndex(null);
    setSubmitForm(false);

    if (edit_id) {
      history.push(toRedirect);
    }
  };

  useEffect(() => {
    if (submitForm) {
      submit();
    }
  }, [submitForm]);

  const toggleEditorCode = () => {
    if (showEditorCode) {
      const contentBlock = htmlToDraft(
        values.emailNotifications[currentIndex]?.emailMessage || ''
      );
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
    setShowEditorCode(!showEditorCode);
  };

  const ShowEditorCode = () => (
    <div className="rdw-option-wrapper" onClick={toggleEditorCode}>
      {'</>'}
    </div>
  );

  const uploadCallback = (file, callback) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onloadend = async () => {
        const form_data = new FormData();
        form_data.append('file', file);
        let res = await uploadFile(form_data);
        // setValue("thumbnail", res);
        resolve({ data: { link: res } });
      };
      reader.readAsDataURL(file);
    });
  };
  const config = {
    image: {
      uploadCallback: uploadCallback,
      defaultSize: { height: 'auto', width: '100%' },
    },
  };

  const uploadFile = async (formData) => {
    let imageURL;
    await fetch('/email_images/upload_email_image', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        imageURL = data?.image_url;
      });
    return imageURL;
  };

  const fetchCurrentLanguageEmail = () => {
    if (currrentLanguage) {
      fetch(`/getLanguageEmailNotification`, {
        method: 'POST',
        body: JSON.stringify({
          shop: domain,
          locale: currrentLanguage,
          current_id: edit_id,
          email_name: values.emailNotifications[currentIndex]?.name,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((data) => {
          if (data?.error) {
            console.log('Something went wrong', data?.error);
          } else {
            setFieldValue(
              `emailNotifications[${currentIndex}]`,
              data?.emailNotification
            );
            setCurrentEmail({ ...data?.emailNotification });
            onLoad(data?.emailNotification?.designJson);
          }
        });
    }
  };

  useEffect(() => {
    fetchCurrentLanguageEmail();
  }, [currrentLanguage]);

  return (
    <>
      <div>
        <PreviewEmail
          i18n={i18n}
          previewActive={previewActive}
          setPreviewActive={setPreviewActive}
          previewHtml={previewHtml}
        />
        <Card
          title={
            <div className="heading_title">
              <PixelIcon />
              {i18n.t('email_notification')}
            </div>
          }
        >
          <Card.Section>
            <Stack vertical>
              <Stack.Item>
                <div
                  className="back-btn-container"
                  onClick={() => {
                    setSelectedIndex(null);
                    if (edit_id) {
                      history.push(toRedirect);
                    }
                  }}
                >
                  <Icon source={CircleLeftMajor} color="base" />
                  <p>Go Back</p>
                </div>
              </Stack.Item>
              <Stack.Item>
                <div className="flex-wrapper justify-between email_notfication_custom_field_wrapper">
                  <div className="custom_field_row">
                    <div className="custom_label">Status</div>
                    <Button
                      primary
                      fullWidth
                      onClick={() => {
                        setFieldValue(
                          `emailNotifications[${currentIndex}].status`,
                          !values.emailNotifications[currentIndex]?.status
                        );
                      }}
                    >
                      {values.emailNotifications[currentIndex]?.status
                        ? 'Enabled'
                        : 'Disabled'}
                    </Button>
                  </div>
                  <div className="custom_field_row">
                    <div className="custom_label">Action</div>
                    <Button fullWidth>Send a test email</Button>
                  </div>
                </div>
              </Stack.Item>
              <Stack.Item>
                <Heading>
                  {values.emailNotifications[currentIndex]?.name}
                </Heading>
              </Stack.Item>
              <Stack.Item>
                <TextStyle variation="subdued">
                  {values.emailNotifications[currentIndex]?.description}
                </TextStyle>
              </Stack.Item>
              <Stack.Item>
                <Heading h4>{i18n.t('email_content')}</Heading>
              </Stack.Item>
              <Stack.Item>
                <FormLayout>
                  <Select
                    label="Language"
                    options={allLanguages}
                    onChange={(e) => {
                      setCurrentLaguage(e);
                      setFieldValue(
                        `emailNotifications[${currentIndex}].locale`,
                        e
                      );
                    }}
                    value={values.emailNotifications[currentIndex]?.locale}
                  />
                </FormLayout>
              </Stack.Item>
              <Stack.Item>
                <FormLayout>
                  <TextField
                    label="From Name"
                    value={
                      values.emailNotifications[currentIndex]?.fromName
                        ? values.emailNotifications[currentIndex]?.fromName
                        : ''
                    }
                    onChange={(e) =>
                      setFieldValue(
                        `emailNotifications[${currentIndex}].fromName`,
                        e
                      )
                    }
                    name="from_name"
                  />
                  <TextField
                    label="From Email"
                    value={
                      values.emailNotifications[currentIndex]?.fromEmail
                        ? values.emailNotifications[currentIndex]?.fromEmail
                        : null
                    }
                    onChange={(e) =>
                      setFieldValue(
                        `emailNotifications[${currentIndex}].fromEmail`,
                        e
                      )
                    }
                    inputMode="email"
                    name="from_email"
                  />
                  <TextField
                    label="Email Subject"
                    value={
                      values.emailNotifications[currentIndex]?.emailSubject
                        ? values.emailNotifications[currentIndex]?.emailSubject
                        : null
                    }
                    onChange={(e) =>
                      setFieldValue(
                        `emailNotifications[${currentIndex}].emailSubject`,
                        e
                      )
                    }
                    name="email_subject"
                  />

                  <label>Email Message</label>
                  <div className="email_editor" style={{ overflow: 'auto' }}>
                    <EmailEditor
                      ref={emailEditorRef}
                      onLoad={onLoad}
                      onReady={onReady}
                    />
                  </div>
                  {/* <div>
                        <button onClick={exportHtml}>Export HTML</button>
                      </div> */}
                  {/* <Editor
                        toolbar={config}
                        editorState={editorState}
                        defaultContentState={
                          values.emailNotifications[currentIndex]?.emailMessage
                        }
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="draftEditorWrapper"
                        editorClassName={showEditorCode ? 'editorHide' : 'editor'}
                        customBlockRenderFunc={customBlockRenderFunc}
                        onEditorStateChange={(e) => {
                          setEditorState(e);
                          setFieldValue(
                            `emailNotifications[${currentIndex}].emailMessage`,
                            draftToHtml(convertToRaw(e.getCurrentContent()))
                          );
                        }}
                        toolbarCustomButtons={[<ShowEditorCode />]}
                        multiline={15}
                      />
                      {showEditorCode && (
                        <textarea
                          ref={codeTextArea}
                          value={values.emailNotifications[currentIndex]?.emailMessage}
                          style={{
                            width: '100%',
                            border: 'none',
                            height: '10rem',
                          }}
                          onChange={(e) => {
                            setFieldValue(
                              `emailNotifications[${currentIndex}].emailMessage`,
                              e.target.value
                            );
                          }}
                        />
                      )} */}

                  {/* <TextField
                  label="Email Message"
                  placeholder={html_text}
                  value={values.emailNotifications[currentIndex]?.emailMessage}
                  onChange={(e) =>
                    setFieldValue(
                      `emailNotifications[${currentIndex}].emailMessage`,
                      e
                    )
                  }
                  multiline={15}
                  name="email_message"
                /> */}
                </FormLayout>
              </Stack.Item>
              <Stack.Item>
                <ButtonGroup>
                  <Button
                    primary
                    onClick={() => {
                      setSelectedIndex(null);
                      if (edit_id) {
                        history.push(toRedirect);
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={() => exportHtml()}>Save Changes</Button>
                </ButtonGroup>
              </Stack.Item>
            </Stack>
          </Card.Section>
        </Card>
      </div>
      <div>
        {/* <Card>
          <Card.Section>
            <TextContainer>
              <Heading h4>Status</Heading>
              <Stack distribution="equalSpacing">
                {values.emailNotifications[currentIndex]?.status ? (
                  <Button primary onClick={() => { }}>
                    Enabled
                  </Button>
                ) : (
                  <Button onClick={() => { }}>Disabled</Button>
                )}
                <Switch
                  // onChange={setFieldValue(
                  //   `emailNotifications[${values.emailNotifications[currentIndex]}].status`,
                  //   !values.emailNotifications[currentIndex]?.status
                  // )}
                  checked={values.emailNotifications[currentIndex]?.status}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                />
              </Stack>
              <br />
              <TextStyle variation="subdued">
                This notification is enabled and will be sent to customers when
                conditions apply.
              </TextStyle>
            </TextContainer>
          </Card.Section>
        </Card> */}

        {process.env.APP_TYPE == 'public' && (
          <Card>
            <Card.Section>
              <TextContainer>
                <Heading h4>Need help with ChargeZen variables?</Heading>
                <br />
                <TextStyle variation="subdued">
                  We’ve compiled a list of all available CharegeZen variables
                  along with additional information and help. You can check out
                  the guide here.
                </TextStyle>
              </TextContainer>
            </Card.Section>
          </Card>
        )}
        {/* <Card>
          <Card.Section>
            <TextContainer>
              <Heading h4>Actions</Heading>
              <Button fullWidth>Send a test email</Button>
              <Button fullWidth
                onClick={() => {
                  emailEditorRef.current.editor.exportHtml((data) => {
                    const { design, html } = data;
                    setPreviewHtml(html)
                  });
                  setPreviewActive(true)
                }
                }
              >
                Preview
              </Button>
            </TextContainer>
          </Card.Section>
        </Card> */}
      </div>

      <HideSideNav />
    </>
  );
};
export default EmailNotificationsDetails;
