import { Frame, Page, Tabs, Layout, Icon, Card, Button } from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router';

import CancellationReasonForm from './CancellationReasons/CancellationReasonForm';
import CancellationReasons from './CancellationReasons/CancellationReasons';
import CustomKeywords from './CustomKeywords/CustomKeywords';
import CustomKeywordsForm from './CustomKeywords/CustomKeywordsForm';
import CustomMessage from './CustomMessage/CustomMessage';
import EditSmartyMessage from './SmartyMessage/EditSmartyMessage';
import SmartyMessage from './SmartyMessage/SmartyMessage';
import '../smarty/tiazan.css';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import CollectSubscribers from './CollectSubscribers';
import FlowIndex from './Flow';
import FlowForm from './Flow/NewForm';
import NewFlowForm from './Flow/NewForm';
import UpdateFlowForm from './Flow/UpdateForm';
import PixelIcon from '../../assets/images/PixelIcon';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import { useParams, useHistory } from 'react-router-dom';

const Smarty = ({ handleBack, i18n, templateType, formatDate, domain }) => {
  const { edit_id } = useParams();
  const location = useLocation();
  const [selectedTitleTab, setSelectedTitleTab] = useState(
    location.state?.tabIndex || 0
  );

  useEffect(() => {
    if (
      location?.state?.tabIndex &&
      selectedTitleTab !== location?.state?.tabIndex
    ) {
      setSelectedTitleTab(location.state.tabIndex);
    }
  }, [location?.state]);
  // const handleTabChange = useCallback((selectedTabIndex) => {
  //   setSelectedTitleTab(selectedTabIndex);
  // }, []);

  const [headerButton, setHeaderButton] = useState(0);

  useEffect(() => {
    setSelectedTitleTab(headerButton);
  }, [headerButton]);
  const headerButtons = [
    {
      val: 0,
      name: 'Transactional messages',
    },
    ...(process.env.APP_TYPE == 'public'
      ? [
        {
          val: 1,
          name: 'Quick response phrases',
        },
        {
          val: 2,
          name: 'Custom Keywords',
        },
      ]
      : []),
    {
      val: 4,
      name: 'Subscribe keywords',
    },
    {
      val: 3,
      name: 'Cancellation Reasons',
    },
    // {
    //   val: 4,
    //   name: 'Collect Subscribers',
    // },
    // {
    //   val: 5,
    //   name: 'Flow',
    // },
  ];

  const [editId, setEditId] = useState('');
  const [showEditPage, setShowEditPage] = useState(false);
  const history = useHistory();
  const domainReplace = domain.replace('.myshopify.com', '')
  const handleCloseEditPage = useCallback(() => {
    setShowEditPage(false);
    setEditId('');
    if (edit_id) {
      history.push(
        window.location.pathname?.replace(`/${edit_id}`, '').replace(`/${domainReplace}`, '').replace('//', '/')
      );
    }
  }, [setShowEditPage, editId]);

  const handleEditPage = useCallback(
    (ID) => {
      history.push(`${window.location.pathname?.replace(`/${domainReplace}`, '')}/${ID}`.replace('//', '/'));
      // setEditId(ID);
      // setShowEditPage(true);
    },
    [setEditId, setShowEditPage]
  );

  useEffect(() => {
    setShowEditPage(false);
    setEditId('');
  }, [selectedTitleTab]);

  useEffect(() => {
    if (edit_id) {
      setEditId(edit_id);
      setShowEditPage(true);
    }
  }, [edit_id]);

  const SamrtyBody = () => {
    return (
      <Card.Section>
        <div className="tizen-page">
          {selectedTitleTab === 0 && (
            <>
              {showEditPage ? (
                <EditSmartyMessage
                  i18n={i18n}
                  id={editId}
                  handleClose={handleCloseEditPage}
                  domain={domain}
                />
              ) : (
                <SmartyMessage
                  i18n={i18n}
                  handleEditSmartyMessage={handleEditPage}
                  templateType={templateType}
                  domain={domain}
                />
              )}
            </>
          )}
          {(selectedTitleTab === (process.env.APP_TYPE == 'public' && 1) || selectedTitleTab === 4) && (
            <>
              {showEditPage ? (
                <EditSmartyMessage
                  i18n={i18n}
                  id={editId}
                  handleClose={handleCloseEditPage}
                  domain={domain}
                />
              ) : (
                <CustomMessage
                  i18n={i18n}
                  handleEditSmartyMessage={handleEditPage}
                  formatDate={formatDate}
                  domain={domain}
                />
              )}
            </>
          )}
          {selectedTitleTab === (process.env.APP_TYPE == 'public' && 2) && (
            <>
              {showEditPage ? (
                <CustomKeywordsForm
                  i18n={i18n}
                  id={editId}
                  handleClose={handleCloseEditPage}
                />
              ) : (
                <CustomKeywords
                  i18n={i18n}
                  handleEditCustomKewords={handleEditPage}
                />
              )}
            </>
          )}
          {selectedTitleTab === (process.env.APP_TYPE == 'public' ? 3 : 1) && (
            <>
              {showEditPage && (
                <CancellationReasonForm
                  i18n={i18n}
                  id={editId}
                  handleClose={handleCloseEditPage}
                />
              )}
              <CancellationReasons
                i18n={i18n}
                handleEditCancellation={handleEditPage}
              />
            </>
          )}
          {selectedTitleTab === (process.env.APP_TYPE == 'public' ? 4 : 2) && (
            <CollectSubscribers i18n={i18n} />
          )}
          {selectedTitleTab === (process.env.APP_TYPE == 'public' ? 5 : 3) && (
            <>
              {showEditPage ? (
                editId ? (
                  <UpdateFlowForm
                    i18n={i18n}
                    id={editId}
                    handleClose={handleCloseEditPage}
                  />
                ) : (
                  <NewFlowForm
                    i18n={i18n}
                    handleEditFlow={handleEditPage}
                    handleClose={handleCloseEditPage}
                  />
                )
              ) : (
                <FlowIndex i18n={i18n} handleEditFlow={handleEditPage} />
              )}
            </>
          )}
        </div>
      </Card.Section>
    );
  };

  const RenderCustomHeader = () => {
    return (
      <div className="chargezen_custom_page_header">
        <div className="page_title_wrapper">
          <div className="back_button_wrapper">
            <div
              className="back-button pointer"
              onClick={() => {
                let toRedirect = `/smartyoverview`;
                history.push(toRedirect);
              }}
            >
              <Icon source={MobileBackArrowMajor} color="base" />
            </div>
            <div class="text_wrapper page_heading_title_wrapper">
              <div
                class="Polaris-Header-Title__TitleAndSubtitleWrapper"
                style={{
                  width: '100%',
                }}
              >
                <div class="Polaris-Header-Title">
                  <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                    <span class="Polaris-TextStyle--variationStrong">
                      <div class="back_button_wrapper">
                        <div
                          class="text_wrapper page_heading_title_wrapper"
                          style={{
                            width: '100%',
                          }}
                        >
                          <span>
                            {
                              edit_id ?
                                ''
                                :
                                'SMS Messages'
                            }
                          </span>
                          {

                            <div className="shpoify_style_button_wrapper">
                              <Button
                                onClick={() => {

                                }}
                              >
                                Settings
                              </Button>
                              <Button
                                onClick={() => {

                                }}
                              >
                                Create flow
                              </Button>
                            </div>
                          }
                        </div>
                      </div>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar_summary header_description">
          Customize transactional SMS messages; quick response phrases, subscribe keywords & custom keywords.
        </div>
      </div>
    );
  };
  return (
    <div className="smart_messages_wrapper">
      <Frame>
        {templateType == 'loyalty' ? (
          <SamrtyBody />
        ) : (
          <Page>
            <RenderCustomHeader />
            <>
              <div className={`chargezen_full_width_main_container`}>
                {/* <div
                    className={`${
                      detailView ? 'sidebar_summary_right_section' : 'hidden'
                    }`}
                  >
                    <SummaryComponent
                      {...{ i18n, summaryView, setSummaryView }}
                    />
                  </div> */}
                <div className={`chargezen_full_width_container`}>
                  <div className={`chargezen_full_width_wrapper`}>
                    <div className="without_header_card">
                      <Card
                        actions={{
                          content: (
                            <div className="card_header_button_group">
                              <div className="card_header_buttons primary_button_group">
                                <HeaderButtons
                                  headerButtons={headerButtons}
                                  setHeaderButton={setHeaderButton}
                                  headerButton={headerButton}
                                />
                              </div>
                            </div>
                          ),
                        }}
                      >
                        <SamrtyBody />
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Page>
        )}
      </Frame>
    </div>
  );
};

export default Smarty;
