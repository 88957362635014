import { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { AppProvider, Link } from "@shopify/polaris";
import enTranslations from '@shopify/polaris/locales/en.json';
import './App.css';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, createHttpLink } from "@apollo/client";
import Layout from "./components/layout/Layout";
import { I18n } from "i18n-js";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import '@shopify/polaris/dist/styles.css';
import { setContext } from '@apollo/client/link/context';
import axiosConfig from "./Pages/axiosconfig";
import dayjs from "dayjs";
import NewDashboard from "./Pages/home/NewDashboard";
import Analytics from './Pages/analytics/Index';
import BuildBox from './Pages/build-a-box/BuildBox';
import CreateBuildBox from './Pages/build-a-box/CreateBuildBox';
import Customers from './Pages/customer/Index';
import CreateCustomer from './Pages/customer/New';
import Installation from './Pages/installation/Index';
import IntegrationDetail from './Pages/integration/Detail';
import Integrations from './Pages/integration/Index';
import BuildABoxPlan from './Pages/plans/BuildABoxPlan';
import FixedPlan from './Pages/plans/FixedPlan';
import SellingPlans from './Pages/plans/Index';
import MysteryBoxPlan from './Pages/plans/MysteryBoxPlan';
import TrialPlan from './Pages/plans/TrialPlan';
import Settings from './Pages/setting/Index';
import Smarty from './Pages/smarty/Index';
import EditSmartyMessage from './Pages/smarty/SmartyMessage/EditSmartyMessage';
import Upsell from './Pages/upsell/Index';
import CreateUpsell from './Pages/upsell/New';
import Tiazen from './Pages/Tiazen/Index';
import Toolbox from './Pages/Toolbox/Index';
import PowerView from './Pages/plans/PowerView';
import CustomerModal from './Pages/CustomerModal/Index';
import ManageStaff from './Pages/ManageStaff';
import Notifications from './components/layout/Notifications';
import LoyaltyRewards from './Pages/loyalty-rewards/Index';
import Referrals from './Pages/Referrals';
import StripeContract from './Pages/StripeContracts';
import StripeContractsList from './Pages/StripeContracts/StripeContractsList';
import CreateBundleMenu from './Pages/BundleMenu/CreateBundleMenu';
import BundleMenus from './Pages/BundleMenu/Index';
import Rebuy from './Pages/Rebuy/Index';
import CreateRebuy from './Pages/Rebuy/CreateRebuy';
import PreOrders from './Pages/PreOrder';
import TryBeforeYouBuy from './Pages/TryBeforeYouBuy';
import CreatePreorder from './Pages/PreOrder/CreatePreorder';
import CreateTryBeforeYouBuy from './Pages/TryBeforeYouBuy/CreateTryBeforeYouBuy';
import CreateMembership from './Pages/Memberships/CreateMembership';
import Memberships from './Pages/Memberships';
import CreateSubscriptionProduct from './Pages/SubscriptionProducts/CreateSubscriptionProduct';
import SubscriptionProducts from './Pages/SubscriptionProducts';
import YodaRevenue from './Pages/YodaRevenue';
import MLAlgorithms from './Pages/MLAlgorithms/Index';
import Quizes from './Pages/Quizes';
import CreateQuiz from './Pages/Quizes/CreateQuiz/CreateQuiz';
import CreateLoisaWeeklyMenu from './Pages/LoisaWeeklyMenu/CreateLoisaWeeklyMenu';
import ChooseTemplate from './Pages/ChooseTemplate';
import SubscriptionView from './Pages/SubscriptionView';
import PointsProgramFAQs from './Pages/loyalty-rewards/PointsProgram/PointsProgramFaqs';
import PointsProgramTiers from './Pages/loyalty-rewards/PointsProgram/PointsProgramTiers';
import PointsProgramTiersPerks from './Pages/loyalty-rewards/PointsProgram/PointsProgramTiersPerks';
import PointsProgram from './Pages/loyalty-rewards/PointsProgram/PointsProgram';
import PointsProgramIndex from './Pages/loyalty-rewards/PointsProgramIndex';
import CreateTemplate from './Pages/ChooseTemplate/CreateTemplate';
import CustomerDetail from './Pages/CustomerModal/CustomerDetail/CustomerDetail';
import ZennPortal from './Pages/ZennPortal';
import GetStarted from './Pages/GetStarted';
import ReferralPage from './Pages/ReferralPage';
import RewardsLauncherSettings from './Pages/RewardsLauncher';
import Quizzes from './Pages/Quizzes';
import Upsells from './Pages/Upsells';
import Bundles from './Pages/BundlesTemplates';
import Workflows from './Pages/Workflows';
import CancellationFlow from './Pages/CancellationFlow';
import PasswordlessLogin from './Pages/PasswordlessLogin';
import Checkout from './Pages/Checkout';
import GiftCard from './Pages/GiftCard';
import GiftCardTemplates from './Pages/GiftCard/GiftCardTemplates';
import AffiliatePageLayout from './Pages/ChargezenAffiliate/AffiliatePageLayout';
import AffiliateDashboard from './Pages/ChargezenAffiliate/AffiliateDashboard';
import AffiliateOrders from './Pages/ChargezenAffiliate/AffiliateOrders';
import AffiliateSettings from './Pages/ChargezenAffiliate/AffiliateSettings';
import AffiliatesIndex from './Pages/Affiliates/AffiliatesIndex';
import PlanBilling from './Pages/PlanBilling';
import UpsellOverview from './Pages/Upsells/UpsellOverview';
import AffiliateDetail from './Pages/Affiliates/AffiliateDetail/AffiliateDetail';
import { PickProduct } from './Pages/Welcome/PickProduct';
import CheckoutOverview from './Pages/Checkout/CheckoutOverview';
import SmartyOverview from './Pages/smarty/SmartyOverview';
import ReplenishmeOverview from './Pages/Replenishme/ReplenishmeOverview';
import KioskOverview from './Pages/Kiosk/KioskOverview';
import QuickActions from './Pages/QuickActions/Index';
import CartFunnels from './Pages/Upsells/CartFunnels/CartFunnels';

import Authentication from "./Pages/Authentication";
import { DomainContext } from "./Pages/domain-context";
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  const [shopVersion, setShopVersion] = useState();
  const [allShops, setAllShops] = useState([]);
  const [userRole, setUserRole] = useState();
  const [userToken, setUserToken] = useState();
  const [shopBillingStatus, setShopBillingStatus] = useState();
  const [selectedApps, setSelectedApps] = useState();
  const [shopApiData, setShopApiData] = useState();
  const [currency, setCurrency] = useState();
  const [country, setCountry] = useState();
  const [customNavTabs, setCustomNavTabs] = useState({});
  const [floatingSetupGuide, setFloatingSetupGuide] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  const [locale, setLocale] = useState();
  const [i18n, seti18n] = useState(new I18n());
  const [mixpanelId, setMixpanelId] = useState();
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken") || "");
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken") || "");
  const [domain, setDomain] = useState();
  const [enablePassword, setEnabledPassword] = useState();
  const [shopEmail, setShopEmail] = useState();



  const fetchAdminSettings = async () => {
    if (domain && accessToken && authToken) {
      const { data } = await axiosConfig.get(`/chargezen_admin`)
      if (data?.nav_bar_tabs) {
        setShopVersion(data?.shopVersion)
        setUserRole(data?.userRole);
        setUserToken(data?.userToken);
        setCountry(data?.country);
        setAllShops(data?.allShops);
        setLocale(data?.locale);
        seti18n(new I18n(data?.translations));
        setSelectedApps(data?.selectedApps);
        setShopApiData(data?.shopApiData);
        setShopBillingStatus(data?.shopBillingStatus);
        setCurrency(data?.currency);
        setCustomNavTabs({ ...data?.nav_bar_tabs });
        setShopEmail(data?.shop_email)
        // setEnabledPassword(data?.enablePassword);
        localStorage.setItem(`${getShopKey(domain)}_userName`, data?.userName)
        localStorage.setItem(`${getShopKey(domain)}_userEmail`, data?.email)
      }
    }

  }

  function getShopKey(shopOrigin) {
    return shopOrigin?.replace(".myshopify.com", "");
  }

  useEffect(() => {
    if (!domain) {
      let urlParams = window.location.pathname.split("/");
      setDomain(urlParams[1] + ".myshopify.com")
    } else {
      const _authToken = localStorage.getItem(`${getShopKey(domain)}_authToken`) || "";
      const _accessToken = localStorage.getItem(`${getShopKey(domain)}_accessToken`) || "";
      setAuthToken(_authToken);
      setAccessToken(_accessToken);
    }
  }, [domain])


  function AdapterLink({ url, ...rest }) {
    return <Link to={url} {...rest} />;
  }

  const authLink = setContext((_, { headers }) => {
    // Get the authentication token and shop domain from local storage or wherever you store them
    const token = accessToken;
    const shopDomain = domain;
    return {
      headers: {
        ...headers,
        'X-Shopify-Access-Token': token,
        'X-Shop-Domain': shopDomain,
      },
    };
  });
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_BACKEND}/${domain?.replace('.myshopify.com', '')}/graphql`,
  });
  const shopifyLink = createHttpLink({
    uri: `${process.env.REACT_APP_BACKEND}/${domain?.replace('.myshopify.com', '')}/graphql-shopify`,
  });

  const client = new ApolloClient({
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === 'shopify-link',
      authLink.concat(shopifyLink),
      authLink.concat(httpLink)
    ),
    fetchOptions: {
      mode: 'no-cors',
    },
    cache: new InMemoryCache(),
  });

  const fetchNavBarTabs = async () => {
    if (domain && accessToken && authToken) {
      const response = await axiosConfig.get(`/get_nav_bar_setting`)

      const data = response.data
      if (data?.nav_bar_tabs) {
        setCustomNavTabs({ ...data?.nav_bar_tabs });
      }
    }
  };




  const withLocalization = (Component, specificProp = {}) => {
    return (props) => {
      return (
        <Component
          {...props}
          shopifyDomain={domain}
          {...{
            specificProp,
            currency,
            i18n,
            mixpanelId,
            domain,
            setDomain,
            allShops,
            shopVersion,
            formatDate,
            customNavTabs,
            setCustomNavTabs,
            floatingSetupGuide,
            currentPlan,
            setCurrentPlan,
            setFloatingSetupGuide,
            getShopKey,
            shopEmail
          }}
        />
      );
    };
  };

  function formatDate(dateString) {
    try {
      let formatTo = 'DD/MM/YYYY';
      let currentCountry = country;
      if (
        currentCountry === 'Canada' ||
        currentCountry === 'United States' ||
        currentCountry === 'Philippines'
      ) {
        formatTo = 'MM/DD/YYYY';
      }
      const formattedDate = dayjs(dateString).format(formatTo);
      return formattedDate;
    } catch (e) {
      return "";
    }
  }
  const fetchAuthUserShop = async () => {
    if (domain && accessToken && authToken) {
      const response = await axiosConfig.post(`/user_shops/authorize_user_shop`, { shopify_domain: domain }).then((res) => {
        localStorage.removeItem('accessSettings');
        localStorage.setItem('accessSettings', res?.data?.accessSettings);
        localStorage.setItem('allShops', JSON.stringify(allShops));
      });
    }
  }

  useEffect(() => {
    fetchAuthUserShop();
    fetchAdminSettings()
  }, [domain, accessToken, authToken]);


  return (
    <BrowserRouter basename={`/${getShopKey(domain) || ""}`} key={domain}>
      <AppProvider
        i18n={enTranslations}
        theme={{}}
        linkComponent={AdapterLink}
      >
        <ApolloProvider client={client}>
          <Switch>
            <Layout
              typePage="createCustomer"
              tabIndex="2"
              {...{
                selectedApps,
                customNavTabs,
                setCustomNavTabs,
                floatingSetupGuide,
                setFloatingSetupGuide,
                fetchNavBarTabs,
                fetchNavBarTabs,
                shopBillingStatus,
                shopApiData,
                currentPlan,
                setCurrentPlan,
                mixpanelId,
                shopVersion,
                domain,
                i18n,
                allShops,
                customNavTabs,
                accessToken
              }}
            >

              <Route
                exact
                path="/"
                component={withLocalization(NewDashboard)}
              />
              <Route
                exact
                path="/authentication/:authparams"
                component={withLocalization(Authentication)}
              />
              <Route
                exact
                path="/pickProduct"
                component={() => (
                  <PickProduct
                    {...{ i18n, userToken, domain, selectedApps }}
                  />
                )}
              />
              <Route
                exact
                path="/affiliates"
                component={withLocalization(AffiliatesIndex)}
              />
              <Route
                exact
                path="/affiliates/:selected_tab"
                component={withLocalization(AffiliatesIndex)}
              />
              <Route
                exact
                path="/affiliatesViewAffiliate/:id"
                component={withLocalization(AffiliateDetail)}
              />
              <Route
                exact
                path="/affiliates/:selected_tab/:edit_id"
                component={withLocalization(AffiliatesIndex)}
              />
              <Route
                exact
                path="/giftCard"
                component={withLocalization(GiftCardTemplates)}
              />
              <Route
                exact
                path="/giftCard/:giftCardId"
                component={withLocalization(GiftCardTemplates)}
              />
              <Route
                exact
                path="/giftCard/create/:templateid"
                component={withLocalization(GiftCardTemplates)}
              />
              <Route
                exact
                path="/checkout"
                component={withLocalization(CheckoutOverview)}
              />
              <Route
                exact
                path="/checkoutStyle"
                component={withLocalization(Checkout)}
              />
              <Route
                exact
                path="/workflow"
                component={withLocalization(RewardsLauncherSettings)}
              />
              <Route
                exact
                path="/RewardsLauncherSettings"
                component={withLocalization(RewardsLauncherSettings)}
              />
              <Route
                exact
                path="/ReferralPage"
                component={withLocalization(ReferralPage)}
              />
              <Route
                exact
                path="/getStarted"
                component={withLocalization(GetStarted)}
              />
              <Route
                exact
                path="/zenn"
                component={withLocalization(ZennPortal)}
              />
              <Route
                exact
                path="/zenn/:edit_id"
                component={withLocalization(ZennPortal)}
              />
              <Route
                exact
                path="/zenn/:edit_id/:selectedTab"
                component={withLocalization(ZennPortal)}
              />
              <Route
                exact
                path="/CreateTemplate"
                component={withLocalization(CreateTemplate)}
              />
              <Route
                exact
                path="/PointsProgram"
                component={withLocalization(PointsProgramIndex)}
              />
              <Route
                exact
                path="/PointsProgramCreate"
                component={withLocalization(PointsProgram)}
              />
              <Route
                exact
                path="/PointsProgramFAQs"
                component={withLocalization(PointsProgramFAQs)}
              />
              <Route
                exact
                path="/PointsProgramTiers"
                component={withLocalization(PointsProgramTiers)}
              />
              <Route
                exact
                path="/PointsProgramTiersPerks"
                component={withLocalization(PointsProgramTiersPerks)}
              />

              <Route
                exact
                path="/subscriptionView"
                component={withLocalization(SubscriptionView)}
              />
              <Route
                exact
                path="/rewardsPage"
                component={withLocalization(ChooseTemplate)}
              />
              <Route
                exact
                path="/rewardsPage/:edit_id/:template_id"
                component={withLocalization(ChooseTemplate)}
              /><Route
                exact
                path="/pointsProgram/:selected_tab"
                component={() => (
                  <PointsProgramIndex
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/pointsProgram/:selected_tab/:edit_id"
                component={() => (
                  <PointsProgramIndex
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/pointsProgram/:selected_tab/:edit_id/:template_id"
                component={() => (
                  <PointsProgramIndex
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    formatDate={formatDate}
                  />
                )}
              />

              <Route
                exact
                path="/createQuiz"
                component={withLocalization(CreateQuiz)}
              />
              <Route
                exact
                path="/quizes"
                component={withLocalization(Quizzes)}
              />
              <Route
                exact
                path="/chargezenUpsells"
                component={withLocalization(UpsellOverview)}
              />
              <Route
                exact
                path="/chargezenUpsells/manage"
                component={withLocalization(Upsells)}
              />
              <Route
                exact
                path="/chargezenUpsells/cartFunnels"
                component={withLocalization(CartFunnels)}
              />
              <Route
                exact
                path="/chargezenUpsells/cartFunnels/:template_id"
                component={withLocalization(CartFunnels)}
              />
              <Route
                exact
                path="/chargezenUpsells/cartFunnels/:template_id/:edit_id"
                component={withLocalization(CartFunnels)}
              />

              <Route
                exact
                path="/chargezenUpsells/manage/:edit_id"
                component={withLocalization(Upsells)}
              />
              <Route
                exact
                path="/chargezenUpsells/manage/create/:template_id"
                component={withLocalization(Upsells)}
              />
              <Route
                exact
                path="/bundle"
                component={withLocalization(Bundles)}
              />
              <Route
                exact
                path="/bundle/:edit_id"
                component={withLocalization(Bundles)}
              />
              <Route
                exact
                path="/bundle/create/:template_id"
                component={withLocalization(Bundles)}
              />

              <Route
                exact
                path="/CreateLoisaWeeklyMenu"
                component={withLocalization(CreateLoisaWeeklyMenu)}
              />
              <Route
                exact
                path="/CustomerDetail"
                component={withLocalization(CustomerDetail)}
              />
              <Route
                exact
                path="/MLAlgorithms"
                component={withLocalization(MLAlgorithms)}
              />
              <Route
                exact
                path="/kMeansClusters"
                component={withLocalization(MLAlgorithms)}
              />
              <Route
                exact
                path="/sentiment-analysis"
                component={withLocalization(MLAlgorithms)}
              />
              <Route
                exact
                path="/anomaly-detection"
                component={withLocalization(MLAlgorithms)}
              />
              <Route
                exact
                path="/market-basket-analysis"
                component={withLocalization(MLAlgorithms)}
              />

              <Route
                exact
                path="/YodaRevenueCopilot"
                component={withLocalization(YodaRevenue)}
              />
              <Route
                exact
                path="/SubscriptionProducts"
                component={withLocalization(SubscriptionProducts)}
              />
              <Route
                exact
                path="/CreateSubscriptionProduct"
                component={withLocalization(CreateSubscriptionProduct)}
              />
              <Route
                exact
                path="/memberships"
                component={withLocalization(Memberships)}
              />
              <Route
                exact
                path="/createMemberships"
                component={withLocalization(CreateMembership)}
              />
              <Route
                exact
                path="/preOrders"
                component={withLocalization(PreOrders)}
              />
              <Route
                exact
                path="/createTryBeforeYouBuy"
                component={withLocalization(CreateTryBeforeYouBuy)}
              />
              <Route
                exact
                path="/createTryBeforeYouBuy/:id"
                component={withLocalization(CreateTryBeforeYouBuy)}
              />
              <Route
                exact
                path="/createPreorder"
                component={withLocalization(CreatePreorder)}
              />
              <Route
                exact
                path="/createPreorder/:id"
                component={withLocalization(CreatePreorder)}
              />
              <Route
                exact
                path="/tryBeforeYouBuy"
                component={withLocalization(TryBeforeYouBuy)}
              />
              <Route
                exact
                path="/createRebuy"
                component={withLocalization(CreateRebuy)}
              />
              <Route
                exact
                path="/createRebuy/:id"
                component={withLocalization(CreateRebuy)}
              />
              <Route
                exact
                path="/rebuy"
                component={withLocalization(Rebuy)}
              />
              <Route
                exact
                path="/bundles"
                component={withLocalization(BundleMenus)}
              />
              <Route
                exact
                path="/createBundleMenu"
                component={withLocalization(CreateBundleMenu)}
              />
              <Route
                exact
                path="/stripeContractsList"
                component={withLocalization(StripeContractsList)}
              />
              <Route
                exact
                path="/createStripeContract"
                component={withLocalization(StripeContract)}
              />
              <Route
                exact
                path="/referrals"
                component={withLocalization(Referrals)}
              />
              {/* <Route exact path="/pointsProgram" component={withLocalization(WaysToEarnPoint)} /> */}
              <Route
                exact
                path="/notifications"
                component={withLocalization(Notifications)}
              />
              <Route
                exact
                path="/chooseTemplate"
                component={withLocalization(LoyaltyRewards)}
              />
              <Route
                exact
                path="/manage-staff"
                component={withLocalization(ManageStaff)}
              />
              <Route
                exact
                path="/customer-model"
                component={withLocalization(CustomerModal)}
              />
              <Route
                exact
                path="/customer-model/:token"
                component={withLocalization(CustomerModal)}
              />

              <Route
                exact
                path="/fixed-subscription-plans/:id"
                component={withLocalization(FixedPlan)}
              />
              <Route
                exact
                path="/power-view-plan/:id/"
                component={withLocalization(PowerView)}
              />
              <Route
                exact
                path="/fixed-subscription-plans"
                component={withLocalization(FixedPlan)}
              />
              <Route
                exact
                path="/trial-subscription-plan/:id"
                component={withLocalization(TrialPlan)}
              />
              <Route
                exact
                path="/trial-subscription-plan"
                component={withLocalization(TrialPlan)}
              />
              <Route
                exact
                path="/build-a-box-subscription-plan/:id"
                component={withLocalization(BuildABoxPlan)}
              />
              <Route
                exact
                path="/build-a-box-subscription-plan"
                component={withLocalization(BuildABoxPlan)}
              />
              <Route
                exact
                path="/mystery-box-subscription-plan/:id"
                component={withLocalization(MysteryBoxPlan)}
              />
              <Route
                exact
                path="/mystery-box-subscription-plan"
                component={withLocalization(MysteryBoxPlan)}
              />
              <Route
                exact
                path="/integrations"
                component={withLocalization(Integrations)}
              />
              <Route
                exact
                path="/integration-detail/:id/:title/:keys?"
                component={withLocalization(IntegrationDetail)}
              />
              <Route
                exact
                path="/smartyoverview"
                component={withLocalization(SmartyOverview)}
              />
              <Route
                exact
                path="/smarty"
                component={withLocalization(Smarty)}
              />
              <Route
                exact
                path="/smarty/:edit_id"
                component={withLocalization(Smarty)}
              />
              <Route
                exact
                path="/edit-smarty-message/:id"
                component={withLocalization(EditSmartyMessage)}
              />
              <Route
                exact
                path="/kioskpos"
                component={withLocalization(KioskOverview)}
              />
              <Route
                exact
                path="/replenishme"
                component={withLocalization(ReplenishmeOverview)}
              />
              <Route
                exact
                path="/app-settings"
                component={() => (
                  <Settings
                    i18n={i18n}
                    enablePassword={enablePassword}
                    setEnabledPassword={setEnabledPassword}
                    domain={domain}
                    formatDate={formatDate}
                    shopVersion={shopVersion}
                    shopApiData={shopApiData}
                    currentPlan={currentPlan}
                    setCurrentPlan={setCurrentPlan}
                  />
                )}
              />
              <Route
                exact
                path="/app-settings/:tabname"
                component={() => (
                  <Settings
                    i18n={i18n}
                    enablePassword={enablePassword}
                    setEnabledPassword={setEnabledPassword}
                    domain={domain}
                    formatDate={formatDate}
                    shopVersion={shopVersion}
                    shopApiData={shopApiData}
                    currentPlan={currentPlan}
                    setCurrentPlan={setCurrentPlan}
                  />
                )}
              />
              <Route
                exact
                path="/app-settings/:tabname/:edit_id"
                component={() => (
                  <Settings
                    i18n={i18n}
                    enablePassword={enablePassword}
                    setEnabledPassword={setEnabledPassword}
                    domain={domain}
                    formatDate={formatDate}
                    shopVersion={shopVersion}
                    shopApiData={shopApiData}
                    currentPlan={currentPlan}
                    setCurrentPlan={setCurrentPlan}
                  />
                )}
              />
              <Route
                exact
                path="/customers"
                component={withLocalization(Customers)}
              />
              <Route
                exact
                path="/customersTab/:selected_tab"
                component={withLocalization(Customers)}
              />
              <Route
                exact
                path="/customersTab/:selected_tab/:edit_id"
                component={withLocalization(Customers)}
              />
              <Route
                exact
                path="/customersTab/:selected_tab/:edit_id/:template_id"
                component={withLocalization(Customers)}
              />
              <Route
                exact
                path="/customers/:contractToken"
                component={withLocalization(Customers)}
              />
              <Route
                exact
                path="/subscriptionContracts"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="contracts"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/subscriptionOrders"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="subscription_orders"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/subscription-plans"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="subscription_plans"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/bulkAction"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="bulk_action"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/customerMigration"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="customerMigration"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/subscriptionFlows"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="subscription_workflow"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/subscriptionFlows/:workflowId"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="subscription_workflow"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/globalSettings"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="global_billing_hour"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/subProducts"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="subscription_products"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/subProducts/:productId"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="subscription_products"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/dunning"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="subscription_billing"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/subEmail"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="subscription_email"
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/subEmail/:emailId"
                component={() => (
                  <Customers
                    i18n={i18n}
                    mixpanelId={mixpanelId}
                    domain={domain}
                    selected_tab="subscription_email"
                    formatDate={formatDate}
                  />
                )}
              />
              {/* <Route exact path="/notifications" component={() => <Customers i18n={i18n} mixpanelId={mixpanelId} domain={domain} selected_tab="notifications" />} /> */}

              <Route
                exact
                path="/customers/new"
                component={withLocalization(CreateCustomer)}
              />
              <Route
                exact
                path="/customers/:id/edit"
                component={withLocalization(CreateCustomer)}
              />
              <Route
                exact
                path="/analytics"
                component={withLocalization(Analytics)}
              />
              <Route
                exact
                path="/analytics/:selectedTab"
                component={withLocalization(Analytics)}
              />
              <Route
                exact
                path="/analytics/:selectedTab/:subTab"
                component={withLocalization(Analytics)}
              />
              <Route
                exact
                path="/installation"
                component={() => (
                  <Installation
                    i18n={i18n}
                    shopifyDomain={domain}
                    enablePassword={enablePassword}
                    formatDate={formatDate}
                  />
                )}
              />
              <Route
                exact
                path="/chargezenUpsell"
                component={withLocalization(Upsell)}
              />
              <Route
                exact
                path="/chargezenUpsell/:id/edit"
                component={withLocalization(CreateUpsell)}
              />
              <Route
                exact
                path="/chargezenUpsell/new"
                component={withLocalization(CreateUpsell)}
              />

              <Route
                exact
                path="/build-a-box"
                component={withLocalization(BuildBox)}
              />
              <Route
                exact
                path="/build-a-box/:id/edit"
                component={withLocalization(CreateBuildBox)}
              />
              <Route
                exact
                path="/build-a-box/new"
                component={withLocalization(CreateBuildBox)}
              />

              <Route
                exact
                path="/tiazen"
                component={withLocalization(Tiazen)}
              />
              <Route
                exact
                path="/tiazen/:tabname"
                component={withLocalization(Tiazen)}
              />
              <Route
                exact
                path="/toolbox"
                component={withLocalization(Toolbox)}
              />
              <Route
                exact
                path="/toolbox/:selected_tab"
                component={withLocalization(Toolbox)}
              />
              <Route
                exact
                path="/toolbox/:selected_tab/:edit_id"
                component={withLocalization(Toolbox)}
              />
              <Route
                exact
                path="/toolbox/:selected_tab/:edit_id/:selectedTab"
                component={withLocalization(Toolbox)}
              />
              <Route
                exact
                path="/choosePlan"
                component={withLocalization(PlanBilling)}
              />

            </Layout>
          </Switch>
        </ApolloProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
