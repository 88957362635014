import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
    Card,
    Select,
    FormLayout,
    Button,
    Icon,
    Modal,
    TextField,
    Layout,
    Page,
    Stack,
    Tabs,
    ColorPicker,
    Frame,
    Toast
} from '@shopify/polaris';

import { GetStartedIcon } from '../../components/layout/CustomIcon';
import ManageTranslations from './ManageTranslations';

const EditReferralPage = (props) => {
    const { handleBack, i18n, setSelectedTemplateId, selectedTemplateId, domain, toastContent, setToastContent, toastActive, setToastActive, toggleToastActive } = props
    const history = useHistory();
    const { edit_id } = useParams();
    const [selectedTitleTab, setSelectedTitleTab] = useState(0);

    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelectedTitleTab(selectedTabIndex),
        []
    );

    const [formData, setFormData] = useState({ title: "", terms_and_conditions: "", subtitles: [""], checkmark_text: [""] })

    const handleSubtitleChange = (value, index) => {
        let newSubtitles = formData?.subtitles;
        newSubtitles[index] = value;
        setFormData({ ...formData, subtitles: newSubtitles });
    }
    const handleCheckTextChange = (value, index) => {
        let newCheckmarkText = formData?.checkmark_text;
        newCheckmarkText[index] = value;
        setFormData({ ...formData, checkmark_text: newCheckmarkText });
    }

    const addMoreSubtitles = () => {
        let newSubtitles = formData?.subtitles;
        newSubtitles.push("");
        setFormData({ ...formData, subtitles: newSubtitles });
    }
    const addMoreCheckTextChange = () => {
        let newCheckmarkText = formData?.checkmark_text;
        newCheckmarkText.push("");
        setFormData({ ...formData, checkmark_text: newCheckmarkText });
    }

    const saveReferralEdit = () => {
        fetch('/create_referral_settings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                shopify_domain: domain, referral_edit: formData, template_id: selectedTemplateId
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setSelectedTemplateId();
                    setToastContent("Saved Successfully");
                    setToastActive(true);
                    history.push(`/pointsProgram/ReferralPage`)
                }
            })
    }

    const getReferralEdit = () => {
        fetch('/get_referral_settings?shopify_domain=' + domain, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data?.referral_edit) {
                    setFormData({ title: data?.referral_edit?.title, terms_and_conditions: data?.referral_edit?.terms_and_conditions, subtitles: data?.referral_edit?.subtitles, checkmark_text: data?.referral_edit?.checkmark_text });
                }
            })
    }

    useEffect(() => {
        getReferralEdit();
    }, [])
    return (
        <>
            {
                toastActive && (
                    <Toast content={toastContent} onDismiss={toggleToastActive} />
                )
            }

            <div className="customize">
                <FormLayout>
                    <Card
                        sectioned
                        primaryFooterAction={{ content: i18n.t("save_and_continue"), onAction: () => { saveReferralEdit() } }}
                        footerActionAlignment="left"
                    >
                        <div className='card-box'>
                            <div className='rewards_settings main-title'>
                                <span className='heading_icons'>
                                    <GetStartedIcon />
                                </span>
                                <span className="title_wrapper">{i18n.t("referrals.referrals")}</span>
                            </div>
                            <FormLayout.Group>
                                <TextField
                                    type="text"
                                    label={i18n.t("title")}
                                    placeholder='Get 20% OFF'
                                    value={formData?.title}
                                    onChange={(newValue) => setFormData({ ...formData, title: newValue })}
                                    autoComplete="off"
                                />
                            </FormLayout.Group>
                            <FormLayout.Group>
                                <TextField
                                    type="text"
                                    label={i18n.t("referrals.terms_cond")}
                                    placeholder={i18n.t("referrals.valid_new_customer")}
                                    value={formData?.terms_and_conditions}
                                    onChange={(newValue) => setFormData({ ...formData, terms_and_conditions: newValue })}
                                    autoComplete="off"
                                />
                            </FormLayout.Group>
                            <FormLayout.Group>
                                <div className='subtitles_wrapper'>
                                    {
                                        formData?.subtitles?.map((subtitle, i) => {
                                            return (
                                                <TextField
                                                    type="text"
                                                    label={i18n.t("subtitle")}
                                                    value={subtitle}
                                                    onChange={(newValue) => handleSubtitleChange(newValue, i)}
                                                    autoComplete="off"
                                                />
                                            )

                                        })
                                    }
                                    <Button className='add_more_button' onClick={() => addMoreSubtitles()}>{i18n.t("referrals.add_more")}</Button>
                                </div>
                            </FormLayout.Group>
                            <FormLayout.Group>
                                <div className='subtitles_wrapper'>
                                    {

                                        formData?.checkmark_text?.map((checkmark_text, i) => {
                                            return (
                                                <TextField
                                                    type="text"
                                                    label={i18n.t("checkmark_test")}
                                                    value={checkmark_text}
                                                    onChange={(newValue) => handleCheckTextChange(newValue, i)}
                                                    autoComplete="off"
                                                />
                                            )

                                        })

                                    }
                                    <Button className='add_more_button' onClick={() => addMoreCheckTextChange()}>{i18n.t("referrals.add_more")}</Button>
                                </div>
                            </FormLayout.Group>
                        </div>
                    </Card>
                    <div className=''>
                        <ManageTranslations i18n={i18n} domain={domain} setToastActive={setToastActive} setToastContent={setToastContent} />
                    </div>
                </FormLayout>
            </div>
        </>
    );
}
export default EditReferralPage;
