import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Text, Card, Toast, ChoiceList, Page, PageActions, TextField, Layout, Banner, List, Frame, Select, Icon, Checkbox, Form, DropZone, Thumbnail, Stack, FormLayout, Button } from '@shopify/polaris';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import SearchProduct from '../../upsell/SearchProduct';
import Preview from '../../plans/Preview';

const PointsProgramFAQs = (props) => {
    const { domain, i18n, setEditForm } = props;
    const [toastContent, setToastContent] = useState("");
    const [toastActive, setToastActive] = useState(false);
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
    const [formData, setFormData] = useState({ title: "", bodyHtml: "", });
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const [fieldError, setFieldError] = useState({ title: '', bodyHtml: '' });

    const CREATE_FAQ = gql`
        mutation($input: AddPointsProgramFaqInput!) {
            addPointsProgramFaq(input: $input) {
                pointsProgramFaq{
                    id
                }
            }
        }
    `;

    const [createCustomer, loading, error] = useMutation(CREATE_FAQ);

    const handleSubmit = () => {
        if (!formData.title || formData.title == "") {
            setFieldError({ ...fieldError, title: i18n.t('enter_title') })
            return;
        } else {
            setFieldError({ ...fieldError, title: "" })
        }
        createCustomer({
            variables: {
                input: { params: formData },
            },
        }).then((resp) => {
            const errors = resp.errors;
            if (!errors) {
                setToastContent(i18n.t('saved_successfully'));
                setToastActive(true)
            }
        });
    };

    return (
        <Frame>
            {
                toastActive && (
                    <Toast content={toastContent} onDismiss={toggleToastActive} />
                )
            }
            <Page
                title={
                    <div className='back_button_wrapper'>
                        <div className="back-button pointer" style={{ marginTop: '15px' }} onClick={() => setEditForm(false)}>
                            <Icon
                                source={MobileBackArrowMajor}
                                color="base" />
                        </div>
                        <div class="text_wrapper">
                            {formData?.id ? i18n.t('edit') : i18n.t('add')}
                        </div>
                    </div>}
            >

                <Layout>
                    <Layout.Section>
                        <Card title={
                            <div className="heading_title">

                                {i18n.t("points_programs.points_program_faqs")}
                            </div>
                        } sectioned>
                            <Form>
                                <FormLayout>
                                    <FormLayout.Group>
                                        <TextField
                                            label={i18n.t("title")}
                                            type="text"
                                            value={`${formData?.title}`}
                                            onChange={(e) => {
                                                setFormData({ ...formData, title: e })
                                            }}
                                            error={fieldError?.title}
                                            autoComplete="off"
                                        />
                                    </FormLayout.Group>

                                    <FormLayout.Group>
                                        <TextField
                                            label={i18n.t("body_html")}
                                            type="text"
                                            multiline={4}
                                            value={`${formData?.bodyHtml}`}
                                            onChange={(e) => {
                                                setFormData({ ...formData, bodyHtml: e })
                                            }}
                                            error={fieldError?.bodyHtml}
                                            autoComplete="off"
                                        />
                                    </FormLayout.Group>
                                </FormLayout>
                                <FormLayout>
                                    <FormLayout.Group>
                                        <Button primary onClick={() => handleSubmit()}>label={i18n.t("save")}</Button>
                                    </FormLayout.Group>
                                </FormLayout>
                            </Form>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        </Frame >
    )
}

export default PointsProgramFAQs;