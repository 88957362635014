import React, { useState } from 'react';
import DragAndDrop from './DragAndDrop';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Card, Select, TextField } from '@shopify/polaris';

const ContentBlock = (props) => {
  const {
    i18n,
    contentBlocks,
    setContentBlocks,
    addContent,
    setAddContent,
    currentContentBlock,
    setCurrentContentBlock,
    templateId,
    domain,
    editId,
  } = props;
  const [currentForm, setCurrentForm] = useState();

  const defaultContentBlock = {
    content_type: '',
    paragraph: '',
    button_text: '',
    button_link: '',
    button_size: '',
    spacer_size: '',
    headline_text: '',
    headline_size: '',
    checkbox_text: '',
    checkbox_description: '',
    checkbox_secondary_style: false,
    gift_card_setting_id: '',
    created_at: '',
    updated_at: '',
    index: '',
  };

  const blockOptions = [
    { ...defaultContentBlock, ...{ content_type: 'Headline' } },
    { ...defaultContentBlock, ...{ content_type: 'Spacer' } },
    { ...defaultContentBlock, ...{ content_type: 'Paragraph' } },
    { ...defaultContentBlock, ...{ content_type: 'Button' } },
  ];

  const handleContentChange = (value, temp_token, field_name) => {
    let content_blocks = contentBlocks?.map((c, i) => {
      c.index = i;
      if (+c.temp_token === +temp_token) {
        c[field_name] = value;
        return c;
      } else {
        return c;
      }
    });
    setContentBlocks([...content_blocks]);
  };

  const addContentBlock = (block) => {
    const valuesArray = contentBlocks.map((obj) => obj['temp_token']);
    const temp_token = Math.max(...valuesArray) + 1;
    block.temp_token = +temp_token;
    setAddContent(block?.temp_token);
    setCurrentForm();
    refetch(block, null, 'add');

    // fetch(`/gift_card/add_content_block`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     content_block: block,
    //     shopify_domain: domain,
    //     template_id: templateId,
    //     gift_card_setting_id: editId || '',
    //   }),
    // })
    //   .then((r) => r.json())
    //   .then((data) => {
    //     if (data.current_block) {

    //       setAddContent(data?.current_block?.id);
    //       setCurrentForm();
    //     }
    //   });
  };

  const removeContentBlock = (temp_token) => {
    refetch(null, temp_token, 'remove');
    // fetch(
    //   `/gift_card/remove_content_block/${content_id}?shopify_domain=${domain}&gift_card_setting_id=${
    //     editId || ''
    //   }`,
    //   {
    //     method: 'DELETE',
    //   }
    // )
    //   .then((r) => r.json())
    //   .then((data) => {
    //     if (data.status) {
    //       setAddContent();

    //       setCurrentForm();
    //     }
    //   });
  };

  const refetch = (block, temp_token, action) => {
    let content_blocks = [...contentBlocks];
    if (action === 'add' && block) {
      content_blocks.push(block);
    } else if (action === 'remove' && temp_token) {
      let index = content_blocks.findIndex((c) => c.temp_token == temp_token);
      if (index >= 0) {
        content_blocks.splice(index, 1);
      }
    }

    setContentBlocks([...content_blocks]);
  };

  return (
    <>
      {currentContentBlock ? (
        <>
          <Card>
            <Card.Section>
              <div className="card_body_content">
                {currentContentBlock.content_type == 'Spacer' ? (
                  <div className="card_body_content">
                    <TextField
                      label="Spacer size"
                      type="number"
                      value={`${currentContentBlock.spacer_size}`}
                      onChange={(e) =>
                        handleContentChange(
                          e,
                          currentContentBlock.temp_token,
                          'spacer_size'
                        )
                      }
                    />
                  </div>
                ) : currentContentBlock.content_type == 'Headline' ? (
                  <>
                    <div className="card_body_content">
                      <TextField
                        label="Headline text"
                        value={currentContentBlock.headline_text}
                        onChange={(e) =>
                          handleContentChange(
                            e,
                            currentContentBlock.temp_token,
                            'headline_text'
                          )
                        }
                      />
                    </div>
                    <div className="card_body_content">
                      <Select
                        options={['H1', 'H2', 'H3']}
                        label="Headline size"
                        value={currentContentBlock.headline_size}
                        onChange={(e) =>
                          handleContentChange(
                            e,
                            currentContentBlock.temp_token,
                            'headline_size'
                          )
                        }
                      />
                    </div>
                  </>
                ) : currentContentBlock.content_type == 'Paragraph' ? (
                  <>
                    <div className="card_body_content">
                      <TextField
                        multiline={10}
                        label="Paragraph"
                        value={currentContentBlock.paragraph}
                        onChange={(e) =>
                          handleContentChange(e, addContent, 'paragraph')
                        }
                      />
                    </div>
                  </>
                ) : currentContentBlock.content_type == 'Checkbox' ? (
                  <>
                    <div className="card_body_content">
                      <TextField
                        label="Checkbox text"
                        value={currentContentBlock.checkbox_text}
                        onChange={(e) =>
                          handleContentChange(e, addContent, 'checkbox_text')
                        }
                      />
                    </div>
                    <div className="card_body_content">
                      <TextField
                        label="Checkbox description"
                        value={currentContentBlock.checkbox_description}
                        onChange={(e) =>
                          handleContentChange(
                            e,
                            addContent,
                            'checkbox_description'
                          )
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card_body_content">
                      <TextField
                        label="Button text"
                        value={currentContentBlock.button_text}
                        onChange={(e) =>
                          handleContentChange(e, addContent, 'button_text')
                        }
                      />
                    </div>
                    <div className="card_body_content">
                      <TextField
                        label="Link"
                        value={currentContentBlock.button_link}
                        onChange={(e) =>
                          handleContentChange(e, addContent, 'button_link')
                        }
                      />
                    </div>
                    <div className="card_body_content">
                      <Select
                        options={['Small', 'Medium', 'Large']}
                        label="Button size"
                        value={currentContentBlock.button_size}
                        onChange={(e) =>
                          handleContentChange(e, addContent, 'button_size')
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </Card.Section>
          </Card>
          <div className="remove_content_block_button_wrapper">
            <button
              className="remove_content_block"
              onClick={() => {
                removeContentBlock(currentContentBlock.temp_token);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="lucide lucide-trash-2"
              >
                <path d="M3 6h18" />
                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                <line x1="10" x2="10" y1="11" y2="17" />
                <line x1="14" x2="14" y1="11" y2="17" />
              </svg>
              <span>Remove element</span>
            </button>
            {currentContentBlock.content_type == 'Checkbox' && (
              <div className="remove_checkbox_help_text">
                Removing checkbox will, permanently make it a gift card.
              </div>
            )}
          </div>
        </>
      ) : currentForm === 'add_element' ? (
        <>
          {blockOptions?.map((block) => {
            return (
              <ul className="polaris-card">
                <li className="gift_card_content_block add_element_list">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.588 2.051c-2.056-.259-3.68.458-5 2.23-2.847 3.823-1.715 9.71 2.223 12.064 4.02 2.404 9.32.946 11.563-3.28.536-1.01.729-2.11.575-3.326-.146-1.153-1.173-1.957-2.284-1.812-.12.015-.237.041-.352.078l-.082.026a3.092 3.092 0 01-3.893-2.033 3.124 3.124 0 01-.142-.93c0-1.54-1.124-2.83-2.608-3.017zm.25-1.984c2.49.313 4.358 2.458 4.358 5.001 0 .114.017.226.051.335.182.584.797.908 1.374.724l.082-.027c.23-.073.465-.126.704-.157 2.216-.288 4.242 1.3 4.526 3.545.205 1.619-.06 3.134-.793 4.515-2.816 5.304-9.42 7.01-14.355 4.059-4.914-2.94-6.384-10.164-2.8-14.975C3.727.746 6.054-.283 8.837.067zM6.833 6.929a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM4.5 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm3 4a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm6.25-1a1.75 1.75 0 110-3.5 1.75 1.75 0 010 3.5z"
                      fill="#637381"
                      fill-rule="nonzero"
                    ></path>
                  </svg>
                  <span className="left_section">{block?.content_type}</span>
                  <button
                    className="add_content_block"
                    onClick={() => {
                      addContentBlock(block);
                    }}
                  >
                    Add
                  </button>
                </li>
              </ul>
            );
          })}
        </>
      ) : (
        <div className="dragAndDropContentBlockContainer">
          <DndProvider backend={HTML5Backend}>
            <DragAndDrop
              cards={contentBlocks}
              setCards={setContentBlocks}
              i18n={i18n}
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
              addContent={addContent}
              setAddContent={setAddContent}
            />
          </DndProvider>
          <ul className="polaris-card content_block_lists">
            <li
              onClick={(e) => {
                setCurrentForm('add_element');
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-plus-square"
              >
                <rect width="18" height="18" x="3" y="3" rx="2" />
                <path d="M8 12h8" />
                <path d="M12 8v8" />
              </svg>
              <span className="left_section">Add element</span>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default ContentBlock;
