import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  ChoiceList,
  DataTable,
  DatePicker,
  DropZone,
  Filters,
  Frame,
  Icon,
  List,
  Modal,
  Page,
  RadioButton,
  Select,
  Spinner,
  Stack,
  Tabs,
  TextField,
  Thumbnail,
  Toast,
} from '@shopify/polaris';
import {
  DeleteMajor,
  NoteMinor,
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import Papa from 'papaparse';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory, useParams } from 'react-router-dom';

import moment from 'moment';
import { Pagination } from '@shopify/polaris';
import LoadingScreen from '../LoadingScreen';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import { DomainContext } from '../domain-context';
import SubscriptionView from '../SubscriptionView';
import RangePicker from './RangePicker';
import dayjs from 'dayjs';
import SubscriptionOrders from './SubscriptionOrders';
import Workflows from '../Workflows';
import GlobalBillingHour from './GlobalBillingHour';
import CustomDatePicker from './CustomDatePicker';
import SubscriptionProducts from '../SubscriptionProducts';
import Billing from '../setting/Billing';
import SubscriptionEmail from './SubscriptionEmail';
import SubscriptionBilling from './SubscriptionBilling';
import SummaryComponent from './SummaryComponent';
import CustomerMigration from '../CustomerMigration';
import SellingPlans from '../plans/Index';
import RenderContracts from './RenderContracts';
import Overview from '../Overview/Overview';
import SubscriptionOverview from './SubscriptionOverview';
import QuickActions from '../QuickActions/Index';
import SubscriptionBundles from '../SubscriptionBundles';
import ThemeExtension from '../ThemeExtension';
import "./style.css";

// import json2csv from 'json2csv';
const subscriptions = [
  'active',
  'new',
  'returning',
  'paused',
  'cancelled',
  'all',
];

const {
  Parser,
  transforms: { unwind },
} = require('json2csv');

// let sortOrder = 0;

const Customers = (props) => {
  const {
    domain,
    shopifyDomain,
    i18n,
    formatDate,
    floatingSetupGuide,
    setFloatingSetupGuide,
    customNavTabs,
  } = props;
  const { contractToken, selected_tab, edit_id } = useParams();
  const [sellingPlansOptions, setSellingPlanOptions] = useState([]);
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState(0);

  const [sortOrder, setSortOrder] = useState(0);

  const [moneySpent, setMoneySpent] = useState(null);
  const [taggedWith, setTaggedWith] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [filterConsignments, setFilterConsignments] = useState([]);
  const [searchFilter, setSearchFilter] = useState({});
  const [selectedDates, setSelectedDates] = useState();
  const [selectedCreatedAtDates, setSelectedCreatedAtDates] = useState();
  const [allSellingPlans, setALlSellingPlans] = useState();
  const [selectedStartDates, setSelectedStartDates] = useState();
  const [selectedPaymentStartDates, setSelectedPaymentStartDates] = useState();
  const [selectedPaymentEndDates, setSelectedPaymentEndDates] = useState();
  const [selectedEndDates, setSelectedEndDates] = useState();
  const [selectedEmailIndex, setSelectedEmailIndex] = useState();

  //HappyCandyCode//
  const happyCandyProducts = [
    {
      bundleName: 'Mega 6 Box',
      image:
        'https://www.haeppycandy.de/cdn/shop/files/6pack_360x.png?v=1704731712',
      variants: [
        {
          image:
            'https://www.haeppycandy.de/cdn/shop/files/4_1500x.svg?v=1704278083',
          variant_id: 44914029953289,
          title: 'Hawaiian Sunrise',
          desc: 'Jetzt Bestellen! Häppy Candy vegane Fruchtgummis mit Grapefruit-, Maracuja- und Kiwigeschmack',
          price: '2.99',
        },
        {
          link: 'https://www.haeppycandy.de/products/bumble-berry',
          image:
            'https://www.haeppycandy.de/cdn/shop/files/6_750x.svg?v=1704277812',
          variant_id: 44914028314889,
          title: 'Bumble Berry',
          desc: 'Jetzt Bestellen! Häppy Candy vegane Fruchtgummis mit Holunder-, Himbeere- und Kirschgeschmack',
          price: '2.99',
        },
      ],
    },
    {
      bundleName: 'Mega 12 Box',
      image:
        'https://www.haeppycandy.de/cdn/shop/files/12pack_360x.png?v=1704731900',
      variants: [
        {
          image:
            'https://www.haeppycandy.de/cdn/shop/files/4_1500x.svg?v=1704278083',
          variant_id: 44914029986057,
          title: 'Hawaiian Sunrise',
          desc: 'Jetzt Bestellen! Häppy Candy vegane Fruchtgummis mit Grapefruit-, Maracuja- und Kiwigeschmack',
          price: '2.74',
        },
        {
          link: 'https://www.haeppycandy.de/products/bumble-berry',
          image:
            'https://www.haeppycandy.de/cdn/shop/files/6_750x.svg?v=1704277812',
          variant_id: 44914028347657,
          title: 'Bumble Berry',
          desc: 'Jetzt Bestellen! Häppy Candy vegane Fruchtgummis mit Holunder-, Himbeere- und Kirschgeschmack',
          price: '2.74',
        },
      ],
    },
  ];
  const currentBundle = (contract_id) => {
    let contract = data.fetchCustomers?.customerSubscriptions?.find(
      (c) => +c.shopifyId === +contract_id
    );
    let currentbundle;
    let apiData = contract?.apiData;
    try {
      apiData =
        apiData != null
          ? JSON.parse(
            apiData?.replaceAll('=>', ':')?.replaceAll('nil', '"nil"')
          )
          : '';
    } catch (e) {
      apiData = '';
    }
    apiData?.lines?.edges?.map((line) => {
      currentbundle ||= happyCandyProducts?.find((bundle) => {
        return bundle?.variants?.some((product) => {
          return +product.variant_id === +line.node.variant_id?.match(/\d+/)[0];
        });
      });
    });
    return currentbundle;
  };
  ////

  const [{ startMonth, startYear }, setStartDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [{ endMonth, endYear }, setEndDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [appliedFilters, setAppliedFilters] = useState([]);
  const handleFilterByDate = () => {
    setFilterConsignments([]);
  };

  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );

  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);

  const handleCustomerRemove = useCallback(
    () => setSearchFilter({ ...searchFilter, customer: null }),
    []
  );
  const handleProductRemove = useCallback(
    () => setSearchFilter({ ...searchFilter, product: null }),
    []
  );
  const handleVariantRemove = useCallback(
    () => setSearchFilter({ ...searchFilter, variant: null }),
    []
  );
  const handleSellingPlanRemove = useCallback(
    () => setSearchFilter({ ...searchFilter, sellingPlan: null }),
    []
  );
  const handleNextBillingDateRemove = useCallback(() => setSelectedDates(), []);

  const handleFiltersClearAll = useCallback(() => {
    handleCustomerRemove();
    handleProductRemove();
    handleVariantRemove();
    handleSellingPlanRemove();
  }, []);

  const fetchAllSellingPlan = () => {
    fetch('/fetch_all_selling_plans?shopify_domain=' + domain)
      .then((r) => r.json())
      .then((data) => {
        if (data?.selling_plans?.length > 0) {
          setALlSellingPlans(data?.selling_plans);
          let options = [];
          data?.selling_plans?.map((s) => {
            options.push({ label: s.name, value: `${s.id}` });
          });
          options.unshift({ label: 'ANY', value: null });
          setSellingPlanOptions([...options]);
        }
      });
  };

  useEffect(() => {
    if (!contractToken) {
      fetchAllSellingPlan();
    }
  }, []);

  const filters = [
    {
      key: 'customer',
      label: 'Customer',
      filter: (
        <TextField
          label=""
          value={searchFilter?.customer}
          placeholder="Select Customer"
          onChange={(e) => {
            setSearchFilter({ ...searchFilter, customer: e });
          }}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: 'product',
      label: 'Product',
      filter: (
        <TextField
          label=""
          value={searchFilter?.product}
          placeholder="Select Customer"
          onChange={(e) => {
            setSearchFilter({ ...searchFilter, product: e });
          }}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: 'productVariant',
      label: 'Product Variant',
      filter: (
        <TextField
          label=""
          value={searchFilter?.variant}
          placeholder="Select Customer"
          onChange={(e) => {
            setSearchFilter({ ...searchFilter, variant: e });
          }}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: 'sellingPlan',
      label: 'Selling Plan',
      filter: (
        <Select
          label=""
          options={sellingPlansOptions}
          onChange={(e) => {
            setSearchFilter({ ...searchFilter, sellingPlan: e });
          }}
          value={`${searchFilter?.sellingPlan}`}
          labelHidden
        />
      ),
      shortcut: true,
    },
    // {
    //   key: 'createdAt',
    //   label: 'Created At',
    //   filter: (
    //     <div className='created_at_filter_wapper'>
    //       <RadioButton
    //         label="Today"
    //         checked={searchFilter?.createdAt === 'today'}
    //         id="today"
    //         name="createdAt"
    //         onChange={() => { setSearchFilter({ ...searchFilter, createdAt: "today" }) }}
    //       />
    //       <RadioButton
    //         label="Last 7 days"
    //         checked={searchFilter?.createdAt === '7days'}
    //         id="7days"
    //         name="createdAt"
    //         onChange={() => { setSearchFilter({ ...searchFilter, createdAt: "7days" }) }}
    //       />
    //       <RadioButton
    //         label="Last 30 days"
    //         checked={searchFilter?.createdAt === '30days'}
    //         id="30days"
    //         name="createdAt"
    //         onChange={() => { setSearchFilter({ ...searchFilter, createdAt: "30days" }) }}
    //       />
    //       <RadioButton
    //         label="Last 90 days"
    //         checked={searchFilter?.createdAt === '90days'}
    //         id="90days"
    //         name="createdAt"
    //         onChange={() => { setSearchFilter({ ...searchFilter, createdAt: "90days" }) }}
    //       />
    //       <RadioButton
    //         label="Custom"
    //         checked={searchFilter?.createdAt === 'custom'}
    //         id="custom"
    //         name="createdAt"
    //         onChange={() => { setSearchFilter({ ...searchFilter, createdAt: "custom" }) }}
    //       />
    //       {
    //         searchFilter?.createdAt == "custom" && (
    //           <>
    //             <div className='createdAtLabelWrapper'>
    //               <label className='custom_created_at_label'>Start date</label>
    //               <DatePicker
    //                 month={startMonth}
    //                 year={startYear}
    //                 onChange={(e) => setSelectedCreatedAtDates(e)}
    //                 onMonthChange={(month, year) => { setStartDate({ month, year }) }}
    //                 selected={selectedDates}
    //               />
    //             </div>
    //             <div className='createdAtLabelWrapper'>
    //               <label className='custom_created_at_label'>End date</label>
    //               <DatePicker
    //                 month={endMonth}
    //                 year={endYear}
    //                 onChange={(e) => setSelectedEndDates(e)}
    //                 onMonthChange={(month, year) => { setEndDate({ month, year }) }}
    //                 selected={selectedEndDates}
    //               />
    //             </div>
    //           </>

    //         )
    //       }
    //     </div>
    //   ),
    //   shortcut: true,
    // },
    {
      key: 'nextBillingDate',
      label: 'Next Billing Date',
      filter: (
        <div className="created_at_filter_wapper">
          <RangePicker
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            handleFilterByDate={() => handleFilterByDate}
          />
        </div>
      ),
      shortcut: true,
    },
  ];

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'customer':
        return `Customer:- $${value}`;
      case 'product':
        return `Product:- ${value}`;
      case 'variant':
        return `Variant:- ${value}`;
      case 'sellingPlan':
        return `SellingPlan:- ${value}`;
      case 'nextBillingDate':
        return value;
      default:
        return value;
    }
  }

  useEffect(() => {
    let applied_filters = [];
    if (!isEmpty(searchFilter?.customer)) {
      const key = 'customer';
      applied_filters.push({
        key,
        label: disambiguateLabel(key, searchFilter?.customer),
        onRemove: handleCustomerRemove,
      });
    }
    if (!isEmpty(searchFilter?.product)) {
      const key = 'product';
      applied_filters.push({
        key,
        label: disambiguateLabel(key, searchFilter?.product),
        onRemove: handleProductRemove,
      });
    }
    if (!isEmpty(searchFilter?.variant)) {
      const key = 'variant';
      applied_filters.push({
        key,
        label: disambiguateLabel(key, searchFilter?.variant),
        onRemove: handleVariantRemove,
      });
    }
    if (!isEmpty(searchFilter?.sellingPlan)) {
      let sellingPlanName = allSellingPlans?.find(
        (s) => +s.id === +searchFilter?.sellingPlan
      )?.name;
      const key = 'sellingPlan';
      applied_filters.push({
        key,
        label: disambiguateLabel(key, sellingPlanName),
        onRemove: handleSellingPlanRemove,
      });
    }
    if (selectedDates) {
      const key = 'nextBillingDate';
      let label = `NextBillingDate from:- ${dayjs(selectedDates?.start).format(
        'YYYY-MM-DD'
      )} ${selectedDates?.end
        ? `to:- ${dayjs(selectedDates?.end).format('YYYY-MM-DD')}`
        : ''
        }`;
      applied_filters.push({
        key,
        label: disambiguateLabel(key, label),
        onRemove: handleNextBillingDateRemove,
      });
    }

    setAppliedFilters([...applied_filters]);
  }, [searchFilter, selectedDates]);

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [status, setStatus] = useState('active');
  const [searchQuery, setSearchQuery] = useState();
  const [headerButton, setHeaderButton] = useState('active');
  const [customHeader, setCustomHeader] = useState();
  const [subOrdersheaderButton, setsubOrdersHeaderButton] = useState('active');
  const [customers, setCustomers] = useState([]);
  const [filterCustomers, setFilterCustomers] = useState([]);
  const [summaryFilterCustomers, setSummaryFilterCustomers] = useState([]);
  const [detailView, setDetailView] = useState(false);
  const [summaryView, setSummaryView] = useState(selected_tab);

  useEffect(() => {
    setPage(1);
    setStatus(headerButton);
  }, [headerButton]);
  const headerButtons = [
    {
      val: 'active',
      name: 'Active',
    },
    {
      val: 'new',
      name: 'New',
    },
    {
      val: 'paused',
      name: 'Paused',
    },
    {
      val: 'expired',
      name: 'Canceled',
    },
    {
      val: 'all',
      name: 'All',
    },
    {
      val: 'pre_paid',
      name: 'Prepaid/GIft contracts',
    },
    {
      val: 'processed_orders',
      name: 'Processed orders',
    },
    {
      val: 'upcoming_orders',
      name: 'Upcoming orders',
    },
    {
      val: 'in_dunning',
      name: 'In dunning',
    },
    {
      val: 'failed_payments',
      name: 'Failed payments',
    },
  ];

  // -------------------
  const GET_CUSTOMERS = gql`
    query (
      $sortColumn: String
      $sortDirection: String
      $page: String
      $status: String
      $searchquery: String
      $billingStartDate: String
      $billingEndDate: String
      $customer: String
      $product: String
      $variant: String
      $sellingPlan: String
    ) {
      fetchCustomers(
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        page: $page
        status: $status
        searchquery: $searchquery
        billingStartDate: $billingStartDate
        billingEndDate: $billingEndDate
        customer: $customer
        product: $product
        variant: $variant
        sellingPlan: $sellingPlan
      ) {
        customerSubscriptions {
          id
          indexId
          shopifyId
          shopDomain
          firstName
          lastName
          name
          email
          phone
          communication
          subscription
          status
          autoCollection
          language
          createdAt
          updatedAt
          apiData
          apiResourceId
          apiSource
          deliveryDay
          deliveryDate
          token
          sellingPlanName
          additionalContacts {
            id
            firstName
            lastName
            email
            phone
            companyName
          }
          billingAddress {
            id
            language
            firstName
            lastName
            email
            company
            phone
            address1
            address2
            city
            zipCode
          }
        }
        totalCount
        totalPages
        pageNumber
      }
    }
  `;
  const { data, loading, error, refetch } = useQuery(GET_CUSTOMERS, {
    fetchPolicy: 'no-cache',
    variables: {
      page: page.toString(),
      searchquery: searchQuery,
      status: status,
      billingStartDate: selectedDates?.start
        ? `${dayjs(selectedDates?.start).format('DD/MM/YYYY')}`
        : null,
      billingEndDate: selectedDates?.end
        ? `${dayjs(selectedDates?.end).format('DD/MM/YYYY')}`
        : null,
      customer: searchFilter?.customer,
      product: searchFilter?.product,
      variant: searchFilter?.variant,
      sellingPlan: searchFilter?.sellingPlan,
    },
  })

  useEffect(() => {
    if (!contractToken) {
      if (searchQuery) {
        refetch({
          variables: {
            page: page.toString(),
            searchquery: searchQuery,
            customer: searchFilter?.customer,
            product: searchFilter?.product,
            variant: searchFilter?.variant,
            sellingPlan: searchFilter?.sellingPlan,
          },
        });
      } else {
        refetch({
          variables: {
            page: page.toString(),
            status: status,
            customer: searchFilter?.customer,
            product: searchFilter?.product,
            variant: searchFilter?.variant,
            sellingPlan: searchFilter?.sellingPlan,
          },
        });
      }
    }
    setTotalPages(data?.fetchCustomers?.totalPages);
  }, [page, status, searchQuery, selectedDates, searchFilter]);

  const handleFiltersQueryChange = (value) => {
    setQueryValue(value);
    if (value.length > 1) {
      setSearchQuery(value);
    } else {
      setSearchQuery();
    }
  };

  useEffect(() => {
    setTotalPages(data?.fetchCustomers?.totalPages);
    if (+page < +totalPages) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    if (+page <= 1) {
      setHasPrevious(false);
    } else {
      setHasPrevious(true);
    }
  }, [data]);

  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const hideSaveSuccess = useCallback(() => setSaveSuccess(false), []);

  const [selectedCustomers, setselectedCustomers] = useState([]);
  const handleChangeCheckedCustomers = (newChecked, customerId) => {
    if (newChecked) {
      setselectedCustomers([...selectedCustomers, customerId]);
    } else {
      const index = selectedCustomers.indexOf(customerId);
      setselectedCustomers([
        ...selectedCustomers.slice(0, index),
        ...selectedCustomers.slice(index + 1),
      ]);
    }
  };
  //upper first letter
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  //each row in data table
  const formatRows = (rows) => {
    let apiData;
    return rows?.map((row, i) => {
      try {
        apiData =
          row?.apiData != null
            ? JSON.parse(
              row?.apiData?.replaceAll('=>', ':')?.replaceAll('nil', '"nil"')
            )
            : '';
      } catch (e) {
        apiData = '';
      }
      let bundleObject = currentBundle(row.shopifyId);
      return row?.subscription !== null
        ? [
          <Checkbox
            label={row?.indexId}
            checked={selectedCustomers.indexOf(row.id) != -1}
            onChange={(newChecked) =>
              handleChangeCheckedCustomers(newChecked, row.id)
            }
          />,
          <a
            onClick={() => history.push(`/customers/${row.token}`)}
          >{`${row.firstName} ${row.lastName}`}</a>,
          <div className="subscription">
            {bundleObject ? bundleObject?.bundleName : row.subscription}
          </div>,
          <div className="subscription">{row.sellingPlanName}</div>,
          <div
            className={
              row.status === 'PAUSED'
                ? 'cancelled'
                : row.status === 'ACTIVE'
                  ? 'active'
                  : 'future'
            }
          >
            <Badge>{capitalize(row.status)}</Badge>
          </div>,
          apiData == ''
            ? ''
            : moment(apiData?.next_billing_date)?.format(
              'MMMM Do YYYY, h:mm:ss a'
            ),
          row.createdAt,
          <p className="capitalize">{row.apiSource || 'shopify'}</p>,

          <div>
            <p className="more">{row.communication}</p>
            <p>
              <span className="price">{row.language}</span>
            </p>
          </div>,
          <input
            type="date"
            value={row?.deliveryDate}
            onChange={(e) => {
              fetch(`/subscriptions/update_contract_delivery_date_day`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
                credentials: 'same-origin',
                body: JSON.stringify({
                  id: row.id,
                  date: e.target.value,
                  shopify_domain: domain,
                }),
              }).then((response) => response.json());
            }}
          />,
        ]
        : [];
    });
  };

  const navigate = (pathname) => {
    history.push(pathname);
  }
  const headerObject = {
    contracts: {
      title: 'Subscriptions Contracts',
      desc: "View & manage subscription contracts; edit billing/products; charge customer; view customer's account & more.",
    },
    subscription_orders: {
      title: 'Subscriptions Orders',
      desc: 'View & manage upcoming, failed & successful orders for each subscription contract',
    },
    subscription_plans: {
      title: 'Subscription Plans',
      desc: 'Create & manage selling plans with fixed billing, prepaid billing or more complex billing or trial rules.',
    },
    subscription_products: {
      title: 'Subscription Products',
      desc: 'Configure & manage subscription products, swap-eligible variants & product specific information for the portal',
      newTitle: "Create new subscription product",
      editTitle: "Update subscription product"
    },
    customerMigration: {
      title: 'Create/import a new subscription contract',
      desc: 'Create or import subscription contracts for one or multiple customers',
    },
    subscription_billing: {
      title: 'Dunning',
      desc: 'Configure dunning rules to set how failed transactions are managed.',
    },
    subscription_email: {
      title: 'Subscription notifications',
      desc: 'Configure & manage transactional email & SMS/Whatsapp notifications for subscription-related events.',
    },
    subscription_workflow: {
      title: 'Workflows',
      newTitle: 'Create new workflow',
      desc: 'Create and manage automations based on subscription events - swap product, order tags, rewards etc .',
    },
    bulk_action: {
      title: 'Bulk actions',
      desc: 'Make price, variant, discount, selling plan changes to multiple subscription contracts all at once.',
    },
    global_billing_hour: {
      title: 'Subscription Settings',
      desc: 'Manage billing rules; behaviour around product price & shipping rate changes; and more.',
    },
    quickActions: {
      newTitle: 'Create a new quick action',
      title: 'Quick actions',
      desc: 'Chargezen  quick actions are standard URLs that can be sent to customers so that they can quickly perform a variety of actions.',
    },
    default: {
      title: 'Subscriptions Home',
      desc: 'Manage contracts & memberships; view upcoming, billed, & failed charges; and configure subscription-specific flows, notifications & settings.',
    },
    subscriptionBundles: {
      desc: 'Create & manage fixed price or dynamic price build-a-box bundles on your shopify store or headless storefronts.',
      editTitle: 'Update subscription bundle',
      title: 'Subscription Bundles',
      newTitle: 'Create a new subscription bundle',
    },
    subscription_widget: {
      title: "Subscription widgets",
      desc: "Customize subscription widgets for product, collection pages & landing pages with upsells for max conversion",
    }
  };

  const mainHeaderButtons = {
    default: {
      buttons: [
        {
          name: "Import",
          onClick: () => navigate('/customersTab/customerMigration')
        },
        {
          name: "Create plan",
          onClick: () => navigate('/fixed-subscription-plans')
        }
      ]
    },
    subscription_workflow: {
      buttons: [
        {
          name: "Logs",
          onClick: () => { }
        },
        {
          name: "Create workflow",
          onClick: () => navigate('/customersTab/subscription_workflow/createNew')
        }
      ]
    },
    subscription_products: {
      buttons: [
        {
          name: "Widget",
          onClick: () => navigate('/theme')
        },
        {
          name: "Add product",
          onClick: () => navigate('/customersTab/subscription_products/createNew')
        }
      ]
    },
    contracts: {
      buttons: [
        {
          name: "Reports",
          onClick: () => navigate('/analytics/reports')
        },
        {
          name: "Import",
          onClick: () => navigate('/customersTab/customerMigration')
        }
      ]
    },
    subscription_plans: {
      buttons: [
        {
          name: "Actions",
          onClick: () => navigate('/customersTab/bulk_action')
        },
        {
          name: "Create plan",
          onClick: () => navigate('/fixed-subscription-plans')
        }
      ]
    }
  };


  useEffect(() => {
    if (selected_tab) {
      setSummaryView(selected_tab);
    } else {
    }
  }, [selected_tab]);

  const filterCustomersValue = () => {
    setFilterCustomers(customers);
  };

  // useEffect(() => {
  //   if (summaryView != selected_tab) {
  //     history.push(`/customersTab/${summaryView}`);
  //   }
  // }, [summaryView, selected_tab]);

  useEffect(() => {
    if (customers) {
      filterCustomersValue();
    }
    // console.log('searchvalue: ', queryValue);
  }, [queryValue, taggedWith, customers]);

  // useEffect(() => {
  //   filterCustomersValue();
  // }, [selectedCustomers]);

  useEffect(() => {
    if (data && data.fetchCustomers) {
      setCustomers(data.fetchCustomers?.customerSubscriptions);
    }
  }, [data]);

  //export data to csv
  // const headers = [
  //   { label: 'Number', key: 'id' },
  //   { label: 'Name', key: 'name' },
  //   { label: 'Date Created', key: 'createdAt' },
  //   { label: 'Status', key: 'status' },
  //   { label: 'Subscription', key: 'subscription' },
  //   { label: 'additionalContacts', key: 'additionalContacts' },
  // ];
  const headers = [
    { label: 'Number', key: 'id' },
    { label: 'firstName', key: 'firstName' },
    { label: 'lastName', key: 'lastName' },
    { label: 'Date Created', key: 'createdAt' },
    { label: 'Status', key: 'status' },
    { label: 'Subscription', key: 'subscription' },
    { label: 'additionalContacts.email', key: 'additionalContacts.email' },
    {
      label: 'additionalContacts.firstName',
      key: 'additionalContacts.firstName',
    },
    {
      label: 'additionalContacts.lastName',
      key: 'additionalContacts.lastName',
    },
    {
      label: 'additionalContacts.companyName',
      key: 'additionalContacts.companyName',
    },
    { label: 'additionalContacts.phone', key: 'additionalContacts.phone' },

    { label: 'billingAddress.firstName', key: 'billingAddress.firstName' },
    { label: 'billingAddress.lastName', key: 'billingAddress.lastName' },
    { label: 'billingAddress.email', key: 'billingAddress.email' },
    { label: 'billingAddress.company', key: 'billingAddress.company' },
    { label: 'billingAddress.phone', key: 'billingAddress.phone' },
    { label: 'billingAddress.language', key: 'billingAddress.language' },
    { label: 'billingAddress.city', key: 'billingAddress.city' },
  ];
  const fields = [
    'id',
    'firstName',
    'lastName',
    'email',
    'communication',
    'phone',
    'language',
    'autoCollection',
    'status',
    'subscription',

    'additionalContacts.id',
    'additionalContacts.email',
    'additionalContacts.firstName',
    'additionalContacts.lastName',
    'additionalContacts.companyName',
    'additionalContacts.phone',

    'billingAddress.firstName',
    'billingAddress.lastName',
    'billingAddress.email',
    'billingAddress.company',
    'billingAddress.phone',
    'billingAddress.address1',
    'billingAddress.address2',
    'billingAddress.city',
    'billingAddress.language',
    'billingAddress.zipCode',
  ];
  const transforms = [
    unwind({ paths: ['additionalContacts', 'billingAddress'] }),
  ];
  // const exportCSV = () => {
  const dataSelected = [...filterCustomers].filter((item) =>
    selectedCustomers.find((select) => select === item.id)
  );
  const json2csvParser = new Parser({ fields, transforms });
  let csv = json2csvParser.parse(dataSelected);

  //

  // };

  //import customer by csv:
  const [active, setActive] = useState(false);
  const [checked, setChecked] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleCheckbox = useCallback((value) => setChecked(value), []);

  //upload file
  const [file, setFile] = useState();

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFile((file) => acceptedFiles[0]),
    []
  );

  // const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFile = file && (
    <Stack>
      <Thumbnail size="small" alt={file.name} source={NoteMinor} />
      <div>
        {file.name} <Caption>{file.size} bytes</Caption>
      </div>
    </Stack>
  );
  //config

  const csvData = [];

  const parsedata =
    file &&
    Papa.parse(file, {
      header: false,
      step: function (result) {
        csvData.push(result.data);
      },
      complete: function (results, file) {
        // console.log('csvData: ', csvData);
      },
    });
  const handleImportCustomer = () => {
    const rcs = [];
    const tempData = csvData.slice(1);
    tempData?.map((item) => {
      const index = rcs.findIndex((customer) => customer.email == item[3]);

      if (index !== -1) {
        rcs[index].additionalContacts.push({
          // id: item[10],
          email: item[11],
          firstName: item[12],
          lastName: item[13],
          companyName: item[14],
          phone: item[15],
        });
      } else {
        rcs.push({
          // id: item[0],
          firstName: item[1],
          lastName: item[2],
          email: item[3],
          communication: item[4],
          phone: item[5],
          language: item[6],
          autoCollection: Boolean(item[7]),
          status: item[8],
          subscription: item[9],
          additionalContacts:
            item[11] === ''
              ? []
              : [
                {
                  email: item[11],
                  firstName: item[12],
                  lastName: item[13],
                  companyName: item[14],
                  phone: item[15],
                },
              ],
          billingAddress: {
            firstName: item[16],
            lastName: item[17],
            email: item[18],
            company: item[19],
            phone: item[20],
            address1: item[21],
            address2: item[22],
            city: item[23],
            language: item[24],
            zipCode: item[25],
          },
        });
      }
    });

    createCustomer({
      variables: {
        input: { params: rcs },
      },
    }).then((resp) => {
      // const errors = resp.errors;
      const data = resp.data;
      const errors = data.errors;

      if (errors) {
        setFormErrors(errors);
      } else {
        setSaveSuccess(true);
        refetch({
          variables: {
            page: page.toString(),
          },
        });
      }
    });
    setFile();
    // refetch();
  };
  const CREATE_CUSTOMER = gql`
    mutation ($input: AddCustomersInput!) {
      addCustomers(input: $input) {
        result
      }
    }
  `;
  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  const handleEndMonthChange = (m, y) => {
    setEndDate({ month: m, year: y });
  };

  const handleStartMonthChange = (m, y) => {
    setStartDate({ month: m, year: y });
  };

  const RenderCustomHeader = () => {
    return (
      <div className="chargezen_custom_page_header">
        <div className="page_title_wrapper">
          <div className="back_button_wrapper">
            {summaryView && (
              <div
                className="back-button pointer"
                onClick={() => {
                  let toRedirect = edit_id
                    ? `/customersTab/${selected_tab}`
                    : `/customers`;
                  history.push(toRedirect);
                }}
              >
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
            )}
            <div class="text_wrapper page_heading_title_wrapper">
              <div
                class="Polaris-Header-Title__TitleAndSubtitleWrapper"
                style={{
                  width: '100%',
                }}
              >
                <div class="Polaris-Header-Title">
                  <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">
                    <span class="Polaris-TextStyle--variationStrong">
                      <div class="back_button_wrapper">
                        {' '}
                        <div
                          class="text_wrapper page_heading_title_wrapper"
                          style={{
                            width: '100%',
                          }}
                        >
                          <span>
                            {
                              customHeader ? customHeader
                                :
                                `${edit_id}`.includes('create')
                                  ? headerObject[selected_tab || 'default']?.newTitle
                                  : (edit_id && headerObject[selected_tab || 'default']?.editTitle) ?
                                    headerObject[selected_tab || 'default']?.editTitle
                                    : headerObject[selected_tab || 'default']?.title}
                          </span>

                          <div className="shpoify_style_button_wrapper">
                            {
                              !edit_id && (
                                mainHeaderButtons[selected_tab || 'default']?.buttons?.map((hButton) => {
                                  return (
                                    <Button
                                      onClick={() => hButton?.onClick()}
                                    >
                                      {hButton?.name}
                                    </Button>
                                  )
                                })
                              )

                            }
                          </div>
                        </div>
                      </div>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sidebar_summary header_description">
          {headerObject[selected_tab || 'default']?.desc}
        </div>
      </div>
    );
  };

  return (
    <>
      {contractToken ? (
        <SubscriptionView
          token={contractToken}
          domain={domain}
          i18n={i18n}
          contractToken={contractToken}
          formatDate={formatDate}
        />
      ) : (
        <>
          <Frame>
            {formErrors.length > 0 && (
              <>
                <Banner
                  title="Customer data has not been saved"
                  status="critical"
                >
                  <List type="bullet">
                    {formErrors.map((message, index) => (
                      <List.Item key={index}>{message.message}</List.Item>
                    ))}
                  </List>
                </Banner>
                <br />
              </>
            )}
            {loadingScreen && (
              <>
                <LoadingScreen />
              </>
            )}
            <Page>
              {<RenderCustomHeader />}
              <>
                <div className={`chargezen_full_width_main_container`}>
                  {/* <div
                    className={`${
                      detailView ? 'sidebar_summary_right_section' : 'hidden'
                    }`}
                  >
                    <SummaryComponent
                      {...{ i18n, summaryView, setSummaryView }}
                    />
                  </div> */}
                  <div className={`chargezen_full_width_container`}>
                    <div className={`chargezen_full_width_wrapper`}>
                      {!summaryView && (
                        <>
                          <SubscriptionOverview
                            {...{
                              i18n,
                              floatingSetupGuide,
                              setFloatingSetupGuide,
                            }}
                            customNavTabs={{ subscription_home: true }}
                          />
                        </>
                      )}
                      {summaryView === 'contracts' && (
                        <RenderContracts
                          {...{
                            domain,
                            shopifyDomain,
                            i18n,
                            detailView,
                            setDetailView,
                            saveSuccess,
                            hideSaveSuccess,
                            headerButtons,
                            setHeaderButton,
                            headerButton,
                            dataSelected,
                            filterCustomers,
                            json2csvParser,
                            selectedTab,
                            summaryFilterCustomers,
                            formatRows,
                            loading,
                            hasPrevious,
                            hasMore,
                            setPage,
                            queryValue,
                            filters,
                            appliedFilters,
                            handleFiltersQueryChange,
                            handleQueryValueRemove,
                            handleFiltersClearAll,
                            sortOrder,
                            setSortOrder,
                            setFilterCustomers,
                            customers,
                          }}
                        />
                      )}
                      {summaryView === 'subscription_orders' && (
                        <>
                          <SubscriptionOrders
                            domain={domain}
                            shopifyDomain={shopifyDomain}
                            i18n={i18n}
                            detailView={detailView}
                            setDetailView={setDetailView}
                          />
                        </>
                      )}
                      {summaryView === 'customerMigration' && (
                        <>
                          <CustomerMigration
                            i18n={i18n}
                            detailView={detailView}
                            setDetailView={setDetailView}
                            domain={domain}
                          />
                        </>
                      )}

                      {summaryView === 'subscription_products' && (
                        <>
                          <SubscriptionProducts
                            i18n={i18n}
                            detailView={detailView}
                            setDetailView={setDetailView}
                          />
                        </>
                      )}
                      {summaryView === 'subscription_plans' && (
                        <>
                          <SellingPlans
                            i18n={i18n}
                            detailView={detailView}
                            setDetailView={setDetailView}
                          />
                        </>
                      )}
                      {summaryView === 'bulk_action' && (
                        <>
                          <SellingPlans
                            i18n={i18n}
                            detailView={detailView}
                            setDetailView={setDetailView}
                            _view_type={'power'}
                          />
                        </>
                      )}

                      {summaryView === 'subscription_workflow' && (
                        <>
                          <Workflows
                            domain={domain}
                            shopifyDomain={shopifyDomain}
                            i18n={i18n}
                            detailView={detailView}
                            setDetailView={setDetailView}
                            formatDate={formatDate}
                            {...{ customHeader, setCustomHeader }}
                          />
                        </>
                      )}

                      {summaryView === 'subscription_email' && (
                        <>
                          <SubscriptionEmail
                            domain={domain}
                            i18n={i18n}
                            detailView={detailView}
                            setDetailView={setDetailView}
                            selectedIndex={selectedEmailIndex}
                            setSelectedIndex={setSelectedEmailIndex}
                            formatDate={formatDate}
                          />
                        </>
                      )}

                      {summaryView === 'subscription_billing' && (
                        <>
                          <SubscriptionBilling
                            {...{ domain, i18n, detailView, setDetailView }}
                          />
                        </>
                      )}

                      {summaryView === 'global_billing_hour' && (
                        <GlobalBillingHour
                          domain={domain}
                          shopifyDomain={shopifyDomain}
                          i18n={i18n}
                          detailView={detailView}
                          setDetailView={setDetailView}
                        />
                      )}
                      {summaryView === 'quickActions' && (
                        <QuickActions
                          {...{
                            i18n,
                            detailView,
                            setDetailView,
                            domain,
                          }}
                        />
                      )}
                      {summaryView === 'subscriptionBundles' && (
                        <SubscriptionBundles
                          {...{
                            i18n,
                            detailView,
                            setDetailView,
                            domain,
                            formatDate,
                          }}
                        />
                      )}
                      {summaryView === 'subscription_widget' && (
                        <ThemeExtension
                          {...{
                            i18n,
                            detailView,
                            setDetailView,
                            domain,
                            formatDate,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            </Page>
          </Frame>
          <Modal
            large
            open={active}
            onClose={toggleActive}
            title="Import customers by CSV"
            primaryAction={{
              content: 'Import customers',
              onAction: () => {
                toggleActive();
                handleImportCustomer();
                // parsedata;
              },
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: () => {
                  toggleActive();
                  setFile();
                },
              },
            ]}
          >
            <Modal.Section>
              <Stack vertical>
                <DropZone
                  allowMultiple={false}
                  onDrop={handleDropZoneDrop}
                  accept=".csv"
                  errorOverlayText="File type must be .csv"
                  type="file"
                >
                  {uploadedFile}
                  {fileUpload}
                </DropZone>
                {/* <Checkbox
              checked={checked}
              label="Overwrite existing customers that have the same customer ID"
              onChange={handleCheckbox}
            /> */}
              </Stack>
            </Modal.Section>
          </Modal>
        </>
      )}
    </>
  );
};

export default Customers;
