import React, { useState, useEffect, useCallback, useRef } from 'react';

import RevenueTrends from './RevenueTrends';
import CustomerInsights from './CustomerInsights';
import Retention from './Retention';
import Product from './Product';
import { FilterContextProvider } from './../common/Contexts/AnalyticsFilterContext';
import {
  Card,
  Select,
  ExceptionList,
  Button,
  Icon,
  Modal,
  TextField,
  Layout,
  Page,
  Stack,
  Tabs,
  Frame,
} from '@shopify/polaris';
import { FlagMajor, ChevronDownMinor } from '@shopify/polaris-icons';
import SmartSms from './SmartSms';
import PixelIcon from '../../assets/images/PixelIcon';
import HeaderButtons from '../HeaderButtons/HeaderButtons';

const MLAlgorithms = (props) => {
  const { i18n } = props
  const [selectedTitleTab, setSelectedTitleTab] = useState(0);
  const [headerButton, setHeaderButton] = useState(0)
  useEffect(() => { setSelectedTitleTab(headerButton) },
    [headerButton]
  );
  const headerButtons = [
    {
      val: 0,
      name: i18n.t('sentiment_analysis'),
      index: 0
    },
    {
      val: 3,
      name: i18n.t('k_means_clusters'),
      index: 3
    },
    {
      val: 1,
      name: i18n.t('anomaly_detection'),
      index: 1
    },
    // ...(process.env.APP_TYPE=="public" ?
    // [{
    //   id: 'retention',
    //   content: 'Retention',
    // }]:[]),
    {
      val: 4,
      name: i18n.t('market_basket_analysis'),
      index: 4
    }
  ];

  useEffect(() => {
    let currentPath = window.location.pathname.split("/").pop();
    switch (currentPath) {
      case "kMeansClusters":
        setSelectedTitleTab(3)
        setHeaderButton(3)
        break;
      case "sentiment-analysis":
        setSelectedTitleTab(0)
        setHeaderButton(0)
        break;
      case "anomaly-detection":
        setSelectedTitleTab(1)
        setHeaderButton(1)
        break;
      case "market-basket-analysis":
        setSelectedTitleTab(4)
        setHeaderButton(4)
        break;
      default:
        break;
    }
  }, [window.location.pathname])
  return (
    <Frame>
      <Page title={i18n.t('customer_segments')}>
        <FilterContextProvider>
          <Card
            title={
              <div className="heading_title">
                <PixelIcon />
                {i18n.t('customer_segments')}
              </div>}
            actions={{
              content:
                <div className='tabButtons'>
                  <HeaderButtons headerButtons={headerButtons} setHeaderButton={setHeaderButton} headerButton={headerButton} />
                </div>
            }}
            sectioned
          >
            <Card.Section subdued>
              {selectedTitleTab === 0 ? (
                <div className="analytics-page-layout">
                  <RevenueTrends i18n={i18n} />
                </div>
              ) : selectedTitleTab === 1 ? (
                <div className="customer-insight">
                  <CustomerInsights i18n={i18n} />
                </div>
              )
                :
                (process.env.APP_TYPE == "public" && selectedTitleTab === 2) ? (
                  <div className="retention">
                    <Retention i18n={i18n} />
                  </div>
                )
                  : selectedTitleTab === (process.env.APP_TYPE == "public" ? 3 : 2) ? (
                    <div className="product">
                      <Product i18n={i18n} />
                    </div>
                  ) : selectedTitleTab === (process.env.APP_TYPE == "public" ? 4 : 3) ? (
                    <>
                      <SmartSms i18n={i18n} />
                    </>
                  ) :
                    ""}
            </Card.Section>
          </Card>
        </FilterContextProvider>
      </Page>
    </Frame>
  );
};
export default MLAlgorithms;
