import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Card,
  Page,
  LegacyCard,
  DataTable,
  Pagination,
  Button,
  Badge,
} from '@shopify/polaris';
import dayjs from 'dayjs';

function sortCurrency(rows, index, direction) {
  return [...rows].sort((rowA, rowB) => {
    const amountA = rowA[index].toString().toLowerCase();
    const amountB = rowB[index].toString().toLowerCase();

    return direction === 'descending' ? amountB - amountA : amountA - amountB;
  });
}

export default function Tables({ headerButton, apiData, formatDate }) {
  let tableData = JSON.parse(JSON.stringify(apiData));
  const [sortedRows, setSortedRows] = useState(null);
  const [formattedRows, setFormattedRows] = useState([]);

  const initiallySortedRows = [
    [
      <span className="success">Success</span>,
      'B €24.00 EUR payment was processed using a Mastercard ending in 9927',
      'Tue, July 4 13:24:27',
    ],
    [
      <span className="failed">Failed</span>,
      'A €24.00 EUR payment was processed using a Mastercard ending in 9927',
      'Wed, July 5 12:30:27',
    ],
  ];
  const rows = sortedRows ? sortedRows : initiallySortedRows;

  const handleSort = useCallback(
    (index, direction) => setSortedRows(sortCurrency(rows, index, direction)),
    [rows]
  );

  const allOrders = [];
  tableData?.subscription?.orders?.edges?.map((order) => {
    order?.node?.events?.edges.map((item) => allOrders.push(item));
  });
  const [pageinationData, setPaginationData] = useState({
    page: 1,
    totalPages: 1,
    perPage: 7,
  });

  const paginatedData = (data, pagenumber = 1) => {
    //manage pagination
    let pagedata = pageinationData;
    let totalPages = Math.ceil(+data?.length / +pagedata?.perPage);
    let hasnexPage = +pagenumber < +totalPages;
    let hasPreviousPage = +pagenumber > 1;
    let paginatedata = [];
    let fromIndex = +pagedata.perPage * (pagenumber - 1);
    let toIndex = fromIndex + pagedata.perPage;
    setPaginationData({
      ...pageinationData,
      page: pagenumber,
      totalPages: totalPages,
      hasnexPage: hasnexPage,
      hasPreviousPage: hasPreviousPage,
    });
    for (let i = fromIndex; i < toIndex; i++) {
      if (i > data.length - 1) {
        //break if there is no data
        break;
      }
      paginatedata.push(data[i]);
    }
    //return paginated/filtered Data
    return paginatedata;
  };
  const formatRows = (pagenumber = 1) => {
    if (tableData) {
      if (headerButton == 'Delivery-Schedule') {
        if (apiData?.shipping_dates?.length > 0) {
          return apiData?.shipping_dates?.map((date, i) => {
            return [
              <div className='delivery_schedule_num_date_wrapper'>
                <strong className='schedule_number'>#{i + 1}</strong>
                <div className="delivery_schedule_date_wrapper">
                  <div className="delivery_schedule_container">
                    {`${dayjs(date)?.format('MMMM DD, YYYY hh:mm A')}`}
                  </div>
                  <div className="delivery_schedulre_button_groups">
                    {
                      i === 1 && (
                        <Button>Place order</Button>
                      )
                    }
                    <Button>Skip order</Button>
                    {
                      i === 1 && (
                        <Button>Reschedule</Button>
                      )
                    }
                  </div>
                </div>
              </div>,
              <div className='delivery_schedule_bage_wrapper'>
                <Badge status="attention">SCHEDULED</Badge>
              </div>,
            ];
          })
        } else {
          return [1, 2, 3, 4, 5]?.map((i) => {
            let next_date = dayjs(tableData?.subscription?.next_billing_date).add(
              i * +tableData?.subscription?.billing_policy?.interval_count,
              tableData?.subscription?.billing_policy?.interval?.toLowerCase()
            );

            return [
              <div className='delivery_schedule_num_date_wrapper'>
                <strong className='schedule_number'>#{i + 1}</strong>
                <div className="delivery_schedule_date_wrapper">
                  <div className="delivery_schedule_container">
                    {`${dayjs(next_date)?.format('MMMM DD, YYYY hh:mm A')}`}
                  </div>
                  <div className="delivery_schedulre_button_groups">
                    {
                      i === 1 && (
                        <Button>Place order</Button>
                      )
                    }
                    <Button>Skip order</Button>
                    {
                      i === 1 && (
                        <Button>Reschedule</Button>
                      )
                    }
                  </div>
                </div>
              </div>,
              <div className='delivery_schedule_bage_wrapper'>
                <Badge status="attention">SCHEDULED</Badge>
              </div>,
            ];
          })
        }
      }
      else if (headerButton === 'Logs') {
        //get the paginated/filtered data
        let filteredData = paginatedData(allOrders, pagenumber);
        return filteredData?.map((row) => {
          return [
            'Success',
            row?.node?.message,
            formatDate(row?.node?.created_at),
          ];
        });
      } else if (headerButton === 'Payment History') {
        return tableData?.subscription?.orders?.edges?.map((order) => {
          return [
            formatDate(order.node.created_at),
            order?.node?.name,
            `$${order?.node?.total_received_set?.presentment_money?.amount}`,
          ];
        });
      } else if (headerButton === 'Weekly-Menu') {
        let preOrders = tableData?.pre_orders
          ?.sort((a, b) => (a?.week || 0) - (b?.week || 0))
          ?.reverse();
        return preOrders?.map((pre_order) => {
          return [
            pre_order?.week,
            <ul>
              {tableData?.pre_order_products[pre_order?.id]?.map((product) => {
                return <li>{`${product?.title} x ${product?.quantity}`}</li>;
              })}
            </ul>,
          ];
        });
      } else if (headerButton === 'Billing-Attempts') {
        return tableData?.billing_attempts?.map((billing_attempt) => {
          return [
            formatDate(billing_attempt?.node?.created_at),
            billing_attempt?.node?.error_code,
            billing_attempt?.node?.error_code || 'N/A',
            billing_attempt?.node?.error_message || 'N/A',
          ];
        });
      } else if (headerButton === 'Contract-History') {
        return tableData?.subscription_logs?.map((subscription_log, i) => {
          return [
            formatDate(subscription_log?.created_at),
            subscription_log?.description,
            subscription_log?.action_by,
          ];
        });
      }
    } else {
      return [[]];
    }
  };

  const handlePagination = (pageNumber = 1) => {
    setFormattedRows(formatRows(pageNumber));
  };

  useEffect(() => {
    if (headerButton != 'Delivery-Schedule') {
      handlePagination(1);
    }
  }, [headerButton, apiData]);

  return (
    <>
      {tableData && (
        <>
          {headerButton === 'Delivery-Schedule' && (
            <div className="tab-conten-wrap" id="Billing-Attempts">
              <DataTable
                columnContentTypes={['text', 'text', 'text', 'text']}
                headings={[]}
                rows={formatRows() || []}
              />
            </div>
          )}
          {headerButton === 'Logs' && (
            <div className="tab-conten-wrap" id="Logs">
              <p>All timestamps are in Eastern Time (US)</p>
              <DataTable
                columnContentTypes={['text', 'text', 'text']}
                headings={['Status', 'Description', 'Date']}
                sortable={[true, true, true]}
                rows={formattedRows}
                onSort={handleSort}
                defaultSortDirection="descending"
                initialSortColumnIndex={2}
              />
              <Pagination
                label={
                  <div className="pagenumbers">
                    <span className="current_page">
                      {pageinationData?.page} of {pageinationData?.totalPages}
                    </span>
                  </div>
                }
                hasPrevious={pageinationData?.hasPreviousPage}
                onPrevious={() => {
                  handlePagination(+pageinationData?.page - 1);
                }}
                hasNext={pageinationData?.hasnexPage}
                onNext={() => {
                  handlePagination(+pageinationData?.page + 1);
                }}
              />
            </div>
          )}
          {headerButton === 'Payment History' && (
            <div className="tab-conten-wrap" id="Payment History">
              <p>All timestamps are in EST Time </p>

              <DataTable
                columnContentTypes={['text', 'text', 'text']}
                headings={['Date', 'Order', 'Amount']}
                rows={formattedRows || []}
              />
            </div>
          )}

          {headerButton === 'Weekly-Menu' && (
            <div className="tab-conten-wrap" id="Weekly-Menu">
              <p>Pre orders for this customer</p>

              <DataTable
                columnContentTypes={['text', 'text']}
                headings={['Week', 'Products']}
                rows={formattedRows || []}
              />
            </div>
          )}

          {headerButton === 'Billing-Attempts' && (
            <div className="tab-conten-wrap" id="Billing-Attempts">
              <DataTable
                columnContentTypes={['text', 'text', 'text', 'text']}
                headings={[
                  'Created at',
                  'Status',
                  'Error Code',
                  'Error Message',
                ]}
                rows={formattedRows || []}
              />
            </div>
          )}

          {headerButton === 'Contract-History' && (
            <div className="tab-conten-wrap" id="Contract-History">
              <DataTable
                columnContentTypes={['text', 'text', 'text']}
                headings={['Date', 'Action', 'Action Taken By']}
                rows={formattedRows || []}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
