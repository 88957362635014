import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import { gql, useLazyQuery } from '@apollo/client';
import {
  Page,
  EmptyState,
  Frame,
  CalloutCard,
  Card,
  Icon,
  Stack,
  Badge,
  DisplayText,
  Button,
  Layout,
  Tabs,
  Heading,
  Spinner,
} from '@shopify/polaris';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import _, { map } from 'lodash';
import integrations from '../../integrations';
import { groupBy } from '../common/utils/utils';
import LoadingScreen from '../LoadingScreen';
// import IntegrationDetail from '../integration/Detail';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import { useParams, useHistory } from 'react-router-dom';
import IntegrationCard from './IntegrationCard';
import IntegrationDetail from './IntegrationDetail';

const Integrations = (props) => {
  const { handleBack, handleForm, i18n } = props;
  const { edit_id, selected_tab, selectedTab } = useParams();
  const history = useHistory();

  const mapCatagory = useMemo(
    () => ({
      all: '',
      sale: 'sales',
      marketing: 'marketing',
      report: 'reporting_and_analytics',
      collabration: 'collaboration',
      accounting: 'accounting',
      customer: 'customer_support_and_success',
      contract: 'contract_management',
      tax: 'tax_management',
    }),
    []
  );

  const IntegerationsQuery = gql`
    query ($type: String!) {
      fetchIntegrations(type: $type) {
        id
        name
        integrationType
        serviceType
        default
        credentials {
          privateKey
          publicKey
          twilioAccountSid
          twilioAuthToken
          twilioPhoneNumber
          activeCampaignApiKey
          activeCampaignApiEndpoint
          activeCampaignListId
        }
        status
        keys
      }
    }
  `;

  const [headerButton, setHeaderButton] = useState(edit_id || 0);

  let headerButtons = integrations.map((item, index) => ({
    name: item.title,
    id: item.id,
    val: index,
  }));

  const [selected, setSelected] = useState(0);
  const [category, setCategory] = useState(headerButtons[selected].id || 'all');

  useEffect(() => {
    setSelected(headerButton);
    setCategory(headerButtons[headerButton].id);
  }, [headerButton]);

  const [fetchIntegrations, { data: integerations, loading }] = useLazyQuery(
    IntegerationsQuery,
    { fetchPolicy: 'network-only' }
  );

  useEffect(() => {
    fetchIntegrations({
      variables: {
        type: mapCatagory[category],
      },
    });
  }, [category]);

  let lodas =
    (category == 'all' &&
      _.groupBy(integerations?.fetchIntegrations, 'integrationType')) ||
    [];
  const [detail, setDetail] = useState(false);
  const [editId, setEditId] = useState();
  const [editTitle, setEditTitle] = useState();
  const [editKeys, setEditKeys] = useState();
  const [credentials, setCredentitals] = useState({});
  const handleCloseEditPage = () => {
    setDetail(false);
  };

  useEffect(() => {
    if (edit_id || edit_id === 0) {
      setHeaderButton(+edit_id);
    }
  }, [edit_id]);

  useEffect(() => {
    if (edit_id != headerButton) {
      history.push(`/toolbox/${selected_tab}/${headerButton}`);
    }
  }, [headerButton]);

  useEffect(() => {
    if (integerations?.fetchIntegrations?.length > 0 && selectedTab) {
      let childItem = integerations?.fetchIntegrations?.find((integ) => integ?.id == selectedTab)
      setEditId(childItem?.id);
      setEditTitle(childItem?.name);
      setEditKeys(childItem?.keys);
      setCredentitals(
        childItem?.credentials
          ? childItem?.credentials
          : {}
      );
      setDetail(childItem);
    }
  }, [selectedTab, integerations?.fetchIntegrations])

  const getImgSrc = (name) => {
    try {
      return require(`../../assets/images/${name
        ?.split(' ')
        .join('')
        .toLowerCase()}`)
    } catch (e) {
      return ''
    }
  }

  return (
    <Frame>
      <div class="integration_main">
        {detail ? (
          <IntegrationDetail
            i18n={i18n}
            id={editId}
            title={editTitle}
            keys={editKeys}
            handleClose={handleCloseEditPage}
            credentials={credentials}
            imgsrc={getImgSrc(detail.name)}
            item={detail}
            getImgSrc={getImgSrc}
            fetchIntegrations={fetchIntegrations}
            mapCategory={mapCatagory[category]}
          />
        ) : (
          <div
            fullWidth
            title={
              process.env.APP_TYPE == 'public'
                ? i18n.t('integerate_with_chargezen')
                : i18n.t('integerations')
            }
          >
            <div className="without_header_card">
              <Card
                actions={{
                  content: (
                    <div className="card_header_button_group">
                      <div className="card_header_buttons primary_button_group">
                        <HeaderButtons
                          headerButtons={headerButtons}
                          setHeaderButton={setHeaderButton}
                          headerButton={headerButton}
                        />
                      </div>
                    </div>
                  ),
                }}
              >
                <Card.Section>
                  {/* Data from API's */}
                  <div className="defauleLayoutSection integrations_main_wrapper">
                    <Layout.Section>
                      {/* <Heading>
                        <span style={{ textTransform: 'capitalize' }}>
                          {category}
                        </span>
                      </Heading> */}
                      {loading ? (
                        <Card>
                          <LoadingScreen />
                        </Card>
                      ) : (
                        <>
                          {category == 'all' ? (
                            <>
                              {integerations?.fetchIntegrations?.map((childItem) => {
                                return (
                                  <>
                                    <IntegrationCard {...{
                                      childItem,
                                      setEditId,
                                      setEditTitle,
                                      setEditKeys,
                                      setCredentitals,
                                      setDetail
                                    }}
                                      integType={_.startCase(childItem?.integrationType)}
                                    />
                                  </>
                                )
                              })
                              }
                            </>
                          ) : (
                            <>

                              {integerations?.fetchIntegrations &&
                                integerations?.fetchIntegrations?.map(
                                  (childItem) => (
                                    <>
                                      <IntegrationCard {...{
                                        childItem,
                                        setEditId,
                                        setEditTitle,
                                        setEditKeys,
                                        setCredentitals,
                                        setDetail
                                      }}
                                        integType={_.startCase(childItem?.integrationType)}
                                      />
                                    </>
                                  )
                                )}
                            </>
                          )}
                        </>
                      )}
                    </Layout.Section>
                  </div>
                </Card.Section>
              </Card>
            </div>
          </div>
        )}
      </div>
    </Frame>
  );
};

export default Integrations;
