import { Card, DisplayText, FormLayout, Layout, Select, TextField, TextStyle } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import PixelIcon from '../../assets/images/PixelIcon'

const ApiAccessToken = ({ handleBack, i18n, domain }) => {
    const [apiToken, setApiToken] = useState();

    const fetchAuthToken = () => {
        //this api is origin specific (i.e will not work for different origin)
        fetch(`/fetch_auth_token?shopify_domain=${domain}`, {
            method: "GET",
            headers: {
                Authorization: domain,
            }
        }).then((r) => r.json())
            .then((data) => {
                setApiToken(data?.chargezen_token)
            })
    }

    useEffect(() => {
        fetchAuthToken()
    }, [])

    return (
        <div className="product-extention">
            <Layout>
                <Layout.Section>
                </Layout.Section>
                <Card title=
                    {
                        <div className="heading_title">
                            <PixelIcon />
                            {i18n.t('api_access_token')}
                        </div>
                    }>
                    <Card.Section>
                        <div className="billing">
                            <FormLayout>
                                <DisplayText size="small" element="h6">
                                    <TextStyle variation="subdued">{i18n.t('your_chargezen_api_token')}</TextStyle>
                                </DisplayText>
                                <div className='chargezen_auth_token_wrapper'>
                                    {apiToken}
                                </div>
                            </FormLayout>
                        </div>
                    </Card.Section>
                </Card>
            </Layout>
        </div>
    )
}

export default ApiAccessToken