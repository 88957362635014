import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import {
  Card,
  Frame,
  Page,
  Tabs,
  AppProvider,
  Form,
  FormLayout,
  TextField,
  Select,
  Button,
  RadioButton,
  ButtonGroup,
  Icon,
  Toast,
} from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import { useCallback } from 'react';
import PixelIcon from '../../assets/images/PixelIcon';
import {
  ArrowDownMinor,
  ArrowUpMinor,
  DeleteMajor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';
import CancellationReasons from './CancellationReasons';
import './style.css';
import HtmlEditor from './HtmlEditor';
import EditCancellation from './EditCancellation';
import CancellationUpsells from './CancellationUpsells';
import EditCancellationUpsell from './EditCancellationUpsell';

const CancellationFlow = (props) => {
  const { i18n, selectedTemplateId, domain, toggleActive } = props;

  const [formData, setFormData] = useState();
  const [edit, showEdit] = useState(false);
  const [upsellEdit, showUpsellEdit] = useState(false);
  const [cancellationReasons, setCancellationReasons] = useState([]);
  const [cancellationUpsells, setCancellationUpsells] = useState([]);
  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(() => setToastContent(), []);

  const handleSubmit = () => {
    fetch('/customer_portal/cancellation_flow_setting', {
      method: 'POST',
      body: JSON.stringify({ data: formData, shopify_domain: domain }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.success) {
          setToastContent('Saved Successfully');
          fetch_cancellation_flow();
        }
      });
  };

  const fetch_cancellation_flow = () => {
    fetch('/customer_portal/fetch_cancellation_flow?shopify_domain=' + domain, {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.cancellation_reasons) {
          setCancellationReasons([...data?.cancellation_reasons]);
        }
        if (data?.cancellation_flow_setting) {
          setFormData({ ...data?.cancellation_flow_setting });
        }
      });
  };

  useEffect(() => {
    fetch_cancellation_flow();
  }, []);

  return (
    <Frame>
      {toastContent && (
        <Toast content={toastContent} onDismiss={hideSaveSuccess} />
      )}
      <Page
        title={
          <div className="back-button back_button_wrapper">
            {edit || upsellEdit ? (
              <div
                className="back-button pointer"
                onClick={() => {
                  showEdit();
                  showUpsellEdit();
                }}
              >
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
            ) : (
              ''
            )}
            <h1 class="shopify_style_header">Cancellation Flow</h1>
          </div>
        }
      >
        <div className="translations_form_wrapper product-extention">
          {edit ? (
            <EditCancellation
              showEdit={showEdit}
              edit={edit}
              domain={domain}
              setToastContent={setToastContent}
              fetch_cancellation_flow={fetch_cancellation_flow}
              i18n={i18n}
            />
          ) : upsellEdit ? (
            <EditCancellationUpsell
              showEdit={showUpsellEdit}
              edit={upsellEdit}
              domain={domain}
              setToastContent={setToastContent}
              fetch_cancellation_flow={fetch_cancellation_flow}
              i18n={i18n}
            />
          ) : (
            <>

              <Card
                title={
                  <div className="heading_title">
                    <PixelIcon />
                    Cancellation reasons & retention strategy
                  </div>
                }
              >
                <Card.Section>
                  <div className="content_wrapper_container">
                    <div className="form_wrapper">
                      <Form>
                        <FormLayout>
                          <CancellationUpsells
                            {...{
                              setFormData,
                              formData,
                              upsellEdit,
                              showUpsellEdit,
                              cancellationUpsells,
                              setCancellationUpsells,
                              setToastContent,
                              fetch_cancellation_flow,
                              domain,
                            }}
                          />
                        </FormLayout>
                      </Form>
                    </div>
                  </div>
                </Card.Section>
              </Card>
              <Card
                title={
                  <div className="heading_title">
                    <PixelIcon />
                    Cancellation reasons & retention strategy
                  </div>
                }
              >
                <Card.Section>
                  <div className="content_wrapper_container">
                    <div className="form_wrapper">
                      <Form>
                        <FormLayout>
                          <CancellationReasons
                            {...{
                              setFormData,
                              formData,
                              edit,
                              showEdit,
                              cancellationReasons,
                              setCancellationReasons,
                              setToastContent,
                              fetch_cancellation_flow,
                              domain,
                            }}
                          />
                        </FormLayout>
                      </Form>
                    </div>
                  </div>
                </Card.Section>
              </Card>
              <Card
                title={
                  <div className="heading_title">
                    <PixelIcon />
                    Subscription benefits pages
                  </div>
                }
              >
                <Card.Section>
                  <div className="content_wrapper_container">
                    <div className="form_wrapper">
                      <Form>
                        <FormLayout>
                          <div className="show_cancellation_instructions_container cancellation_flow_row_wrapper">
                            <h6 className="description">
                              Create a subscription benefit page shown to
                              customers when they try to cancel their
                              subscriptions
                            </h6>
                            <FormLayout.Group>
                              <div className="enable_disable_button_wrapper">
                                <span className="text">
                                  Subscription benefits page is currently{' '}
                                  {formData?.benefits_page
                                    ? 'enabled'
                                    : 'disabled'}
                                </span>
                                <span className="button_wrapper">
                                  <button
                                    className={`enable_disable_button ${formData?.benefits_page ? '' : 'enabled'
                                      }`}
                                    onClick={() => {
                                      setFormData({
                                        ...formData,
                                        benefits_page: !formData?.benefits_page,
                                      });
                                    }}
                                  >
                                    {formData?.benefits_page
                                      ? 'Disable'
                                      : 'Enable'}
                                  </button>
                                </span>
                              </div>
                            </FormLayout.Group>
                            <div className="show_cancellation_instructions_wrapper">
                              <TextField
                                label="Title"
                                name=""
                                value={formData?.benefits_page_title}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    benefits_page_title: e,
                                  });
                                }}
                              />

                              <div className="html_editor_container">
                                <label className="custom_label">Body</label>
                                <HtmlEditor
                                  formData={formData}
                                  setFormData={setFormData}
                                  fieldName={'benefits_page_body'}
                                />
                              </div>

                              <TextField
                                label="Keep subscription button text"
                                name=""
                                value={formData?.keep_sub_button_text}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    keep_sub_button_text: e,
                                  });
                                }}
                              />
                              <TextField
                                label="Cancel subscription button text"
                                name=""
                                value={formData?.cancel_sub_button_text}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    cancel_sub_button_text: e,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </FormLayout>
                      </Form>
                    </div>
                  </div>
                </Card.Section>
              </Card>

              <div className="submit_button_wrapper">
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </div>
      </Page>
    </Frame>
  );
};

export default CancellationFlow;
