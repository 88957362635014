import React, { useEffect, useState } from "react";
import { render } from "react-dom";

import {
  Frame,
  TextField,
  Select,
  Collapsible,
  Icon
} from "@shopify/polaris";
import { ChevronRightMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import "@shopify/polaris/dist/styles.css";
import "./ManageStyle.css";

const ManageStyle = (props) => {
  const { allStyles, setAllStyles, style_object } = props
  const [collapsible, setCollapsible] = useState({});

  const handleCssChange = (value, styleIndex, fieldIndex, subfieldIndex) => {
    let all_styles = allStyles;
    all_styles[styleIndex].fields[fieldIndex].sub_fields[
      subfieldIndex
    ].value = value;
    setAllStyles([...all_styles]);
  };
  return (
    <Frame>
      <div className="subscription_widget_style_tab">
        {allStyles?.map((style, i) => {
          return (
            <div className="collapsible_wrapper_main form-wrapper">
              <div
                className="collapsible_header"
                onClick={() => {
                  setCollapsible({
                    ...collapsible,
                    [style?.category]: !collapsible[style?.category]
                  });
                }}
              >
                <div className="header_text">{style?.category}</div>
                <Icon
                  source={
                    collapsible[style?.category]
                      ? ChevronUpMinor
                      : ChevronRightMinor
                  }
                  color="base"
                />
              </div>
              <Collapsible
                open={collapsible[style?.category]}
                id={style?.category}
                transition={{
                  duration: "500ms",
                  timingFunction: "ease-in-out"
                }}
                expandOnPrint
              >
                <div className="subfields_wrapper_main">
                  {style.fields?.map((f, j) => {
                    return (
                      <div className="field_wrapper_container">
                        <h6>{f.css_property}</h6>

                        <div className="field_wrapper">
                          {f.sub_fields?.map((sf, k) => {
                            return (
                              <div className="sub_fields_wrapper">
                                {sf.type !== "select" ? (
                                  <div className="sub_field">
                                    <TextField
                                      type={sf.type}
                                      value={sf.value}
                                      onChange={(e) => {
                                        handleCssChange(e, i, j, k);
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="sub_field">
                                    <Select
                                      options={sf.options}
                                      value={sf.value}
                                      onChange={(e) => {
                                        handleCssChange(e, i, j, k);
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Collapsible>
            </div>
          );
        })}
      </div>
    </Frame>
  );
};

export default ManageStyle;
