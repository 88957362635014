import axios from "axios";

const axiosconfig = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_BACKEND,
});

axiosconfig.interceptors.request.use(
  (config) => {
    const _domain = localStorage.getItem("chargezenDomain") || "";
    const domainKey = _domain?.replace(".myshopify.com", "");
    const _authToken = localStorage.getItem(`${domainKey}_authToken`) || "";
    const _accessToken = localStorage.getItem(`${domainKey}_accessToken`) || "";

    config.headers['X-Shopify-Access-Token'] = _accessToken;
    config.headers['Authorization'] = _authToken;
    config.headers['X-Shop-Domain'] = _domain;

    return config;
  },
  (error) => Promise.reject(error),
);

export const setupInterceptors = (signOutProps) => {
  const { navigate, dispatch } = signOutProps;

  axiosconfig.interceptors.response.use(
    (response) => {
      // handling response
      return response;
    },
    (error) => {
      console.log("error", error);
      // Returning the Rejected Promise
      return Promise.reject(error);
    },
  );
};

export default axiosconfig;
