import { Card, Icon, Layout, Stack, TextStyle } from '@shopify/polaris';
import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import Export from './Export';
import ExportForm from './ExportForm';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import PixelIcon from '../../../../assets/images/PixelIcon';

const MainExport = ({ handleBack, i18n }) => {
  const [showForm, setShowForm] = useState(false);
  const [exportData, setExportData] = useState(null);

  const handleShowForm = useCallback(() => {
    setShowForm(true);
  }, [setShowForm]);
  const handleCloseForm = useCallback(
    (data, filters) => {
      setShowForm(false);
      if (!isEmpty(data) && !isEmpty(filters)) {
        setExportData({ data: data, filters: filters });
      }
    },
    [setShowForm]
  );

  return (
    <>
      <div>
        <div>
          <Card title=
            {
              <div className="heading_title">
                <PixelIcon />
                Export
              </div>
            }

          >
            {showForm ? (
              <ExportForm i18n={i18n} handleCloseForm={handleCloseForm} />
            ) : (
              <Export i18n={i18n} exportData={exportData} handleCreateExport={handleShowForm} />
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

export default MainExport;
