import { Button, Checkbox, Icon, RadioButton, Select, TextField } from '@shopify/polaris';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SearchVariants from './SearchVariants';
import SearchPlan from '../upsell/SearchPlan';
import DeleteSVG from '../../assets/images/delete.svg';
import axiosconfig from '../axiosconfig';
import { MobileCancelMajor } from '@shopify/polaris-icons';
function EditModel({
  setEditProductModel,
  editProductModel,
  domain,
  setToastContent,
  getSubscription,
  setSaveSuccess,
  formatDate,
  getTotalPrice
}) {
  const [formData, setFaqFormData] = useState({
    id: editProductModel?.contract?.shopify_id,
    line_id: editProductModel?.line?.id,
    current_price: editProductModel?.line?.current_price?.amount,
    quantity: editProductModel?.line?.quantity,
    shop: domain,
  });

  const saveChangeProduct = () => {
    axiosconfig.post('/edit_product_line_item', formData).then(({ data }) => {
      setEditProductModel();
      setToastContent('Product Updated Successfully');
      setSaveSuccess(true);
      setTimeout(() => {
        getSubscription();
      }, [3000]);
    });
  };

  return (
    <div className="edit_model modal-popup-cstm">
      <div class="modal-dialouge">
        <div className="Modal-header">
          <h2>Edit Subscription Product</h2>
        </div>
        <div className="modal-body">
          <div className="edit-product-form">
            <form>
              <div className="form-group">
                <label>Product name</label>
                <input
                  type="text"
                  name="Product-name"
                  placeholder=""
                  value={editProductModel?.line?.title}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>
                  Quantity<sup>*</sup>
                </label>
                <input
                  type="number"
                  name="Product-name"
                  placeholder=""
                  value={formData?.quantity}
                  onChange={(e) =>
                    setFaqFormData({ ...formData, quantity: e.target?.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>
                  Recurring Price<sup>*</sup>
                </label>
                <input
                  type="number"
                  name="Product-name"
                  placeholder=""
                  value={formData?.current_price}
                  onChange={(e) =>
                    setFaqFormData({
                      ...formData,
                      current_price: e.target?.value,
                    })
                  }
                />
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer">
          <div className="save-cancel-button">
            <button
              onClick={() => setEditProductModel()}
              className="cancel-button"
            >
              Cancel
            </button>
            <button className="save-button" onClick={() => saveChangeProduct()}>
              Save{' '}
            </button>
          </div>
        </div>
      </div>

      <div className="modal-cstm-backdrop"></div>
    </div>
  );
}

function EditFrequency({
  setEditFrequencyModel,
  editFrequencyModel,
  domain,
  setToastContent,
  getSubscription,
  setSaveSuccess,
  apiData,
  formatDate,
}) {
  const [formData, setFormData] = useState({
    contract_id: apiData?.contract?.shopify_id,
    interval: editFrequencyModel?.interval,
    interval_count: editFrequencyModel?.interval_count,
    shop: domain,
  });

  const handleSelectChange = (value, key) =>
    setFormData({ ...formData, [key]: value });
  const saveChangeFrequency = () => {
    axiosconfig.post('/change_delivery_frequency_contract', formData).then(({ data }) => {
      setEditFrequencyModel();
      setToastContent('Updated Successfully');
      setSaveSuccess(true);
      setTimeout(() => {
        getSubscription();
      }, [3000]);
    });
  };

  return (
    <div className="edit_model modal-popup-cstm">
      <div class="modal-dialouge">
        <div className="Modal-header">
          <h2>Edit order schedule</h2>
        </div>
        <div className="modal-body">
          <div className="edit-product-form">
            <form>
              <div className="form-group">
                <label>Ship every</label>
                <div className="input_flex_wrapper">
                  <TextField
                    label=""
                    type="number"
                    value={`${formData?.interval_count}`}
                    onChange={(e) =>
                      setFormData({ ...formData, interval_count: e })
                    }
                    autoComplete="off"
                  />
                  <Select
                    label=""
                    options={[
                      { label: 'day(s)', value: 'DAY' },
                      { label: 'week(s)', value: 'WEEK' },
                      { label: 'month(s)', value: 'MONTH' },
                    ]}
                    onChange={(e) => handleSelectChange(e, 'interval')}
                    value={formData?.interval}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal-footer">
          <div className="save-cancel-button">
            <button
              onClick={() => setEditFrequencyModel()}
              className="cancel-button"
            >
              Cancel
            </button>
            <button
              className="save-button"
              onClick={() => saveChangeFrequency()}
            >
              Save{' '}
            </button>
          </div>
        </div>
      </div>

      <div className="modal-cstm-backdrop"></div>
    </div>
  );
}

function EditSubsModel({
  setShowEditSwapModel,
  setShowEditSubModel,
  apiData,
  setToastContent,
  setSaveSuccess,
  getSubscription,
  setEditFrequencyModel,
  cancelSubscription,
  formatDate,
  getTotalPrice
}) {
  var responseData = apiData ? JSON.parse(JSON.stringify(apiData)) : {};
  // console.log("responseData", responseData)
  const pauseSubscription = () => {
    let myDate = new Date();
    myDate.setDate(myDate.getDate() + 1);
    // format a date

    var date =
      myDate.getDate() +
      '/' +
      ('0' + (myDate.getMonth() + 1)).slice(-2) +
      '/' +
      myDate.getFullYear();
    axiosconfig.post(`/subscriptions/${responseData?.contract?.shopify_id}/pause`, {
      billing_date: date,
      action_by: 'admin',
      token: responseData?.contract?.token,
    }).then(({ data }) => {
      setShowEditSubModel(false);
      if (data.success == 'true') {
        setToastContent('Subscription Paused Successfully');
        setTimeout(() => {
          getSubscription();
        }, [3000]);
      } else {
        setToastContent('An error occured', data);
      }
      setSaveSuccess(true);
    });
  };

  return (
    <div className="edit_sub_model modal-popup-cstm">
      <div className="modal-dialouge">
        <div className="Modal-header">
          <h2>Edit Subscription</h2>
          <button class="close-btn" onClick={() => setShowEditSubModel(false)}>
            <svg
              fill="none"
              height="20"
              viewBox="0 0 18 20"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 19.9998H2.212L8.484 11.0398L14.756 19.9998H17.052L9.688 9.58378L16.408 0.175781H14.196L8.568 8.23978L2.856 0.175781H0.672L7.448 9.58378L0 19.9998Z"
                fill="#DDDDDD"
              ></path>
            </svg>
          </button>
        </div>
        <div className="modal-content">
          <div className="subscription-details">
            {responseData?.subscription?.lines?.edges?.length > 0 ? (
              <>
                <div class="img">
                  <img
                    src={
                      responseData?.subscription?.lines?.edges?.[0]?.node
                        ?.variant_image?.url
                    }
                  />
                </div>
                <div class="title">
                  <h2>
                    {responseData?.subscription?.lines?.edges?.[0]?.node?.title}
                  </h2>
                  <h3>
                    {`${apiData?.subscription?.currency_code} ${getTotalPrice()} / ${responseData?.subscription?.billing_policy?.interval_count} ${responseData?.subscription?.billing_policy?.interval}`}
                  </h3>
                </div>
              </>
            ) : (
              <></>
            )}
            <div class="delivery-charge">
              <div>
                <h3>est. next delivery</h3>
                <h4></h4>
              </div>
              <div>
                <h3>NEXt card charge</h3>
                <h4>
                  {formatDate(responseData?.subscription?.next_billing_date)}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div class="btn-wrapper">
            <button
              onClick={() => {
                setEditFrequencyModel({
                  ...apiData?.contract?.api_data?.billing_policy,
                });
              }}
            >
              Edit Frequency
            </button>
            <button
              onClick={() => {
                pauseSubscription();
              }}
            >
              Pause Subscription
            </button>
            <button
              onClick={() => {
                setShowEditSubModel(false);
                setShowEditSwapModel(true);
              }}
            >
              SWAP Product
            </button>
            <button onClick={() => cancelSubscription()}>
              CANCEL SUBSCRIPTION
            </button>
            <button>Cancel Later</button>
            <button>Pause Later</button>
          </div>
        </div>
      </div>
      <div className="modal-cstm-backdrop"></div>
    </div>
  );
}

function EditSwapModel({
  setShowEditSubModel,
  setShowEditSwapModel,
  apiData,
  setToastContent,
  setSaveSuccess,
  i18n,
  getSubscription,
  formatDate,
}) {
  let lineItem = apiData?.contract?.api_data?.lines?.edges?.[0]?.node;
  const [fieldValue, setFieldValue] = useState({
    variantIds: '',
    sellingPlan: '',
  });
  const [allVariants, setAllVariants] = useState([]);
  const [allSelectedPlans, setAllSelectedPlans] = useState([]);

  // const getSwapProducts = (selling_plan_id) => {
  //     return apiData?.all_subcription_products?.filter{(asp)=>{ asp.selling_plan_id || asp.selling_plan.id == selling_plan_id}?.pluck(:product_images)&.flatten&.compact rescue []
  // }

  const swapProductSubmit = (variant_id = null) => {
    const formData = new FormData();
    if (variant_id) {
      formData.append(`variant_id`, variant_id);
    }
    formData.append(`line_id`, lineItem.id);
    formData.append(`action_by`, 'admin');
    if (allSelectedPlans.length > 0) {
      formData.append(`selling_plan_id`, allSelectedPlans[0]?.sellingPlanId);
    }
    formData.append(`format`, 'json');

    fetch(`/subscriptions/${apiData?.contract?.shopify_id}/swap_product`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status) {
          setToastContent(data?.response);
          setSaveSuccess(true);
          setShowEditSwapModel(false);
          setTimeout(() => {
            getSubscription();
          }, [3000]);
        } else {
          setToastContent(data?.error);
        }
      });
  };

  const handleRemovePlan = (id) => {
    setAllSelectedPlans(
      allSelectedPlans.filter((plan) => plan.sellingPlanId !== id)
    );
  };
  useEffect(() => {
    if (allSelectedPlans.length > 1) {
      let selling_plan = [allSelectedPlans[allSelectedPlans?.length - 1]];
      setAllSelectedPlans([...selling_plan]);
    }
  }, [allSelectedPlans]);
  return (
    <div className="EditSwapModel  modal-popup-cstm">
      <div className="modal-dialouge">
        <div className="Modal-header">
          <h2>Swap {lineItem?.title} to:</h2>
          <button
            class="close-btn"
            onClick={() => {
              setShowEditSubModel(true);
              setShowEditSwapModel(false);
            }}
          >
            <svg
              fill="none"
              height="20"
              viewBox="0 0 18 20"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 19.9998H2.212L8.484 11.0398L14.756 19.9998H17.052L9.688 9.58378L16.408 0.175781H14.196L8.568 8.23978L2.856 0.175781H0.672L7.448 9.58378L0 19.9998Z"
                fill="#DDDDDD"
              ></path>
            </svg>
          </button>
        </div>
        <div className="modal-content swap_modal">
          <div className="search_plan_wrapper">
            <h3 className="heading">Search Plan to swap</h3>
            <SearchPlan
              idForTextField={`serchPlan-${Math.random()}`}
              value={fieldValue.sellingPlan}
              setFomData={setFieldValue}
              fieldName={`sellingPlan`}
              allSelectedPlans={allSelectedPlans || []}
              setAllSelectedPlans={setAllSelectedPlans}
              i18n={i18n}
              fomData={fieldValue}
            />
            {allSelectedPlans?.map((plan) => (
              <div className="selected-plan-container swap_modal">
                <span>{plan?.sellingPlanName}</span>
                <img
                  src={DeleteSVG}
                  onClick={() => handleRemovePlan(plan.sellingPlanId)}
                  alt="Delete"
                />
              </div>
            ))}
            {allSelectedPlans.length > 0 && (
              <Button
                onClick={() => {
                  swapProductSubmit();
                }}
              >
                Swap only selling plan
              </Button>
            )}
          </div>
          <h3 className="heading">Search Variant to swap</h3>
          <SearchVariants
            value={fieldValue?.variantId || [[]]}
            setFieldValue={setFieldValue}
            fieldName={`variantId`}
            allVariants={allVariants || [[]]}
            setAllVarients={setAllVariants}
            i18n={i18n}
            setFormData={setFieldValue}
            formData={fieldValue}
          />
          <div className="swap-grid">
            {allVariants?.map((variant) => {
              return (
                <div className="variant_card">
                  <div className="image_wrapper">
                    <img src={variant?.image} />
                  </div>
                  <div className="varaint_content">
                    <div className="title">{variant?.title}</div>
                    <div className="price">{variant?.price}</div>
                    <div className="button-wrapper">
                      <button
                        type="button"
                        onClick={() => {
                          swapProductSubmit(variant.variantId);
                        }}
                      >
                        SWAP PRODUCT
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* {
                            apiData?.all_selling_plans?.map((selling_plan, index) => {
                                let products = apiData?.all_selling_plan_groups.find((sg) => sg.id === selling_plan?.selling_plan_group_id)?.product_ids
                                console.log("products", products)
                                return (
                                    products?.map((prod) => {
                                        console.log("prod", prod)
                                        return (
                                            <div className="item">
                                                <div className="img">
                                                    <img src={prod?.images?.edges?.[0]?.original_src} />
                                                </div>
                                                <div className="title">
                                                    <h2>{prod.title}</h2>
                                                    <p className="price_per_week">${prod?.variants?.edges?.[0]?.price} / {apiData?.subscription?.billing_policy?.interval_count} {apiData?.subscription?.billing_policy?.interval}</p>
                                                </div>
                                                {
                                                    product?.variants?.edges.map((variant, i) => {
                                                        console.log("variant", variant)
                                                        return (
                                                            <div className="button-wrapper" onClick={swapProductSubmit(selling_plan?.id, index, prod, variant, i)}>
                                                                <button type="button">SWAP SUBSCRIPTION</button>
                                                            </div>
                                                        )
                                                    })

                                                }

                                            </div>
                                        )

                                    })
                                )
                            })
                        } */}
          </div>
        </div>
      </div>
      <div className="modal-cstm-backdrop"></div>
    </div>
  );
}

function CreateBilling({
  setCreateBillingModel,
  apiData,
  setToastContent,
  setSaveSuccess,
  formatDate,
}) {
  const [loading, setLoading] = useState(false);
  const creatingBilling = () => {
    if (!loading) {
      setLoading(true);
      setToastContent('Billing attempt is attempting…');
      fetch(`/subscriptions/create_billing_attempt`, {
        method: 'POST',
        body: JSON.stringify({ id: apiData?.contract?.id }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          setCreateBillingModel(false);
          if (data?.success) {
            setToastContent('Billing attempt was successful, reloading ...');
            // setTimeout(() => {
            //   getSubscription();
            // }, [3000]);
          } else {
            setToastContent('Billing attempt was unsuccessful');
          }
          setSaveSuccess(true);
        })
        .catch((e) => {
          setLoading(false);
          setCreateBillingModel(false);
          setToastContent('Something went wrong');
          console.log('error:- ', e);
        });
    }
  };
  return (
    <div className="create_billing modal-popup-cstm">
      <div className="modal-dialouge">
        <div className="Modal-header">
          <h2>Charge Card</h2>
          <button
            class="close-btn"
            onClick={() => setCreateBillingModel(false)}
          >
            <svg
              aria-hidden="true"
              class="Polaris-Icon__Svg"
              focusable="false"
              viewBox="0 0 20 20"
            >
              <path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z"></path>
            </svg>
          </button>
        </div>
        <div className="modal-content">
          <p>
            Are you sure? This will bill the customer’s card on file & create an
            order immediately.
          </p>
        </div>
        <div className="modal-footer">
          <button
            className="btn-green"
            onClick={() => {
              creatingBilling();
            }}
          >
            {loading ? <div class="chargezen_button_spinner"></div> : <>Yes</>}
          </button>
          <button
            className="btn-red"
            onClick={() => setCreateBillingModel(false)}
          >
            No
          </button>
        </div>
      </div>
      <div className="modal-cstm-backdrop"></div>
    </div>
  );
}

function AddDiscount({
  setAddDiscount,
  apiData,
  setToastContent,
  setSaveSuccess,
  formatDate,
}) {
  const [discountcode, setdiscountcode] = useState('');
  const addDiscountCode = () => {
    setToastContent('Billing attempt is attempting…');
    fetch(`/apply_discount`, {
      method: 'POST',
      body: JSON.stringify({
        discount_code: discountcode,
        id: apiData?.contract?.shopify_id,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAddDiscount(false);
        if (data?.error) {
          setToastContent('Something went wrong.');
        } else {
          setToastContent('Your request is being processed. Reloading...');
          // setTimeout(() => {
          //   getSubscription();
          // }, [3000]);
        }
        setSaveSuccess(true);
      });
  };

  return (
    <div className="create_billing modal-popup-cstm">
      <div className="modal-dialouge">
        <div className="Modal-header">
          <h2>Add Discount</h2>
        </div>
        <div className="modal-content">
          <div className='modal-form-control flex_control'>
            <Select
              label="Discount type"
              options={[
                { label: 'Shopify code', value: 'code' },
              ]}
              onChange={(e) => { }}
              value={null}
            />
            <TextField
              label="Discount code"
              type="text"
              value={discountcode}
              onChange={(e) => setdiscountcode(e)}
              autoComplete="off"
            // helpText="Use a discount code you have already created in your shopify account"
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn-green"
            onClick={() => {
              addDiscountCode();
            }}
          >
            Save
          </button>
          <button className="btn-red" onClick={() => setAddDiscount(false)}>
            Cancel
          </button>
        </div>
      </div>
      <div className="modal-cstm-backdrop"></div>
    </div>
  );
}

const UpdatePaymentModal = (props) => {
  const { setUpdatePayment } = props;
  const closeModal = () => {
    setUpdatePayment()
  }
  return (
    <div className="create_billing modal-popup-cstm">
      <div className="modal-dialouge">
        <div className="Modal-header">
          <h2>Send link to update payment details</h2>
          <div onClick={closeModal} >
            <Icon source={MobileCancelMajor} tone="base" />
          </div>
        </div>
        <div className="modal-content">
          <p>Are you sure that you want to send payment method update link to the customer?</p>
        </div>
        <div className="modal-footer">
          <button className="btn-red" onClick={closeModal}>
            Cancel
          </button>
          <button
            className="btn-green"
            onClick={() => {
            }}
          >
            Send
          </button>
        </div>
      </div>
      <div className="modal-cstm-backdrop"></div>
    </div>
  )
}

const ChangePaymentModal = (props) => {
  const { setChangePayment, apiData } = props;
  const closeModal = () => {
    setChangePayment()
  }
  return (
    <div className="create_billing modal-popup-cstm">
      <div className="modal-dialouge">
        <div className="Modal-header">
          <h2>Change payment method</h2>
          <div onClick={closeModal}>
            <Icon source={MobileCancelMajor} tone="base" />
          </div>
        </div>
        <div className="modal-content">
          <div className='modal-form-control'>
            <Select
              label="Select new payment method"
              options={apiData?.customer?.api_data?.data?.paymentMethods?.edges?.map((pm) => {
                pm = pm?.node;
                let label = `${pm?.instrument?.brand} card ending in ${pm?.instrument?.lastDigits}, expires ${pm?.instrument?.expiryMonth}/${pm.instrument?.expiryYear}`
                return ({ label: label, value: pm?.node?.id })
              })}
              onChange={(e) => { }}
              value={null}
            />
            <Checkbox
              label="Apply this payment method to all subscriptions of the customer"
              checked={false}
              onChange={() => { }}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn-red" onClick={closeModal}>
            Cancel
          </button>
          <button
            className="btn-green"
            onClick={() => {
            }}
          >
            Save
          </button>
        </div>
      </div>
      <div className="modal-cstm-backdrop"></div>
    </div>
  )
}

const ConfirmPauseModal = (props) => {
  const { setPauseModal } = props;
  const closeModal = () => setPauseModal();
  return (
    <div className="create_billing modal-popup-cstm">
      <div className="modal-dialouge">
        <div className="Modal-header">
          <h2>Confirm Pause?</h2>
          <div onClick={closeModal}>
            <Icon source={MobileCancelMajor} tone="base" />
          </div>
        </div>
        <div className="modal-content cancellation_reason_container">
          <p>Are you sure you want to pause this subscription?</p>
          <div className='modal-form-control'>
            <RadioButton
              label="Pause duration"
              checked={true}
              id="auto_resume"
              name="auto_resume"
              onChange={() => { }}
            />
            <div className='auto_resume_options'>
              <RadioButton
                label="1 month"
                checked={true}
                id="pause_duration"
                name="pause_duration"
                onChange={() => { }}
              /><RadioButton
                label="2 months"
                checked={false}
                id="pause_duration"
                name="pause_duration"
                onChange={() => { }}
              /><RadioButton
                label="3 months"
                checked={false}
                id="pause_duration"
                name="pause_duration"
                onChange={() => { }}
              />
            </div>
            <RadioButton
              label="No, this subscription will not be auto-resumed"
              checked={false}
              id="auto_resume"
              name="auto_resume"
              onChange={() => { }}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className='modal-form-control'>
            <Checkbox
              label="Notify customer?"
              checked={false}
              onChange={() => { }}
            />
            <p>
              Enable this to notify the customer about the change in their subscription. You can
              manage the Subscription paused email template in your  notifications settings.
            </p>
            <p>
              Please note that the event will be triggered in 3rd party apps like Klaviyo irrespective of
              this setting.
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn-red" onClick={closeModal}>
            Cancel
          </button>
          <button
            className="btn-green"
            onClick={() => {
            }}
          >
            Save
          </button>
        </div>
      </div>
      <div className="modal-cstm-backdrop"></div>
    </div>
  )
}

const ConfirmCancelModal = (props) => {
  const { setCancelModal } = props
  const closeModal = () => setCancelModal()
  return (
    <div className="create_billing modal-popup-cstm">
      <div className="modal-dialouge">
        <div className="Modal-header">
          <h2>Confirm Cancel?</h2>
          <div onClick={closeModal}>
            <Icon source={MobileCancelMajor} tone="base" />
          </div>
        </div>
        <div className="modal-content cancellation_reason_container">
          <p>Are you sure you want to cancel this subscription?</p>
          <div className='modal-form-control'>
            <label className='customlabel'>Cancellation reason</label>
            <RadioButton
              label="Not satisfied with the product(s) quality"
              checked={true}
              id="cancellation_reason"
              name="cancellation_reason"
              onChange={() => { }}
            />
            <RadioButton
              label="No longer need the product(s)"
              checked={false}
              id="cancellation_reason"
              name="cancellation_reason"
              onChange={() => { }}
            />
            <RadioButton
              label="Already have enough stock"
              checked={false}
              id="cancellation_reason"
              name="cancellation_reason"
              onChange={() => { }}
            />
            <RadioButton
              label="Too expensive to continue"
              checked={false}
              id="cancellation_reason"
              name="cancellation_reason"
              onChange={() => { }}
            />
            <RadioButton
              label="Want a different product or variety"
              checked={false}
              id="cancellation_reason"
              name="cancellation_reason"
              onChange={() => { }}
            />
            <RadioButton
              label="Other reason"
              checked={false}
              id="cancellation_reason"
              name="cancellation_reason"
              onChange={() => { }}
            />

            <TextField
              multiline={5}
              label="Cancellation remark"
              placeholder='Add reason for cancelling the subscription'
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className='modal-form-control'>
            <Checkbox
              label="Notify customer?"
              checked={false}
              onChange={() => { }}
            />
            <p>
              Enable this to notify the customer about the change in their subscription. You can
              manage the Subscription paused email template in your  notifications settings.
            </p>
            <p>
              Please note that the event will be triggered in 3rd party apps like Klaviyo irrespective of
              this setting.
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn-red" onClick={() => { }}>
            Cancel
          </button>
          <button
            className="btn-green"
            onClick={closeModal}
          >
            Save
          </button>
        </div>
      </div>
      <div className="modal-cstm-backdrop"></div>
    </div>
  )
}

export {
  EditModel,
  EditSubsModel,
  EditSwapModel,
  CreateBilling,
  AddDiscount,
  EditFrequency,
  UpdatePaymentModal,
  ChangePaymentModal,
  ConfirmPauseModal,
  ConfirmCancelModal
};
