import React, { useState, useCallback, useEffect, useContext } from 'react';

import {
    Card,
    DisplayText,
    FormLayout,
    Layout,
    Select,
    TextStyle,
    Stack,
    Icon,
    Button,
    Toast,
} from '@shopify/polaris';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import { gql, useQuery } from '@apollo/client';
import { DomainContext } from '../domain-context';
import PixelIcon from '../../assets/images/PixelIcon';
const SetCurrency = ({ handleBack, i18n }) => {
    const { domain } = useContext(DomainContext);
    const [currencies, setCurrencies] = useState([]);
    const [selectCurrency, setSelectCurrency] = useState();
    const [toastActive, setToastActive] = useState(false);
    const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
    const GET_CURRENCY = gql`
    query {
      fetchCurrency{
        allCurrencies{
          label
          value
        }
        currentCurrency
      }
    }
  `;
    const { data, loading, error, refetch } = useQuery(GET_CURRENCY, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        refetch();
    }, [])

    useEffect(() => {
        setCurrencies(data?.fetchCurrency?.allCurrencies)
        setSelectCurrency(data?.fetchCurrency?.currentCurrency)
    }, [data])


    const updateCurrency = () => {
        fetch('/settings/update_currency', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ currency: selectCurrency, shopify_domain: domain }),
        }).then((response) => response.json())
            .then(() => {
                setToastActive(true)
            })
    }
    return (
        <div className="product-extention">
            {
                toastActive && (
                    <Toast content="Saved Successfully" onDismiss={toggleToastActive} />
                )
            }
            <Layout>
                <Layout.Section>
                </Layout.Section>
                <Card title=
                    {
                        <div className="heading_title">
                            <PixelIcon />
                            Currency setting
                        </div>
                    }>
                    <Card.Section>
                        <div className="billing">
                            <FormLayout>
                                <DisplayText size="small" element="h6">
                                    <TextStyle variation="subdued">Currency setting</TextStyle>
                                </DisplayText>
                                <Select
                                    label="Select Currency"
                                    value={selectCurrency}
                                    onChange={(e) => setSelectCurrency(e)}
                                    options={currencies?.length > 0 ? currencies : []}
                                />
                                <div className='button'>
                                    <Button
                                        onClick={() => updateCurrency()}
                                    >Save</Button>
                                </div>
                            </FormLayout>
                        </div>
                    </Card.Section>
                </Card>
            </Layout>
        </div>
    );
};

export default SetCurrency;