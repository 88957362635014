import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Banner,
  Button,
  ButtonGroup,
  Caption,
  Card,
  Checkbox,
  ChoiceList,
  DataTable,
  DropZone,
  Filters,
  Frame,
  Icon,
  List,
  Modal,
  Page,
  Spinner,
  Stack,
  Tabs,
  Thumbnail,
  Toast,
} from '@shopify/polaris';
import {
  DeleteMajor,
  NoteMinor,
  ChevronRightMinor,
} from '@shopify/polaris-icons';
import Papa from 'papaparse';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Pagination } from '@shopify/polaris';
import LoadingScreen from '../LoadingScreen';
import { DomainContext } from '../domain-context';
import { sortBy } from 'lodash';
import HeaderButtons from '../HeaderButtons/HeaderButtons';
import CreateAffiliateCommission from './CreateAffiliateCommission';
import { useHistory, useParams } from 'react-router-dom';
import axiosconfig from '../axiosconfig';

const {
  Parser,
  transforms: { unwind },
} = require('json2csv');

const AffiliateCommission = (props) => {
  const {
    shopifyDomain,
    i18n,
    setDetailView,
    detailView,
    setHeaderTitle,
    customHeader,
    setCustomHeader,
  } = props;
  const { domain } = useContext(DomainContext);
  const { edit_id } = useParams();
  const history = useHistory();
  const [uid, setuid] = useState();
  const [createNew, setCreateNew] = useState(edit_id ? true : false);

  const headerButtons = [
    {
      val: 'all',
      name: 'All',
    },
    {
      val: 'active',
      name: 'Active',
    },
    {
      val: 'scheduled',
      name: 'Scheduled',
    },
    {
      val: 'expired',
      name: 'expired',
    },
  ];
  const [headerButton, setHeaderButton] = useState(headerButtons[0]?.val);

  // End tabs
  const [sortOrder, setSortOrder] = useState(0);

  const [moneySpent, setMoneySpent] = useState(null);
  const [taggedWith, setTaggedWith] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );

  const handleMoneySpentRemove = useCallback(() => setMoneySpent(null), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleMoneySpentRemove, handleQueryValueRemove, handleTaggedWithRemove]);

  const filters = [
    {
      key: 'taggedWith',
      label: 'Tagged with',
      filter: (
        <ChoiceList
          title="Tagged with"
          titleHidden
          choices={[
            { label: 'Cancelled', value: 'cancelled' },
            { label: 'Active', value: 'active' },
            { label: 'In Trial', value: 'inTrial' },
            { label: 'Future', value: 'future' },
          ]}
          selected={taggedWith || []}
          onChange={handleTaggedWithChange}
        // allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'moneySpent',
      label: 'Money spent',
      // filter: (
      //   <RangeSlider
      //     label="Money spent is between"
      //     labelHidden
      //     value={moneySpent || [0, 500]}
      //     prefix="$"
      //     output
      //     min={0}
      //     max={2000}
      //     step={1}
      //     onChange={handleMoneySpentChange}
      //   />
      // ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(moneySpent)) {
    const key = 'moneySpent';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = 'taggedWith';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'moneySpent':
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [status, setStatus] = useState('active');
  const [searchQuery, setSearchQuery] = useState();
  const [sortFilter, setSortFilter] = useState('newest');
  const [customers, setCustomers] = useState([]);
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [filterCustomers, setFilterCustomers] = useState([]);

  // -------------------
  const GET_AFFILIATE_COMMISSIONS = gql`
    query ($page: String) {
      fetchCommissionProfiles(page: $page) {
        commisionProfiles {
          id
          title
          commissionType
          affiliateCommission
          discount
          assignedTo
        }
        totalPages
        totalCount
        pageNumber
      }
    }
  `;
  const { data, loading, error, refetch } = useQuery(
    GET_AFFILIATE_COMMISSIONS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        page: page.toString(),
      },
    }
  );

  useEffect(() => {
    refetch({
      variables: {
        page: page.toString(),
      },
    });
  }, [page]);

  const handleFiltersQueryChange = (value) => {
    setQueryValue(value);
    if (value.length > 1) {
      setSearchQuery(value);
    } else {
      setSearchQuery();
    }
  };

  useEffect(() => {
    setTotalPages(data?.fetchCommissionProfiles?.totalPages);
    if (+page < +totalPages) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    if (+page <= 1) {
      setHasPrevious(false);
    } else {
      setHasPrevious(true);
    }
  }, [data]);

  const filterCustomersValue = () => {
    const rowsData = customers;

    setFilterCustomers(rowsData);
  };
  useEffect(() => {
    if (customers) {
      filterCustomersValue();
    }
  }, [queryValue, taggedWith, customers]);

  const [formErrors, setFormErrors] = useState([]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [toastContent, setToastContent] = useState();
  const hideSaveSuccess = useCallback(() => setToastContent(), []);

  const [selectedCustomers, setselectedCustomers] = useState([]);
  const handleChangeCheckedCustomers = (newChecked, customerId) => {
    if (newChecked) {
      setselectedCustomers([...selectedCustomers, customerId]);
    } else {
      const index = selectedCustomers.indexOf(customerId);
      setselectedCustomers([
        ...selectedCustomers.slice(0, index),
        ...selectedCustomers.slice(index + 1),
      ]);
    }
  };
  //upper first letter
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  //each row in data table
  const formatRows = (rows) => {
    let apiData;
    return rows?.map((row, i) => {
      return row !== null
        ? [
          <Checkbox
            label={
              <div
                className=""
                onClick={() => {
                  history.push(`/affiliates/affiliate_commission/${row.id}`);
                }}
              >
                {row?.title}
              </div>
            }
            checked={selectedCustomers.indexOf(row?.id) != -1}
            onChange={(newChecked) =>
              handleChangeCheckedCustomers(newChecked, row?.id)
            }
          />,
          <div
            className=""
            onClick={() => {
              history.push(`/affiliates/affiliate_commission/${row.id}`);
            }}
          >
            {row?.commissionType}
          </div>,
          <div
            className=""
            onClick={() => {
              history.push(`/affiliates/affiliate_commission/${row.id}`);
            }}
          >
            {row?.affiliateCommission}
          </div>,
          row?.discount,
          row?.assignedTo,
        ]
        : [];
    });
  };


  // useEffect(() => {
  //   filterCustomersValue();
  // }, [selectedCustomers]);

  useEffect(() => {
    if (data && data.fetchCommissionProfiles) {
      let rowsData = formatRows(
        data.fetchCommissionProfiles?.commisionProfiles
      );
      setCustomers(data.fetchCommissionProfiles?.commisionProfiles);
    }
  }, [data]);

  const fields = [
    'id',
    'firstName',
    'lastName',
    'email',
    'communication',
    'phone',
    'language',
    'autoCollection',
    'status',
    'subscription',

    'additionalContacts.id',
    'additionalContacts.email',
    'additionalContacts.firstName',
    'additionalContacts.lastName',
    'additionalContacts.companyName',
    'additionalContacts.phone',

    'billingAddress.firstName',
    'billingAddress.lastName',
    'billingAddress.email',
    'billingAddress.company',
    'billingAddress.phone',
    'billingAddress.address1',
    'billingAddress.address2',
    'billingAddress.city',
    'billingAddress.language',
    'billingAddress.zipCode',
  ];
  const transforms = [
    unwind({ paths: ['additionalContacts', 'billingAddress'] }),
  ];
  // const exportCSV = () => {
  const dataSelected = [...filterCustomers].filter((item) =>
    selectedCustomers.find((select) => select === item.id)
  );
  const json2csvParser = new Parser({ fields, transforms });
  let csv = json2csvParser.parse(dataSelected);

  //

  // };

  //import customer by csv:
  const [active, setActive] = useState(false);
  const [checked, setChecked] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleCheckbox = useCallback((value) => setChecked(value), []);

  //upload file
  const [file, setFile] = useState();

  const csvData = [];

  const parsedata =
    file &&
    Papa.parse(file, {
      header: false,
      step: function (result) {
        csvData.push(result.data);
      },
      complete: function (results, file) {
        // console.log('csvData: ', csvData);
      },
    });

  const CREATE_CUSTOMER = gql`
    mutation ($input: AddCustomersInput!) {
      addCustomers(input: $input) {
        result
      }
    }
  `;
  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
  useEffect(() => {
    if (status == 'subscriptions') {
      filterCustomer();
    } else {
      setFilteredCustomer([]);
    }
  }, [status, customers]);

  const filterCustomer = () => {
    // let allcustomers = deepCopy(customers)
    // let filteredAllCustomers = allcustomers.map((customer) => {
    //   let contracts = []
    //   JSON.parse(customer?.contracts)?.map((contract) => {
    //     try {
    //       contract = JSON.parse(contract)
    //     }
    //     catch (e) {
    //       console.log("error", e)
    //     }
    //     if (contract?.status == "ACTIVE") { contracts.push(JSON.stringify(contract)) }
    //   })

    //   console.log("contracts", contracts);
    //   customer.contracts = JSON.stringify(contracts);
    //   return customer
    // })
    let allcustomers = deepCopy(customers);
    let filteredAllCustomers = allcustomers.filter((customer) => {
      return JSON.parse(customer?.contracts)?.length > 0;
    });
    setFilteredCustomer([...filteredAllCustomers]);
  };

  useEffect(() => {
    if (createNew) {
      setHeaderTitle('Create commision profile');
    } else {
      setHeaderTitle('Commission profiles');
    }
  }, [createNew]);

  const deleteSelectedCommissions = () => {
    axiosconfig.delete(`/commissionProfile/deleteCommissions/${selectedCustomers.join(
      ','
    )}?shopify_domain=${domain}`).then(({ data }) => {
      refetch();
      setToastContent('Deleted Successfully');
    });
  };

  return (
    <>
      <Frame>
        {toastContent && (
          <Toast content={toastContent} onDismiss={hideSaveSuccess} />
        )}

        {formErrors.length > 0 && (
          <>
            <Banner title="Customer data has not been saved" status="critical">
              <List type="bullet">
                {formErrors.map((message, index) => (
                  <List.Item key={index}>{message.message}</List.Item>
                ))}
              </List>
            </Banner>
            <br />
          </>
        )}
        {loadingScreen && (
          <>
            <LoadingScreen />
          </>
        )}
        {createNew ? (
          <CreateAffiliateCommission
            {...{
              i18n,
              domain,
              toastContent,
              setToastContent,
              customHeader,
              setCustomHeader,
            }}
          />
        ) : (
          <div className="without_header_card">
            <Card
              actions={{
                content: (
                  <div className="card_header_button_group">
                    <div className="card_header_buttons primary_button_group">
                      <HeaderButtons
                        headerButtons={headerButtons}
                        setHeaderButton={setHeaderButton}
                        headerButton={headerButton}
                      />
                    </div>
                    <div className="card_header_buttons secondary_button_group">
                      {selectedCustomers.length > 0 && false && (
                        <Button
                          id="deleteButton"
                          onClick={() => {
                            deleteSelectedCommissions();
                          }}
                        >
                          Delete
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          history.push(
                            `/affiliates/affiliate_commission/createNew`
                          );
                        }}
                      >
                        Create commission profile
                      </Button>
                    </div>
                  </div>
                ),
              }}
            >
              <Card.Section>
                <div className={'table customer-subscription-tbl' + ' '}>
                  <DataTable
                    columnContentTypes={['text', 'text', 'text', 'text']}
                    headings={[
                      'Title',
                      'Type',
                      'Affiliate commission',
                      'Discount',
                      'Assigned to',
                    ]}
                    rows={formatRows(customers)}
                  />
                </div>
                {loading && <Spinner />}
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Pagination
                    hasPrevious={hasPrevious}
                    onPrevious={() => {
                      setPage((prev) => +prev - 1);
                    }}
                    hasNext={hasMore}
                    onNext={() => {
                      setPage((prev) => +prev + 1);
                    }}
                  />
                </div>
              </Card.Section>
            </Card>
          </div>
        )}
      </Frame>
    </>
  );
};

export default AffiliateCommission;
