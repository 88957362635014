import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button, ButtonGroup, Card, Icon } from '@shopify/polaris';
import {
  ChevronRightMinor,
  MobileBackArrowMajor,
} from '@shopify/polaris-icons';

const TooglePointsProgram = (props) => {
  const { domain, i18n, detailView, setDetailView } = props;
  const [pointProgramStatus, setPointProgramStatus] = useState(false);

  const GET_DATA = gql`
    query {
      fetchWaysToEarnPoint {
        pointProgramStatus
      }
    }
  `;

  const { data, loading, error, refetch } = useQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.fetchWaysToEarnPoint?.pointProgramStatus) {
      setPointProgramStatus(data?.fetchWaysToEarnPoint?.pointProgramStatus);
    }
  }, [data]);


  const toggleStatus = (status) => {
    fetch('/togglePointsProgram', {
      method: 'POST',
      body: JSON.stringify({
        active: status,
        shopify_domain: domain,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((data) => {
        if (data?.success) {
          setPointProgramStatus(status);
        }
      });
  };
  return (
    <Card>
      <Card.Section>
        <div className="card_body_wrapper">
          <div className="content_wrapper">
            <div className="title">
              {!pointProgramStatus
                ? i18n.t('points_programs.program_is_deactivated')
                : i18n.t('points_programs.program_is_activated')}
            </div>
            <div
              className={`programStatusButtonWrapper ${pointProgramStatus ? 'deactivate' : 'activate'
                }`}
            >
              <Button
                onClick={() => {
                  toggleStatus(!pointProgramStatus);
                }}
              >
                {pointProgramStatus
                  ? i18n.t('points_programs.deactivate_program')
                  : i18n.t('points_programs.activate_program')}
              </Button>
            </div>
          </div>
        </div>
      </Card.Section>
    </Card>
  );
};

export default TooglePointsProgram;
