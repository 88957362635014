import { Button, Checkbox, Icon, RadioButton, Select, TextField } from '@shopify/polaris';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SearchVariants from './SearchVariants';
import SearchPlan from '../upsell/SearchPlan';
import DeleteSVG from '../../assets/images/delete.svg';
import axiosconfig from '../axiosconfig';
import { MobileCancelMajor } from '@shopify/polaris-icons';
import SellingPlanPicker from '../SubscriptionProducts/SellingPlanPicker';
import ProductVariantPicker from '../plans/ProductVariantPicker';
import "./Swap.css"
import SubscriptionBundleModal from '../SubscriptionProducts/SubscriptionBundleModal';
import SubscriptionBundlePicker from '../SubscriptionProducts/SubscriptionBundlePicker';

function EditSwapModel({
    setShowEditSubModel,
    setShowEditSwapModel,
    apiData,
    setToastContent,
    setSaveSuccess,
    i18n,
    getSubscription,
    formatDate,
    editModalData
}) {
    const lineItem = apiData?.contract?.api_data?.lines?.edges
    const contractProducts = lineItem?.map((item) => {
        return { id: item.node.product_id, title: item.node.title, image: item.node.variant_image?.url, selected: true, variants: [{ id: item.node.variant_id, title: item.node.variant_title, selected: true, quantity: item.node.quantity, price: item.node.current_price.amount }] }
    })
    const sellingplan = { sellingPlanName: lineItem?.[0]?.node?.selling_plan_name, sellingPlanId: lineItem?.[0]?.node?.selling_plan_id, selected: true, intervalCount: +apiData?.contract?.api_data?.billing_policy?.interval_count, intervalType: apiData?.contract?.api_data?.billing_policy?.interval }
    const current_bundle = apiData?.current_bundle ? [apiData?.current_bundle] : [];
    if (current_bundle?.length > 0) {
        current_bundle[0].selected = true;
        current_bundle[0].bundles = current_bundle[0]?.collection ? JSON.parse(current_bundle[0].collection?.metafields?.edges?.find((col) => col.node.key == "bundle21_options")?.node?.value) : []
        current_bundle[0].bundles?.map((b) => {
            if (+b.size === +apiData.contract.bundle) {
                b.selected = true
            }
        })
    }
    const [formData, setFormData] = useState({
        variantIds: '',
        sellingPlan: [sellingplan],
        variants: contractProducts,
        bundles: current_bundle
    });
    const [allVariants, setAllVariants] = useState([]);
    const [allSelectedPlans, setAllSelectedPlans] = useState([]);

    // const getSwapProducts = (selling_plan_id) => {
    //     return apiData?.all_subcription_products?.filter{(asp)=>{ asp.selling_plan_id || asp.selling_plan.id == selling_plan_id}?.pluck(:product_images)&.flatten&.compact rescue []
    // }

    const swapProductSubmit = (variant_id = null) => {
        let variantsCount = 0;
        formData.variants?.map((p) => {
            let variantsQty = p.variants.filter((v) => v.selected)?.reduce((qty, v) => {
                return qty + v.quantity
            }, 0)
            variantsCount += +variantsQty
        })
        if (formData?.bundles?.[0] && +variantsCount != +formData?.bundles?.[0]?.bundles?.find((bundle) => bundle.selected)?.size) {
            setToastContent("Selected products quantity  must be equal to the selected bundle size");
            setSaveSuccess(true);
        } else {
            const formdata = { ...formData }
            formdata.action_by = 'admin';
            formdata.format = 'json';
            formdata.sellingPlan = formdata.sellingPlan[0]
            formdata.bundles = formData.bundles[0]
            axiosconfig.post(`/subscriptions/${apiData?.contract?.shopify_id}/advance_swap`, formdata).then(({ data }) => {
                if (data?.success) {
                    setToastContent("Processed successfully!");
                    setSaveSuccess(true);
                    setShowEditSwapModel(false);
                    setTimeout(() => {
                        getSubscription();
                    }, [3000]);
                } else {
                    setToastContent(data?.error);
                    setSaveSuccess(true);
                }
            });
        }
    };

    useEffect(() => {
        if (allSelectedPlans.length > 1) {
            let selling_plan = [allSelectedPlans[allSelectedPlans?.length - 1]];
            setAllSelectedPlans([...selling_plan]);
        }
    }, [allSelectedPlans]);
    const closeModal = () => { setShowEditSwapModel(false) }
    const handleSellingPlanChange = (val, key) => {
        let sellingplan = formData.sellingPlan[0];
        sellingplan[key] = val
        setFormData({ ...formData, sellingPlan: [sellingplan] })
    }

    // const handleBundleSize = (e, "intervalCount") => {

    // }
    return (
        <div className="create_billing modal-popup-cstm">
            <div className="modal-dialouge">
                <div className="Modal-header">
                    <h2>Confirm Edit/Swap?</h2>
                    <div onClick={closeModal} >
                        <Icon source={MobileCancelMajor} tone="base" />
                    </div>
                </div>
                <div className="modal-content">
                    <div className='plan_picker_contaner'>
                        <SellingPlanPicker {...{ formData, setFormData }} fieldName={"sellingPlan"} singleLine={true} label="Selling Plan" selectedPlans={""} />
                        <div className='setBillingFrequency'>
                            <label>Billing frequency</label>
                            <div className='billing_frequency_fields'>
                                <TextField
                                    type='number'
                                    value={`${formData?.sellingPlan?.[0]?.intervalCount}`}
                                    onChange={(e) => handleSellingPlanChange(e, "intervalCount")}
                                />
                                <Select
                                    options={["DAY", "MONTH", "WEEK", "YEAR"]}
                                    value={formData?.sellingPlan?.[0]?.intervalType}
                                    onChange={(e) => handleSellingPlanChange(e, "intervalType")}
                                    key={formData?.sellingPlan?.[0]?.intervalType}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='product_picker_contaner'>
                        <ProductVariantPicker {...{ formData, setFormData }} fieldName={"variants"} label="Subscription products" customQuantityPrice={true} selectedProducts={[]} />
                    </div>
                    <div className='plan_picker_contaner'>
                        <SubscriptionBundlePicker {...{ formData, setFormData }} fieldName={"bundles"} singleLine={true} label="Subscription bundle" selectedBundle={""} />
                        {
                            formData?.bundles?.length > 0 && (
                                <div className='setBillingFrequency'>
                                    <label>Size</label>
                                    <div className='billing_frequency_fields'>
                                        <TextField
                                            type='number'
                                            value={`${formData?.bundles?.[0]?.bundles?.find((bundle) => bundle.selected)?.size}`}
                                            disabled
                                        />
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="btn-green"
                        onClick={() => {
                            swapProductSubmit()
                        }}
                    >
                        Save
                    </button>
                    <button className="btn-red" onClick={() => { closeModal() }}>
                        Cancel
                    </button>
                </div>
            </div>
            <div className="modal-cstm-backdrop"></div>
        </div>
    );
}

export default EditSwapModel