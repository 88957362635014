import React, { useState } from 'react';
import { Card, Icon } from '@shopify/polaris';
import { HorizontalDotsMinor } from '@shopify/polaris-icons';
import { useParams, useHistory } from 'react-router-dom';

const AlreadyCreatedOffers = ({
  reward,
  setEditReward,
  setToastContent,
  fetchAllRewards,
  templateImage,
  formatDate,
}) => {
  const [moreOptions, setMoreOptions] = useState(false);
  const { edit_id } = useParams();
  const history = useHistory();

  const deleteReward = (reward_id) => {
    fetch(`/rewards/delete_reward/${reward_id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchAllRewards();
          setToastContent('Reward Deleted');
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  const duplicateOffer = (reward_id) => {
    fetch(`/rewards/duplicate_reward/${reward_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchAllRewards();
          setToastContent('Reward Duplicated');
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  const toggleStatus = (reward_id, status) => {
    fetch(`/rewards/toggle_rewards_status/${reward_id}`, {
      method: 'POST',
      body: JSON.stringify({ status: status }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          fetchAllRewards();
        }
      })
      .catch((e) => {
        console.log('Somethind went wrong' + e);
      });
  };

  return (
    <div className="upsell_offer_wrapper_container">
      <Card>
        <div className="upsell_offer_wrapper">
          <div
            className="more_options"
            onClick={() => {
              setMoreOptions(!moreOptions);
            }}
          >
            <div className="more_options icon_wrapper">
              <Icon source={HorizontalDotsMinor} color="base" />
            </div>
            {moreOptions && (
              <div className="options_lists">
                <div
                  className="option_wrapper"
                  onClick={() =>
                    history.push(
                      `/pointsProgram/rewardsPage/${reward?.id}/${reward?.rewards_template_id}`
                    )
                  }
                >
                  Edit
                </div>

                <div
                  className="option_wrapper"
                  onClick={() => {
                    toggleStatus(
                      reward?.id,
                      `${reward.status == 'live' ? 'draft' : 'live'}`
                    );
                  }}
                >
                  {reward.status == 'live' ? 'Unpublish' : 'Publish'}
                </div>
                <div
                  className="option_wrapper"
                  onClick={() => duplicateOffer(reward?.id)}
                >
                  Duplicate
                </div>
                <div
                  className="option_wrapper delete_offer"
                  onClick={() => deleteReward(reward?.id)}
                >
                  Delete
                </div>
              </div>
            )}
          </div>
          <div className="img_wrapper">
            <img src={templateImage} />
          </div>
          <div className="content_block">
            <span
              className="offer_title"
              onClick={() => setEditReward(reward?.id)}
            >
              {reward?.name}
            </span>
            <div className="date_wrapper">
              Created at: {formatDate(reward?.created_at)}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AlreadyCreatedOffers;
