import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Card,
  Toast,
  ChoiceList,
  Page,
  PageActions,
  TextField,
  Layout,
  Banner,
  List,
  Frame,
  Select,
  Icon,
  Checkbox,
  RadioButton,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
  TextContainer,
} from '@shopify/polaris';
import PixelIcon from '../../assets/images/PixelIcon';
import { MobileBackArrowMajor, NoteMinor } from '@shopify/polaris-icons';
import SearchProduct from '../upsell/SearchProduct';
import Preview from '../plans/Preview';
import '../WaysToEarnPoint/waysToEarnPoint.css';
import SearchCollection from '../plans/SearchCollection';
import SummaryFullWidthStyle from '../../components/layout/SummaryFullWidthStyle';
import { useHistory } from 'react-router-dom';

const EditReferralRewards = ({
  selectedCollections,
  setSelectedCollections,
  formData,
  setFormData,
  setEditForm,
  refetch,
  formErrors,
  setFormErrors,
  saveSuccess,
  setSaveSuccess,
  titleOptions,
  i18n,
  allProducts,
  setAllProducts,
  detailView,
  setRenderConfirmDelete,
  currentView,
}) => {
  const [icon, setIcon] = useState();
  const [defaultIcon, setDefaultIcon] = useState('default');
  const history = useHistory();
  const [fieldError, setFieldError] = useState({
    title: '',
    pointsAwarded: '',
  });
  const [defaultIcons, setDefaultIcons] = useState([]);
  const [redemptionStyle, setRedemptionStyle] = useState('fixed');
  const [base64Icon, setBase64Icon] = useState();
  const [discountPrefix, setDiscountPrefix] = useState(false);
  const [minPointsRequired, setMinPointsRequired] = useState(false);
  const [maxPointsRequired, setMaxPointsRequired] = useState(false);
  const [maxShippingAmount, setmaxShippingAmount] = useState(false);
  const [minimumPurchaseAmount, setMinimumPurchaseAmount] = useState('none');
  const [selectedCollectionOptions, setSelectedCollectionOptions] = useState(
    []
  );

  const [file, setFile] = useState();

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => setFile(acceptedFiles[0]),
    []
  );

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFile = file > 0 && (
    <div style={{ padding: '0' }}>
      <Stack vertical spacing="5">
        <Stack alignment="center">
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{' '}
            <TextContainer>
              <p>{file.size} bytes</p>
            </TextContainer>
          </div>
        </Stack>
      </Stack>
    </div>
  );

  useEffect(() => {
    if (formData?.iconUrl) {
      setDefaultIcon('upload');
    }
  }, [formData?.iconUrl]);

  const fetchDefaultIcons = () => {
    fetch('/fetch_redeem_default_icons', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.icons?.length > 0) {
          setDefaultIcons([...data?.icons]);
        }
      });
  };

  useEffect(() => {
    fetchDefaultIcons();
  }, []);


  const ADD_POINTS_REFERRAL = gql`
    mutation ($input: AddPointsReferralInput!) {
      addPointsReferral(input: $input) {
        pointsReferral {
          id
        }
      }
    }
  `;
  const [addPointsReferral] = useMutation(ADD_POINTS_REFERRAL);

  const [updated, setUpdated] = useState(false);

  const convertToBase64 = () => {
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setBase64Icon(reader.result);
      };
    }
  };

  useEffect(() => {
    convertToBase64();
  }, file);
  const handleSubmit = () => {
    let formdata = formData;
    formdata.icon = base64Icon;
    formdata.collectionImages = selectedCollections;
    formdata.maxShippingAmount = +formData?.maxShippingAmount;
    formdata.minimumPurchaseAmount = +formData?.minimumPurchaseAmount;
    formdata.discountValue = +formData?.discountValue;
    formdata.minPointsRequired = +formData?.minPointsRequired;
    formdata.maxPointsRequired = +formData?.maxPointsRequired;

    addPointsReferral({
      variables: {
        input: { params: formdata },
      },
    })
      .then((resp) => {
        const data = resp.data;
        const errors = data.errors;

        if (errors) {
          setFormErrors(errors);
        } else {
          setSaveSuccess(true);
          refetch();
          history.push(`/pointsProgram/referral_rewards`);
          setRenderConfirmDelete(false);
        }
      })
      .catch((error) => {
        setFormErrors(error);
      });
  };
  const [summaryText, setSummaryText] = useState([]);
  useEffect(() => {
    let summary = [];
    let max_ship = formData?.maxShippingAmount || 0;
    summary =
      formData?.title == 'Free Shipping'
        ? i18n.t('points_programs.free_shipping_summary_with_max_shipment', {
          maxShippingAmount: max_ship,
        })
        : formData?.title == 'Amount discount'
          ? i18n.t('ways_to_redeem.amount_discount_not_zero_summary', {
            discount_value: formData?.discountValue,
          })
          : formData?.title == 'Percentage off'
            ? i18n.t('ways_to_redeem.percentage_off_summary', {
              discount_value: formData?.discountValue,
            })
            : i18n.t('ways_to_earn.points_awarded_summary', {
              pointsAwarded: formData?.points,
            });
    setSummaryText(summary);
    setFormData({ ...formData, summary: summary });
  }, [
    formData?.title,
    formData?.pointsAwarded,
    formData?.points,
    formData?.discountValue,
  ]);

  const handleChange = (_, newValue) => {
    setDefaultIcon(newValue);
    setFile();
  };

  const handleRedmptionChange = (_, newValue) => {
    setRedemptionStyle(newValue);
  };


  return (
    <Frame>
      <Page
        title={
          detailView != 'summary' && (
            <div className="back_button_wrapper">
              <div
                className="back-button pointer"
                style={{ marginTop: '15px' }}
                onClick={() => history.push(`/pointsProgram/referral_rewards`)}
              >
                <Icon source={MobileBackArrowMajor} color="base" />
              </div>
              <div class="text_wrapper">
                {formData?.id
                  ? `${i18n.t('edit')} ${currentView}`
                  : `${i18n.t('add')} ${currentView}`}
              </div>
            </div>
          )
        }
      >
        <Layout>
          <Layout.Section>
            <Card
              title={
                <div className="heading_title">
                  <PixelIcon />
                  {i18n.t('points_programs.reward_value')}
                </div>
              }
              sectioned
            >
              <div className="earningValues">
                <Select
                  label="Title"
                  options={titleOptions}
                  onChange={(e) => {
                    setFormData({ ...formData, title: e });
                  }}
                  value={formData?.title}
                />
                {formData?.title == 'Points' ? (
                  <div className="text_flex_wrapper">
                    <div
                      className={
                        formData?.title != 'Free Shipping' ? '' : 'hidden'
                      }
                    >
                      <TextField
                        label={
                          <div className="order_summary">
                            {i18n.t('points_programs.points_value')}
                          </div>
                        }
                        type="number"
                        suffix="points"
                        value={formData?.points}
                        onChange={(e) => {
                          setFormData({ ...formData, points: e });
                        }}
                        error={fieldError?.points}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                ) : redemptionStyle == 'fixed' ||
                  formData?.title != 'Amount discount' ? (
                  <>
                    <div className="text_flex_wrapper">
                      <div
                        className={
                          formData?.title != 'Free Shipping' ? '' : 'hidden'
                        }
                      >
                        <TextField
                          label={
                            <div className="order_summary">
                              {i18n.t('points_programs.discount_value')}
                            </div>
                          }
                          type="number"
                          value={formData?.discountValue}
                          onChange={(e) => {
                            setFormData({ ...formData, discountValue: e });
                          }}
                          error={fieldError?.discountValue}
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    <div
                      className={`block_wrapper ${formData?.title == 'Free Shipping' ? '' : 'hidden'
                        }`}
                    >
                      <Checkbox
                        label={i18n.t(
                          'points_programs.max_number_shipping_amount_for_reward'
                        )}
                        checked={maxShippingAmount}
                        onChange={(newChecked) => {
                          setmaxShippingAmount(newChecked);
                        }}
                      />
                      {maxShippingAmount && (
                        <TextField
                          label=""
                          type="number"
                          prefix="$"
                          value={formData?.maxShippingAmount}
                          onChange={(e) => {
                            setFormData({ ...formData, maxShippingAmount: e });
                          }}
                          error={fieldError?.maxShippingAmount}
                          autoComplete="off"
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <div className="text_flex_wrapper">
                    <Checkbox
                      label={i18n.t(
                        'points_programs.minimum_amount__of_points_for_reward'
                      )}
                      checked={minPointsRequired}
                      onChange={(newChecked) => {
                        setMinPointsRequired(newChecked);
                      }}
                    />
                    {minPointsRequired && (
                      <TextField
                        label=""
                        type="number"
                        suffix="points"
                        value={formData?.minPointsRequired}
                        onChange={(e) => {
                          setFormData({ ...formData, minPointsRequired: e });
                        }}
                        error={fieldError?.minPointsRequired}
                        autoComplete="off"
                      />
                    )}
                    <Checkbox
                      label={i18n.t(
                        'points_programs.max_amount_of_points_customer_spend'
                      )}
                      checked={maxPointsRequired}
                      onChange={(newChecked) => {
                        setMaxPointsRequired(newChecked);
                      }}
                    />

                    {maxPointsRequired && (
                      <TextField
                        label=""
                        type="number"
                        suffix="points"
                        value={formData?.maxPointsRequired}
                        onChange={(e) => {
                          setFormData({ ...formData, maxPointsRequired: e });
                        }}
                        error={fieldError?.maxPointsRequired}
                        autoComplete="off"
                      />
                    )}
                  </div>
                )}
              </div>
            </Card>

            <div
              className={
                (formData?.title == 'Amount discount' ||
                  formData?.title == 'Percentage off') &&
                  formData?.title != 'Points'
                  ? ''
                  : 'hidden'
              }
            >
              <Card
                title={
                  <div className="heading_title">
                    <PixelIcon />
                    {i18n.t('points_programs.applies_to')}
                  </div>
                }
                sectioned
              >
                <div
                  className="radioWrapper"
                  style={{ display: 'flex', gap: '10px', flexFlow: 'column' }}
                >
                  <RadioButton
                    label={i18n.t('points_programs.entire_order')}
                    checked={formData?.appliesTo === 'entire_order'}
                    id="entire_order"
                    name="appliesTo"
                    onChange={(_, newValue) => {
                      setFormData({ ...formData, appliesTo: newValue });
                    }}
                  />
                  <RadioButton
                    label={i18n.t('points_programs.specific_collection')}
                    checked={formData?.appliesTo === 'specific_collection'}
                    id="specific_collection"
                    name="appliesTo"
                    onChange={(_, newValue) => {
                      setFormData({ ...formData, appliesTo: newValue });
                    }}
                  />

                  {formData?.appliesTo === 'specific_collection' && (
                    <div className="box-subscription-search">
                      <SearchCollection
                        selectedOptions={selectedCollectionOptions}
                        setSelectedOptions={setSelectedCollectionOptions}
                        selectedCollections={selectedCollections}
                        setSelectedCollections={setSelectedCollections}
                        i18n={i18n}
                      />
                    </div>
                  )}
                </div>
              </Card>

              <Card
                title={
                  <div className="heading_title">
                    <PixelIcon />
                    {i18n.t('points_programs.minimum_requirement')}
                  </div>
                }
                sectioned
              >
                <div
                  className="radioWrapper"
                  style={{ display: 'flex', gap: '10px', flexFlow: 'column' }}
                >
                  <RadioButton
                    label={i18n.t('none')}
                    checked={minimumPurchaseAmount == 'none'}
                    id="none"
                    name="minimumPurchaseAmount"
                    onChange={(_, newChecked) => {
                      setMinimumPurchaseAmount(newChecked);
                    }}
                  />
                  <RadioButton
                    label={i18n.t('points_programs.minimum_purchase_amount')}
                    checked={minimumPurchaseAmount == 'minimumPurchaseAmount'}
                    id="minimumPurchaseAmount"
                    name="minimumPurchaseAmount"
                    onChange={(_, newChecked) => {
                      setMinimumPurchaseAmount(newChecked);
                    }}
                  />

                  {minimumPurchaseAmount == 'minimumPurchaseAmount' && (
                    <TextField
                      label=""
                      type="number"
                      value={formData?.minimumPurchaseAmount}
                      prefix="$"
                      onChange={(e) => {
                        setFormData({ ...formData, minimumPurchaseAmount: e });
                      }}
                      error={fieldError?.minimumPurchaseAmount}
                      autoComplete="off"
                    />
                  )}
                </div>
              </Card>
            </div>

            {
              <div
                className={
                  formData?.title != 'POS amount discount' &&
                    formData.title != 'POS percentage off' &&
                    formData?.title != 'Points'
                    ? ``
                    : `hidden`
                }
              >
                <Card
                  title={
                    <div className="heading_title">
                      <PixelIcon />
                      {i18n.t('points_programs.discount_code')}
                    </div>
                  }
                  sectioned
                >
                  <div
                    className="radioWrapper"
                    style={{ display: 'flex', gap: '10px', flexFlow: 'column' }}
                  >
                    <Checkbox
                      label={i18n.t(
                        'points_programs.add_prefix_to_discount_codes'
                      )}
                      checked={discountPrefix}
                      onChange={(newChecked) => {
                        setDiscountPrefix(newChecked);
                      }}
                    />
                    {discountPrefix && (
                      <TextField
                        label=""
                        type="text"
                        value={`${formData?.prefixDiscountCode} `}
                        placeholder="e.g. $10OFF-"
                        helpText={i18n.t(
                          'points_programs.helptext_prefix_to_discount_codes'
                        )}
                        onChange={(e) => {
                          setFormData({ ...formData, prefixDiscountCode: e });
                        }}
                        error={fieldError?.prefixDiscountCode}
                        autoComplete="off"
                      />
                    )}
                  </div>
                </Card>
              </div>
            }
          </Layout.Section>
          <div className="defauleLayoutSection">
            <Layout.Section secondary>
              <Card title={i18n.t('summary')}>
                <Card.Section>
                  {/* <TextField
                                        multiline={2}
                                        value={formData?.summary}
                                        onChange={(e) => setFormData({ ...formData, summary: e })}
                                        autoComplete="off"
                                    /> */}
                  <List type="bullet">
                    <List.Item>{summaryText}</List.Item>
                  </List>
                </Card.Section>
                {/* <Card.Section subdued title="STATUS">
                                    <ChoiceList
                                        title=""
                                        choices={[
                                            { label: 'Active', value: true },
                                            { label: 'Disabled', value: false },
                                        ]}
                                        selected={[formData?.status]}
                                        onChange={(e) => setFormData({ ...formData, status: e[0] })}
                                    />
                                </Card.Section> */}
              </Card>
              <Card title={i18n.t('icon')} sectioned>
                <div
                  className="radioWrapper"
                  style={{ display: 'flex', gap: '10px', flexFlow: 'column' }}
                >
                  <RadioButton
                    label={i18n.t('default')}
                    checked={defaultIcon === 'default'}
                    id="default"
                    name="icon"
                    onChange={handleChange}
                  />
                  {defaultIcon == 'default' && (
                    <div className="icon_wrapper">
                      <img
                        src={
                          defaultIcons[0]?.find(
                            (val) => val?.for == formData?.title
                          )?.icon
                        }
                        style={{ width: '40px' }}
                      />
                    </div>
                  )}
                  <RadioButton
                    label={i18n.t('points_programs.upload_own_icon')}
                    checked={defaultIcon === 'upload'}
                    id="upload"
                    name="icon"
                    onChange={handleChange}
                  />
                  {formData?.iconUrl && (
                    <div className="icon_wrapper">
                      <img src={formData?.iconUrl} style={{ width: '40px' }} />
                    </div>
                  )}
                  {defaultIcon == 'upload' && (
                    <DropZone
                      allowMultiple={false}
                      onDrop={handleDropZoneDrop}
                      type="image"
                    >
                      {uploadedFile}
                      {fileUpload}
                    </DropZone>
                  )}
                </div>
              </Card>
            </Layout.Section>
          </div>
          <Layout.Section>
            <PageActions
              primaryAction={{
                content: i18n.t('save'),
                onClick: () => handleSubmit(),
              }}
              secondaryActions={[
                {
                  content: i18n.t('delete'),
                  destructive: true,
                },
              ]}
            />
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  );
};

export default EditReferralRewards;
