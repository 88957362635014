import React, { useState, useCallback, useEffect } from 'react';

import {
  Card,
  DisplayText,
  FormLayout,
  Layout,
  Select,
  TextStyle,
  Stack,
  Icon,
} from '@shopify/polaris';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import PixelIcon from '../../assets/images/PixelIcon';
const ProductExtention = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleBack,
  i18n
}) => {
  const options = [
    { label: 'Design I', value: 'one' },
    { label: 'Design II', value: 'two' },
    { label: 'Design III', value: 'three' },
  ];
  return (
    <div className="product-extention">
      <div>
        <div>
          <Card
            title={
              <div className="heading_title">
                <PixelIcon />
                {i18n.t("product_extension")}
              </div>
            }
          >
            <Card.Section>
              <div className="billing">
                <FormLayout>
                  <DisplayText size="small" element="h6">
                    <TextStyle variation="subdued">{i18n.t("product_extension")}</TextStyle>
                  </DisplayText>
                  <Select
                    label={i18n.t("design")}
                    value={values.designType}
                    error={touched.designType && errors.designType}
                    onChange={(e) => setFieldValue('designType', e)}
                    options={options}
                  />
                </FormLayout>
              </div>
            </Card.Section>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ProductExtention;
