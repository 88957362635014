import { Frame, Page, Toast } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { BarChartIcon, PlayIcon, ToggleButtonIcon } from '../../components/layout/CustomIcon';
import './style.css';
import { useHistory } from 'react-router-dom';
import axiosConfig from "../axiosconfig";

export const PickProduct = (props) => {
  const { domain, selectedApps } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [toastContent, setToasContent] = useState();
  const hideSaveSuccess = useCallback(() => setToasContent());
  const cardBoxesContent = [
    {
      title: 'RevenueOS',
      desc: `A comprehensive revenue Ops platform built to unify advanced subscriptions & replenishment; 
      SMS checkout; upsells & checkout extensions; affiliates & influencer marketing; & more.`,
      url: 'revenueos',
      icon: <BarChartIcon />,
    },
    {
      title: 'Subscriptions & Replenishment',
      desc: `Boost recurring revenue with advanced replenishment, access, build-a-box subscriptions; memberships & replenishment across online & retail channels`,
      url: 'subscription_home',
      icon: <PlayIcon />,
    },
    {
      title: 'Affiliates & Influencers',
      desc: `Scale word-of-mouth marketing with influencers, creators & traditional affiliate publishers with our next gen cookieless & coupon-free tracking`,
      url: 'affiliate_influencer',
      icon: <ToggleButtonIcon />,
    },
    {
      title: 'CheckoutOS',
      desc: `Optimize conversions with a comprehensive suite of checkout extensions - pre-checkout upsells, trust badges, thank you page surveys, order status surveys etc.`,
      url: 'checkout_extensions',
      icon: <BarChartIcon />,
    },
    {
      title: 'Post-purchase upsells',
      desc: `Scale average order value with irresistable one-click upsells just before the thank you page. `,
      url: 'upsells',
      icon: <PlayIcon />,
    },
    {
      title: 'SMSmarty',
      desc: `Sell more with SMS; save abandoned carts and enable customers manage their subscription with SMS prompts `,
      url: 'sms_smarty',
      icon: <ToggleButtonIcon />,
    },
  ];

  const selectProduct = (selectedProduct) => {
    if (!loading) {
      setLoading(true);
      axiosConfig.post(`/setSelectedProduct`, {
        shopify_domain: domain,
        selected_product: selectedProduct,
      }).then(({ data }) => {
        if (data?.success) {
          window.location.replace(
            `/${domain?.replace('.myshopify.com', '')}`
          );
        } else {
          setToasContent('Something went wrong ...');
          console.log('error:-', data?.error);
          setLoading(false);
        }
      });
    }
  };

  return (
    <Frame>
      {toastContent && (
        <Toast onDismiss={hideSaveSuccess} content={toastContent} />
      )}
      <Page>
        <div className="pick_product_main_container">
          <div className="text-center pick_product_header">
            <h1>Pick your first product.</h1>
            <h5>
              Pick your first product to get started with. You can set up any
              others you'd like later.
            </h5>
          </div>
          <div className="pick_product_container">
            {cardBoxesContent?.map((cardBox) => {
              return (
                <div className="card_box">
                  <div className="iconWrapper">{cardBox?.icon}</div>
                  <h6>{cardBox?.title}</h6>
                  <p>{cardBox?.desc}</p>
                  {selectedApps?.includes(cardBox?.url) ? (
                    <button className="get_started">Active</button>
                  ) : (
                    <button
                      className="get_started"
                      onClick={() => selectProduct(cardBox?.url)}
                    >
                      Get started
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </Page>
    </Frame>
  );
};
