import React, { useCallback, useEffect, useState } from 'react';
import Overview from '../Overview/Overview';

import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

const PointsProgramOverview = (props) => {
  var today = new Date();
  const { i18n, customNavTabs, floatingSetupGuide, setFloatingSetupGuide } =
    props;
  const [subHeaderData, setSubHeaderData] = useState([]);
  const [filters, setFilters] = useState({
    startDate: new Date(new Date().setDate(today.getDate() - 31)),
    endDate: new Date(new Date().setDate(today.getDate() - 1)),
  });

  const [fetchDashboardReport, setFetchDashboardReport] = useState({});
  const getGraphDataQuery = gql`
    query ($startDate: String!, $endDate: String!) {
      fetchDashboardReport(startDate: $startDate, endDate: $endDate) {
        todayOrders
        lastWeekOrders
        lastWeekOrderRevenue
        todayOrderRevenue
        upcomingSales
        currencySymbol
        currency
        mrr {
          value
          percent
          up
        }
        activeSubscriptionsCount {
          value
          percent
          up
        }
        churnRate {
          value
          percent
          up
        }
        customerLifetimeValue {
          value
          percent
          up
        }
        activeCustomers {
          data {
            value
          }
          date
        }
        revenueChurn {
          date
          data {
            value
          }
        }
        arrData {
          date
          data {
            value
          }
        }
        mrrData {
          date
          data {
            value
          }
        }
        refundData {
          date
          data {
            value
          }
        }
        salesData {
          date
          data {
            value
          }
        }
        renewalData {
          date
          data {
            value
          }
        }
      }
    }
  `;

  const [getReport, { loading, error, data }] = useLazyQuery(
    getGraphDataQuery,
    { fetchPolicy: 'network-only' }
  );

  const getReportData = useCallback(() => {
    getReport({
      variables: {
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
    });
  }, [filters, getReport]);

  useEffect(() => {
    getReportData();
  }, [filters, dateFilters]);

  const handleFiltersDates = (dates, span) => {
    if (!isEmpty(dates)) {
      const { start, end } = dates;
      setFilters({
        startDate: dayjs(start).format('YYYY-MM-DD'),
        endDate: dayjs(end).format('YYYY-MM-DD'),
        span: span,
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(data?.fetchDashboardReport)) {
      setFetchDashboardReport(data?.fetchDashboardReport);
    }
  }, [data]);

  const [dateFilters, setDateFilters] = useState({
    startDate: '',
    endDate: '',
  });

  const calculateValue = (arr) => {
    let value = 0;
    arr?.map((val) => {
      value += +val?.data?.value;
    });
    return +value;
  };

  useEffect(() => {
    let subheaderdata = [
      {
        title: 'MRR',
        value: fetchDashboardReport?.mrr?.value
          ? `${fetchDashboardReport?.currencySymbol}${parseFloat(
            fetchDashboardReport?.mrr?.value
          )?.toFixed(2)}`
          : '--',
        desc: ``,
      },
      {
        title: `Active Contracts`,
        value: fetchDashboardReport?.activeSubscriptionsCount?.value || '--',
        desc: ``,
      },
      {
        title: i18n.t('upcoming_sales'),
        value: fetchDashboardReport?.upcomingSales
          ? `${fetchDashboardReport?.currencySymbol}${parseFloat(
            fetchDashboardReport?.upcomingSales
          )?.toFixed(2)}`
          : '--',
        desc: i18n.t('next_seven_days'),
      },
    ];
    setSubHeaderData([...subheaderdata]);
  }, [fetchDashboardReport]);

  return (
    <>
      <Overview
        {...{
          i18n,
          floatingSetupGuide,
          setFloatingSetupGuide,
          subHeaderData,
        }}
        customNavTabs={{ subscription_home: true }}
      />
    </>
  );
};

export default PointsProgramOverview;
