import React, { useEffect, useState } from 'react'
import {
    HomeMajor,
    SettingsMinor,
    AnalyticsMajor,
    HintMajor,
    ProfileMajor,
    InstallMinor,
    CustomersMajor,
    GiftCardMajor,
    ChevronLeftMinor,
    LogOutMinor,
    BalanceMajor,
    ImageMajor,
    EmailNewsletterMajor,
    PointOfSaleMajor,
    ReferralMajor,
    CashDollarMajor,
    StoreStatusMajor,
} from '@shopify/polaris-icons';
import {
    DashboardIcon,
    MangeIcon,
    SubscriptionIcon,
    ToolboxIcon,
    CrystalBall,
    GetStartedIcon,
    ZenBrainIcon,
    SubscriptionsIcon,
    ReplenishIcon,
    MoreIcon,
    SubscriptionContractsIcon,
    CustomersIcon,
    AnalyticsIcon,
    ZennPortalIcon,
    ThemeIcon,
    HelpdeskIcon,
    TiazenIcon,
    SmartyMessageIcon,
} from './CustomIcon';
import {
    Button,
    Frame,
    Icon,
    Select,
    Tabs,
} from '@shopify/polaris';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import mixpanel from 'mixpanel-browser';
import "./Navigation.css";
import axiosconfig from '../../Pages/axiosconfig';
import ToggleButton from 'react-toggle-button';

const Navigation = (props) => {
    const {
        navCollapse,
        setNavCollapse,
        selected,
        i18n,
        mixpanelId,
        shopVersion,
        customNavTabs,
        setCustomNavTabs,
    } = props;
    const history = useHistory();
    const urlDomain = `/${props?.domain?.replace('.myshopify.com', '')}`;
    const [revenueMoreCond, setrevenueMoreCond] = useState(true);
    const [navigations, setNavigations] = useState([]);
    const [dynamicStyle, setDynamicStyle] = useState(``);
    const [activeTab, setActiveTab] = useState();
    const [toogleMenu, setToogleMenu] = useState({ revenueChannels: false });
    const [toggleNav, setToggleNav] = useState(false);

    const setNavigation = () => {
        const getAccessTabs = () => {
            let tabs;
            try {
                tabs = JSON.parse(localStorage.getItem('accessSettings'))
            } catch (e) {
                tabs = {}
            }
            return tabs
        }
        const accessTabs = getAccessTabs();
        let navigation = [];
        let subNavItems;
        let dashSub = [];
        if (accessTabs?.dashboard_access) {
            if (accessTabs?.dashboard_access) {
                dashSub.push({
                    icon: <DashboardIcon />,
                    label: i18n.t('dashboard'),
                    url: '/',
                    onClick: () => history.push("/"),
                    selected: window.location.pathname == `${urlDomain}/` ? true : false,
                });
            }
            if (shopVersion != 'chargezen_lite') {
                dashSub.push(
                    {
                        icon: <GetStartedIcon />,
                        label: 'Revenue Recipes',
                        url: '/getStarted',
                        onClick: () => setActiveTab('dash'),
                        selected:
                            window.location.pathname == `${urlDomain}/getStarted`
                                ? true
                                : false,
                    },
                    {
                        url: `${window.location.pathname.replace(
                            urlDomain,
                            ''
                        )}?zenbrain=true`,
                        label: i18n.t('zenbrain'),
                        icon: <ZenBrainIcon />,
                        selected:
                            new URLSearchParams(window.location.search).get('zenbrain') == 'true'
                                ? true
                                : false,
                        subNavigationItems: [
                            {
                                // icon: CrystalBall,
                                label: i18n.t('yoda_revenue_copilot'),
                                url: '/YodaRevenueCopilot',
                                onClick: () => setActiveTab('dash'),
                                selected:
                                    window.location.pathname == `${urlDomain}/YodaRevenueCopilot`
                                        ? true
                                        : false,
                            },
                            {
                                // icon: AnalyticsMajor,
                                label: i18n.t('customer_segments'),
                                url: `/MLAlgorithms?corhorts_subitems=true`,
                                onClick: () => setActiveTab('dash'),
                                selected:
                                    new URLSearchParams(window.location.search).get(
                                        'corhorts_subitems'
                                    ) == 'true'
                                        ? true
                                        : false,
                                subNavigationItems: [
                                    {
                                        icon: MangeIcon,
                                        label: i18n.t('sentiment_analysis'),
                                        url: '/sentiment-analysis',
                                        selected:
                                            window.location.pathname ==
                                                `${urlDomain}/sentiment-analysis`
                                                ? true
                                                : false,
                                    },
                                    {
                                        label: i18n.t('k_means_clusters'),
                                        icon: <MangeIcon />,
                                        url: '/kMeansClusters',
                                        onClick: () => setActiveTab('kMeansClusters'),
                                        selected:
                                            window.location.pathname == `${urlDomain}/kMeansClusters`
                                                ? true
                                                : false,
                                    },
                                    {
                                        icon: <MangeIcon />,
                                        label: i18n.t('anomaly_detection'),
                                        url: '/anomaly-detection',
                                        selected:
                                            window.location.pathname ==
                                                `${urlDomain}/anomaly-detection`
                                                ? true
                                                : false,
                                    },
                                    {
                                        icon: <MangeIcon />,
                                        label: i18n.t('market_basket_analysis'),
                                        url: '/market-basket-analysis',
                                        selected:
                                            window.location.pathname ==
                                                `${urlDomain}/market-basket-analysis`
                                                ? true
                                                : false,
                                    },
                                ],
                            },
                        ],
                    }
                );
            }

            navigation.push({
                url: '',
                label: i18n.t('revenue'),
                selected: true,
                subNavigationItems: dashSub,
            });
        }
        if (activeTab === 'subscriptions') {
            subNavItems = [
                {
                    icon: <SubscriptionContractsIcon />,
                    label: 'Overview',
                    url: '/customers',
                    selected: window.location.pathname == `${urlDomain}/customers`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Contracts',
                    url: '/customersTab/contracts',
                    selected:
                        window.location.pathname == `${urlDomain}/customersTab/contracts`,
                },

            ];
            if (accessTabs?.manage_plan_access) {
                subNavItems.push({
                    icon: <Icon
                        source={ReferralMajor}
                        tone="base"
                    />,
                    label: i18n.t('subscription_plans'),
                    url: '/customersTab/subscription_plans',
                    selected:
                        window.location.pathname.includes(
                            `${urlDomain}/customersTab/subscription_plans`
                        ) ||
                            window.location.pathname.includes(
                                `${urlDomain}/fixed-subscription-plans/`
                            )
                            ? true
                            : false,
                });
            }
            subNavItems.push(
                {
                    icon: <Icon
                        source={CashDollarMajor}
                        tone="base"
                    />,
                    label: 'Products',
                    url: '/customersTab/subscription_products',
                    disabled: true,
                    selected:
                        window.location.pathname ==
                        `${urlDomain}/customersTab/subscription_products`,
                },
                {
                    icon: <Icon
                        source={<Icon
                            source={ImageMajor}
                            tone="base"
                        />}
                        tone="base"
                    />,
                    label: 'Bundles',
                    url: '/customersTab/subscriptionBundles',
                    selected:
                        window.location.pathname ==
                        `${urlDomain}/customersTab/subscriptionBundles`,
                },
                {
                    icon: <Icon
                        source={ReferralMajor}
                        tone="base"
                    />,
                    label: 'Create & import subscriptions',
                    url: '/customersTab/customerMigration',
                    disabled: true,
                    selected:
                        window.location.pathname ==
                            `${urlDomain}/customersTab/customerMigration`
                            ? true
                            : false,
                },
                {
                    icon: <Icon
                        source={BalanceMajor}
                        tone="base"
                    />,
                    label: 'Emails & SMS',
                    url: '/customersTab/subscription_email',
                    disabled: true,
                    selected:
                        window.location.pathname ==
                        `${urlDomain}/customersTab/subscription_email`,
                },
                {
                    icon: <SubscriptionContractsIcon />,
                    label: 'Quick actions ',
                    url: '/customersTab/quickActions',
                    selected: window.location.pathname.includes(
                        '/customersTab/quickActions'
                    ),
                },
                {
                    icon: <Icon
                        source={CashDollarMajor}
                        tone="base"
                    />,
                    label: 'Workflow automations',
                    url: '/customersTab/subscription_workflow',
                    selected:
                        window.location.pathname ==
                            `${urlDomain}/customersTab/subscription_workflow`
                            ? true
                            : false,
                },
                {
                    icon: <Icon
                        source={ReferralMajor}
                        tone="base"
                    />,
                    label: 'Bulk actions',
                    url: '/customersTab/bulk_action',
                    selected:
                        window.location.pathname == `${urlDomain}/customersTab/bulk_action`
                            ? true
                            : false,
                },
                {
                    icon: <Icon
                        source={ReferralMajor}
                        tone="base"
                    />,
                    label: 'Dunning',
                    url: '/customersTab/subscription_billing',
                    disabled: true,
                    selected:
                        window.location.pathname ==
                            `${urlDomain}/customersTab/subscription_billing`
                            ? true
                            : false,
                },

                {
                    icon: <Icon
                        source={ReferralMajor}
                        tone="base"
                    />,
                    label: 'Settings',
                    url: '/customersTab/global_billing_hour',
                    selected:
                        window.location.pathname ==
                            `${urlDomain}/customersTab/global_billing_hour`
                            ? true
                            : false,
                },
                {
                    icon: <Icon
                        source={ReferralMajor}
                        tone="base"
                    />,
                    label: 'Subscription widget',
                    url: '/customersTab/subscription_widget',
                    selected:
                        window.location.pathname ==
                        `${urlDomain}/customersTab/subscription_widget`
                },
            );

            navigation.push({
                url: '',
                label: (
                    <div className="sub_nav_label" onClick={() => history.push("/")}>
                        <Icon source={ChevronLeftMinor} tone="base" />
                        <span>{i18n.t('subscriptions_home')}</span>
                    </div>
                ),
                disabled: true,
                selected: true,
                subNavigationItems: subNavItems,
            });
        } else if (activeTab === 'pointsProgram') {
            if (customNavTabs?.loyalty_rewards) {
                subNavItems = [
                    {
                        icon: <SubscriptionContractsIcon />,
                        label: 'Overview',
                        url: '/pointsProgram',
                        selected: window.location.pathname == `${urlDomain}/pointsProgram`,
                    },
                    {
                        icon: <Icon
                            source={StoreStatusMajor}
                            tone="base"
                        />,
                        url: '/pointsProgram/tooglePoints',
                        disabled: false,
                        selected:
                            window.location.pathname ==
                                `${urlDomain}/pointsProgram/tooglePoints`
                                ? true
                                : false,
                        label: i18n.t('points_programs.program_status'),
                    },
                    {
                        icon: <Icon
                            source={<Icon
                                source={ImageMajor}
                                tone="base"
                            />}
                            tone="base"
                        />,
                        url: '/pointsProgram/pointsProgram',
                        disabled: false,
                        selected:
                            window.location.pathname ==
                                `${urlDomain}/pointsProgram/pointsProgram`
                                ? true
                                : false,
                        label: i18n.t('points_programs.hero_image'),
                    },
                    {
                        icon: <Icon
                            source={CashDollarMajor}
                            tone="base"
                        />,
                        url: '/pointsProgram/waysToEarn',
                        disabled: false,
                        selected:
                            window.location.pathname ==
                                `${urlDomain}/pointsProgram/waysToEarn`
                                ? true
                                : false,
                        label: i18n.t('points_programs.ways_to_earn_points'),
                    },
                    {
                        icon: <Icon
                            source={BalanceMajor}
                            tone="base"
                        />,
                        url: '/pointsProgram/waysToRedeem',
                        disabled: false,
                        selected:
                            window.location.pathname ==
                                `${urlDomain}/pointsProgram/waysToRedeem`
                                ? true
                                : false,
                        label: i18n.t('points_programs.ways_to_redeem_points'),
                    },
                    {
                        icon: <Icon
                            source={ReferralMajor}
                            tone="base"
                        />,
                        url: '/pointsProgram/referral_rewards',
                        disabled: false,
                        selected:
                            window.location.pathname ==
                                `${urlDomain}/pointsProgram/referral_rewards`
                                ? true
                                : false,
                        label: i18n.t('points_programs.referrals'),
                    },
                    customNavTabs?.ios_wallet_passes
                        ? {
                            icon: <Icon
                                source={ReferralMajor}
                                tone="base"
                            />,
                            url: '/pointsProgram/appleWalletPasses',
                            disabled: false,
                            selected:
                                window.location.pathname ==
                                    `${urlDomain}/pointsProgram/appleWalletPasses`
                                    ? true
                                    : false,
                            label: 'Apple Wallet Passess',
                        }
                        : {},
                    {
                        icon: <Icon
                            source={PointOfSaleMajor}
                            tone="base"
                        />,
                        url: '/pointsProgram/point_program_tiers',
                        disabled: false,
                        selected:
                            window.location.pathname ==
                                `${urlDomain}/pointsProgram/point_program_tiers`
                                ? true
                                : false,
                        label: i18n.t('points_programs.points_program_tiers'),
                    },
                    {
                        icon: <Icon
                            source={EmailNewsletterMajor}
                            tone="base"
                        />,
                        url: '/pointsProgram/points_emails',
                        disabled: false,
                        selected:
                            window.location.pathname ==
                                `${urlDomain}/pointsProgram/points_emails`
                                ? true
                                : false,
                        label: i18n.t('points_programs.points_program_email'),
                    },
                    {
                        icon: <Icon
                            source={EmailNewsletterMajor}
                            tone="base"
                        />,
                        url: '/pointsProgram/rewardsPage?loyalty=true',
                        disabled: false,
                        label: i18n.t('rewards_page'),
                        selected:
                            window.location.pathname ==
                                `${urlDomain}/pointsProgram/rewardsPage`
                                ? true
                                : false,
                    },
                    {
                        icon: <Icon
                            source={EmailNewsletterMajor}
                            tone="base"
                        />,
                        url: '/pointsProgram/ReferralPage?loyalty=true',
                        disabled: false,
                        label: 'Referral Page',
                        selected:
                            window.location.pathname ==
                                `${urlDomain}/pointsProgram/ReferralPage`
                                ? true
                                : false,
                    },
                    {
                        icon: <Icon
                            source={EmailNewsletterMajor}
                            tone="base"
                        />,
                        url: '/pointsProgram/RewardsLauncherSettings?loyalty=true',
                        disabled: false,
                        label: 'Rewards Launcher',
                        selected:
                            window.location.pathname ==
                                `${urlDomain}/pointsProgram/RewardsLauncherSettings`
                                ? true
                                : false,
                    },
                ];
                navigation.push({
                    label: (
                        <div className="sub_nav_label" onClick={() => history.push("/")}>
                            <Icon source={ChevronLeftMinor} tone="base" />
                            <span>{i18n.t('loyalty_rewards')}</span>
                        </div>
                    ),
                    url: '',
                    selected: true,
                    disabled: true,
                    subNavigationItems: subNavItems,
                });
            }
        } else if (activeTab === 'affiliates') {
            subNavItems = [
                {
                    icon: <SubscriptionContractsIcon />,
                    label: 'Overview',
                    url: '/affiliates',
                    selected: window.location.pathname == `${urlDomain}/affiliates`,
                },
                // {
                //   icon: StoreStatusMajor,
                //   label: 'Affiliates Status',
                //   url: '/affiliates/affiliatesStatus',
                //   selected:
                //     window.location.pathname ===
                //     `${urlDomain}/affiliates/affiliatesStatus`,
                // },
                {
                    icon: <Icon
                        source={ImageMajor}
                        tone="base"
                    />,
                    label: 'Affiliates',
                    url: '/affiliates/affiliates',
                    selected:
                        window.location.pathname === `${urlDomain}/affiliates/affiliates`,
                },
                {
                    icon: <Icon
                        source={ImageMajor}
                        tone="base"
                    />,
                    label: 'Commission Profiles',
                    url: '/affiliates/affiliate_commission',
                    selected:
                        window.location.pathname ===
                        `${urlDomain}/affiliates/affiliate_commission`,
                },
                {
                    icon: <Icon
                        source={ImageMajor}
                        tone="base"
                    />,
                    label: 'Affiliate Orders',
                    url: '/affiliates/affiliateOrders',
                    selected:
                        window.location.pathname ===
                        `${urlDomain}/affiliates/affiliateOrders`,
                },
                // {
                //   icon: MangeIcon,
                //   label: 'Affiliates Payouts',
                //   url: '/affiliates/affiliatesPayouts',
                //   selected:
                //     window.location.pathname ===
                //     `${urlDomain}/affiliates/affiliatesPayouts`,
                // },
                // {
                //   // icon: MangeIcon,
                //   label: 'Affiliate Page',
                //   url: '/affiliates/affiliatePage',
                //   selected:
                //     window.location.pathname ===
                //     `${urlDomain}/affiliates/affiliatePage`,
                // },
                // {
                //   // icon: MangeIcon,
                //   label: 'Affiliates Home',
                //   url: '/affiliates/affiliatesHome',
                //   selected:
                //     window.location.pathname ===
                //     `${urlDomain}/affiliates/affiliatesHome`,
                // },
                // {
                //   // icon: MangeIcon,
                //   label: 'Analytics',
                //   url: '/affiliates/analytics',
                //   selected:
                //     window.location.pathname === `${urlDomain}/affiliates/analytics`,
                // },
                // {
                //   // icon: MangeIcon,
                //   label: 'Affiliate Program Setting',
                //   url: '/affiliates/affiliateProgramSetting',
                //   selected:
                //     window.location.pathname ===
                //     `${urlDomain}/affiliates/affiliateProgramSetting`,
                // },
            ];
            navigation.push({
                url: '',
                label: (
                    <div className="sub_nav_label" onClick={() => history.push("/")}>
                        <Icon source={ChevronLeftMinor} tone="base" />
                        <span>Affiliates & Influencers</span>
                    </div>
                ),

                disabled: true,
                selected: true,
                subNavigationItems: subNavItems,
            });
        } else if (activeTab === 'upsells') {
            subNavItems = [
                {
                    icon: <SubscriptionContractsIcon />,
                    label: 'Overview',
                    url: '/chargezenUpsells',
                    selected: window.location.pathname == `${urlDomain}/chargezenUpsells`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Upsell Funnels',
                    url: '/chargezenUpsells/manage',
                    selected:
                        window.location.pathname === `${urlDomain}/chargezenUpsells/manage`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Cart Funnels',
                    url: '/chargezenUpsells/cartFunnels',
                    selected:
                        window.location.pathname === `${urlDomain}/chargezenUpsells/cartFunnels`,
                },
            ];
            navigation.push({
                url: '',
                label: (
                    <div className="sub_nav_label" onClick={() => history.push("/")}>
                        <Icon source={ChevronLeftMinor} tone="base" />
                        <span>Upsells</span>
                    </div>
                ),
                disabled: true,
                selected: true,
                subNavigationItems: subNavItems,
            });
        } else if (activeTab === 'checkout') {
            subNavItems = [
                {
                    icon: <ThemeIcon />,
                    label: 'Overview',
                    url: '/checkout',
                    selected: window.location.pathname == `${urlDomain}/checkout`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Checkout extensions',
                    url: '/checkoutStyle',
                    selected: window.location.pathname === `${urlDomain}/checkoutStyle`,
                },
            ];
            navigation.push({
                url: '',
                label: (
                    <div className="sub_nav_label" onClick={() => history.push("/")}>
                        <Icon source={ChevronLeftMinor} tone="base" />
                        <span>Checkout</span>
                    </div>
                ),
                disabled: true,
                selected: true,
                subNavigationItems: subNavItems,
            });
        } else if (activeTab === 'smarty') {
            subNavItems = [
                {
                    icon: <SmartyMessageIcon />,
                    label: 'Overview',
                    url: '/smartyoverview',
                    selected: window.location.pathname == `${urlDomain}/smartyoverview`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'SMS messages',
                    url: '/smarty',
                    selected: window.location.pathname === `${urlDomain}/smarty`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Whatsapp messages',
                    url: '/whatsapp_messages',
                    selected: window.location.pathname === `${urlDomain}/whatsapp_messages`,
                },

                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Collect subscribers',
                    url: '/collect_subscribers',
                    selected: window.location.pathname === `${urlDomain}/collect_subscribers`,
                },

                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Flows',
                    url: '/Sms_flows',
                    selected: window.location.pathname === `${urlDomain}/smartysettings`,
                },

                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Integrations',
                    url: '/sms_integrations',
                    selected: window.location.pathname === `${urlDomain}/sms_integrations`,
                },

                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'SMS settings',
                    url: '/sms_settings',
                    selected: window.location.pathname === `${urlDomain}/sms_settings`,
                },
            ];
            navigation.push({
                url: '',
                label: (
                    <div className="sub_nav_label" onClick={() => history.push("/")}>
                        <Icon source={ChevronLeftMinor} tone="base" />
                        <span>SmartySMS™</span>
                    </div>
                ),
                disabled: true,
                selected: true,
                subNavigationItems: subNavItems,
            });
        } else if (activeTab === 'kioskpos') {
            subNavItems = [
                {
                    icon: <ThemeIcon />,
                    label: 'Overview',
                    url: '/kioskpos',
                    selected: window.location.pathname == `${urlDomain}/kioskpos`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Kiosk orders',
                    url: '/kioskposOrders',
                    selected: window.location.pathname === `${urlDomain}/kioskposOrders`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Menu templates',
                    url: '/kioskposmenuTemplates',
                    selected:
                        window.location.pathname === `${urlDomain}/kioskposmenuTemplates`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Reservations',
                    url: '/kioskposreservations',
                    selected:
                        window.location.pathname === `${urlDomain}/kioskposreservations`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Zip code',
                    url: '/kioskposzipcode',
                    selected: window.location.pathname === `${urlDomain}/kioskposzipcode`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Settings',
                    url: '/kioskpossettings',
                    selected:
                        window.location.pathname === `${urlDomain}/kioskpossettings`,
                },
            ];
            navigation.push({
                url: '',
                label: (
                    <div className="sub_nav_label" onClick={() => history.push("/")}>
                        <Icon source={ChevronLeftMinor} tone="base" />
                        <span>Kiosk</span>
                    </div>
                ),
                disabled: true,
                selected: true,
                subNavigationItems: subNavItems,
            });
        } else if (activeTab === 'replenishme') {
            subNavItems = [
                {
                    icon: <ThemeIcon />,
                    label: 'Overview',
                    url: '/replenishme',
                    selected: window.location.pathname == `${urlDomain}/replenishme`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Replenishment flows',
                    url: '/replenishmentflows',
                    selected:
                        window.location.pathname === `${urlDomain}/replenishmentflows`,
                },
                {
                    icon: <Icon
                        source={StoreStatusMajor}
                        tone="base"
                    />,
                    label: 'Replenishment orders',
                    url: '/replenishmentorders',
                    selected:
                        window.location.pathname === `${urlDomain}/replenishmentorders`,
                },
            ];
            navigation.push({
                url: '',
                label: (
                    <div className="sub_nav_label" onClick={() => history.push("/")}>
                        <Icon source={ChevronLeftMinor} tone="base" />
                        <span>Replenishme</span>
                    </div>
                ),
                disabled: true,
                selected: true,
                subNavigationItems: subNavItems,
            });
        } else {
            let subscriptionSub = [];
            if (accessTabs?.manage_plan_access) {
                //Manage tabs settings
                if (customNavTabs?.subscription_home) {
                    subscriptionSub.push({
                        url: '/customers?subscription_home=true',
                        label: i18n.t('subscriptions_home'),
                        icon: <SubscriptionContractsIcon />,
                        selected:
                            new URLSearchParams(window.location.search).get('subscription_home') ==
                                'true'
                                ? true
                                : false,
                    });
                }

                if (customNavTabs?.gift_card_store_credit) {
                    subscriptionSub.push({
                        url: '/giftCard',
                        label: 'Gift cards & store credit',
                        selected: window.location.href.includes('giftCard'),
                        icon: <SubscriptionContractsIcon />,
                        subNavigationItems: [
                            {
                                url: '/giftCard',
                                disabled: false,
                                selected: window.location.pathname == `${urlDomain}/giftCard`,
                                label: 'Gift card popup template',
                            },
                            {
                                url: '/giftCard/giftCardProduct',
                                disabled: false,
                                selected:
                                    window.location.pathname == `${urlDomain}/giftCard/giftCard`,
                                label: 'Gift card product',
                            },
                            {
                                url: '/giftCard/storeCredit',
                                disabled: false,
                                selected:
                                    window.location.pathname ==
                                    `${urlDomain}/giftCard/storeCredit`,
                                label: 'Store credit',
                            },
                            {
                                url: '/giftCard/analytics',
                                disabled: false,
                                selected:
                                    window.location.pathname == `${urlDomain}/giftCard/analytics`,
                                label: 'Analytics',
                            },
                        ],
                    });
                }
                if (customNavTabs?.loyalty_rewards) {
                    subscriptionSub.push({
                        label: i18n.t('loyalty_rewards'),
                        icon: <Icon
                            source={GiftCardMajor}
                            tone="base"
                        />,
                        url: `/pointsProgram`,
                        selected: window.location.pathname.includes('/pointsProgram'),
                    });
                }
                if (customNavTabs?.replenish_me) {
                    subscriptionSub.push({
                        icon: <ReplenishIcon />,
                        label: i18n.t('rebuy_cart'),
                        url: '/rebuy',
                        onClick: () => setActiveTab('dash'),
                        selected:
                            window.location.pathname == `${urlDomain}/rebuy` ? true : false,
                    });
                }
                if (shopVersion != 'chargezen_lite') {
                    subscriptionSub.push({
                        url: `${window.location.pathname.replace(
                            urlDomain,
                            ''
                        )}?revenue_more=true`,
                        label: 'More',
                        icon: <MoreIcon />,
                        selected:
                            new URLSearchParams(window.location.search).get('revenue_more') == 'true'
                                ? true
                                : false,
                        subNavigationItems: [
                            {
                                // icon: MangeIcon,
                                label: i18n.t('memberships'),
                                url: '/memberships?revenue_more=true',
                                selected:
                                    window.location.pathname == `${urlDomain}/memberships`
                                        ? true
                                        : false,
                            },
                            customNavTabs?.pre_orders
                                ? {
                                    // icon: MangeIcon,
                                    label: i18n.t('pre_orders'),
                                    url: '/preOrders?revenue_more=true',
                                    selected:
                                        window.location.pathname == `${urlDomain}/preOrders`
                                            ? true
                                            : false,
                                }
                                : {},
                            customNavTabs?.try_before_you_buy
                                ? {
                                    // icon: MangeIcon,
                                    label: i18n.t('try_before_you_buy'),
                                    url: '/tryBeforeYouBuy?revenue_more=true',
                                    selected:
                                        window.location.pathname == `${urlDomain}/tryBeforeYouBuy`
                                            ? true
                                            : false,
                                }
                                : {},
                        ],
                    });
                }

                if (customNavTabs?.upsells) {
                    subscriptionSub.push({
                        icon: <MangeIcon />,
                        label: 'Upsells',
                        url: '/chargezenUpsells',
                        selected:
                            window.location.pathname == `${urlDomain}/chargezenUpsells`
                                ? true
                                : false,
                    });
                }

                if (customNavTabs?.checkout_extensions) {
                    subscriptionSub.push({
                        icon: <ThemeIcon />,
                        label: 'Checkout',
                        url: '/checkout',
                        onClick: () => setActiveTab('checkout'),
                        selected:
                            window.location.pathname == `${urlDomain}/checkout`
                                ? true
                                : false,
                    });
                }

                if (customNavTabs?.sms_smarty) {
                    subscriptionSub.push({
                        icon: <SmartyMessageIcon />,
                        label: 'SmartySMS™',
                        url: '/smartyoverview',
                        onClick: () => setActiveTab('smarty'),
                        selected:
                            window.location.pathname == `${urlDomain}/smartyoverview`
                                ? true
                                : false,
                    });
                }

                if (customNavTabs?.kiosk_pos) {
                    subscriptionSub.push({
                        icon: <SmartyMessageIcon />,
                        label: 'Kiosk',
                        url: '/kioskpos',
                        onClick: () => setActiveTab('kioskpos'),
                        selected: window.location.pathname == `${urlDomain}/kioskpos`,
                    });
                }
                if (customNavTabs?.replenish_me) {
                    subscriptionSub.push({
                        icon: <SmartyMessageIcon />,
                        label: 'Replenishme',
                        url: '/replenishme',
                        onClick: () => setActiveTab('replenishme'),
                        selected: window.location.pathname == `${urlDomain}/replenishme`,
                    });
                }

                //AffiliateInfluencer
                if (customNavTabs?.affiliate_influencer) {
                    subscriptionSub.push({
                        url: '/affiliates',
                        label: 'Affiliates & Influencers',
                        icon: <SubscriptionContractsIcon />,
                        selected: window.location.pathname.includes('affiliates'),
                    });
                }
            }

            navigation.push({
                url: '',
                label: i18n.t('revenue_channels'),
                disabled: true,
                selected: true,
                onClick: () =>
                    setToogleMenu({
                        ...toogleMenu,
                        revenueChannels: !toogleMenu?.revenueChannels,
                    }),
                subNavigationItems: subscriptionSub,
            });

            if (
                accessTabs?.subscription_orders_access ||
                accessTabs?.analytics_access
            ) {
                navigation.push({
                    url: '',
                    label: i18n.t('workspace'),
                    disabled: true,
                    selected: true,
                    subNavigationItems: [
                        accessTabs?.customer_modal
                            ? {
                                label: i18n.t('subscription_customers'),
                                icon: <CustomersIcon />,
                                url: '/customer-model',
                                onClick: () => setActiveTab('customerModal'),
                                selected:
                                    window.location.pathname == `${urlDomain}/customer-model`
                                        ? true
                                        : false,
                            }
                            : {},
                        accessTabs?.analytics_access
                            ? {
                                label: i18n.t('analytics'),
                                icon: <AnalyticsIcon />,
                                url: '/analytics',
                                onClick: () => setActiveTab('analytics'),
                                selected:
                                    window.location.pathname == `${urlDomain}/analytics`
                                        ? true
                                        : false,
                            }
                            : {},
                        accessTabs?.tiazen_access && shopVersion != 'chargezen_lite'
                            ? {
                                label: i18n.t('tiazen'),
                                icon: <TiazenIcon />,
                                url: '/tiazen',
                                onClick: () => setActiveTab('tiazen'),
                                selected:
                                    window.location.pathname == `${urlDomain}/tiazen`
                                        ? true
                                        : false,
                            }
                            : {},
                    ],
                });
            }

            navigation.push({
                url: '',
                label: 'STOREFRONT',
                disabled: true,
                selected: true,
                subNavigationItems: [
                    {
                        icon: <ZennPortalIcon />,
                        label: 'Customer Portal',
                        url: '/zenn',
                        onClick: () => setActiveTab('zenn'),
                        selected: window.location.pathname.includes(`${urlDomain}/zenn`)
                            ? true
                            : false,
                        // subNavigationItems: [
                        //   {
                        //     url: '/zenn/cancellationflow',
                        //     disabled: false,
                        //     selected:
                        //       window.location.pathname ==
                        //       `${urlDomain}/zenn/cancellationflow`
                        //         ? true
                        //         : false,
                        //     label: 'Cancellation flow',
                        //   },
                        //   {
                        //     url: '/zenn/passwordlessLogin',
                        //     disabled: false,
                        //     selected:
                        //       window.location.pathname ==
                        //       `${urlDomain}/zenn/passwordlessLogin`
                        //         ? true
                        //         : false,
                        //     label: 'Passwordless login',
                        //   },
                        // ],
                    },
                    // {
                    //   icon: ThemeIcon,
                    //   label: 'Theme Extensions',
                    //   url: '/theme',
                    //   onClick: () => setActiveTab('theme'),
                    //   selected:
                    //     window.location.pathname == `${urlDomain}/theme` ? true : false,
                    // },
                    {
                        icon: <MangeIcon />,
                        label: 'Bundles ',
                        url: '/bundle',
                        selected:
                            window.location.pathname == `${urlDomain}/bundles` ? true : false,
                    },
                    shopVersion != 'chargezen_lite'
                        ? {
                            icon: <MangeIcon />,
                            label: i18n.t('quizes'),
                            url: '/quizes',
                            selected:
                                window.location.pathname == `${urlDomain}/quizes`
                                    ? true
                                    : false,
                        }
                        : {},
                ],
            });

            if (
                accessTabs?.installation_access ||
                accessTabs?.tiazen_access ||
                accessTabs?.toolbox_access
            ) {
                navigation.push({
                    url: '',
                    label: i18n.t('general'),
                    selected: true,
                    disabled: true,
                    subNavigationItems: [
                        accessTabs?.installation_access
                            ? {
                                label: i18n.t('installation'),
                                icon: <Icon
                                    source={InstallMinor}
                                    tone="base"
                                />,
                                url: '/installation',
                                onClick: () => setActiveTab('inst'),
                                selected:
                                    window.location.pathname == `${urlDomain}/installation`
                                        ? true
                                        : false,
                            }
                            : {},

                        accessTabs?.toolbox_access
                            ? {
                                label: 'Integrations',
                                icon: <ToolboxIcon />,
                                url: '/toolbox/integerationsMain',
                                onClick: setActiveTab('tool'),
                                selected:
                                    window.location.pathname ==
                                        `${urlDomain}/toolbox/integerationsMain`
                                        ? true
                                        : false,
                            }
                            : {},
                        shopVersion != 'chargezen_lite'
                            ? {
                                label: 'Help Desk',
                                icon: <HelpdeskIcon />,
                                url: '/helpdesk',
                                onClick: () => setActiveTab('settings'),
                                selected:
                                    window.location.pathname == `${urlDomain}/helpdesk`
                                        ? true
                                        : false,
                            }
                            : {},
                    ],
                });
            }
        }
        if (accessTabs?.settings_access) {
            navigation.push({
                url: '',
                label: '',
                selected: true,
                disabled: true,
                subNavigationItems: [
                    accessTabs?.settings_access
                        ? {
                            label: i18n.t('settings'),
                            icon: <Icon
                                source={SettingsMinor}
                                tone="base"
                            />,
                            url: '/app-settings',
                            onClick: () => setActiveTab('settings'),
                            selected: window.location.pathname.includes(
                                `${urlDomain}/app-settings`
                            )
                                ? true
                                : false,
                        }
                        : {},
                ],
            });
            setNavigations([...navigation]);
        }
    };

    //Mixpanel
    const [currentMixpanel, setCurrentMixpanel] = useState();
    //Initialise Mixpanel
    mixpanel.init(mixpanelId, {
        debug: true,
        loaded: function () {
            setCurrentMixpanel(mixpanel.get_distinct_id());
        },
    });
    useEffect(() => {
        setNavigation();
    }, [window.location.href, customNavTabs, activeTab]);

    const getMixpanelUser = () => {
        axiosconfig.get(`/getMixpanelUser/${currentMixpanel}`).then(({ data }) => {
            let event = window.location.pathname.split('/').pop();
            event = event ? event : 'Admin Dashboard';
            if (data?.user) {
                mixpanel.track(`Admin ${event}`, {
                    shop: `${props?.domain}`,
                    userEmail: `${data?.user}`,
                });
            } else {
                mixpanel.track(`Admin ${event}`, {
                    shop: `${props?.domain}`,
                    userEmail: `${localStorage.getItem('currentuser')}`,
                });
            }
        });
    };
    const setActiveTabByPath = () => {
        if (window.location.pathname.includes('/customers')) {
            setActiveTab('subscriptions');
        } else if (window.location.pathname.includes('/pointsProgram')) {
            setActiveTab('pointsProgram');
        } else if (window.location.pathname.includes('affiliates')) {
            setActiveTab('affiliates');
        } else if (
            window.location.pathname?.toLowerCase().includes('/chargezenupsells')
        ) {
            setActiveTab('upsells');
        } else if (window.location.pathname.includes('checkout')) {
            setActiveTab('checkout');
        } else if (window.location.pathname.includes('smarty')) {
            setActiveTab('smarty');
        } else if (window.location.pathname.includes('/kioskpos')) {
            setActiveTab('kioskpos');
        } else if (window.location.pathname.includes('replenishme')) {
            setActiveTab('replenishme');
        } else {
            setActiveTab();
        }
    };
    useEffect(() => {
        setActiveTabByPath();
        if (currentMixpanel) {
            getMixpanelUser();
        }
    }, [window.location.pathname]);

    return (
        <div className={`chargezen_navbar ${toggleNav ? "opened" : ""}`}>
            <div className='chargezen_nav_contents'>
                <div className='toggle_nav_button'>
                    <ToggleButton
                        inactiveLabel={''}
                        activeLabel={''}
                        colors={{
                            active: {
                                base: '#001219',
                                hover: '#001219ba',
                            }
                        }}
                        value={toggleNav}
                        onToggle={() => {
                            setToggleNav(!toggleNav);
                        }}
                        onClick={() => {
                            setToggleNav(!toggleNav)
                        }}
                    />
                </div>
                {
                    navigations?.map((navItem) => {
                        return (
                            <div className='nav_list_item'>
                                <h2 className='nav_item_header'>{navItem?.label}</h2>
                                <ul className='subNavItems'>
                                    {
                                        navItem?.subNavigationItems?.map((subNav) => {
                                            return (
                                                <li className='subnav_list' onClick={() => { history.push(subNav.url) }}>
                                                    <div className='nav_icon_wrapper'>
                                                        {subNav?.icon}
                                                    </div>
                                                    <span className='nav_text'>{subNav?.label}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default Navigation