import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Card,
  Toast,
  ChoiceList,
  Page,
  PageActions,
  TextField,
  Layout,
  Banner,
  List,
  Frame,
  Select,
  Icon,
  Checkbox,
  RadioButton,
  DropZone,
  Stack,
  Thumbnail,
  Caption,
  TextContainer,
} from '@shopify/polaris';
import PixelIcon from '../../assets/images/PixelIcon';
import { MobileBackArrowMajor, NoteMinor } from '@shopify/polaris-icons';
import SearchProduct from '../upsell/SearchProduct';
import Preview from '../plans/Preview';
import './waysToEarnPoint.css';
import SummaryFullWidthStyle from '../../components/layout/SummaryFullWidthStyle';
import { useHistory } from 'react-router-dom';

const EditWaysToEarn = ({
  formData,
  setFormData,
  setEditForm,
  refetch,
  formErrors,
  setFormErrors,
  saveSuccess,
  setSaveSuccess,
  titleOptions,
  i18n,
  allProducts,
  setAllProducts,
}) => {
  const [icon, setIcon] = useState();
  const [defaultIcon, setDefaultIcon] = useState('default');
  const [fieldError, setFieldError] = useState({
    title: '',
    pointsAwarded: '',
  });
  const [defaultIcons, setDefaultIcons] = useState([]);
  const [base64Icon, setBase64Icon] = useState();

  const [file, setFile] = useState();

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) => setFile(acceptedFiles[0]),
    []
  );


  useEffect(() => {
    if (!formData?.title) {
      setFormData({ ...formData, title: titleOptions[0]?.value || 'Place an Order' })
    }
  }, [titleOptions])

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFile = file && (
    <div style={{ padding: '0' }}>
      <Stack vertical spacing="5">
        <Stack alignment="center" key={1}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{' '}
            <TextContainer>
              <p>{file.size} bytes</p>
            </TextContainer>
          </div>
        </Stack>
      </Stack>
    </div>
  );

  useEffect(() => {
    if (formData?.iconUrl) {
      setDefaultIcon('upload');
    }
  }, [formData?.iconUrl]);

  const fetchDefaultIcons = () => {
    fetch('/fetch_default_icons', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.icons?.length > 0) {
          setDefaultIcons([...data?.icons]);
        }
      });
  };

  useEffect(() => {
    fetchDefaultIcons();
  }, []);

  const ADD_WAYS_TO_EARN = gql`
    mutation ($input: AddWaysToEarnPointsInput!) {
      addWaysToEarnPoints(input: $input) {
        waysToEarnPoints {
          id
        }
      }
    }
  `;
  const [addWaysToEarn] = useMutation(ADD_WAYS_TO_EARN);

  const [updated, setUpdated] = useState(false);

  const convertToBase64 = () => {
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setBase64Icon(reader.result);
      };
    }
  };

  useEffect(() => {
    convertToBase64();
  }, file);
  const handleSubmit = () => {
    let formdata = formData;
    formdata.icon = base64Icon;
    addWaysToEarn({
      variables: {
        input: { params: formdata },
      },
    })
      .then((resp) => {
        const data = resp.data;
        const errors = data.errors;

        if (errors) {
          setFormErrors(errors);
        } else {
          setSaveSuccess(true);
          refetch();
          setEditForm(false);
          history.push('/pointsProgram/waysToEarn');
        }
      })
      .catch((error) => {
        setFormErrors(error);
      });
  };

  const deleteSubmit = () => {
    fetch(`/ways_to_earn_point/destroy/${formData?.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then(() => {
        refetch();
        setEditForm(false);
        history.push('/pointsProgram/waysToEarn');
      });
  };

  const [summaryText, setSummaryText] = useState();
  useEffect(() => {
    let summary;
    if (formData?.pointsDiscount) {
      summary =
        formData?.title == 'Place an Order'
          ? i18n.t('ways_to_earn.order_summary', {
            pointsAwarded: parseInt(formData?.pointsAwarded),
          })
          : formData?.title == 'Create an account'
            ? i18n.t('ways_to_earn.create_an_account_summary', {
              pointsAwarded: parseInt(formData?.pointsAwarded),
            })
            : formData?.title == 'Celebrate a birthday'
              ? i18n.t('ways_to_earn.celeberate_birthday_summary', {
                pointsAwarded: parseInt(formData?.pointsAwarded),
              })
              : formData?.title == 'Goal spend'
                ? i18n.t('ways_to_earn.goal_spend_summary', {
                  pointsAwarded: parseInt(formData?.pointsAwarded),
                  minSpend: parseInt(formData?.goalAmount),
                })
                : formData?.title == 'Repeat orders'
                  ? i18n.t('ways_to_earn.repeat_orders_summary', {
                    pointsAwarded: parseInt(formData?.pointsAwarded),
                    purchaseNum: parseInt(formData?.numOfPurchases),
                    minSpend: parseInt(formData?.minSpendPerPuchase),
                  })
                  : i18n.t('ways_to_earn.points_awarded_summary', {
                    pointsAwarded: parseInt(formData?.pointsAwarded),
                  });
    } else {
      if (formData?.discountType == 'Percentage') {
        summary =
          formData?.title == 'Place an Order'
            ? i18n.t('ways_to_earn.percent_order_summary', {
              discountAmount: parseInt(formData?.discountAmount),
            })
            : formData?.title == 'Create an account'
              ? i18n.t('ways_to_earn.percent_create_an_account_summary', {
                discountAmount: parseInt(formData?.discountAmount),
              })
              : formData?.title == 'Celebrate a birthday'
                ? i18n.t('ways_to_earn.percent_celeberate_birthday_summary', {
                  discountAmount: parseInt(formData?.discountAmount),
                })
                : formData?.title == 'Goal spend'
                  ? i18n.t('ways_to_earn.percent_goal_spend_summary', {
                    discountAmount: parseInt(formData?.discountAmount),
                    minSpend: parseInt(formData?.goalAmount),
                  })
                  : formData?.title == 'Repeat orders'
                    ? i18n.t('ways_to_earn.percent_repeat_orders_summary', {
                      discountAmount: parseInt(formData?.discountAmount),
                      purchaseNum: parseInt(formData?.numOfPurchases),
                      minSpend: parseInt(formData?.minSpendPerPuchase),
                    })
                    : i18n.t('ways_to_earn.percent_points_awarded_summary', {
                      discountAmount: parseInt(formData?.discountAmount),
                    });
      } else {
        summary =
          formData?.title == 'Place an Order'
            ? i18n.t('ways_to_earn.amount_order_summary', {
              discountAmount: parseInt(formData?.discount_amount),
            })
            : formData?.title == 'Create an account'
              ? i18n.t('ways_to_earn.amount_create_an_account_summary', {
                discountAmount: parseInt(formData?.discountAmount),
              })
              : formData?.title == 'Celebrate a birthday'
                ? i18n.t('ways_to_earn.amount_celeberate_birthday_summary', {
                  discountAmount: parseInt(formData?.discountAmount),
                })
                : formData.title == 'Create an account'
                  ? i18n.t('ways_to_earn.amount_create_an_account_summary', {
                    discountAmount: parseInt(formData?.discountAmount),
                  })
                  : formData?.title == 'Goal spend'
                    ? i18n.t('ways_to_earn.amount_goal_spend_summary', {
                      discountAmount: parseInt(formData?.discountAmount),
                      minSpend: parseInt(formData?.goalAmount),
                    })
                    : formData?.title == 'Repeat orders'
                      ? i18n.t('ways_to_earn.amount_repeat_orders_summary', {
                        discountAmount: parseInt(formData?.discountAmount),
                        purchaseNum: parseInt(formData?.numOfPurchases),
                        minSpend: parseInt(formData?.minSpendPerPuchase),
                      })
                      : i18n.t('ways_to_earn.amount_points_awarded_summary', {
                        discountAmount: parseInt(formData?.discountAmount),
                      });
      }
    }
    setSummaryText(summary);

  }, [
    formData?.title,
    formData?.pointsAwarded,
    formData?.discountAmount,
    formData?.discountType,
    formData?.pointsDiscount,
    formData?.minSpendPerPuchase,
    formData?.goalAmount,
    formData?.numOfPurchases,
  ]);

  const handleChange = useCallback((_, newValue) => {
    setDefaultIcon(newValue);
    setFile();
  }, []);
  const history = useHistory();

  useState(() => {
    setFormData({ ...formData, summary: summaryText });
  }, [summaryText])

  return (
    <Frame>
      {/* <Page
        title={
          <div className="back_button_wrapper">
            <div
              className="back-button pointer"
              style={{ marginTop: '15px' }}
              onClick={() => {
                setEditForm(false);
                history.push('/pointsProgram/waysToEarn');
              }}
            >
              <Icon source={MobileBackArrowMajor} color="base" />
            </div>
            <div class="text_wrapper">
              {`${
                formData?.id
                  ? i18n.t('ways_to_earn.edit_ways_to_earn_points')
                  : i18n.t('ways_to_earn.add_ways_to_earn_points')
              }`}
            </div>
          </div>
        }
      > */}
      <Layout>
        <Layout.Section>
          <Card
            title={
              <div className="heading_title">
                <PixelIcon />
                {i18n.t('ways_to_earn.earning_value')}
              </div>
            }
            sectioned
          >
            <div className="earningValues">
              <Select
                label="Title"
                options={titleOptions}
                onChange={(e) => {
                  setFormData({ ...formData, title: e });
                }}
                value={formData?.title}
              />
              {formData?.title === 'Goal spend' && (
                <>
                  <TextField
                    label={'Goal amount'}
                    helpText="Minimum spent/goal amount"
                    type="number"
                    prefix="$"
                    value={`${formData?.goalAmount}`}
                    onChange={(e) => {
                      if (e.length > 0 && +e <= 0) {
                        setFieldError({
                          ...fieldError,
                          pointsAwarded: i18n.t('It should be greater than 0'),
                        });
                        setFormData({ ...formData, goalAmount: '1' });
                      } else {
                        setFieldError({ ...fieldError, goalAmount: '' });
                        setFormData({ ...formData, goalAmount: `${e}` });
                      }
                    }}
                    error={fieldError?.goalAmount}
                    autoComplete="off"
                  />
                </>
              )}
              {formData?.title === 'Repeat orders' && (
                <>
                  <TextField
                    label={'Number of purchases'}
                    type="number"
                    value={`${formData?.numOfPurchases}`}
                    onChange={(e) => {
                      if (e.length > 0 && +e < 0) {
                        setFieldError({
                          ...fieldError,
                          numOfPurchases: i18n.t(
                            'It should be greater/equal to 0'
                          ),
                        });
                        setFormData({ ...formData, numOfPurchases: '1' });
                      } else {
                        setFieldError({ ...fieldError, numOfPurchases: '' });
                        setFormData({ ...formData, numOfPurchases: `${e}` });
                      }
                    }}
                    error={fieldError?.numOfPurchases}
                    autoComplete="off"
                  />
                  <TextField
                    label={'Minimum spend per purchase'}
                    type="number"
                    prefix="$"
                    value={`${formData?.minSpendPerPuchase}`}
                    onChange={(e) => {
                      if (e.length > 0 && +e <= 0) {
                        setFieldError({
                          ...fieldError,
                          minSpendPerPuchase: 'It should be greater than 0',
                        });
                        setFormData({ ...formData, minSpendPerPuchase: '1' });
                      } else {
                        setFieldError({
                          ...fieldError,
                          minSpendPerPuchase: '',
                        });
                        setFormData({
                          ...formData,
                          minSpendPerPuchase: `${e}`,
                        });
                      }
                    }}
                    error={fieldError?.minSpendPerPuchase}
                    autoComplete="off"
                  />
                </>
              )}
              {formData?.title !== 'Buy a subscription' && (
                <div className="buy_subscription_checkboxes">
                  <Checkbox
                    label={i18n.t('ways_to_earn.points_discount')}
                    checked={formData?.pointsDiscount}
                    onChange={(newChecked) =>
                      setFormData({
                        ...formData,
                        pointsDiscount: newChecked,
                      })
                    }
                  />
                  <Checkbox
                    label={i18n.t('ways_to_earn.amount_discount')}
                    checked={formData?.pointsDiscount ? false : true}
                    onChange={(newChecked) =>
                      newChecked
                        ? setFormData({
                          ...formData,
                          pointsDiscount: false,
                        })
                        : setFormData({
                          ...formData,
                          pointsDiscount: true,
                        })
                    }
                  />
                </div>
              )}
              {formData?.pointsDiscount ? (
                <>
                  <TextField
                    label={
                      formData?.title == 'Place an Order' ? (
                        <div className="order_summary">
                          {i18n.t('ways_to_earn.points_for_1')}
                        </div>
                      ) : (
                        i18n.t('ways_to_earn.points_awarded')
                      )
                    }
                    type="number"
                    value={`${formData?.pointsAwarded}`}
                    onChange={(e) => {
                      if (e.length > 0 && +e <= 0) {
                        setFieldError({
                          ...fieldError,
                          pointsAwarded: i18n.t(
                            'ways_to_earn.points_must_be_greater_than_0s'
                          ),
                        });
                        setFormData({ ...formData, pointsAwarded: '1' });
                      } else {
                        setFieldError({ ...fieldError, pointsAwarded: '' });
                        setFormData({ ...formData, pointsAwarded: `${e}` });
                      }
                    }}
                    error={fieldError?.pointsAwarded}
                    autoComplete="off"
                  />
                </>
              ) : (
                <>
                  <div className="amount_discount_wrapper">
                    <Select
                      label="Discount Type"
                      options={['Fixed amount', 'Percentage']}
                      onChange={(e) => {
                        setFormData({ ...formData, discountType: e });
                      }}
                      value={formData?.discountType}
                    />
                    <TextField
                      label={'Amount Discount'}
                      type="number"
                      value={`${formData?.discountAmount}`}
                      prefix={
                        formData?.discountType == 'Fixed amount' ? '$' : ''
                      }
                      suffix={formData?.discountType == 'Percentage' ? '%' : ''}
                      onChange={(e) => {
                        if (e.length > 0 && +e <= 0) {
                          setFieldError({
                            ...fieldError,
                            pointsAwarded: i18n.t(
                              'ways_to_earn.points_must_be_greater_than_0s'
                            ),
                          });
                          setFormData({ ...formData, discountAmount: '1' });
                        } else {
                          setFieldError({
                            ...fieldError,
                            discountAmount: '',
                          });
                          setFormData({
                            ...formData,
                            discountAmount: `${e}`,
                          });
                        }
                      }}
                      error={fieldError?.discountAmount}
                      autoComplete="off"
                    />
                  </div>
                  <div className="code_prefix_wrapper">
                    <TextField
                      label={`Code prefix`}
                      type="text"
                      value={`${formData?.codePrefix}`}
                      onChange={(e) => {
                        setFormData({ ...formData, codePrefix: `${e}` });
                      }}
                      autoComplete="off"
                    />
                  </div>
                </>
              )}

              {formData?.title == 'Buy a subscription' && (
                <div className="buy_subscription_checkboxes">
                  <Checkbox
                    label={i18n.t('ways_to_earn.all_products')}
                    checked={formData?.allProducts}
                    onChange={(newChecked) =>
                      setFormData({ ...formData, allProducts: newChecked })
                    }
                  />
                  <Checkbox
                    label={i18n.t('ways_to_earn.select_specific_products')}
                    checked={formData?.allProducts ? false : true}
                    onChange={(newChecked) =>
                      newChecked
                        ? setFormData({ ...formData, allProducts: false })
                        : setFormData({ ...formData, allProducts: true })
                    }
                  />
                </div>
              )}
            </div>
          </Card>

          {(formData?.title == 'Follow us on facebook' ||
            formData?.title == 'Follow us on instagram') && (
              <Card
                title={
                  <div className="heading_title">
                    <PixelIcon />
                    {i18n.t('ways_to_earn.social_links')}
                  </div>
                }
                sectioned
              >
                <div className="earningValues">
                  <TextField
                    label={
                      formData?.title == 'Follow us on facebook'
                        ? i18n.t('ways_to_earn.follow_facebook')
                        : i18n.t('ways_to_earn.insta_username')
                    }
                    type="Text"
                    value={`${formData?.socialLink}`}
                    placeHolder={
                      formData?.title == 'Follow us on facebook'
                        ? 'facebook.com/'
                        : ''
                    }
                    onChange={(e) => {
                      setFormData({ ...formData, socialLink: `${e}` });
                    }}
                    error={fieldError?.socialLink}
                    autoComplete="off"
                  />
                </div>
              </Card>
            )}

          {formData?.title == 'Buy a Bundle' && (
            <Card
              title={i18n.t('ways_to_earn.select_bundle_product')}
              sectioned
            >
              <div className="product-search">
                <SearchProduct
                  value={formData.products || [[]]}
                  setFieldValue={setFormData}
                  fieldName={`products`}
                  allProducts={allProducts || [[]]}
                  setAllProducts={setAllProducts}
                  i18n={i18n}
                  setFormData={setFormData}
                  formData={formData}
                />
              </div>
              <Preview
                isUpdate={false}
                allProducts={allProducts || [[]]}
                setAllProducts={setAllProducts}
                setUpdated={setUpdated}
                i18n={i18n}
              />
            </Card>
          )}
          {formData?.title == 'Buy a subscription' &&
            !formData?.allProducts && (
              <Card title="Select Product" sectioned>
                <div className="product-search">
                  <SearchProduct
                    value={formData.products || [[]]}
                    setFieldValue={setFormData}
                    fieldName={`products`}
                    allProducts={allProducts || [[]]}
                    setAllProducts={setAllProducts}
                    i18n={i18n}
                    setFormData={setFormData}
                    formData={formData}
                  />
                </div>
                <Preview
                  isUpdate={false}
                  allProducts={allProducts || [[]]}
                  setAllProducts={setAllProducts}
                  setUpdated={setUpdated}
                  i18n={i18n}
                />
              </Card>
            )}
        </Layout.Section>
        <div className="defauleLayoutSection">
          <Layout.Section secondary>
            <Card title="Summary">
              <Card.Section>
                {/* <TextField
                                    multiline={2}
                                    value={formData?.summary}
                                    onChange={(e) => setFormData({ ...formData, summary: e })}
                                    autoComplete="off"
                                /> */}
                <List type="bullet">
                  <List.Item>{summaryText}</List.Item>
                </List>
              </Card.Section>
              {/* <Card.Section subdued title="STATUS">
                                    <ChoiceList
                                        title=""
                                        choices={[
                                            { label: 'Active', value: true },
                                            { label: 'Disabled', value: false },
                                        ]}
                                        selected={[formData?.status]}
                                        onChange={(e) => setFormData({ ...formData, status: e[0] })}
                                    />
                                </Card.Section> */}
            </Card>
            <Card title="Icon" sectioned>
              <div
                className="radioWrapper"
                style={{ display: 'flex', gap: '10px', flexFlow: 'column' }}
              >
                <RadioButton
                  label="Default"
                  checked={defaultIcon === 'default'}
                  id="default"
                  name="icon"
                  onChange={handleChange}
                />
                {defaultIcon == 'default' && (
                  <div className="icon_wrapper">
                    <img
                      src={
                        defaultIcons[0]?.find(
                          (val) => val?.for == formData?.title
                        )?.icon
                      }
                      style={{ width: '40px' }}
                    />
                  </div>
                )}
                <RadioButton
                  label={i18n.t('upload_own_icon')}
                  checked={defaultIcon === 'upload'}
                  id="upload"
                  name="icon"
                  onChange={handleChange}
                />
                {formData?.iconUrl && defaultIcon == 'upload' && (
                  <div className="icon_wrapper">
                    <img src={formData?.iconUrl} style={{ width: '40px' }} />
                  </div>
                )}
                {defaultIcon == 'upload' && (
                  <DropZone
                    allowMultiple={false}
                    onDrop={handleDropZoneDrop}
                    type="image"
                  >
                    {uploadedFile}
                    {fileUpload}
                  </DropZone>
                )}
              </div>
            </Card>
          </Layout.Section>
        </div>
        <Layout.Section>
          <PageActions
            primaryAction={{
              content: 'Save',
              onClick: () => handleSubmit(),
            }}
            secondaryActions={[
              {
                content: 'Delete',
                onClick: () => deleteSubmit(),
                destructive: true,
              },
            ]}
          />
        </Layout.Section>
      </Layout>
      {/* </Page> */}
    </Frame>
  );
};

export default EditWaysToEarn;
